import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {TYPE_LOGIN_SUCCESS, TYPE_LOGOUT, TYPE_RELOAD_USER} from "../actions";
import authServices from "../../services/auth-services";

const authUser = authServices.getAuthUser();

const INIT_STATE = {
    isLoggedIn: !!authUser?.role,
    isSuperAdmin: authUser?.role && authUser?.role === 'SuperAdmin',
    isParent: authUser?.role === 'Parent',
    isTeacher: authUser?.role === 'Teacher',
    authUser
};

const authFunction = (state = INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case TYPE_LOGIN_SUCCESS: {
            return {
                ...state,
                authUser: action.payload,
                isLoggedIn: !!action?.payload?.email,
                isSuperAdmin: authUser?.payload?.role === 'SuperAdmin',
                isParent: authUser?.payload?.role === 'Parent',
                isTeacher: authUser?.payload?.role === 'Teacher',
            }
        }
        case TYPE_LOGOUT: {
            console.log('authUser', state.authUser)
            return {
                ...state,
                authUser: undefined,
                isLoggedIn: false,
                isSuperAdmin: undefined,
                isParent: undefined,
                isTeacher: undefined,
            }
        }
        case TYPE_RELOAD_USER: {

            const authUser = authServices.getAuthUser();
            // authUser.completedStep3 = true;
            return {
                ...state,
                // authUser: authServices.getAuthUser,
                authUser: authUser
            }
        }
        default: {
            return state;
        }
    }
}

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        auth: authFunction
    });
};

export default exportReducers;

