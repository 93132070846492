import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import {Form, Formik} from "formik";
import {LANGUAGES} from "../../../../utils/constants/appActions";
import Select from "@mui/material/Select";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import DDImageField from "../../../../component/DDImageField";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step1 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    const [isLoading] = React.useState(false);
    const {mutate: loadLevels, data: levels,} = useAppMutation(adminServices.loadLevels);
    const {t} = useTranslation();
    const [values, setValues] = React.useState({
        name: 'Program 1',
        level: 'A1',
        language: 'EN',
        profileImage: '',
        description: 'Program 1 Description',
        newFile: ''
    });
    const [errMsg, setErrMsg] = React.useState('');

    React.useEffect(() => {

        if (!levels) {
            loadLevels();
        }

    }, [levels])

    useEffect(() => {


        if (stepsData?.name) {


            setValues({...stepsData});

            console.log(values);

            // setValues({...stepsData});

        }


    }, [stepsData])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep1 = async () => {
        setErrMsg('');

        const {name, language, level, description} = values;

        if (name && language && level && description) {
            setStepsData({...stepsData, ...values})

            handleNext(2);
        } else {
            setErrMsg(t("admin-program.title.fieldNotFilled"));
        }


    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'}
                        }}>
                            <TextField
                                required
                                label={t("admin-program.title.name")}
                                value={values.name}
                                onChange={handleChange('name')}

                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'}
                        }}>
                            <Autocomplete
                                required
                                freeSolo
                                id="free-solo-2-demo"
                                value={values.level}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setValues({...values, level: newValue ? newValue.trim().toUpperCase() : ''});
                                }}
                                inputValue={values.level}
                                onInputChange={(event, newInputValue) => {
                                    setValues({
                                        ...values,
                                        level: newInputValue ? newInputValue.trim().toUpperCase() : ''
                                    });
                                }}
                                options={levels}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => {
                                    // console.log(params);
                                    //  setValues({...values, level : params});
                                    return (
                                        <TextField
                                            {...params}
                                            label={t("admin-program.title.level")}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )
                                }}
                                sx={{width: '100%'}}
                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},
                            mt: 1,
                            ml: 1

                        }}>
                            <Div sx={{}}>
                                <InputLabel id="demo-simple-select-label">{t("parent-children.title.language")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    sx={{width: '95%', minWidth: '95%'}}
                                    value={values.language}
                                    label={t("admin-program.title.language")}
                                    onChange={handleChange('language')}
                                >
                                    {LANGUAGES.map(lang => (<MenuItem value={lang.code}>{lang.label}</MenuItem>))}

                                </Select>
                            </Div>

                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            ml: 2,
                        }}>
                            <Typography variant={"h5"}>{t("common.image")}</Typography>


                            {!values.newImage && values.images?.length > 0 ? (
                                    <DDImageField  name={"profile_pic"}
                                                   alt={"Program Picture"}
                                                   onFileSelection={( file) => {
                                                       console.log(file)
                                                       setValues({...values, newImage: file});
                                                   }}
                                                   sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                                   path={values.images[0]} alt={values.firstName + ' ' + values.lastName}/>) :
                                <JumboAvatarField
                                    name={"profile_pic"}
                                    src={values.newImage}
                                    alt={"Program Picture"}
                                    onFileSelection={(file) => {
                                        setValues({...values, newImage: file});
                                    }}
                                    sx={{width: 100, height: 100, margin: '0 auto 24px'}}
                                />}



                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,

                        }}>
                            <Typography sx={{ml: 2}} variant={"h5"}>{t("common.desc")}</Typography>
                            <TextField
                                required
                                multiline
                                maxRows={3}
                                minRows={3}
                                label={t("admin-program.title.backInfo")}
                                value={values.description}
                                onChange={handleChange('description')}

                            />
                        </FormControl>
                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        hideDialog()
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep1}>
                    {t("common.nextBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step1;
