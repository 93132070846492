import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import Select from "@mui/material/Select";
import {EDUCATION_NEEDS, PROJECTS} from "../../../../utils/constants/appActions";


const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step3 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        projects: [],
        educationNeeds: [],
        importantToKnowAbout: ''
    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [projects, setProjects]= React.useState([]);
    const [edNeeds, setEdNeeds] = React.useState([]);

    useEffect(() => {

        if (stepsData) {
            setValues({...values, ...stepsData});
        }

        const translatedProjects = PROJECTS.map(project =>({

            key: project.key,
            value: t(project.key)
        }));

        setProjects(translatedProjects);

        const translatedEdNeeds = EDUCATION_NEEDS.map(edNeed => ({
            key: edNeed.key,
            value: t(edNeed.key)
        }));

        setEdNeeds(translatedEdNeeds);

    }, [stepsData])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep = async () => {
        setErrMsg('');

        const {projects, educationNeeds, importantToKnowAbout} = values;

        setStepsData({...stepsData, educationNeeds, projects, importantToKnowAbout})
        handleNext(4);


    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            mb: 1,
                            ml: 1

                        }}>
                            <InputLabel id="demo-simple-select-label">{t("student-form.project")}</InputLabel>
                            <Select
                                required
                                sx={{width: '97%', minWidth: '50%'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.projects}
                                onChange={handleChange('projects')}
                                label={t("student-form.project")}
                                multiple

                            >
                                {projects.map(project => (
                                    <MenuItem key={project.key} value={project.key}>
                                        {t(`projectList.${project.key}`)}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            mb: 1,
                            ml: 1

                        }}>
                            <InputLabel id="demo-simple-select-label">{t("student-form.edNeeds")}</InputLabel>
                            <Select
                                required
                                sx={{width: '97%', minWidth: '50%'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.educationNeeds}
                                onChange={handleChange('educationNeeds')}
                                label={t("student-form.edNeeds")}
                                multiple

                            >
                                {edNeeds.map(edNeed => (
                                    <MenuItem key={edNeed.key} value={edNeed.key}>
                                        {t(`edNeedList.${edNeed.key}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},

                        }}>

                            <TextField
                                id="importantToKnowAbout"
                                label={t("student-form.childInfo")}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                                value={values.importantToKnowAbout}
                                onChange={handleChange('importantToKnowAbout')}
                            />
                        </FormControl>


                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        handleNext(2)
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.backBtn"))}
                </Button>

                <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.cancelBtn"))}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep}>
                    {(t('common.nextBtn'))}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step3;
