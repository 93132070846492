import React, {useEffect} from 'react';
import {
    Alert, ButtonGroup,
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useAppMutation} from "../../../services";
import Link from "@mui/material/Link";
import OTPInput from "otp-input-react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import teacherServices from "../../../services/teacher-services";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {navigate} from "react-big-calendar/lib/utils/constants";
import {useNavigate} from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";


const ForgotPassword = ({disableSmLogin}) => {

    const [values, setValues] = React.useState({
        email: '', otp: '', password: '', showPassword: false,
    });
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [step, setStep] = React.useState(1);
    const {
        mutate: forgotPassword,
        isError: isFPError,
        isLoading: isFPLoading,
        data: fpData,
        isSuccess: isFPSuccess
    } = useAppMutation(teacherServices.forgotPassword);
    const {
        mutate: resetForgotPassword,
        isError: isRFPError,
        isLoading: isRFPLoading,
        data: rfpData,
        isSuccess: isRFPSuccess
    } = useAppMutation(teacherServices.resetForgotPassword);

    useEffect(() => {
        if (fpData && isFPSuccess) {
            setStep(2);
        }
        if (isFPError) {
            setErrMsg(t('common.emailErrorMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    }, [fpData, isFPSuccess, isFPError])

    useEffect(() => {
        if (rfpData && isRFPSuccess) {
            navigate('/teacher/login');
        }
        if (isRFPError) {
            setErrMsg(t('common.emailErrorMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    }, [rfpData, isRFPSuccess, isRFPError])


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };


    const handleOTPChange = (otp) => {
        setValues({
            ...values,
            otp: otp, // Concatenate all OTP values
            otp1: otp.charAt(0) || '',
            otp2: otp.charAt(1) || '',
            otp3: otp.charAt(2) || '',
            otp4: otp.charAt(3) || '',
            otp5: otp.charAt(4) || '',
            otp6: otp.charAt(5) || '',
        });
    };


    const sentEmail = () => {
        if (step === 1) {
            if (!values.email) {
                setErrMsg(t('common.emailErrorMsg'))
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            } else {
                forgotPassword({...values});
            }
        }
    };


    const sentOTP = () => {
        if (step === 2) {
            if (values.otp1 && values.otp2 && values.otp3 && values.otp4 && values.otp5 && values.otp6) {
                // Handle OTP validation or any other logic here
                console.log('Entered OTP:', values.otp1 + values.otp2 + values.otp3 + values.otp4 + values.otp5 + values.otp6);
                // You can add additional logic here, e.g., calling an API to verify the OTP
                // Move to the New Password step
                setStep(3);
            } else {
                setErrMsg(t('teacher-forgot.title.otpErrorMsg'))
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            }
        }
    };

    const saveNewPassword = () => {
        if (step === 3) {
            if (!values.password) {
                setErrMsg(t('teacher-forgot.title.passwordErrorMsg'))
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            } else {
                const enteredOTP = values.otp1 + values.otp2 + values.otp3 + values.otp4 + values.otp5 + values.otp6;

                resetForgotPassword({email: values.email, code: enteredOTP, password: values.password})
            }
        }
    };


    const [errMsg, setErrMsg] = React.useState('');

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Div sx={{width: 720, maxWidth: '100%', margin: 'auto', p: 4}}>
            <Card sx={{display: 'flex', minWidth: 0, flexDirection: {xs: 'column', md: 'row'}}}>
                <CardContent
                    sx={{
                        flex: {
                            xs: '0 1 400px',
                            sm: '0 1 400px',
                            md: '0 1 400px',
                            lg: '0 1 300px',
                            xl: '0 1 300px',
                        },
                        position: 'relative',
                        background: {
                            xs: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-teacher-xs.png`, "640x428")}) no-repeat center`,
                            sm: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-teacher-xs.png`, "640x428")}) no-repeat center`,
                            md: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-teacher-xs.png`, "640x428")}) no-repeat center`,
                            lg: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-teacher-xs.png`, "640x428")}) no-repeat center`,
                            xl: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-teacher-xs.png`, "640x428")}) no-repeat center`,
                        }, backgroundSize: {
                            xs: 'cover',
                            sm: 'cover',
                            md: 'cover',
                            lg: 'cover',
                            xl: 'cover'
                        },
                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .20)
                        },
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={'h3'} color={'inherit'} fontWeight={500} mb={3}>
                                {step === 1 ? t('teacher-forgot.title.forgotHeading') : t('teacher-forgot.title.forgotHeading')}

                            </Typography>
                            <Typography variant={'body1'} mb={2}>
                                {step === 1
                                    ? t('teacher-forgot.title.forgotSubHeading')
                                    : step === 2
                                        ? t('teacher-forgot.title.forgotSubHeading2')
                                        : t('teacher-forgot.title.forgotSubHeading3')}
                            </Typography>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}>
                    <Div sx={{mb: 2, display: 'flex', justifyContent: 'center'}}>
                        <img src="/images/logos/logo.png" alt="Logo" width="150"/>
                    </Div>

                    {step === 1 ? (
                        // Step 1: Email input
                        <>
                            <Div sx={{mt: 1, mb: 2}}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        required
                                        id="email"
                                        label={t('common.email')}
                                        fullWidth
                                        value={values.email}
                                        onChange={handleChange('email')}
                                    />
                                </FormControl>
                            </Div>

                            <LoadingButton
                                fullWidth
                                type="submit"

                                variant="contained"
                                size="large"
                                sx={{mb: 3}}
                                onClick={sentEmail}
                                loading={isFPLoading}
                                endIcon={<SendIcon/>}
                                loadingPosition="end"
                            >
                                Verify Email
                            </LoadingButton>


                            <Stack
                                sx={{
                                    mb: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    position: 'relative', // Add position relative to enable absolute positioning of loader
                                }}
                                spacing={2}>
                                {errMsg && <Alert severity="error">{errMsg}</Alert>}
                            </Stack>

                        </>
                    ) : step === 2 ? (
                        // Step 2: OTP input
                        <>
                            <Div
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mb: 2,
                                }}
                            >
                                <OTPInput
                                    value={values?.otp}
                                    onChange={handleOTPChange}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                    secure
                                    style={{marginLeft: 10}}
                                    sx={{
                                        border: '1px solid black', // Default border color
                                        ':focus': {
                                            borderColor: 'red', // Border color when focused
                                        },
                                        marginLeft: 10,
                                    }}
                                />
                            </Div>

                            <ButtonGroup
                                sx={{
                                    mb: 2,
                                    mt:2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                disableElevation
                                variant="contained"
                                color={"warning"}
                            >

                                <LoadingButton
                                    fullWidth
                                    onClick={() => setStep(1)}
                                    variant="contained"
                                    size="large"
                                    //loading={isLoading}
                                    startIcon={<ArrowBackIosNewOutlinedIcon/>}
                                    loadingPosition="end"
                                    sx={{mr:.25}}
                                >
                                    Back
                                </LoadingButton>

                                <LoadingButton
                                    fullWidth
                                    onClick={sentOTP}
                                    variant="contained"
                                    size="large"
                                    //loading={isLoading}
                                    endIcon={<SendIcon/>}
                                    loadingPosition="end"
                                >{t('teacher-forgot.title.verifyBtn')}
                                </LoadingButton>

                            </ButtonGroup>


                            <Stack
                                sx={{
                                    mb: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    position: 'relative', // Add position relative to enable absolute positioning of loader
                                }}
                                spacing={2}>
                                {errMsg && <Alert severity="error">{errMsg}</Alert>}
                            </Stack>

                        </>
                    ) : (
                        // Step 3: New Password input
                        <>
                            <Div sx={{mt: 1, mb: 2}}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel
                                        htmlFor="outlined-adornment-password">{t('common.password')}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={t('common.password')}
                                        required
                                    />
                                </FormControl>
                            </Div>


                            <Stack
                                sx={{
                                    mb: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    position: 'relative', // Add position relative to enable absolute positioning of loader
                                }}
                                spacing={2}>
                                {errMsg && <Alert severity="error">{errMsg}</Alert>}
                            </Stack>

                            <ButtonGroup
                                sx={{
                                    mb: 2,
                                    mt:2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                disableElevation
                                variant="contained"
                                color={"warning"}
                            >

                                <LoadingButton
                                    fullWidth
                                    onClick={() => setStep(2)}
                                    variant="contained"
                                    size="large"
                                    sx={{mr: .25}}
                                    //loading={isLoading}
                                    startIcon={<ArrowBackIosNewOutlinedIcon/>}
                                    loadingPosition="end"
                                >
                                    Back
                                </LoadingButton>

                                <LoadingButton
                                    fullWidth
                                    onClick={saveNewPassword}
                                    variant="contained"
                                    size="large"
                                    //loading={isLoading}
                                    endIcon={<SendIcon/>}
                                    loadingPosition="end"
                                >
                                    {t('common.updateBtn')}
                                </LoadingButton>

                            </ButtonGroup>

                        </>
                    )}

                    <Typography textAlign={'center'} variant={'body1'} mb={1}>
                        {t('teacher-forgot.title.alreadyMsg')}
                        <Link underline="none" href="/parent/login" ml={0.5} color={'#ff8933'}>
                            {t('teacher-forgot.title.loginNow')}
                        </Link>
                        {' '}
                        {t('teacher-forgot.title.orText')}
                        {' '}
                        <Link underline="none" href="/parent/signup" color={'#ff8933'}>
                            {t('teacher-forgot.title.createNew')}
                        </Link>
                    </Typography>

                </CardContent>
            </Card>
        </Div>
    );
};

export default ForgotPassword;
