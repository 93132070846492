import appAxios from "./config";
import adminServices from "./admin-services";
import authServices from "./auth-services";

const commonServices = {};


commonServices.blobToBase64 = (blob, type) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        if (type) {
            reader.onloadend = () => {
                let base64 = reader.result;
                base64 = 'data:' + type + ';base64' + base64.split(';base64,')[1];
                resolve(base64)
            };
        } else {
            reader.onloadend = () => resolve(reader.result);
        }
        // reader.onload = () => resolve(encoded);
        // reader.readAsDataURL(blob);
    });
}

commonServices.getImagePath = () => {
    //  console.log(process.env.REACT_APP_API_URL).replace(/\/+$/, ''));
    return process.env.REACT_APP_API_URL.replace(/\/+$/, '');
}

commonServices.loadImage = async (path) => {
    const {data} = await appAxios.get('v1/' + path);
    return data;
};


commonServices.loadFile = async (path) => {
    const {data} = await appAxios.get('v1/' + path);
    return data;
};

commonServices.statistics = async (obj) => {
    if(authServices.isSuperAdmin())
    {
        const {data} = await appAxios.post('v1/admin/statistics', obj);
        return data;
    }
    else if (authServices.isParent())
    {
        const {data} = await appAxios.post('v1/parent/statistics', obj);
        return data;
    }
    return null;
};


export default commonServices;
