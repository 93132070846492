import React from 'react';
import {useTranslation} from "react-i18next";
import parentServices from "../../../../../services/parent-services";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {useAppMutation} from "../../../../../services";
import DiscountCoupon from "./DiscountCoupon";
import PaymentStripe from "./PaymentStripe";


const Index = ({plan, hide}) => {
    const {t} = useTranslation();
    const [options, setOptions] = React.useState('');

    const [showDiscount, setShowDiscount] = React.useState(false);
    const [discountCode, setDiscountCode] = React.useState();
    const [showStripePayment, setShowStripePayment] = React.useState(false);
    const {
        mutate: showDiscountCode,
        data: promoData,
        isError: pError,
        isSuccess: isPSuccess
    } = useAppMutation(parentServices.showDiscountCode);

    React.useEffect(() => {
        if (plan.planId) {
            showDiscountCode({planId: plan.planId});
            setShowStripePayment(false);
            setShowDiscount(false);
        }
    }, [plan])


    React.useEffect(() => {
        if (isPSuccess) {

            setShowDiscount(promoData.show);

        } else if (pError) {

            setShowDiscount(false);
            setShowStripePayment(true);
        }
    }, [isPSuccess, pError]);


    const hideMe = ({payment}) => {

        setShowStripePayment(false);
        if (payment) {
            setShowDiscount(true);

        } else {
            setShowDiscount(false);
            hide();
        }
    }


    if (showDiscount || showStripePayment) {
        return (

            <Dialog open={!!plan?.planId} sx={{}}>

                {showDiscount &&
                    <DialogTitle>{t('parent-subscription.title.planPay')}</DialogTitle>}

                {showStripePayment &&
                    <DialogTitle>{t('parent-subscription.title.planPay')}</DialogTitle>}

                <DialogContent
                    sx={{width: {xs: 300, sm: 300, md: 400, lg: 400, xl: 400}, minHeight: 400}}>
                    {showDiscount && <DiscountCoupon hide={hideMe} plan={plan} next={({discountCode}) => {
                        setDiscountCode(discountCode);
                        setShowDiscount(false);
                        setShowStripePayment(true);
                    }}/>}
                    {showStripePayment &&
                        <div className='notranslate'><PaymentStripe hide={hideMe} plan={plan}
                                                                    discountCode={discountCode}/></div>}
                </DialogContent>
            </Dialog>)

    }

    return null;
};

export default Index;
