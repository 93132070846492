import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import {Alert, Box, Button, FormControl, Switch, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {Form, Formik} from "formik";
import {usePromotionsContext} from "../context";
import {useTranslation} from "react-i18next";
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from "@mui/material/FormControlLabel";

const Step3 = ({hideDialog, stepsData, handleNext, setStepsData}) => {
        const [errMsg, setErrMsg] = React.useState('');
        const {globalState, setGlobalState} = usePromotionsContext();
        const {
            mutate: searchParents,
            isLoading: isSearchingParent,
            data: searchResult
        } = useAppMutation(adminServices.searchParents);
        const [parentsData, setParentsData] = React.useState([]);
        const [selectedParents, setSelectedParents] = React.useState([]);
        const {
            mutate: saveDiscount,
            isLoading: isDiscountSaving,
            isError,
            isSuccess
        } = useAppMutation(adminServices.saveDiscount);
        const [values, setValues] = React.useState({
            noOfUses: '',
            discountId: null,
            active : true
        });

        useEffect(() => {
                if (searchResult) {
                    let parents = searchResult.parents;
                    if (selectedParents && selectedParents.length > 0) {
                        for (const p of selectedParents) {

                            const index = parents.findIndex(ii => ii.parentId == p.parentId);
                            if (index > -1) {
                                parents.splice(index, 1);
                            }
                        }
                    }

                    setParentsData(parents);
                }
            }, [searchResult]
        )
        ;

        useEffect(() => {
            if (stepsData.noOfUses || stepsData.parents) {
                setValues({noOfUses: stepsData.noOfUses || '', discountId: stepsData.discountId || null, active : stepsData.active});
                setSelectedParents(stepsData.parents || []);
            }
        }, [stepsData]);

        useEffect(() => {
            if (isSuccess) {
                hideDialog();
            }
        }, [isSuccess, isError]);

        const handleSearch = ({search}) => {
            searchParents(search);
        };

        const submitStep = async () => {
            setErrMsg('');
            const {noOfUses} = values;
            if (!noOfUses) {
                setErrMsg('Error no of uses')
                return;
            }
            if (selectedParents) {
                values.parents = []
                for (const parent of selectedParents) {
                    values.parents.push(parent.parentId)
                }
            }
            const data = {...stepsData, ...values};
            // setStepsData(data);
            saveDiscount(data);
        };

        const handleChange = (prop) => (event) => {
            let newValue = event.target.value;

            if (prop === 'noOfUses') {
                newValue = newValue.replace(/[^0-9]/g, '');
            }
            setValues({...values, [prop]: newValue});
        };

        const {t} = useTranslation();

        return (
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1},
                }}
                noValidate
                autoComplete="off"
            >
                <Formik
                    validateOnChange={true}
                    initialValues={values}
                    enableReinitialize={true}
                    onSubmit={() => {
                    }}
                >
                    {({isSubmitting}) => (
                        <Form noValidate autoComplete="off">
                            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                                <TextField
                                    type={"text"}
                                    label={t("discount.uses")}
                                    value={values.noOfUses}
                                    onChange={handleChange('noOfUses')}
                                />
                            </FormControl>

                            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                                <Autocomplete
                                    id="parents-select"
                                    multiple
                                    value={selectedParents}
                                    onChange={(event, newValue, v) => {
                                        console.log(event, newValue, v);
                                        setSelectedParents(newValue);
                                    }}
                                    filterSelectedOptions
                                    options={parentsData}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={selectedParents.length === 0 ? t("discount.parent") : ""}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => {
                                                handleSearch({search: e.target.value});
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControlLabel
                                label={t("admin-plans.title.active")}
                                labelPlacement="top"
                                sx={{
                                    display: "flex",
                                    alignItems:'start',
                                    width: { xs: '100%', sm: '100%', xl: '100%' },
                                    ml:1,
                                    mt: 2,
                                }}
                                control={
                                    <Switch
                                        edge="end"
                                        checked={values.active}
                                        onChange={(event) => {
                                            setValues({...values, active: event.target.checked})
                                        }}
                                        inputProps={{
                                            'aria-labelledby': 'switch-list-label-bluetooth',
                                        }}
                                    />
                                }
                            />
                        </Form>
                    )}
                </Formik>

                <Stack sx={{width: '100%'}} spacing={2}>
                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                </Stack>
                <Div sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between'}}>
                    <Div>
                        <Button
                            onClick={hideDialog}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => handleNext(2)}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                    </Div>
                    <Div>
                        <LoadingButton
                            sx={{mr: 1}}
                            loading={isDiscountSaving}
                            variant={"contained"}
                            onClick={submitStep}
                        >
                            {values.discountId ? t("discount.updateBtn") : t("discount.createBtn")}
                        </LoadingButton>
                    </Div>
                </Div>
            </Box>
        );
    }
;

export default Step3;
