import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, Button, FormControl, TextField, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step5 = ({stepsData, handleNext}) => {

    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        profileImage: '',
        backgroundInfo: ''
    });
    const [errMsg, setErrMsg] = React.useState('');
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);
    const {t} = useTranslation();

    useEffect(() => {


    }, [])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep = async () => {
        setErrMsg('');

        const {firstName, lastName, title, phone} = values;

        handleNext(6);

        if (firstName && lastName) {


        } else {
            // setErrMsg('Fields not filled.');
        }


    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">

                        <Typography sx={{mt: 1, ml: 1}} variant={"h5"}>{t("admin-program.title.perEval")}</Typography>
                        <Typography sx={{mt: 1, ml: 1}}>{t("admin-program.title.evalTxt")}</Typography>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},


                        }}>

                            <TextField
                                id="datetime-local"
                                label={t("admin-program.title.nxtApp")}
                                type="datetime-local"
                                defaultValue="2017-05-24T10:30"
                                sx={{width: 250}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>


                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        handleNext(4)
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>

                <Button
                    onClick={() => {

                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep}>
                    {t("common.backBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step5;
