import React, {useEffect} from 'react';
import {Alert, Card, CardContent, IconButton, Typography, useTheme} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {useDispatch, useSelector} from "react-redux";
import {onSuccessLogin} from "../../../redux/actions";
import {useAppMutation} from "../../../services";
import authServices from "../../../services/auth-services";
import {useTranslation} from "react-i18next";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


const Login = ({disableSmLogin}) => {
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(authServices.parentSignIn);
    const {setAuthToken} = useJumboAuth();
    const navigate = useNavigate();
    const {isLoggedIn} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (data && isSuccess) {
            setAuthToken(data.token);
            dispatch(onSuccessLogin(data));
            window.location = '/dashboard'
        }
        if (isError && error) {
            setErrMsg(t('common.signInErrorMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
        console.log(data);

    }, [data, isSuccess, isError]);

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email(t('common.emailErrorMsg'))
            .required(t('common.emailRequired')),
        password: yup
            .string('Enter your password')
            .required(t('parent-signIn.title.passwordRequired')),
    });

    const onSignIn = async (email, password) => {
        /*authServices.signIn({email, password})
            .then((data) => {
                setAuthToken(data?.token);
                navigate("/dashboard");
            });*/


        setErrMsg('');

        mutate({email, password});


        // alert(isLoggedIn);
    };



    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            {/*<Typography textAlign={"center"} variant={"body1"} mb={1}>
                  <Link underline="none"
                  href="/admin/login">Admin Login
                  </Link>
            </Typography>*/}
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: {
                            xs : '0 1 400px',
                            sm : '0 1 500px',
                            md : '0 1 300px',
                            lg : '0 1 300px',
                            xl : '0 1 300px',
                        },
                        position: 'relative',
                        background: {
                            xs: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-parent-xs.png`, "640x428")}) no-repeat center`,
                            sm: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-parent-sm.jpg`, "640x428")}) no-repeat center`,
                            md: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-parent.png`, "640x428")}) no-repeat center`,
                            lg: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-parent.png`, "640x428")}) no-repeat center`,
                            xl: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/hipekids-parent.png`, "640x428")}) no-repeat center`,
                        },
                        backgroundSize: {
                            xs: 'cover',
                            sm: 'cover',
                            md: 'cover',
                            lg: 'cover',
                            xl: 'cover'
                        },
                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .20)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500}
                                        mb={3}>{t('parent-signIn.title.signInHeading')}</Typography>
                            <Typography variant={"body1"} mb={2}>
                                {t('parent-signIn.title.signInSubHeading')}
                            </Typography>
                            <Typography variant={"body1"}>
                                <Button
                                    variant = "outlined"
                                    size="small"
                                    startIcon={<LockOutlinedIcon/>}
                                    sx={{
                                        width:"220px",
                                        backgroundColor:"transparent",
                                        borderColor:"#ff8993"
                                    }}
                                    href="/parent/forgotPassword"
                                >
                                    {t('common.passwordForgot')}
                                </Button>
                            </Typography>
                        </Div>

                        <Stack direction="column" spacing={2}>

                            {/*<Button
                            variant="outlined"
                            size="small"
                            startIcon={<SupervisorAccountOutlinedIcon/>}
                            sx={{
                                width:"200px",
                                backgroundColor:"transparent",
                                borderColor:"#ff8933"
                            }}
                            href="/admin/login"
                        >
                            {t('parent-signIn.title.loginAsAdmin')}
                        </Button>*/}

                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<RecentActorsOutlinedIcon/>}
                                sx={{
                                    width: "220px",
                                    backgroundColor: "transparent",
                                    borderColor: "#ff8933"
                                }}
                                href="/teacher/login"
                            >
                                {t('parent-signIn.title.loginAsTeacher')}
                            </Button>
                        </Stack>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}
                >
                    <Div sx={{
                        mb: 1, // Adjust margin as needed
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Link href="/" underline="none" sx={{display: 'inline-flex'}}>
                            <img src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React" width="150"/>
                        </Link>

                    </Div>
                    <Div sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Typography variant={"h6"} mb={1.5}>
                            {t('sidebar.menuItem.parent')}
                        </Typography>
                    </Div>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mb: 3}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label={t('common.email')}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Div>
                                <Div sx={{mt: 1, mb: 2}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label={t('common.password')}
                                        type="password"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Div>
                                { /*  <Div sx={{mb: 2}}>
                                    <FormControlLabel control={<Checkbox/>}
                                                      label={t('parent-signIn.title.rememberMe')}/>
                                </Div>
                                */}
                                <Stack sx={{width: '100%', mb: 2}} spacing={2}>
                                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                </Stack>

                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isLoading}
                                    endIcon={<SendIcon/>}
                                    loadingPosition="end"
                                >{t('common.signInBtn')}
                                </LoadingButton>


                                <Typography textAlign={"center"} variant={"body1"}
                                            mb={1}>{t('parent-signIn.title.noAccountMsg')}
                                    <Link underline="none" href="/parent/signup" ml={0.5}
                                          color={"#ff8933"}>{t('parent-signIn.title.signUp')}</Link>
                                </Typography>


                                {
                                    !disableSmLogin && (
                                        <React.Fragment>
                                            <Typography variant={"body1"}
                                                        mb={2}>{t("admin-signIn.title.signInWith")}</Typography>
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <IconButton sx={{
                                                    bgcolor: '#385196',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#385196',
                                                    }
                                                }} aria-label="Facebook">
                                                    <Facebook fontSize="small"/>
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#00a8ff',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#00a8ff',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Twitter fontSize="small"/>
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#23272b',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#23272b',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Google fontSize="small"/>
                                                </IconButton>
                                            </Stack>
                                        </React.Fragment>
                                    )
                                }

                            </Form>
                        )}
                    </Formik>
                </CardContent>

            </Card>
            <Div sx={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                py: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <Typography variant={"body1"} color={"text.primary"} style={{fontSize: "0.7rem"}}>
                    Copyright HipeKids © 2024 Powered By{" "}
                    <a href="https://deliverydigital.fr/" target="_blank">DELIVERY Digital</a>
                </Typography>
            </Div>
        </Div>
    );
};

export default Login;
