import React, {useEffect} from 'react';
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, TextField, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Select from "@mui/material/Select";
import {useAppMutation} from "../../../services";
import parentServices from "../../../services/parent-services";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";


const Step3 = ({stepsData, handleNext}) => {

    const [values, setValues] = React.useState({knownUs: '', comments: ''});
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(parentServices.completeStep3);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [value, setValue] = React.useState();

    useEffect(() => {
        console.log(isSuccess, isError)
        if (stepsData) {
            const knownUs = stepsData.knownUs ? stepsData.knownUs : '';
            const comments = stepsData.comments ? stepsData.comments : '';

            setValues({knownUs, comments});
        }
        if (error && isError) {
            setErrMsg(error.message);
        } else if (isSuccess) {
            handleNext(4);
        }
    }, [stepsData, isError, error, isSuccess]);

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep3 = async () => {
        setErrMsg('')
        const {knownUs, comments} = values;

        if (knownUs) {
            mutate({...values})
        } else {
            setErrMsg(t('errMsg.notFill'));
        }
    };


    const backToStep2 = async () => {
        handleNext(2);
    };

    return (<Box
        component="form"
        sx={{
            '& .MuiTextField-root': { },
        }}
        noValidate
        autoComplete="off"
    >
        <Div sx={{display: 'flex', flexDirection: 'row', mt: 2}}>

            <Div sx={{
                width: 400,
                maxWidth: '100%',
                ml : 1
            }}>

                <Typography variant={"h3"} color={"inherit"} fontWeight={500}>
                    {t('parent-profile.title.options')}
                </Typography>
                <FormControl sx={{
                    width: '100%',
                }}>
                    <InputLabel id="demo-simple-select-label">{t("parent.title.select")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.knownUs}
                        onChange={handleChange('knownUs')}
                        label={t('common.select')}
                    >
                        <MenuItem value="">{t("common.select")}</MenuItem>
                        <MenuItem value="Recherche internet">{t('listItem.item1')}</MenuItem>
                        <MenuItem value="Bouche à oreille">{t('listItem.item2')}</MenuItem>
                        <MenuItem value="Instagram">{t('listItem.item3')}</MenuItem>
                        <MenuItem value="Facebook">{t('listItem.item4')}</MenuItem>
                        <MenuItem value="YouTube">{t('listItem.item5')}</MenuItem>
                        <MenuItem value="CSE">{t('listItem.item6')}</MenuItem>
                        <MenuItem value="Association">{t('listItem.item7')}</MenuItem>
                        <MenuItem value="Salon d’exposition">{t('listItem.item8')}</MenuItem>
                        <MenuItem value="Ecole privée">{t('listItem.item9')}</MenuItem>
                        <MenuItem value="Medias (télé, journaux, radio...)">{t('listItem.item10')}</MenuItem>
                        <MenuItem value="Autre">{t('listItem.item11')}</MenuItem>
                    </Select>
                </FormControl>

            </Div>
            <Div sx={{
                width: 400,
                maxWidth: '100%',
                ml : 1
            }}>

                <Typography variant={"h3"} color={"inherit"} fontWeight={500}>
                    {t('parent-profile.title.text')}
                </Typography>
                <FormControl sx={{
                    width: '100%',
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('common.comment')}
                        multiline
                        rows={1}
                        value={values.comments}
                        name="comments"
                        onChange={handleChange('comments')}
                    />


                </FormControl>

            </Div>

        </Div>


        <Stack sx={{width: '100%'}} spacing={2}>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

            <Button
                onClick={backToStep2}
                sx={{mr: 1}}
            >
                {t("common.backBtn")}
            </Button>
            <Div sx={{flex: '1 1 auto'}}/>
            <Button variant={"contained"} onClick={submitStep3}>
                {t("common.finishBtn")}
            </Button>
        </Div>
    </Box>);

};

export default Step3;
