import React, {useEffect} from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Button, Card, Dialog, DialogContent, DialogTitle, useMediaQuery} from "@mui/material";
import {useParams} from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import Item from "./Item";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import Sidebar from "./sidebar";
import {TeacherProvider, useTeacherContext} from "./context";
import {useJumboTheme} from "@jumbo/hooks";
import Stack from "@mui/material/Stack";
import FolderDropdown from "../Teachers/FolderDropdown";
import {useAppMutation} from "../../../services";
import adminServices from "../../../services/admin-services";
import TeacherForm from "./TeacherForm";
import {useTranslation} from "react-i18next";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

const BulkActions = () => {
    return <>Bulk Action</>
}


const Children = () => {
    return <TeacherProvider><InnerChildren/></TeacherProvider>
}

const InnerChildren = () => {

    const {mutate: mLoadTeachers, error, isError, data, isSuccess} = useAppMutation(adminServices.loadTeachers);
    const {theme} = useJumboTheme();
    const {t} = useTranslation();
    const {globalState, setGlobalState} = useTeacherContext();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    // const {mutate,error,isError, data,isSuccess} = useAppMutation(authServices.adminSignIn);

    const loadTeacher = async ({queryKey}) => {

        const {page, limit: size, queryParams} = queryKey[1];
        const result = await adminServices.loadTeachers({page: page + 1, size, search: queryParams?.keywords});
        return {teachers: result.teachers, count: result.totalRecords};

    }



    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);
    const params = useParams();
    const listRef = React.useRef();
    // const {refreshContactsList, setContactsListRefresh, setSelectedContacts} = useContactsApp();


    const [view, setView] = React.useState("list");

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "teachers",
        queryParams: { },
        countKey: "count",
        dataKey: "teachers",
    });


    useEffect(() => {
        if (globalState?.refreshList) {
            listRef.current.refresh();
            setGlobalState({...globalState, refreshList: false})
        }
    }, [globalState]);


    React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {...state.queryParams, category: params.category, id: params.id}
        }))
    }, [params]);


    const renderContact = React.useCallback((teacher) => {
        return (<Item teacher={teacher} view={view}/>)
    }, [view]);


    const handleOnChange = React.useCallback((keywords) => {


        setQueryOptions(state => ({
            ...state,
            queryParams: {...state.queryParams, keywords: keywords}
        }))

    }, []);

    return (
        <JumboContentLayout
            layoutOptions={layoutOptions}
            sidebar={<Sidebar/>}
            header={
                <Header title={t("admin-teacher.title.title")}
                        subheader={t("admin-teacher.title.subheader")}/>
            }

        >
            {
                lg && (
                    <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                        {/*<FolderDropdown/>*/}
                    </Stack>
                )
            }
            <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                primaryKey={"teacherId"}
                queryOptions={queryOptions}
                itemsPerPage={10}
                itemsPerPageOptions={[10, 15, 20]}
                renderItem={renderContact}

                service={loadTeacher}
                componentElement={"div"}
                sx={view === 'grid' && {p: theme => theme.spacing(1, 3, 3)}}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar
                        hideItemsPerPage={false}
                        bulkActions={false}
                        actionTail={
                            <ButtonGroup
                                variant="outlined"
                                disableElevation
                                sx={{
                                    '& .MuiButton-root': {
                                        px: 1,
                                    }
                                }}
                            >
                                <Button variant={view === "list" ? "contained" : "outlined"}
                                        onClick={() => setView("list")}><ListIcon/></Button>
                                <Button variant={view === "grid" ? "contained" : "outlined"}
                                        onClick={() => setView("grid")}><ViewComfyIcon/></Button>
                            </ButtonGroup>
                        }
                    >
                        <JumboSearch
                            onChange={handleOnChange}
                            sx={{
                                display: {xs: 'none', md: 'block'}
                            }}
                        />
                    </JumboListToolbar>
                }
                view={view}
            />
            <Dialog open={globalState.openAddEdit}>
                <DialogTitle>{t("admin-teacher.title.updateDetails")}</DialogTitle>
                <DialogContent sx={{width: '100%'}}><TeacherForm/></DialogContent>
            </Dialog>
        </JumboContentLayout>

    );
};

export default Children;
