import React, {useState} from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import {
    Alert,
    Autocomplete,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle, FormControl,
    ListItem,
    ListItemIcon,
    ListItemText, TextField,
    Typography
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {COUNTRIES, findCountryByCode} from "../../../../utils/constants/appActions";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CottageIcon from '@mui/icons-material/Cottage';
import {useAppMutation} from "../../../../services";
import {useProfileContext} from "../../../parent/Profile/context";
import parentServices from "../../../../services/parent-services";
import {useTranslation} from "react-i18next";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards/es/styles-compiled.css';
import {Form, Formik} from "formik";
const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));

const AboutForm = ({hide, location}) => {

    const {
        mutate: updateInfo,
        isSuccess,
        data,
        isError,
        isLoading
    } = useAppMutation(parentServices.updateProfileCardInfoDetails);
    const {globalState, setGlobalState} = useProfileContext();
    const [errMsg, setErrMsg] = React.useState('');
    //const [countryRefresh, setCountryRefresh] = React.useState(false);
    const {t} = useTranslation();

    /*React.useEffect(() => {

        console.log('location---------------------', location)
        if (location) {
            const country = findCountryByCode(location.country);
            setValues({...location, country});
            setCountryRefresh(true);
        }

    }, [location]); */


    React.useEffect(() => {
        if (isSuccess) {
            setGlobalState({...globalState, refreshList: true})
            hide(false);
        }
    }, [isError, data, isSuccess])


    const saveCardInfo = async () => {

        setErrMsg('')

        //const {cardNumber, name, expiryDate, securityCode, } = values;

        console.log(values)

        if (values.number && values.name && values.expiry && values.cvc) {
            console.log("Card Data----------------",values);
        } else {
            setErrMsg(t('common.inputError'))
        }


    };


    const [values, setValues] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;

        setValues((prev) => ({ ...prev, [name]: value }));
    }

    const handleInputFocus = (evt) => {
        setValues((prev) => ({ ...prev, focus: evt.target.name }));
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <FormControl fullWidth noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1,
                            mt: 0.5
                        },
                    }}
                >
                    <Cards
                        number={values.number}
                        expiry={values.expiry}
                        cvc={values.cvc}
                        name={values.name}
                        focused={values.focus}
                    />

                    <Div sx={{mt:4}}>
                        <form>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'},
                            }}>

                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="number"
                                    label={t('cardInfo.cardNo')}
                                    value={values.number}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </FormControl>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="name"
                                    label={t('cardInfo.name')}
                                    value={values.name}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </FormControl>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>

                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="expiry"
                                    label={t('cardInfo.valid')}
                                    value={values.expiry}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </FormControl>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>

                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="cvc"
                                    label={t('cardInfo.cvc ')}
                                    value={values.cvc}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </FormControl>
                        </form>
                    </Div>


                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: '30%',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            sx={{mr: 1, width: "30%"}}
                            onClick={() => hide(false)}
                        >
                            {t('common.closeBtn')}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>


                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isLoading}
                            onClick={saveCardInfo}
                            sx={{width: "30%"}}
                        >
                            {t('common.saveBtn')}
                        </LoadingButton>
                    </Div>


                </Div>
            </FormControl>
        </Box>
    );
}


const CardInfo = () => {

    const [value, setValue] = React.useState('1');
    const [openDialog, setOpenDialog] = React.useState(false);
    const {globalState, setGlobalState} = useProfileContext();
    const {t} = useTranslation();

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };


    const [values, setValues] = React.useState({location: {}});

    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>{t('parent.title.cardDetails')}
                    <Span sx={{color: 'text.secondary', fontSize: 13}}>
                        <EditOutlinedIcon onClick={handleEmailIconClick} sx={{fontSize: 13,ml:1}} />
                    </Span>
                </Typography>
            }
            action={
                <TabContext value={value}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        {/*<TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Overview" value="1"/>
                            <Tab label="Work" value="2"/>
                            <Tab label="Education" value="3"/>
                        </TabList>*/}
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{mb: 3.75}}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                    mb:2
                }}
            >
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '100%'},
                    }}
                >
                    <StyledListItemIcon>
                        <PaymentOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText

                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent.title.cardNo')}</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">
                            {values.cardNumber} </Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <AccountCircleOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent.title.cardName')}
                        </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{values.name}</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <CalendarTodayOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent.title.cardExpiry')}
                        </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{values.expiryDate}</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <VpnKeyOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent.title.securityCode')}
                        </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{values.securityCode}</Typography>}
                    />
                </ListItem>
            </List>

            <Div>
                <Dialog open={openDialog}>
                    <DialogTitle>{t('parent.title.updateDetails')}</DialogTitle>
                    <DialogContent>
                        <AboutForm hide={setOpenDialog} location={globalState?.profile?.cardNumber}/>
                    </DialogContent>
                </Dialog>
            </Div>

        </JumboCardQuick>
    );
};

export default CardInfo;