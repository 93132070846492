import React, {useEffect} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";

const moment = require('moment');

const Step1 = ({hideDialog, stepsData, handleNext, setStepsData}) => {
    const [values, setValues] = React.useState({
        name: '',
        discountType: '',
        discountAmount: '',
        expiryDate: '',
        categories: [],
    });
    const [categories, setCategories] = React.useState([]);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const {
        mutate: loadDiscountCategories,
        isError: isDCError,
        data: dataDiscountCategories,
        isSuccess: isDCSuccess,
        isLoading: isDCLoading
    } = useAppMutation(adminServices.loadDiscountCategories);

    const handleChange = (prop) => (event) => {
        let updatedValue = event.target.value;

        if (prop === 'name') {
            updatedValue = event.target.value.toUpperCase();
        }

        if (prop === 'category') {
            setValues({...values, [prop]: updatedValue});
        } else {
            if (prop === 'discountAmount') {
                // Remove non-numeric characters from the input value
                updatedValue = updatedValue.replace(/[^0-9]/g, '');
            } else if (prop === 'expiryDate') {
                // Convert the input value to 'YYYY-MM-DD' format
                console.log(event.target.value);
                if (event.target.value) {
                    // updatedValue = moment(event.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
                    updatedValue = event.target.value;
                } else {
                    updatedValue = null;
                }
            }
            setValues({...values, [prop]: updatedValue});
        }
    };


    const getMaxDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return moment(date).format('YYYY-MM-DD');
    };


    const submitStep1 = async () => {
        setErrMsg('');
        const {name, discountType, discountAmount, expiryDate} = values;
        if (name && discountAmount && discountType) {
            const data = {...stepsData, ...values};
            setStepsData(data);
            handleNext(2);
        } else {
            setErrMsg(t('errMsg.notFill'));
        }
    };

    useEffect(() => {
        if (isDCSuccess) {
            console.log('dataDiscountCategories', dataDiscountCategories)
            setCategories(dataDiscountCategories);
        }
    }, [isDCError, isDCSuccess])

    useEffect(() => {
        if (stepsData?.name) {
            const val = {...values, ...stepsData};
            if (val.expiryDate) {
                val.expiryDate = moment(stepsData.expiryDate).format('YYYY-MM-DD');
            }
            setValues(val);
        }
        loadDiscountCategories();
    }, [stepsData])

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},
            }}
            noValidate
            autoComplete="off"
        >

            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                <Autocomplete
                    style={{margin: "10px 0"}}
                    multiple
                    id="tags-outlined"
                    options={categories}
                    value={values.categories ? values.categories : []}
                    freeSolo
                    autoSelect
                    onChange={(e, newValues) => {

                        const val = e.target.value;
                        console.log(newValues, val);


                        if (e.target.value) {
                            let {categories: cats} = values

                            const index = cats.findIndex(ii => ii.trim().toLowerCase() === val.trim().toLowerCase())
                            if (index === -1) {
                                cats = [...cats, val.trim().toLowerCase()]
                                setValues({...values, categories: cats});
                            }

                        } else {
                            setValues({...values, categories: newValues});

                        }


                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("discount.category")}
                            placeholder={t("discount.category")}
                            value={values.categories}
                        />
                    )}
                />
            </FormControl>


            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                <TextField
                    label={t("discount.codeName")}
                    value={values.name}
                    onChange={handleChange('name')}
                />
            </FormControl>

            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                <InputLabel id="category">{t("discount.type")}</InputLabel>
                <Select
                    required
                    labelId="discountType"
                    id="discountType"
                    value={values.discountType}
                    label={t("discount.type")}
                    onChange={handleChange('discountType')}
                    sx={{ml: 1, mr: 1}}
                >
                    <MenuItem value={"percentage"}>{t("%")}</MenuItem>
                    <MenuItem value={"fixed"}>{t("Fixed")}</MenuItem>
                </Select>
            </FormControl>


            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                <TextField
                    required
                    type={"text"}
                    label={t("discount.amount")}
                    value={values.discountAmount}
                    onChange={handleChange('discountAmount')}
                />

            </FormControl>

            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 1.5}}>
                <TextField
                    id="date"
                    label={t('discount.expiry')}
                    type="date"
                    value={values.expiryDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: getMaxDate()
                    }}
                    onChange={handleChange('expiryDate')}
                />
            </FormControl>

            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    onClick={hideDialog}
                    sx={{mr: 1}}
                >
                    {t("common.closeBtn")}
                </Button>
                <Box sx={{flex: '1 1 auto'}}/>
                <LoadingButton variant={"variant"} onClick={submitStep1}>
                    {t("common.nextBtn")}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default Step1;
