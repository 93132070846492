import React from 'react';
import FoldersList from "./FoldersList";

const ContactsAppSidebar = ({title, subheader}) => {

    return (
        <React.Fragment>
            <FoldersList/>
        </React.Fragment>
    )

};
export default React.memo(ContactsAppSidebar);
