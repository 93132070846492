import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import {
    Alert,
    Autocomplete,
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {useAppMutation} from "../../../services";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from "react-i18next";
import {PHONE_COUNTRIES,countryFormattingRules} from "../../../utils/constants/appActions";
import teacherServices from "../../../services/teacher-services";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";


const Signup = () => {
    return <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
    ><InnerSignup/></GoogleReCaptchaProvider>
}


const InnerSignup = () => {

    const {mutate: signUp, error, isError, isLoading, data: result, isSuccess} = useAppMutation(teacherServices.signUp);
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = React.useState('');
    const {executeRecaptcha} = useGoogleReCaptcha();


    useEffect(() => {
        console.log(error)
        if (result && isSuccess) {
            console.log(result, isSuccess)
        }
        if (isError && error) {

            if (error?.includes('User Already Exists')) {
                setErrMsg(t('teacher-signup.title.alreadyEmailMsg'))
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            } else {
                setErrMsg(t('teacher-signup.title.signUpErrorMsg'))
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            }
        }
        console.log('Signup-----------------------', isError, error, isSuccess, result)
    }, [result, isSuccess, isError])

    const [values, setValues] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        country: {
            countryCode: 'FR',
            label: 'France',
            code: '33',
            suggested: true,
        },
        phone: '',
        showPassword: false,
        //subscribeNewsLetter: false,
        terms: false
    });

    const handleChange = (prop) => (event) => {
        let value = event.target.value;

        // Remove leading zeros from the phone number
        if (prop === 'phone') {
            value = value.replace(/^0+/, '');
        }

        const selectedCountry = values.country.code;
        const formattingRule = countryFormattingRules.find(rule => rule.code === selectedCountry);

        if (formattingRule) {
            const matches = value.match(formattingRule.pattern);

            if (matches) {
                // Remove undefined and join the matched groups with the specified format
                value = matches.slice(1).filter(match => match !== undefined).join(' ');
            }
        }

        setValues({ ...values, [prop]: value });
    };

    const handleCountryChange = (event, newValue) => {
        console.log(newValue)
        setValues({...values, country: newValue});
    };

    const handleSubsribeChange = (event) => {
        setValues({...values, subscribeNewsLetter: event.target.checked});
    };

    const handleTermChange = (event) => {
        setValues({...values, terms: event.target.checked});
    };


    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleChangePhone = (event) => {
        let inputNumber = event.target.value;

        const sanitizedNumber = inputNumber.replace(/\D/g, '');

        let phone = sanitizedNumber;

        phone = phone.replace(/^0+/, '');
        // Get selected country code
        const selectedCountryCode = values.country.code; // Corrected this line to use `values.country.code` instead of `values.selectedCountryCode`

        // Find the formatting rule for the selected country code
        const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

        // Enforce maximum digits restriction based on country code
        if (countryFormattingRule) {
            const maxDigits = countryFormattingRule.maxDigits;

            // If the input length exceeds the maximum allowed digits, truncate the input
            if (phone.length > maxDigits) {
                phone = phone.slice(0, maxDigits);
            }

            if (phone.length === maxDigits) {
                event.preventDefault();
            }
        }

        setValues({ ...values, phone });
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignup = () => {
        console.log(values);
        setErrMsg('');

        const fullPhoneNumber = `+${values.country.code}${values.phone}`;
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;

        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(values.email)) {
            setErrMsg("Enter a valid email address");
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (
            !values.email ||
            !values.firstName ||
            !values.lastName ||
            !values.password ||
            !values.country ||
            !values.phone
        ) {
            setErrMsg(t('teacher-signup.title.requiredFieldErrorMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (!values.terms) {
            setErrMsg(t('teacher-signup.title.termsErrorMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (!fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
            setErrMsg(t("errMsg.invalidPhone"));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        } else {

            if (!executeRecaptcha) {
                console.log("Execute recaptcha not yet available");
                return;
            }

            executeRecaptcha("teacherSigunForm").then((gReCaptchaToken) => {
                const validatedFullPhoneNumber = fullPhoneNumberWithoutSpaces;
                const obj = {
                    ...values,
                    location: {country: values.country.code},
                    phone: {
                        fullNumber: validatedFullPhoneNumber,
                        code: values.country.code,
                        number: values.phone
                    },
                    token: gReCaptchaToken,
                };
                signUp(obj);
            });


        }
    };


    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>


            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >

                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <Div sx={{
                        mb: 2, // Adjust margin as needed
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <img src="/images/logos/logo.png" alt="Logo" width="150"/>
                    </Div>

                    {isSuccess ? (
                        <>
                            <Div sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <img src="/images/logos/success2.png" alt="Logo" width="50"/>
                                <Div sx={{textAlign: 'center', marginLeft: '10px'}}>
                                    <Typography variant="h4" color="success" fontWeight={500} mb={0}>
                                        {t('teacher-signup.title.successMsg')}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {t('teacher-signup.title.clickTo')}
                                        <Link
                                            href="/teacher/login"
                                            color={"inherit"}
                                            underline={'none'}
                                            sx={{
                                                '&:hover': {
                                                    color: '#ff8933', // Change the color to red on hover
                                                },
                                                textDecoration: 'underline',
                                                ml: 0.5
                                            }}
                                        >
                                            {t('teacher-signup.title.login')}
                                        </Link>
                                    </Typography>
                                </Div>
                            </Div>

                        </>

                    ) : (
                        <>

                            <Div sx={{mt: 1, display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"h3"} color={"inherit"} fontWeight={500}>
                                    {t('teacher-signup.title.signupHeading')}
                                </Typography>
                            </Div>


                            <Div sx={{mt: 1, mb: 2}}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        required
                                        id="email"
                                        label={t('common.email')}
                                        fullWidth
                                        value={values.email}
                                        InputLabelProps={{ shrink: true}}
                                        onChange={handleChange('email')}
                                    />
                                </FormControl>
                            </Div>
                            <Div sx={{mt: 1, mb: 2}}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        required
                                        id="firstName"
                                        label={t('common.firstName')}
                                        fullWidth
                                        value={values.firstName}
                                        onChange={handleChange('firstName')}
                                    />
                                </FormControl>
                            </Div>

                            <Div sx={{mt: 1, mb: 2}}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        required
                                        id="lastName"
                                        label={t('common.lastName')}
                                        fullWidth
                                        value={values.lastName}
                                        onChange={handleChange('lastName')}
                                    />
                                </FormControl>
                            </Div>

                            <Div sx={{mt: 1, mb: 2}}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-password" shrink>{t("common.password")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={t('common.password')}
                                        required
                                    />
                                </FormControl>
                            </Div>


                            <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                                <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                                    <Autocomplete
                                        defaultValue={values.country}
                                        id="country-select-demo"
                                        options={PHONE_COUNTRIES}
                                        autoHighlight
                                        onChange={handleCountryChange}
                                        getOptionLabel={(option) => {
                                            return ' +' + option.code;
                                        }}
                                        disableClearable={true}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.label} ({option.countryCode}) +{option.code}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('teacher-signup.title.country')}
                                                required
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <React.Fragment>
                                                            {values.country && (
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    src={`https://flagcdn.com/w20/${values.country.countryCode.toLowerCase()}.png`}
                                                                    srcSet={`https://flagcdn.com/w40/${values.country.countryCode.toLowerCase()}.png 2x`}
                                                                    alt=""
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl variant="outlined" sx={{flex: 1}}>
                                    <TextField
                                        required
                                        id="phone"
                                        label={t('teacher-signup.title.phone')}
                                        fullWidth
                                        value={values.phone}
                                        onChange={handleChangePhone}
                                    />
                                </FormControl>
                            </Div>


                              <Div sx={{display: 'flex', alignItems: 'center', mb: 2, ml: 1}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.subscribeNewsLetter}
                                            onChange={handleSubsribeChange}
                                            sx={{
                                                color: 'gray',
                                                '&.Mui-checked': {
                                                    color: '#ff8933',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {t('teacher-signup.title.subscriptionMsg')}
                                        </Typography>
                                    }
                                />
                            </Div>

                            <Div sx={{display: 'flex', alignItems: 'center', mb: 2, ml: 1}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.terms}
                                            onChange={handleTermChange}
                                            sx={{
                                                color: 'gray',
                                                '&.Mui-checked': {
                                                    color: '#ff8933',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {t('parent-signup.title.text1')} <a href="https://hipekids.com/conditions-generales-dutilisation/" target="_blank" rel="noopener noreferrer">{t('parent-signup.title.text2')}</a> {t('parent-signup.title.text3')} <a href="https://hipekids.com/politique-de-confidentialite/" target="_blank" rel="noopener noreferrer">{t('parent-signup.title.text4')}</a> {t('parent-signup.title.text5')}
                                        </Typography>
                                    }
                                />
                            </Div>

                            <Stack
                                sx={{
                                    mb: 2,
                                    width: '90%',
                                    mx: 'auto',
                                    display: 'block',
                                    position: 'relative', // Add position relative to enable absolute positioning of loader
                                }}
                                spacing={2}>
                                {errMsg && <Alert severity="error">{errMsg}</Alert>}
                            </Stack>

                            <Button
                                variant="contained"
                                sx={{
                                    mb: 2,
                                    width: '90%',
                                    mx: 'auto',
                                    display: 'block',
                                    position: 'relative', // Add position relative to enable absolute positioning of loader
                                }}
                                onClick={handleSignup}
                                disabled={isLoading} // Disable the button when loading
                            >
                                {isLoading && (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: '#FFFFFF', // Set the loader color
                                            position: 'absolute', // Position the loader absolute
                                            top: '50%', // Center vertically
                                            right: '35%', // Center horizontally
                                            marginTop: '-12px', // Adjustments to center vertically
                                            marginLeft: '-12px', // Adjustments to center horizontally
                                        }}
                                    />
                                )}
                                {isLoading ? t('teacher-signup.title.signingupBtn') : t('teacher-signup.title.signupBtn')}

                            </Button>


                            <Div sx={{mt: 1, display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"body1"}>
                                    {t('teacher-signup.title.alreadyMsg')}
                                    <Link
                                        href="/teacher/login"
                                        color={"inherit"}
                                        underline={'none'}
                                        sx={{
                                            '&:hover': {
                                                color: '#ff8933', // Change the color to red on hover
                                            },
                                            ml: 0.5
                                        }}
                                    >
                                        {t('teacher-signup.title.signIn')}
                                    </Link>
                                </Typography>
                            </Div>

                        </>
                    )}


                </CardContent>
            </Card>

        </Div>
    );
};

export default Signup;
