import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import {Form, Formik} from "formik";
import DDImageField from "../../../../component/DDImageField";
import {useTranslation} from "react-i18next";
import {COUNTRIES, countryFormattingRules, PHONE_COUNTRIES} from "../../../../utils/constants/appActions";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step1 = ({hideDialog = {hideDialog}, stepsData, handleNext, setStepsData}) => {


    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        profileImage: '',
        email: '',
        residenceCountry: 'FR',
        phoneCountry: {},
        country: {},
        phoneNumber: ''
    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);


    useEffect(() => {

        console.log(stepsData)
        if (stepsData) {
            const v = {...values, ...stepsData};
            if (v.phone?.code) {
                const phoneCountry = PHONE_COUNTRIES.find(item => item.code === v.phone.code);
                v.phoneNumber = v.phone.number
                v.phoneCountry = phoneCountry;
            }

            if (v.residenceCountry) {

                const country = COUNTRIES.find(item => item.code === v.residenceCountry);
                v.country = country;

            }

            setValues(v);
        }


    }, [stepsData])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };


    const handleChangePhone = (event) => {
        let inputNumber = event.target.value;

        const sanitizedNumber = inputNumber.replace(/\D/g, '');

        let phoneNumber = sanitizedNumber;

        // Get selected country code

        console.log(values)
        const selectedCountryCode = values.phoneCountry.countryCode; // Corrected this line to use `values.country.code` instead of `values.selectedCountryCode`

        // Find the formatting rule for the selected country code
        const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

        // Enforce maximum digits restriction based on country code
        if (countryFormattingRule) {
            const maxDigits = countryFormattingRule.maxDigits;

            // If the input length exceeds the maximum allowed digits, truncate the input
            if (phoneNumber.length > maxDigits) {
                phoneNumber = phoneNumber.slice(0, maxDigits);
            }

            if (phoneNumber.length === maxDigits) {
                event.preventDefault();
            }
        }

        setValues({...values, phoneNumber});
    };

    const submitStep1 = async () => {
        setErrMsg('');

        const {firstName, lastName, gender, backgroundInfo, dateOfBirth, newImage, phoneNumber} = values;
        const fullPhoneNumber = `+${values.phoneCountry.code}${values.phoneNumber}`;
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');


        if (phoneNumber && !fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
            setErrMsg(t('errMsg.invalidPhone'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }


        const v = {...values};

            if (phoneNumber) {
                v.phone = {
                    fullNumber: fullPhoneNumberWithoutSpaces,
                    code: values.phoneCountry.code,
                    number: values.phoneNumber
                }
            } else {
                v.phone = undefined;
            }


            setStepsData({...stepsData, ...v});

            console.log(stepsData);

            handleNext(2);

    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phoneCountry: newValue});
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form noValidate autoComplete="off">
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            ml: 2,
                        }}>
                            <Typography variant={"h5"}>{(t('parent-children.title.profileImage'))}</Typography>
                            {!values.newImage && values.images?.length > 0 ? (
                                    <DDImageField name={"profile_pic"}
                                                  alt={"user profile pic"}
                                                  onFileSelection={async (file) => {
                                                      let b = await fetch(file).then(r => r.blob());
                                                      if (b.size > 1000000) {
                                                          setErrMsg(t("common.sizeErr"))
                                                          setTimeout(() => {
                                                              setErrMsg("");
                                                          }, 3000);
                                                      } else {
                                                          setValues({...values, newImage: file});
                                                      }
                                                  }}
                                                  sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                                  path={values.images[0]}
                                                  alt={values.firstName + ' ' + values.lastName}/>) :
                                <JumboAvatarField
                                    name={"profile_pic"}
                                    alt={"user profile pic"}
                                    src={values.newImage}
                                    onFileSelection={async (file) => {
                                        let b = await fetch(file).then(r => r.blob());
                                        if (b.size > 1000000) {
                                            setErrMsg(t("common.sizeErr"))
                                            setTimeout(() => {
                                                setErrMsg("");
                                            }, 3000);
                                        } else {
                                            setValues({...values, newImage: file});
                                        }
                                    }}
                                    sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                />}

                            <Typography sx={{mb: 2}}>{(t('parent-children.title.profileDesc'))}</Typography>

                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,

                        }}>
                            <Div sx={{}}>
                                <InputLabel sx={{ml: 1}} error={errTitle}
                                            id="demo-simple-select-label">{(t('parent-children.title.gender'))} *</InputLabel>
                                <Select
                                    required
                                    sx={{ml: 1, width: '94%', maxWidth: "100%"}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.gender}
                                    onChange={handleChange('gender')}
                                    label={(t('parent-children.title.gender'))}
                                    disabled={true}
                                    error={errTitle}
                                >
                                    <MenuItem value=" ">{(t('parent-children.title.select'))}</MenuItem>
                                    <MenuItem value="male">{(t('parent-children.title.male'))}</MenuItem>
                                    <MenuItem value="female">{(t('parent-children.title.female'))}</MenuItem>
                                </Select>
                            </Div>
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},
                        }}>
                            <TextField
                                required
                                error={errFirstName}
                                label={(t('common.firstName'))}
                                value={values.firstName}
                                disabled={true}
                                onChange={handleChange('firstName')}

                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '47%'},
                        }}>
                            <TextField
                                required
                                label={(t('common.lastName'))}
                                value={values.lastName}
                                disabled={true}
                                onChange={handleChange('lastName')}

                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},

                        }}>

                            <TextField
                                id="date"
                                required
                                label={(t('parent-children.title.dateBirth'))}
                                type="date"
                                value={values.dateOfBirth}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                onChange={handleChange('dateOfBirth')}
                            />

                        </FormControl>


                        <FormControl variant="outlined" sx={{
                            width: {xs: '100%', sm: '100%', xl: '45%'},
                        }}>


                            <Autocomplete
                                value={values.country}
                                disabled={true}
                                id="country-select-demo"
                                options={COUNTRIES}
                                autoHighlight
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    setValues({...values, country: newValue, residenceCountry: newValue.code})
                                }}
                                getOptionLabel={(option) => {
                                    console.log(option);
                                    return option.label ? option.label : '';
                                }}
                                disableClearable={true}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt=""
                                        />
                                        {option.label} ({option.code})
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('common.country')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <React.Fragment>
                                                    {values.country?.code && (
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${values.country.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${values.country.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},

                        }}>

                            <TextField
                                id="email"
                                label={t('common.email')}
                                disabled={true}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                                value={values.email}
                                onChange={handleChange('email')}
                            />
                        </FormControl>


                        <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                            <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                                <Autocomplete
                                    value={values.phoneCountry}
                                    disabled={true}
                                    id="country-select-demo"
                                    options={PHONE_COUNTRIES}
                                    autoHighlight
                                    onChange={handleCountryChange}
                                    getOptionLabel={(option) => {
                                        return option.code ? ' +' + option.code : '';
                                    }}
                                    disableClearable={true}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label}+{option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Code'}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <React.Fragment>
                                                        {values.phoneCountry?.countryCode && (
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${values.phoneCountry.countryCode.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${values.phoneCountry.countryCode.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl variant="outlined" sx={{flex: 1}}>
                                <TextField
                                    id="phoneNumber"
                                    label={t('common.phone')}
                                    disabled={true}
                                    fullWidth
                                    value={values.phoneNumber}
                                    onChange={handleChangePhone}
                                />
                            </FormControl>
                        </Div>


                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%', pl: 1, mt: 2}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.cancelBtn"))}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep1}>
                    {(t('common.nextBtn'))}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step1;
