import Div from "@jumbo/shared/Div";
import React from "react";
import {Step, StepLabel, Stepper} from "@mui/material";
import {useCalendarContext} from "../../context";
import Step2 from "./step2";
import Step1 from "./step1";

const steps = ['Basic Info', 'Complete', 'Finish'];

const Form = ({hide, showBookingForm}) => {

    const {globalState, setGlobalState} = useCalendarContext();
    const [activeStep, setActiveStep] = React.useState(0);
    const [teacherFeedback, setTeacherFeedback] = React.useState({});
    const [bookingId, setBookingId] = React.useState();

    React.useEffect(() => {

        if (globalState.booking) {
            setBookingId(globalState.booking.bookingId)
            if (globalState.booking?.teacherFeedback) {
                console.log('globalState.booking', globalState.booking);
                setTeacherFeedback(globalState.booking?.teacherFeedback);
                setActiveStep(1);
            }
        }

    }, [globalState])

    const handleNext = (step) => {

        if (step === 4) {


            hide();

        } else {
            setActiveStep(step - 1);
        }
    };


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>

        <Div>
            {activeStep + 1 === 1 &&
                <></>}
            {activeStep + 1 === 2 &&
                <Step1 bookingId={bookingId} teacherFeedback={teacherFeedback}
                       setTeacherFeedback={setTeacherFeedback}
                       showBookingForm={showBookingForm}
                       handleNext={handleNext}
                       hide={hide}/>}
            {activeStep + 1 === 3 &&
                <Step2 bookingId={bookingId} setTeacherFeedback={setTeacherFeedback}
                       teacherFeedback={teacherFeedback} handleNext={handleNext}
                       hide={hide}/>}


        </Div>
    </Div>;
}


export default Form;
