import {createContext, useContext, useState} from 'react';

const ParentContext = createContext({});
export const useParentContext = () => {
    return useContext(ParentContext);
}

export const ParentProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({refreshList: false, openAddEdit: false, openChildInfo:false, openPurchaseInfo: false, openReservationInfo: false});

    return <ParentContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </ParentContext.Provider>
}
