import React from 'react';
import {DashboardProvider} from "./context";
import Enrollment from "./Enrollment";
import {useAppMutation} from "../../../services";
import adminServices from "../../../services/admin-services";
import parentServices from "../../../services/parent-services";
import {useNavigate, useParams} from "react-router-dom";



const Dashboard = () => {
    return <DashboardProvider><InnerDashboard/></DashboardProvider>
}

const InnerDashboard = () => {

    const {mutate: loadChildrenEnrollmentInfo, isSuccess : isCESuccess, data : enrollmentInfo} = useAppMutation(parentServices.loadEnrollmentInfo);
    const navigate = useNavigate();
    const params = useParams();

    React.useEffect(()=>{
        if((params.status === 'success' || params.status === 'error') && (params.message === 'added-by-parent' || params.message === 'cancelled-by-parent')){
            setTimeout(()=>{
                navigate('/dashboard');
            },1000)
        }
        if(!enrollmentInfo){
            loadChildrenEnrollmentInfo();
        }
    },[enrollmentInfo])

    return (
        <>
            <Enrollment enrollmentInfo={enrollmentInfo}/>
        </>

    );
};

export default Dashboard;
