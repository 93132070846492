import React from "react";
import Page from "@jumbo/shared/Page";
import Children from "../pages/parent/Children";
import Programs from "../pages/parent/Programs";
import Dashboard from "../pages/teacher/Dashboard";
import Subscription from "../pages/parent/Subscription";
import TeacherProfile from "../pages/teacher/Profile"
import Calendar from "../pages/teacher/Calendar";

const teacherRoutes = [
    {
        path: "/dashboard",
        element: <Page component={Dashboard}/>,
    },
    {
        path: "/dashboard/students",
        element: <Page component={Children}/>,
    },
    {
        path: ["/dashboard/calendar", "/dashboard/calendar/:status/:message"],
        element: <Page component={Calendar}/>,
    },
    {
        path: "/dashboard/programs",
        element: <Page component={Programs}/>,
    },
    {
        path: "/dashboard/subscription",
        element: <Page component={Subscription}/>,
    },
    {
        path: "/dashboard/profile",
        element: <Page component={TeacherProfile}/>,
    },
];

export default teacherRoutes;
