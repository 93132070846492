import React from "react";
import { Button, Card, CardContent, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';

const Enrollment = ({ enrollmentInfo }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [children, setChildren] = React.useState(undefined);
    const [steps, setSteps] = React.useState([]);
    const navigate = useNavigate();
    const {t} = useTranslation();

    // Function to get steps
    const getSteps = () => {
        return [
            {
                key: 1,
                label: t('parent-enrollment.title.addFirstChild'),
            },
            {
                key: 2,
                label: t('parent-enrollment.title.bookAppointment'),
                description: 'An ad group contains one or more ads which target a shared set of keywords.',
            },
            {
                key: 3,
                label: t('parent-enrollment.title.purchasePrograms'),
                description: `Try out different ad text to see what brings in the most customers,
                  and learn how to enhance your ads using features like ad extensions.
                  If you run into any problems with your ads, find out how to tell if
                  they're running and how to resolve approval issues.`,
            },
            {
                key: 4,
                label: t('parent-enrollment.title.startBooking'),
                description: `Try out different ad text to see what brings in the most customers,
                  and learn how to enhance your ads using features like ad extensions.
                  If you run into any problems with your ads, find out how to tell if
                  they're running and how to resolve approval issues.`,
            },
        ];
    };

    React.useEffect(() => {
        if (enrollmentInfo && enrollmentInfo.children.length === 0) {
            setSteps(getSteps());
            setChildren([]);
        } else if (enrollmentInfo && enrollmentInfo.children.length > 0) {
            setSteps(getSteps());
            const sts = [];
            for (const child of enrollmentInfo.children) {
                child.steps = getSteps();
                child.steps[0].label = `${t('parent-enrollment.title.yourChild')} ${child.firstName} ${child.lastName} ${t('parent-enrollment.title.addedSuccessfully')}`;
                child.activeStep = 1;
                if (child.evaluation) {
                    if (steps && steps.length > 1 && steps[1].label) {
                        child.steps[1].label = (
                            <div>
                                <Typography sx={{ fontWeight: '500' }}>
                                    {steps[1].label}
                                </Typography>
                            </div>
                        );
                    }

                    if (child.subscription) {
                        child.activeStep = 3;
                    } else {
                        child.activeStep = 2;
                    }
                }
                sts.push({...child});
            }
            setChildren(sts);
        } else {
        }
    }, [enrollmentInfo]);
    const pad = (num) => {
        return num < 10 ? '0' + num : num;
    };


    const handleNext = (step, child) => {
        if (step === 0) {
            navigate('/dashboard/children/add');
        } else if (step === 1) {
            navigate('/dashboard/appointments/add/' + child.studentId);
        } else if (step === 2) {
            navigate('/dashboard/subscription/plans');
        } else if (step === 3) {
            navigate('/dashboard/bookings');
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    if (children && children.length === 0) {
        return (
            <Card sx={{ display: 'flex', minWidth: 0, flexDirection: { xs: 'column', md: 'row' } }}>
                <CardContent sx={{ flex: 1, direction: 'column', p: 4, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                    <Div sx={{ maxWidth: '50%' }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel optional={index === 6 ? (<Typography variant="caption">Last step</Typography>) : null}>
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Div sx={{ mb: 2 }}>
                                            <div>
                                                <Button variant="contained" onClick={() => { handleNext(index) }} sx={{ mt: 1, mr: 1 }}>
                                                    {index === steps.length - 1 ? t('parent-enrollment.title.finish') : t('parent-enrollment.title.continue')}
                                                </Button>
                                            </div>
                                        </Div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Div>
                </CardContent>
            </Card>
        );
    } else if (children && children.length > 0) {
        return children.map((child, index) => (
            <Card sx={{ display: 'flex', mb: 2, minWidth: 0, flexDirection: { xs: 'column', md: 'row' } }} key={index}>
                <CardContent sx={{ flex: 1, direction: 'column', p: 4, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                    <Div sx={{ maxWidth: '100%' }}>
                        <Stepper activeStep={child.activeStep} orientation="vertical">
                            {child.steps.map((step, index) => {
                                const appointment = child.appointment;
                                const startTime = appointment ? moment(appointment.startDate).format('HH:mm') : '';
                                const endTime = appointment ? moment(appointment.endDate).format('HH:mm') : '';
                                const startDate = appointment ? moment(appointment.startDate).format('DD-MM-YYYY') : '';
                                return (
                                    <Step key={step.label}>
                                        <StepLabel optional={index === 7 ? (<Typography variant="caption">Last step</Typography>) : null}>
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            {index === 1 && child.appointment?.appointmentId && (
                                                <>
                                                    <Typography>{t('parent-enrollment.title.alreadyInEvaluation')}</Typography>
                                                    <Typography>
                                                        {t('evaluation.evaluatorName')}: {child.appointment.teacherName.firstName} {child.appointment.teacherName.lastName}
                                                    </Typography>
                                                    <Typography>
                                                        {startDate}
                                                    </Typography>
                                                    <Typography>
                                                         {startTime} - {endTime}
                                                    </Typography>

                                                    <Div sx={{ mb: 2 }}>
                                                        <div>
                                                            <Button variant="contained" onClick={() => { navigate('/dashboard/appointments/edit/' + child.appointment?.appointmentId) }} sx={{ mt: 1, mr: 1 }}>
                                                                {t('parent-enrollment.title.modify')}
                                                            </Button>
                                                        </div>
                                                    </Div>
                                                </>
                                            )}
                                            {index === 1 && !child.appointment?.appointmentId && (
                                                <>
                                                    <Typography>{t('parent-enrollment.title.placeAppointment')}</Typography>
                                                    <Div sx={{ mb: 2 }}>
                                                        <div>
                                                            <Button variant="contained" onClick={() => { handleNext(index, child) }} sx={{ mt: 1, mr: 1 }}>
                                                                {index === steps.length - 1 ? t('parent-enrollment.title.finish') : t('parent-enrollment.title.continue')}
                                                            </Button>
                                                        </div>
                                                    </Div>
                                                </>
                                            )}
                                            {index !== 1 && (
                                                <Div sx={{ mb: 2 }}>
                                                    <div>
                                                        <Button variant="contained" onClick={() => { handleNext(index, child) }} sx={{ mt: 1, mr: 1 }}>
                                                            {index === steps.length - 1 ? t('parent-enrollment.title.startBooking') : t('parent-enrollment.title.continue')}
                                                        </Button>
                                                    </div>
                                                </Div>
                                            )}
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Div>
                </CardContent>

            </Card>
        ));
    } else {
        return null;
    }
};

export default Enrollment;
