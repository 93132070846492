import React, {useState} from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Button, Card} from "@mui/material";
import {useParams} from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import Item from "./Item";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import Sidebar from "./sidebar";
import {useTranslation} from "react-i18next";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};
export const contacts = [
    {
        id: 1451,
        name: 'Stella Johnson',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, "40x40"),
        email: 'stella@example.com',
        phone: '(215)-659-7529',
        designation: 'CEO',
        starred: false,
        frequent: true,
        company: 'TCS Ltd.',
        labels: [2],
        folder: 'contacts',
    },
    {
        id: 1452,
        name: 'Garry Sobars',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
        email: 'garry@example.com',
        phone: '(215)-659-7529',
        designation: 'CFO',
        starred: false,
        frequent: false,
        company: 'InfoSys Inc.',
        labels: [2, 1],
        folder: 'contacts',
    },
    {
        id: 1453,
        name: 'Alex Dolgove',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`, "40x40"),
        email: 'alex@example.com',
        phone: '(215)-748-7855',
        designation: 'Designer',
        starred: false,
        frequent: false,
        company: 'Accenture',
        labels: [3],
        folder: 'contacts',
    },
    {
        id: 1454,
        name: 'Domnic Brown',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "40x40"),
        email: 'domnic@example.com',
        phone: '(215)-659-7529',
        designation: 'PHP Developer',
        starred: false,
        frequent: true,
        company: 'Pizza Hut',
        labels: [3],
        folder: 'contacts',
    },
    {
        id: 1455,
        name: 'Kadir M',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
        email: 'kadir@example.com',
        phone: '(215)-659-8965',
        designation: 'HR Manager',
        starred: true,
        frequent: false,
        company: 'Dominos Ltd.',
        labels: [2, 3],
        folder: 'contacts',
    },
    {
        id: 1456,
        name: 'John Smith',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar8.jpg`, "40x40"),
        email: 'john@example.com',
        phone: '(215)-876-5434',
        designation: 'Marketing Head',
        starred: true,
        frequent: false,
        company: 'Subway',
        labels: [3, 1],
        folder: 'contacts',
    },
    {
        id: 1457,
        name: 'Domnic Harris',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
        email: 'domnic@example.com',
        phone: '(215)-659-7529',
        designation: 'BDO',
        starred: true,
        frequent: true,
        company: 'Honda Inc.',
        labels: [],
        folder: 'contacts',
    },
    {
        id: 1458,
        name: 'Jimmy Jo',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar10.jpg`, "40x40"),
        email: 'jimmy@example.com',
        phone: '(215)-456-2346',
        designation: 'CCO',
        starred: false,
        frequent: false,
        company: 'TVS Corp.',
        labels: [3],
        folder: 'contacts',
    },
    {
        id: 1459,
        name: 'Jimmy Jon',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar11.jpg`, "40x40"),
        email: 'jimmy@example.com',
        phone: '(215)-278-4357',
        designation: 'Developer',
        starred: true,
        frequent: false,
        company: 'Hero Honda',
        labels: [2, 1],
        folder: 'contacts',
    },
    {
        id: 1460,
        name: 'Jeson Born',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar12.jpg`, "40x40"),
        email: 'jeson@example.com',
        phone: '(215)-286-0788',
        designation: 'UI-UX Designer',
        starred: false,
        frequent: false,
        company: 'Hyundai Ltd.',
        labels: [1, 2, 3],
        folder: 'contacts',
    },
    {
        id: 1461,
        name: 'Steve Smith',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar13.jpg`, "40x40"),
        email: 'steve@example.com',
        phone: '(215)-586-4676',
        designation: 'CEO',
        starred: false,
        frequent: true,
        company: 'Maruti Corp',
        labels: [1],
        folder: 'contacts',
    },
    {
        id: 1462,
        name: 'Stella Johnson',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar14.jpg`, "40x40"),
        email: 'stella@example.com',
        phone: '(215)-659-7529',
        designation: 'CEO',
        starred: false,
        frequent: true,
        company: 'Chevrolet',
        labels: [1, 2, 3],
        folder: 'contacts',
    },
    {
        id: 1468,
        name: 'Domnic Harris',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar8.jpg`, "40x40"),
        email: 'domnic@example.com',
        phone: '(215)-785-0967',
        designation: 'BDO',
        starred: true,
        frequent: false,
        company: 'Reliance Energy',
        labels: [],
        folder: 'contacts',
    },
    {
        id: 1469,
        name: 'Jimmy Jo',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
        email: 'jimmy@example.com',
        phone: '(215)-456-0677',
        designation: 'CCO',
        starred: false,
        frequent: true,
        company: 'Flipkart',
        labels: [],
        folder: 'contacts',
    },
    {
        id: 1472,
        name: 'Steve Smith',
        profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar12.jpg`, "40x40"),
        email: 'steve@example.com',
        phone: '(215)-586-2355',
        designation: 'CEO',
        starred: true,
        frequent: false,
        company: 'Myntra',
        labels: [2, 1],
        folder: 'contacts',
    },
];
const BulkActions = () => {
    return <>Test</>
}

const loadContacts = async ({queryKey}) => {

    return {contacts: contacts, count: 22};
};


const Children = () => {


    const params = useParams();
    const listRef = React.useRef();
    const {t} = useTranslation();
    // const {refreshContactsList, setContactsListRefresh, setSelectedContacts} = useContactsApp();

    const [refreshContactsList, setContactsListRefresh] = useState(true);
    const [selectedContacts, setSelectedContacts] = useState();

    const [view, setView] = React.useState("list");

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "contacts",
        queryParams: {category: params.category, id: params.id},
        countKey: "count",
        dataKey: "contacts",
    });

    React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {...state.queryParams, category: params.category, id: params.id}
        }))
    }, [params]);


    const renderContact = React.useCallback((contact) => {
        console.log('renderContact', contact);
        return (<Item contact={contact} view={view}/>)
    }, [view]);

    React.useEffect(() => {
        if (refreshContactsList) {
            listRef.current.refresh();
            setContactsListRefresh(false);
        }

    }, [refreshContactsList]);

    const handleOnChange = React.useCallback((keywords) => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords,
            }
        }))
    }, []);

    return (

        <JumboContentLayout
            layoutOptions={{}}
            sidebar={<Sidebar/>}
            header={
                <Header title={t("parent.title.programTitle")}
                        subheader={t("parent.title.programContent")}/>
            }

        >
            <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                primaryKey={"id"}
                queryOptions={queryOptions}
                itemsPerPage={8}
                itemsPerPageOptions={[8, 15, 20]}
                renderItem={renderContact}
                service={loadContacts}
                componentElement={"div"}
                // sx={view === 'grid' && {p: theme => theme.spacing(1, 3, 3)}}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar
                        hideItemsPerPage={true}
                        bulkActions={
                            <BulkActions/>
                        }
                        actionTail={
                            <ButtonGroup
                                variant="outlined"
                                disableElevation
                                sx={{
                                    '& .MuiButton-root': {
                                        px: 1,
                                    }
                                }}
                            >
                                <Button variant={view === "list" ? "contained" : "outlined"}
                                        onClick={() => setView("list")}><ListIcon/></Button>
                                <Button variant={view === "grid" ? "contained" : "outlined"}
                                        onClick={() => setView("grid")}><ViewComfyIcon/></Button>
                            </ButtonGroup>
                        }
                    >
                        <JumboSearch
                            onChange={handleOnChange}
                            sx={{
                                display: {xs: 'none', md: 'block'}
                            }}
                        />
                    </JumboListToolbar>
                }
                onSelectionChange={setSelectedContacts}
                view={view}
            />
        </JumboContentLayout>

    );
};

export default Children;
