import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from '@mui/material';
import DDImage from '../../../component/DDImage';
import { useTranslation } from 'react-i18next';
import Div from '@jumbo/shared/Div';
import { useAppMutation } from '../../../services';
import adminServices from '../../../services/admin-services';
import { useParentContext } from './context';
import JumboChipsGroup from '@jumbo/components/JumboChipsGroup';
import { styled } from '@mui/system';
import Evaluation from './evaluated';
import moment from "moment";

const Item = styled('div')({
    padding: 8,
    textAlign: 'center',
    '@media (max-width: 600px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

const ChildInfoForm = () => {
    const { t } = useTranslation();
    const [children, setChildren] = useState([]);
    const { globalState, setGlobalState } = useParentContext();
    const [values, setValues] = useState({
        studentId: '',
    });
    const [selectedChild, setSelectedChild] = useState(null);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const {
        mutate: loadStudentByParent,
        isError: isLPError,
        isSuccess: isLPSuccess,
        data: parent,
        isLoading: isLPLoading,
    } = useAppMutation(adminServices.loadStudentByParent);

    useEffect(() => {
        console.log('parent ID:', globalState.parent?.parentId);
        if (globalState && globalState.parent) {
            loadStudentByParent(globalState.parent.parentId);
        }
    }, [globalState]);

    useEffect(() => {
        console.log('Parent data:', parent);
        if (isLPSuccess && parent) {
            setChildren(parent);
        }
    }, [isLPSuccess, parent]);

    const hide = () => {
        setGlobalState({ ...globalState, openChildInfo: false, refreshList: true, parent: undefined });
    };

    const showChildEvaluation = (child) => {
        setSelectedChild(child);
    };

    const handleNotEvaluatedClick = (child) => {
        // Add any additional handling if needed
    };

    const handleCloseEvaluation = () => {
        setSelectedChild(null);
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 5 },
            }}
            noValidate
            autoComplete="off"
        >
            {!selectedChild ? (
                <Div>
                    <Div sx={{ minWidth: 120 }}>
                        <Div>
                            {children.map((item) => (
                                <ListItem key={item.studentId}>
                                    <ListItemAvatar>
                                        {item.images?.length > 0 ? (
                                            <DDImage path={item.images[0]} alt={`${item.firstName} ${item.lastName}`} />
                                        ) : (
                                            <Avatar alt={`${item.firstName} ${item.lastName}`} />
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                                    <Item
                                        sx={{
                                            flexBasis: { md: '22%' },
                                            display: { md: 'block' },
                                            width: { xs: '100%', sm: '50%', xl: '10%' }
                                        }}
                                    >
                                        {item.evaluated ? (
                                            <JumboChipsGroup onClick={() => showChildEvaluation(item)}
                                                             chips={[{ label: t('parent-children.title.evaluated') }]}
                                                             mapKeys={{ label: "evaluated" }}
                                                             spacing={1}
                                                             size={"small"}
                                                             defaultColor={"#146ae4"}
                                            />
                                        ) : (
                                            <>
                                                <JumboChipsGroup onClick={() => handleNotEvaluatedClick(item)}
                                                                 chips={[{label: t('parent-children.title.notEvaluated')}]}
                                                                 mapKeys={{label: "notEvaluated"}}
                                                                 spacing={1}
                                                                 size={"small"}
                                                                 defaultColor={"#ff0000"}
                                                />
                                                {item.appointment?.startDate && <div style={{ marginTop: '8px' }}>{moment(item.appointment.startDate).format('DD-MM-YYYY')}</div>}
                                            </>
                                        )}
                                    </Item>
                                </ListItem>
                            ))}
                        </Div>
                    </Div>
                </Div>
            ) : (
                <Evaluation evaluation={selectedChild.evaluation} onClose={handleCloseEvaluation} />
            )}
            {!selectedChild && (
                <Div sx={{ display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center', justifyContent: 'center' }}>
                    <Button onClick={hide} sx={{ mr: 1 }}>
                        {t('common.closeBtn')}
                    </Button>
                </Div>
            )}
        </Box>
    );
};

export default ChildInfoForm;
