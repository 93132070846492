import React, { useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme,Skeleton  } from '@mui/material';
import ProgressGraph from './progressGraph';
import ProgressLinear from './progressLinear';

const ProgressScreen = ({ studentId,isLoading  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is mobile

    useEffect(() => {
        console.log("Student ID in ProgressScreen:", studentId);
    }, [studentId]);

    if (isLoading) {
        // Show loading skeletons while data is being fetched
        return (
            <Box>
                <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" width="100%" height={400} />
            </Box>
        );
    }

    return (
        <div>
            <Box sx={{ position: 'relative' }}>
                <ProgressLinear />
            </Box>
            <Box sx={{ mt: isMobile ? 5 : 10 }}> {/* Adjust margin for mobile */}
                {<ProgressGraph />}
            </Box>
        </div>
    );
};

export default ProgressScreen;
