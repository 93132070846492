import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { useAppMutation } from "../../../../services";
import adminServices from "../../../../services/admin-services";
import Div from "@jumbo/shared/Div";

const Step2 = ({ hideDialog, stepsData, handleNext, setStepsData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { mutate: loadPlans, isSuccess, data } = useAppMutation(adminServices.loadPlans);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [values, setValues] = useState({
        description: '',
    });
    const [errMsg, setErrMsg] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            loadPlans({ size: 100 });
            setLoaded(true);
        }
    }, [loaded]);

    useEffect(() => {
        if (isSuccess) {
            const packs = data.plans.filter(item => item.category === 'packs');
            const subscriptions = data.plans.filter(item => item.category === 'subscription');
            const allPlansData = [...packs, ...subscriptions];
            setAllPlans(allPlansData);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (stepsData?.description || stepsData?.plans) {
            setValues({ description: stepsData.description || '' });
            let plans = ['all'];
            if(stepsData.plans && stepsData.plans.length > 0){
                plans = [...stepsData.plans];
            }
            setSelectedPlans(plans);
        }
    }, [stepsData]);

    const handlePlanChange = (event) => {
        const values = event.target.value;
        console.log(values)

        const all = values.find(ii => ii === 'all');
        if(all){
            setSelectedPlans(['all']);
        }else{
            setSelectedPlans(values);
        }
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const submitStep2 = async () => {
        setErrMsg('');
        const { description } = values;
        if (description && selectedPlans.length > 0) {
            const data = { ...stepsData, ...values, plans :selectedPlans};
            setStepsData(data);
            handleNext(3);
        } else {
            setErrMsg(t("admin-plans.title.fieldNotFilled"));
        }
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                onSubmit={() => { }}
            >
                {({ isSubmitting }) => (
                    <Form noValidate autoComplete="off">
                        <FormControl sx={{ width: { xs: '100%', sm: '100%', xl: '100%' }, mt: 1.5 }}>
                            <TextField
                                id="outlined-multiline-static"
                                label={t('discount.desc')}
                                value={values.description}
                                name="Description"
                                onChange={handleChange('description')}
                            />
                        </FormControl>

                        <FormControl sx={{ width: { xs: '100%', sm: '100%', xl: '100%' }, mt: 1 }}>
                            <InputLabel id="select-plan-label">
                                {t("discount.plan")}
                            </InputLabel>
                            <Select
                                labelId="select-plan-label"
                                value={selectedPlans}
                                label={t("discount.plan")}
                                onChange={handlePlanChange}
                                inputProps={{ 'aria-label': 'Select Plan' }}
                                multiple
                            >
                                <MenuItem value="all">
                                    {t("All")}
                                </MenuItem>
                                {allPlans.map((plan) => (
                                    <MenuItem key={plan.planId} value={plan.planId}>
                                        {plan.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Form>
                )}
            </Formik>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    onClick={hideDialog}
                    sx={{ mr: 1 }}
                >
                    {t("common.closeBtn")}
                </Button>
                <Button
                    onClick={() => handleNext(1)}
                    sx={{ mr: 1 }}
                >
                    {t("common.backBtn")}
                </Button>
                <Div sx={{ flex: '1 1 auto' }} />
                <LoadingButton loading={isLoading} variant={"contained"} onClick={submitStep2}>
                    {t("common.nextBtn")}
                </LoadingButton>
            </Div>
        </Box>
    );
};

export default Step2;
