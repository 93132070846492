import React from 'react';
import ContactsIcon from "@mui/icons-material/Contacts";
import StarsIcon from "@mui/icons-material/Stars";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FolderItem from "./FolderItem";
import {useParams} from "react-router-dom";
import StyledMenu from "../../../shared/StyledMenu";

const folders = [
    {
        icon: <ContactsIcon fontSize={"small"}/>,
        label: "All ",
        slug: "all",
        path: "/dashboard/programs/all"
    },
    {
        icon: <StarsIcon fontSize={"small"}/>,
        label: "Active",
        slug: "active",
        path: "/dashboard/programs/active"
    },
    {
        icon: <AccessTimeIcon fontSize={"small"}/>,
        label: "Inactive",
        slug: "inactive",
        path: "/dashboard/programs/inactive"
    }
];

const FoldersList = () => {
    const params = useParams();
    return (
         <StyledMenu sx={{mb: 2}}>
                {
                    folders.map(folder => (
                        <FolderItem
                            key={folder.slug}
                            path={folder.path}
                            label={folder.label}
                            icon={folder.icon}
                            selected={folder.slug === params?.category}

                        />
                    ))
                }
            </StyledMenu>
    );
};

export default React.memo(FoldersList);
