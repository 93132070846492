import React, {useState} from "react";
import {useAppMutation} from "../../../../../services";
import parentServices from "../../../../../services/parent-services";
import Div from "@jumbo/shared/Div";
import {Alert, Button, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";


const DiscountCoupon = ({plan, hide, next}) => {

    const {t} = useTranslation();
    const [values, setValues] = useState({discountCode: ''});
    const {
        mutate: isEligibleForDiscount,
        isError,
        data,
        isSuccess,
        isLoading
    } = useAppMutation(parentServices.isEligibleForDiscount);
    const [errMsg, setErrMsg] = React.useState('');

    const handleChange = (prop) => (event) => {
        values[prop] = event.target.value.toUpperCase();
        if (prop === 'discountCode') {
            setValues({...values, applyDiscountCode: undefined});
        } else {
            setValues({...values});

        }
    }

    React.useEffect(() => {
        if (isSuccess) {

            if (data.eligible) {
                setValues({...values, applyDiscountCode: data})
                // next({discountCode: values.discountCode})
            } else {
                setErrMsg(data.message)
            }

        } else if (isError) {
            setErrMsg('Discount code is not valid')
        }
    }, [isSuccess, isError])


    return <Div sx={{}}>

        <Div sx={{}}>
            <Div> <TextField
                label={t('discount.promoCode')}
                value={values.discountCode}
                onChange={handleChange('discountCode')}
                margin="normal"
                variant="outlined"
                sx={{width: '70%'}}
                fullWidth
            /><LoadingButton disabled={!values.discountCode} sx={{mt: 3, ml: 1}} loading={isLoading} onClick={() => {
                setErrMsg('')
                if (values.discountCode) {
                    isEligibleForDiscount({planId: plan.planId, discountCode: values.discountCode})
                }
            }} color="primary">{t('discount.applyBtn')}</LoadingButton></Div>

            <Typography sx={{mt: 2}} variant="h6" mb={0}>{t('admin-plans.title.planName')} {plan.name}</Typography>
            <Typography sx={{mt: 1}} variant="h6"
                        mb={0}>{t('admin-plans.title.planPricing')} {plan.amount} €</Typography>

            {values.applyDiscountCode?.eligible && <Typography sx={{mt: 1}} variant="h6"
                                                               mb={0}>{t('discount.amount')} {values.applyDiscountCode.discountAmount} €</Typography>}

            <Typography sx={{mt: 1}} variant="h6">{t('admin-plans.title.totalAmount')} {values.applyDiscountCode ? values.applyDiscountCode.chargedAmount : plan.amount} €</Typography>

            <Stack sx={{width: '100%', pl: 1, mt: 2}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
        </Div>
        <Div sx={{
            display: 'flex',
            position: "absolute",
            bottom: 10,
            left: 15,
            right: 15,
            flexDirection: 'row',
            flex: 1,
            mb: 2
        }}>

            <Button sx={{alignItems: 'flex-start'}} onClick={hide} color="primary">{t('common.closeBtn')}</Button>

            <Div sx={{flex: '1 1 auto'}}/>

            <LoadingButton sx={{alignItems: 'flex-end', ml: 1}} loading={false} onClick={() => {
                if (values.applyDiscountCode?.eligible) {
                    next({discountCode: values.discountCode})
                } else {
                    next({})
                }


            }} color="primary">{t('common.nextBtn')}</LoadingButton>
        </Div>

    </Div>
}

export default DiscountCoupon;
