import React from "react";
import {useCalendarContext} from "../context";
import Evaluation from "./evaluation";
import Appointment from "./appointment";
import {useAppMutation} from "../../../../services";
import services from "../../../../services/admin-services";


const AForm = () => {

    const {globalState, setGlobalState} = useCalendarContext();
    const [values, setValues] = React.useState({isForm: true, isEvaluation: false, evaluation: {}});

    const [evaluators, setEvaluators] = React.useState([]);
    const [teachers, setTeachers] = React.useState([]);
    const [students, setStudents] = React.useState([]);
    const {mutate: loadStudents, isSuccess: isSSuccess, data: sResult} = useAppMutation(services.loadStudents);
    const {mutate: loadEvaluators, isSuccess: isESuccess, data: eResult} = useAppMutation(services.loadEvaluators);
    const {mutate: loadTeachers, isSuccess: isTSuccess, data: tResult} = useAppMutation(services.loadTeachers);


    React.useEffect(() => {

        if (isSSuccess && sResult) {
            setStudents(sResult.students);
        }
        if (!sResult) {
            loadStudents({size: 2000})
        }

    }, [sResult, isSSuccess])


    React.useEffect(() => {

        if (isESuccess && eResult) {
            setEvaluators(eResult);
        }
        if (!eResult) {
            loadEvaluators({})
        }

    }, [eResult, isESuccess]);


    React.useEffect(() => {

        if (isTSuccess && tResult) {
            setTeachers(tResult.teachers);
        }
        if (!tResult) {
            loadTeachers({size: 2000})
        }

    }, [tResult, isTSuccess])

    const showEvaluation = ({evaluation}) => {
        setValues({...values, isForm: false, isEvaluation: true, evaluation});
    }
    const showForm = () => {
        setValues({...values, isForm: true, isEvaluation: false, evaluation: {}});

    }

    React.useEffect(() => {
    }, []);

    if (values.isForm) {
        return <Appointment showEvaluation={showEvaluation}/>;
    } else if (values.isEvaluation) {
        return <Evaluation teachers={teachers} evalutation={values.evaluation} showForm={showForm}/>;

    }
    return null;
}


export default AForm;
