import {createContext, useContext, useState} from 'react';

const BookingContext = createContext({});

export const useBookingContext = () => {
    return useContext(BookingContext);
}

export const BookingProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({addEditBooking: false});
    const [availableDates, setAvailableDates] = useState([]);

    return <BookingContext.Provider value={{globalState, setGlobalState, availableDates, setAvailableDates}}>
        {children}
    </BookingContext.Provider>
}
