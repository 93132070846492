import {createContext, useContext, useState} from 'react';

const AppointmentContext = createContext({});

export const useAppointmentContext = () => {
    return useContext(AppointmentContext);
}

export const AppointmentProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ addNewBooking : false});

    return <AppointmentContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </AppointmentContext.Provider>
}
