import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, Button, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Avatar from "@mui/material/Avatar";
import commonServices from "../../../../services/common-services";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import DDImage from "../../../../component/DDImage";
import {useTranslation} from "react-i18next";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const valuetext = (value) => {
    return value;
};

const marks = [
    {
        value: 1,
        label: 'Disagree',
    },
    {
        value: 10,
        label: 'agree',
    },
];


const Step4 = ({hideDialog, stepsData, handleNext}) => {

    const [values, setValues] = React.useState({});
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = React.useState('');
    const {
        mutate,
        isError,
        isLoading: isProgramSaving,
        isSuccess,
        data: program,
        error
    } = useAppMutation(adminServices.saveProgram);


    useEffect(() => {

        if (stepsData) {
            setValues({...stepsData});
        }


    }, [stepsData])

    useEffect(() => {

        if (isSuccess && program) {
            handleNext(5);
        }

        if (isError && error) {
            console.log('Error-----', isError, error);
        }

    }, [isSuccess, program, isError, error])


    const submitStep = async () => {
        setErrMsg('');

        const {modules} = values;

        // handleNext(5);

        if (modules) {


            if (stepsData.newImage) {
                let b = await fetch(stepsData.newImage).then(r => r.blob());
                stepsData.imageBase64 = await commonServices.blobToBase64(b)
            }

            const modules = stepsData.modules.filter(module => module?.newImage);

            for (const module of modules) {
                let b = await fetch(module.newImage).then(r => r.blob());
                module.imageBase64 = await commonServices.blobToBase64(b)
            }


            return stepsData;


        } else {
            setErrMsg(t("admin-program.title.fieldNotFilled"));
        }


        return null;
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Div sx={{minHeight: 450}}>

                <Typography variant={"h5"} sx={{mt: 1, ml: 1}}>{t("admin-program.title.module")}</Typography>

                <Div sx={{display: 'flex', flexWrap: 'wrap'}}>
                    {values?.modules?.map((m, index) => {
                        return <JumboCardQuick bgColor={m.color} sx={{m: 1}}>
                            <Div sx={{display: "flex", alignItems: 'center'}}>
                                {m.images?.length > 0 ?
                                    <DDImage sx={{width: 40, height: 40, cursor: 'pointer'}}
                                             alt={m.name} path={m.images[0]}/> :
                                    <Avatar
                                        sx={{width: 40, height: 40, cursor: 'pointer'}}
                                        alt={m.name}
                                        src={m.newImage}
                                    />}
                                <Div sx={{ml: 2, flex: 1}}>
                                    <Typography color={'common.white'} variant={"h2"} mb={.5}>{m.name}</Typography>
                                    <Typography color={'common.white'} variant={"h5"}
                                                mb={0}>{m.segments.length} {t("admin-program.title.seg")}</Typography>
                                </Div>

                            </Div>


                        </JumboCardQuick>
                    })}
                </Div>


            </Div>
            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        handleNext(2)
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>

                <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isProgramSaving} variant={"variant"} onClick={() => {
                    submitStep().then(res => {
                        if (res) {
                            mutate(res);
                        }
                    })
                }}>
                    {t("admin-program.title.createBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step4;
