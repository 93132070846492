import React from 'react';
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {Formik} from "formik";
import DDImageField from "../../../component/DDImageField";
import commonServices from "../../../services/common-services";
import {useDispatch, useSelector} from "react-redux";
import {useAppMutation} from "../../../services";
import authServices from "../../../services/auth-services";
import {reloadUser} from "../../../redux/actions";
import adminServices from "../../../services/admin-services";

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    padding: theme.spacing(0, 1),

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& .MuiTouchRipple-root': {
        display: 'none'
    }

}));

const Item = styled("div")({
    textAlign: 'center',
});


const Header = () => {

    const dispatch = useDispatch();
    const {
        authUser
    } = useSelector(state => state.auth);
    const {mutate: saveImage, isError, data, isSuccess, isLoading} = useAppMutation(adminServices.saveProfileImage);
    const [values, setValues] = React.useState({newImage: (authUser.images?.length > 0 ? authUser.images[0] : '')});

    React.useEffect(() => {

        if (isSuccess && data) {
            setValues({})
            console.log(data);
            const user = {...authServices.getAuthUser(), ...data}
            console.log('Profile-------------->', user);
            setValues({...values, newImage: data.images?.length > 0 ? data.images[0] : ''});
            authServices.updateAuthUser(user);
            dispatch(reloadUser());
        }


    }, [isSuccess, data])



    return (
        <ContentHeader
            avatar={
                <><Formik initialValues={values} onSubmit={() => {
                }}>
                    <DDImageField
                        name={"profile_pic"}
                        alt={"user profile pic"}
                        path={values.newImage}
                        onFileSelection={async (file) => {
                            const newImage = file;
                            let b = await fetch(newImage).then(r => r.blob());
                            saveImage(await commonServices.blobToBase64(b));
                        }}
                        sx={{width: 60, height: 60, margin: '0 auto 5px'}}
                    />
                </Formik>
                </>
            }
            title={"Delivery Digital (Admin)"}
            subheader={<Typography fontSize={12} variant={'body1'} color={'inherit'} mt={.2}>Nice, France</Typography>}



            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}

        />
    );
};

export default Header;
