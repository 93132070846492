import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Autocomplete, Box, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import Select from "@mui/material/Select";
import {countryFormattingRules, PHONE_COUNTRIES} from "../../../utils/constants/appActions";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppMutation} from "../../../services";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import teacherServices from "../../../services/teacher-services";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});


const Step1 = ({stepsData, handleNext}) => {

    const [values, setValues] = React.useState({firstName: '', lastName: '', title: '', dateOfBirth: ''});
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(teacherServices.completeStep1);
    const [errMsg, setErrMsg] = React.useState('');
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);
    const [errDateOfBirth, setDateofBirth] = React.useState(false);
    const {t} = useTranslation();
    useEffect(() => {
        console.log('Step 1', values, stepsData);
        if (stepsData) {
            const val = PHONE_COUNTRIES.find(item => item.code === stepsData.phone?.code)
            stepsData.phone.label = val?.label;
            stepsData.phone.countryCode = val?.countryCode;
            setValues({...values, ...stepsData});
        }
    }, [stepsData])

    useEffect(() => {
        if (error && isError) {
            setErrMsg(error.message);
        } else if (isSuccess) {
            handleNext(2);
        }
    }, [isSuccess, isError])


    const handleChange = (prop) => (event) => {
        console.log([prop], event.target.value)
        setValues({...values, [prop]: event.target.value});
        console.log('--', values.title, '--');
    };

    const submitStep11 = async () => {
        setErrMsg('');

        const {firstName, lastName, dateOfBirth, title, phone} = values;

        if (firstName && lastName && dateOfBirth && title && phone && phone.number && phone.code) {
            mutate({...values})
        } else {
            setErrMsg('Fields not filled.');
        }

    };

    const submitStep1 = async () => {
        setErrMsg('');

        const {firstName, lastName, title, phone} = values;
        const fullPhoneNumber = `+${phone.code}${phone.number}`;
        console.log("fullPhoneNumber", fullPhoneNumber);
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');


        if (firstName && lastName && title && phone && phone.number && phone.code) {
            if (!fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
                setErrMsg("Invalid Full Phone Number");
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            }

            const validatedFullPhoneNumber = fullPhoneNumberWithoutSpaces;
            const obj = {
                firstName,
                lastName,
                title,
                phone: {
                    fullNumber: fullPhoneNumberWithoutSpaces,
                    code: values.phone.code,
                    number: values.phone.number
                },
            };
            console.log("Step2------------", obj);
            mutate(obj)
            handleNext(2);
        } else {
            setErrMsg('Fields not filled.');
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    return (

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, mt: 5},
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <Div sx={{minWidth: 120}}>
                    <FormControl sx={{
                        mt: 4,
                        ml: 1,
                        width: "21%"

                    }}>
                        <InputLabel error={errTitle}
                                    id="demo-simple-select-label">{t("parent.title.profileStepTitle")} *</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.title}
                            onChange={handleChange('title')}
                            label={t("parent.title.profileStepTitle")}
                            error={errTitle}
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="Mr">{t('common.mr')}</MenuItem>
                            <MenuItem value="Mrs">{t('common.mrs')}</MenuItem>
                        </Select>
                    </FormControl>
                </Div>
                <Div sx={{
                    mt: -2,
                }}>
                    <FormControl sx={{
                        width: 500,
                        maxWidth: '100%',

                    }}>
                        <TextField
                            required
                            error={errFirstName}
                            label={t("common.firstName")}
                            value={values.firstName}
                            onChange={handleChange('firstName')}

                        />
                    </FormControl>
                    <FormControl sx={{
                        width: 500,
                        maxWidth: '100%',

                    }}>
                        <TextField
                            required
                            error={errLastName}
                            label={t("common.lastName")}
                            value={values.lastName}
                            onChange={handleChange('lastName')}
                        />
                    </FormControl>
                </Div>
                <Div sx={{mt: -2, mb: 2, display: 'flex', alignItems: 'center'}}>
                    <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                        {values.phone?.code && <Autocomplete
                            defaultValue={values.phone}
                            options={PHONE_COUNTRIES}
                            autoHighlight
                            onChange={handleCountryChange}
                            getOptionLabel={(option) => {
                                return ' +' + option.code;
                            }}
                            disableClearable={true}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.countryCode?.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.countryCode?.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.countryCode}(+{option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('common.code')}
                                    error={errPhone}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <React.Fragment>
                                                {values.phone && values.phone.countryCode && (
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${values.phone.countryCode?.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${values.phone.countryCode?.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                )}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />}
                    </FormControl>

                    <FormControl variant="outlined" sx={{flex: 1}}>
                        <TextField
                            required
                            id="phone"
                            label={t('common.phone')}
                            error={errPhone}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            value={values.phone?.number}
                            onChange={(event) => {
                                let inputNumber = event.target.value;

                                // Remove leading zeros
                                const sanitizedNumber = inputNumber.replace(/\D/g, '').replace(/^0+/, '');

                                let phone = values.phone;
                                phone.number = sanitizedNumber;

                                // Get selected country code
                                const selectedCountryCode = phone.code;

                                // Find the formatting rule for the selected country code
                                const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

                                // Enforce maximum digits restriction based on country code
                                if (countryFormattingRule) {
                                    const maxDigits = countryFormattingRule.maxDigits;

                                    // If the input length exceeds the maximum allowed digits, truncate the input
                                    if (phone.number.length > maxDigits) {
                                        phone.number = phone.number.slice(0, maxDigits);
                                    }

                                    // If the input length equals the maximum allowed digits, prevent further input
                                    if (phone.number.length === maxDigits) {
                                        event.preventDefault();
                                    }
                                }

                                setValues({...values, phone});
                            }}
                        />
                    </FormControl>


                </Div>
            </Div>
            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep1}>
                    {t("common.nextBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step1;
