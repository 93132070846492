import React from 'react';
import FoldersList from "./FoldersList";
import {useStudentContext} from "./context";

const ContactsAppSidebar = ({title, subheader}) => {
    const {globalState, setGlobalState} = useStudentContext();

    return (
        <React.Fragment>
            <FoldersList/>
        </React.Fragment>
    )

};
export default React.memo(ContactsAppSidebar);
