import React, {useEffect} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BusinessIcon from '@mui/icons-material/Business';
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ChildDetail from "./ChildDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import DDImage from "../../../component/DDImage";
import {useChildrenContext} from "./context";
import {useAppMutation} from "../../../services";
import parentServices from "../../../services/parent-services";
import {useTranslation} from "react-i18next";
import Evaluation from "./Evaluation";
import {useNavigate,Link} from "react-router-dom";
import Div from "@jumbo/shared/Div";

const Item = styled(Span)(({theme}) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const ChildItem = ({child, view}) => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {globalState, setGlobalState} = useChildrenContext();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {mutate: deleteChild, isError, isSuccess, isLoading} = useAppMutation(parentServices.deleteChild);


    useEffect(() => {

        if (isSuccess) {
            setGlobalState({...globalState, refreshChildrenList: true});
            hideDialog(true);
        }

    }, [isSuccess])


    const showChildDetail = React.useCallback(() => {
        showDialog({
            content: <ChildDetail child={child} onClose={hideDialog}/>
        })
    }, [showDialog, child]);


    const showChildEvaluation = React.useCallback(() => {
        showDialog({
            content: <Evaluation evaluation={child.evaluation} onClose={hideDialog}/>
        })
    }, [showDialog, child]);

    const handleNotEvaluatedClick = () => {
        console.log(child);

        if(child.appointment?.appointmentId){
            navigate('/dashboard/appointments/edit/' + child.appointment.appointmentId);
        }else{
            navigate('/dashboard/appointments/add/' + child.studentId);
        }
    };

    const handleProgress = () => {
        console.log(child.studentId);

        if(child.studentId){
            navigate('/dashboard/children/progress/' + child.studentId);
        }
    };


    const handleItemAction = (menuItem) => {

        switch (menuItem.action) {
            case 'edit':

                const openAddEditChild = true;
                const childId = child.studentId;
                setGlobalState({...globalState, childId, openAddEditChild});

                break;
            case 'delete':

                showDialog({
                    variant: 'confirm',
                    title: (t('parent-children.title.deleteTitle')),
                    content: (t('parent-children.title.deleteContent')),
                    onYes: () => deleteChild(child.studentId),
                    onNo: hideDialog
                })
        }
    };

    if (view === "grid") {
        return (
            <JumboGridItem xs={12} lg={6}>
                <Card variant="outlined" elevation={0}>
                    <CardHeader
                        avatar={child.images?.length > 0 ? (
                                <DDImage path={child.images[0]} alt={child.firstName + ' ' + child.lastName}/>) :
                            (<Avatar alt={child.firstName + ' ' + child.lastName}/>)
                        }
                        action={
                            <JumboDdMenu
                                icon={<MoreHorizIcon/>}
                                menuItems={[
                                    {icon: <EditIcon/>, title: (t('parent-children.title.edit')), action: "edit"},
                                    {icon: <DeleteIcon/>, title: (t('parent-children.title.delete')), action: "delete"}
                                ]}
                                onClickCallback={handleItemAction}
                            />
                        }
                        title={
                            <Typography
                                variant={"h6"}
                                color={"text.primary"}
                                mb={.25}
                            >
                                {child.firstName} {child.lastName}
                            </Typography>}
                        subheader={
                            <Typography
                                variant={"body1"}
                                color={"text.secondary"}
                            >
                                {child.designation}
                            </Typography>}
                    />
                    <CardContent sx={{pt: 0}}>
                        <Divider sx={{mb: 2}}/>
                        <List disablePadding>
                            <ListItem sx={{p: theme => theme.spacing(.75, 1.5)}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <BusinessIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={child.firstName + ' ' + child.lastName}
                                />
                            </ListItem>
                            {/*<ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <AlternateEmailIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={child.email}
                                />
                            </ListItem>*/}
                            {/*<ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <PhoneIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={child.phone}
                                />
                            </ListItem>*/}
                        </List>
                        <Divider sx={{my: 2}}/>
                        <Stack direction={"row"} alignItems={"center"} sx={{minWidth: 0}}>
                            <Item>

                                {child.evaluated ? (
                                    <JumboChipsGroup onClick={showChildEvaluation}
                                                     chips={[{label: (t('parent-children.title.evaluated'))}]}
                                                     mapKeys={{label: "evaluated"}}
                                                     spacing={1}
                                                     size={"small"}
                                                     defaultColor={"#146ae4"}
                                    />
                                ) : (
                                    <JumboChipsGroup onClick={handleNotEvaluatedClick}
                                                     chips={[{label:(t('parent-children.title.notEvaluated'))}]}
                                                     mapKeys={{label: "notEvaluated"}}
                                                     spacing={1}
                                                     size={"small"}
                                                     defaultColor={"#ff0000"}
                                    />
                                )}

                            </Item>


                            <Item>

                                <JumboChipsGroup onClick={handleProgress}
                                                 chips={[{label: (t('parent-children.title.progressBtn'))}]}
                                                 mapKeys={{label: "progress"}}
                                                 spacing={1}
                                                 size={"small"}
                                                 defaultColor={"#FF8C43"}
                                />
                            </Item>


                        </Stack>
                    </CardContent>
                </Card>
            </JumboGridItem>
        )
    }
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={child}

                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon/>}
                        menuItems={[
                            {icon: <EditIcon/>, title: (t('parent-children.title.edit')), action: "edit"},
                            {icon: <DeleteIcon/>, title: (t('parent-children.title.delete')), action: "delete"}
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >
                <ListItemAvatar onClick={showChildDetail}>
                    {child.images?.length > 0 ? (
                            <DDImage path={child.images[0]} alt={child.firstName + ' ' + child.lastName}/>) :
                        (<Avatar alt={child.firstName + ' ' + child.lastName}/>)}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{minWidth: 0}}>
                                <Item
                                    sx={{
                                        flexBasis: {xs: '100%', sm: '50%', md: '25%'}
                                    }}
                                >

                                    <Typography onClick={handleProgress}>
                                        {child.firstName} {child.lastName}
                                    </Typography>


                                    <Typography
                                        variant={"body1"}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: {sm: 'none'}
                                        }}
                                    >
                                        {child.email}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {sm: '50%', md: '28%'},
                                        display: {xs: 'none', sm: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{child.email}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {md: '25%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{child.phone}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: {md: '22%'},
                                        display: {xs: 'none', md: 'block'},
                                        width:{ xs:'100%', sm: '50%', xl: '10%'}
                                    }}
                                >
                                    <JumboChipsGroup onClick={handleProgress}
                                                     chips={[{label: (t('parent-children.title.progressBtn'))}]}
                                                     mapKeys={{label: "progress"}}
                                                     spacing={1}
                                                     size={"small"}
                                                     defaultColor={"#FF8C43"}
                                    />


                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: {md: '22%'},
                                        display: {md: 'block'},
                                        width:{ xs:'100%', sm: '50%', xl: '10%'}
                                    }}
                                >

                                    {child.evaluated ? (
                                        <JumboChipsGroup onClick={showChildEvaluation}
                                                         chips={[{label: (t('parent-children.title.evaluated'))}]}
                                                         mapKeys={{label: "evaluated"}}
                                                         spacing={1}
                                                         size={"small"}
                                                         defaultColor={"#146ae4"}
                                        />
                                    ) : (
                                        <JumboChipsGroup onClick={handleNotEvaluatedClick}
                                                         chips={[{label:(t('parent-children.title.notEvaluated'))}]}
                                                         mapKeys={{label: "notEvaluated"}}
                                                         spacing={1}
                                                         size={"small"}
                                                         defaultColor={"#ff0000"}
                                        />
                                    )}
                                </Item>

                            </Stack>
                        </Typography>
                    }
                />

            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo child, view prop define */
export default ChildItem;
