import {createContext, useContext, useState} from 'react';

const DashboardContext = createContext({});

export const useProgramsContext = () => {
    return useContext(DashboardContext);
}

export const DashboardProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({});

    return <DashboardContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </DashboardContext.Provider>
}
