import React from 'react';
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import {CalendarProvider, useCalendarContext} from "./context";
import CalendarBasic from "./calendars/CalendarBasic";
import AppointmentForm from "./AppointmentForm";
import CancelForm from "./AvailabilityForm";
import {useTranslation} from "react-i18next";
import BookingForm from "./BookingForm";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

const BulkActions = () => {
    return <>Test</>
}

const Children = () => {
    return <CalendarProvider><InnerChildren/></CalendarProvider>
}

const InnerChildren = () => {

    const {globalState, setGlobalState} = useCalendarContext();
    const {t} = useTranslation();


    return (
        <JumboContentLayout
            layoutOptions={{}}
            //sidebar={<Sidebar/>}
            header={
                <Header title={'Calendar'}/>
            }

        >
            <CalendarBasic/>
            <Dialog open={globalState.openAppointmentAddEdit}>
                <DialogTitle>{t('teacher-appointment.title.appointmentConfirmation')}</DialogTitle>
                <DialogContent sx={{width: '100%'}}><AppointmentForm/></DialogContent>
            </Dialog>


            <Dialog open={globalState.openBookingAddEdit}>
                <DialogTitle>{t('teacher-appointment.title.bookingConfirmation')}</DialogTitle>
                <DialogContent sx={{width: '100%'}}><BookingForm/></DialogContent>
            </Dialog>

            <Dialog open={globalState.openUnavailabilityForm}>
                <DialogTitle>{t('teacher-appointment.title.availabilityForm')}</DialogTitle>
                <DialogContent sx={{width: '100%'}}><CancelForm/></DialogContent>
            </Dialog>

        </JumboContentLayout>

    );
};

export default Children;
