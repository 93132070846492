import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import {Form, Formik} from "formik";
import DDImageField from "../../../../component/DDImageField";
import {useTranslation} from "react-i18next";
import {
    COUNTRIES,
    countryFormattingRules,
    levelsWithPoints1,
    PHONE_COUNTRIES
} from "../../../../utils/constants/appActions";
import {levelsWithPoints} from "../../../../utils/constants/appActions";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step1 = ({hideDialog = {hideDialog}, stepsData, handleNext, setStepsData}) => {


    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        profileImage: '',
        email: '',
        residenceCountry: 'FR',
        phoneCountry: {
            countryCode: 'FR',
            label: 'France',
            code: '33',
            suggested: true,
        },
        country: {},
        phoneNumber: '',
        levelProgress: {
            actualLevel2: { label: '', points: 0 },
            noOfPoints: 0
        }
    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);



    useEffect(() => {

        console.log("---",stepsData)
        if (stepsData) {
            const v = {...values, ...stepsData};
            if (v.phone?.code) {
                const phoneCountry = PHONE_COUNTRIES.find(item => item.code === v.phone.code);
                v.phoneNumber = v.phone.number
                v.phoneCountry = phoneCountry;
            }

            if (v.residenceCountry) {

                const country = COUNTRIES.find(item => item.code === v.residenceCountry);
                v.country = country;

            }

            setValues(v);
        }


    }, [stepsData])



    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };


    const handleChangePhone = (event) => {
        let inputNumber = event.target.value;

        const sanitizedNumber = inputNumber.replace(/\D/g, '');

        let phoneNumber = sanitizedNumber;

        phoneNumber = phoneNumber.replace(/^0+/, '');
        // Get selected country code

        const selectedCountryCode = values.phoneCountry.code; // Corrected this line to use `values.country.code` instead of `values.selectedCountryCode`

        // Find the formatting rule for the selected country code
        const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

        // Enforce maximum digits restriction based on country code
        if (countryFormattingRule) {
            const maxDigits = countryFormattingRule.maxDigits;

            // If the input length exceeds the maximum allowed digits, truncate the input
            if (phoneNumber.length > maxDigits) {
                phoneNumber = phoneNumber.slice(0, maxDigits);
            }

            if (phoneNumber.length === maxDigits) {
                event.preventDefault();
            }
        }

        setValues({...values, phoneNumber});
    };

    const submitStep1 = async () => {
        setErrMsg('');

        const { firstName, lastName, gender, backgroundInfo, dateOfBirth, newImage, phoneNumber } = values;
        const fullPhoneNumber = `+${values.phoneCountry.code}${values.phoneNumber}`;
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');

        // Validate phone number
        if (phoneNumber && !fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
            setErrMsg(t('errMsg.invalidPhone'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        // Extract the selected actual level and its range
        const selectedLevel = levelsWithPoints1.find(
            level => level.label === values.levelProgress.actualLevel2.label
        );

        if (!selectedLevel) {
            setErrMsg(t('Please select a valid level.'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        const minPoints = selectedLevel.points;
        const maxPoints = selectedLevel.maxPoints; // Use maxPoints directly from selectedLevel

        // Validate if the noOfPoints falls within the range
        const noOfPoints = parseInt(values.levelProgress.noOfPoints, 10);
        if (noOfPoints < minPoints || noOfPoints > maxPoints) {
            setErrMsg(t(`No of points should be between ${minPoints} and ${maxPoints}`));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        // Check required fields
        if (firstName && lastName && gender && dateOfBirth) {
            const v = { ...values };

            // Set phone details if available
            if (phoneNumber) {
                v.phone = {
                    fullNumber: fullPhoneNumberWithoutSpaces,
                    code: values.phoneCountry.code,
                    number: values.phoneNumber,
                };
            } else {
                v.phone = undefined;
            }

            // Update steps data and move to the next step
            setStepsData({ ...stepsData, ...v });

            console.log(stepsData);

            handleNext(2); // Proceed to the next step
        } else {
            setErrMsg(t('common.inputError')); // Display error for missing required fields
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    };


    const submitStep1Old = async () => {
        setErrMsg('');

        const {firstName, lastName, gender, backgroundInfo, dateOfBirth, newImage, phoneNumber} = values;
        const fullPhoneNumber = `+${values.phoneCountry.code}${values.phoneNumber}`;
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');


        if (phoneNumber && !fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
            setErrMsg(t('errMsg.invalidPhone'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        // Extract the selected actual level and its range
        const selectedLevel = levelsWithPoints.find(
            level => level.label === values.levelProgress.actualLevel2.label
        );
        const nextLevel = levelsWithPoints.find(
            level => level.points > selectedLevel.points
        );
        const minPoints = selectedLevel.points;
        const maxPoints = nextLevel ? nextLevel.points : minPoints;

        // Validate if the noOfPoints falls within the range
        const noOfPoints = parseInt(values.levelProgress.noOfPoints, 10);
        if (noOfPoints < minPoints || noOfPoints > maxPoints) {
            setErrMsg(t(`No of points should be between ${minPoints} and ${maxPoints}`));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (firstName && lastName && gender && dateOfBirth) {

            const v = {...values};

            if (phoneNumber) {
                v.phone = {
                    fullNumber: fullPhoneNumberWithoutSpaces,
                    code: values.phoneCountry.code,
                    number: values.phoneNumber
                }
            } else {
                v.phone = undefined;
            }


            setStepsData({...stepsData, ...v});

            console.log(stepsData);

            handleNext(2);
        } else {
            setErrMsg(t('common.inputError'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }


    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phoneCountry: newValue});
    };

    /*useEffect(() => {
        if (stepsData?.levelProgress) {
            const { actualLevel2, noOfPoints } = stepsData.levelProgress;

            if (actualLevel2) {
                const { label } = actualLevel2;

                // Find the current level and the next level
                const currentLevel = levelsWithPoints.find(level => level.label === label);
                const currentIndex = levelsWithPoints.indexOf(currentLevel);
                const minPoints = currentLevel.points; // Minimum points for the current level
                const maxPoints = currentIndex < levelsWithPoints.length - 1
                    ? levelsWithPoints[currentIndex + 1].points // Points for the next level
                    : currentLevel.points + 100; // If it's the last level, set maxPoints to current + 100

                console.log("Updated Level Progress:", {
                    actualLevel2,
                    noOfPoints,
                    minPoints,
                    maxPoints,
                });

                // Update your state if necessary
                setValues((prevValues) => ({
                    ...prevValues,
                    levelProgress: {
                        ...prevValues.levelProgress,
                        actualLevel2, // Keep the current actualLevel2
                        noOfPoints: noOfPoints !== null ? noOfPoints : '', // Default to '' if null
                    },
                    minPoints,
                    maxPoints,
                }));
            }
        }
    }, [stepsData]);*/

    useEffect(() => {
        if (stepsData?.levelProgress) {
            const { actualLevel2, noOfPoints } = stepsData.levelProgress;

            if (actualLevel2) {
                const { label } = actualLevel2;

                // Find the current level from levelsWithPoints1
                const currentLevel = levelsWithPoints1.find(level => level.label === label);

                if (currentLevel) {
                    const minPoints = currentLevel.points; // Minimum points for the current level
                    const maxPoints = currentLevel.maxPoints; // Maximum points from the current level

                    console.log("Updated Level Progress:", {
                        actualLevel2,
                        noOfPoints,
                        minPoints,
                        maxPoints,
                    });

                    // Update your state if necessary
                    setValues((prevValues) => ({
                        ...prevValues,
                        levelProgress: {
                            ...prevValues.levelProgress,
                            actualLevel2, // Keep the current actualLevel2
                            noOfPoints: noOfPoints !== null ? noOfPoints : '', // Default to '' if null
                        },
                        minPoints, // Update state with minPoints from current level
                        maxPoints, // Update state with maxPoints from current level
                    }));
                }
            }
        }
    }, [stepsData]);



    const handleNoOfPointsChange = (e) => {
        const input = e.target.value;
        // Ensure the input contains only numbers by removing any non-digit characters.
        const onlyNumbers = input.replace(/\D/g, '');

        const isValid = onlyNumbers >= values.minPoints && onlyNumbers <= values.maxPoints;

        // Update the state with the cleaned input directly.
        setValues((prevValues) => ({
            ...prevValues,
            levelProgress: {
                ...prevValues.levelProgress,
                noOfPoints: onlyNumbers,
            },
            error: !isValid,
            helperText: onlyNumbers
                ? `Enter points between ${values.minPoints} and ${values.maxPoints}` // Use current values of min and max points
                : '',
            helperTextColor: isValid ? 'blue' : 'red', // Dynamic helper text color
        }));
    };


    const handleLevelChange = (e) => {
        const selectedLabel = e.target.value;
        const selectedLevel = levelsWithPoints1.find((level) => level.label === selectedLabel);

        setValues((prevValues) => ({
            ...prevValues,
            levelProgress: {
                ...prevValues.levelProgress,
                actualLevel2: {
                    label: selectedLevel.label,
                    points: selectedLevel.points,
                },
                noOfPoints: '', // Reset points when changing the level
            },
            error: '', // Clear any previous errors
            minPoints: selectedLevel.points, // Set the minimum points for the selected level
            maxPoints: selectedLevel.maxPoints, // Set the maxPoints based on the selected level
            helperText: `Enter points between ${selectedLevel.points} and ${selectedLevel.maxPoints}`, // Set the helper text for guidance
        }));
    };


    const handleLevelChangeOld = (e) => {
        const selectedLabel = e.target.value;
        const selectedLevel = levelsWithPoints.find((level) => level.label === selectedLabel);
        const nextLevel = levelsWithPoints.find((level, index) => levelsWithPoints[index - 1]?.label === selectedLabel);

        setValues((prevValues) => ({
            ...prevValues,
            levelProgress: {
                ...prevValues.levelProgress,
                actualLevel2: {
                    label: selectedLevel.label,
                    points: selectedLevel.points,
                },
                noOfPoints: '',
            },
            error: '',
            minPoints: selectedLevel.points,
            maxPoints: nextLevel ? nextLevel.points : selectedLevel.points,
        }));
    };

    const validatePoints = () => {
        const { noOfPoints, actualLevel2 } = values.levelProgress;
        const minPoints = values.minPoints;
        const maxPoints = values.maxPoints;

        if (
            noOfPoints &&
            (parseInt(noOfPoints, 10) < minPoints ||
                parseInt(noOfPoints, 10) > maxPoints)
        ) {
            setValues((prevValues) => ({
                ...prevValues,
                error: `No of points should be between ${minPoints} and ${maxPoints}`,
            }));
        } else {
            setValues((prevValues) => ({
                ...prevValues,
                error: '',
                helperText: `Enter points between ${minPoints} and ${maxPoints}`,
            }));
        }
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form noValidate autoComplete="off">
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            ml: 2,
                        }}>
                            <Typography variant={"h5"}>{(t('parent-children.title.profileImage'))}</Typography>
                            {!values.newImage && values.images?.length > 0 ? (
                                    <DDImageField name={"profile_pic"}
                                                  alt={"user profile pic"}
                                                  onFileSelection={async (file) => {
                                                      let b = await fetch(file).then(r => r.blob());
                                                      if (b.size > 1000000) {
                                                          setErrMsg(t("common.sizeErr"))
                                                          setTimeout(() => {
                                                              setErrMsg("");
                                                          }, 3000);
                                                      } else {
                                                          setValues({...values, newImage: file});
                                                      }
                                                  }}
                                                  sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                                  path={values.images[0]}
                                                  alt={values.firstName + ' ' + values.lastName}/>) :
                                <JumboAvatarField
                                    name={"profile_pic"}
                                    alt={"user profile pic"}
                                    src={values.newImage}
                                    onFileSelection={async (file) => {
                                        let b = await fetch(file).then(r => r.blob());
                                        if (b.size > 1000000) {
                                            setErrMsg(t("common.sizeErr"))
                                            setTimeout(() => {
                                                setErrMsg("");
                                            }, 3000);
                                        } else {
                                            setValues({...values, newImage: file});
                                        }
                                    }}
                                    sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                />}

                            <Typography sx={{mb: 2}}>{(t('parent-children.title.profileDesc'))}</Typography>

                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,

                        }}>
                            <Div sx={{}}>
                                <InputLabel sx={{ml: 1}} error={errTitle}
                                            id="demo-simple-select-label">{(t('parent-children.title.gender'))} *</InputLabel>
                                <Select
                                    required
                                    sx={{ml: 1, width: '94%', maxWidth: "100%"}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.gender}
                                    onChange={handleChange('gender')}
                                    label={(t('parent-children.title.gender'))}
                                    error={errTitle}
                                >
                                    <MenuItem value=" ">{(t('parent-children.title.select'))}</MenuItem>
                                    <MenuItem value="male">{(t('parent-children.title.male'))}</MenuItem>
                                    <MenuItem value="female">{(t('parent-children.title.female'))}</MenuItem>
                                </Select>
                            </Div>
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},
                        }}>
                            <TextField
                                required
                                error={errFirstName}
                                label={(t('common.firstName'))}
                                value={values.firstName}
                                onChange={handleChange('firstName')}

                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '47%'},
                        }}>
                            <TextField
                                required
                                label={(t('common.lastName'))}
                                value={values.lastName}
                                onChange={handleChange('lastName')}

                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},

                        }}>

                            <TextField
                                id="date"
                                required
                                label={(t('parent-children.title.dateBirth'))}
                                type="date"
                                value={values.dateOfBirth}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChange('dateOfBirth')}
                            />

                        </FormControl>


                        <FormControl variant="outlined" sx={{
                            width: {xs: '100%', sm: '100%', xl: '45%'},
                        }}>


                            <Autocomplete
                                value={values.country}
                                id="country-select-demo"
                                options={COUNTRIES}
                                autoHighlight
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    setValues({...values, country: newValue, residenceCountry: newValue.code})
                                }}
                                getOptionLabel={(option) => {
                                    console.log(option);
                                    return option.label ? option.label : '';
                                }}
                                disableClearable={true}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt=""
                                        />
                                        {option.label} ({option.code})
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('common.country')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <React.Fragment>
                                                    {values.country?.code && (
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`https://flagcdn.com/w20/${values.country.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${values.country.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '50%'},

                        }}>

                            <TextField
                                id="email"
                                label={t('common.email')}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                                value={values.email}
                                onChange={handleChange('email')}
                            />
                        </FormControl>


                        <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                            <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                                <Autocomplete
                                    value={values.phoneCountry}
                                    id="country-select-demo"
                                    options={PHONE_COUNTRIES}
                                    autoHighlight
                                    onChange={handleCountryChange}
                                    getOptionLabel={(option) => {
                                        return option.code ? ' +' + option.code : '';
                                    }}
                                    disableClearable={true}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label}+{option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'Code'}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <React.Fragment>
                                                        {values.phoneCountry?.countryCode && (
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${values.phoneCountry.countryCode.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${values.phoneCountry.countryCode.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl variant="outlined" sx={{flex: 1}}>
                                <TextField
                                    id="phoneNumber"
                                    label={t('common.phone')}
                                    fullWidth
                                    value={values.phoneNumber}
                                    onChange={handleChangePhone}
                                />
                            </FormControl>
                        </Div>

                        <FormControl sx={{ width: '100%', mt: 1 }}>
                            <Div>
                                <InputLabel sx={{ ml: 1 }} error={errTitle} id="demo-simple-select-label">
                                    {(t('Actual Level'))} *
                                </InputLabel>
                                <Select
                                    required
                                    sx={{ ml: 1, width: '94%', maxWidth: '100%' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values?.levelProgress?.actualLevel2?.label || ''}
                                    onChange={handleLevelChange}
                                    label={(t('Actual Level'))}
                                    error={errTitle}
                                >
                                    <MenuItem value="">{(t('parent-children.title.select'))}</MenuItem>
                                    {levelsWithPoints1.map((level) => (
                                        <MenuItem key={level.label} value={level.label}>
                                            {level.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Div>
                        </FormControl>

                        <FormControl
                            sx={{ width: { xs: '100%', sm: '100%', xl: '50%' }, mt: 2 }}
                        >
                            <TextField
                                required
                                label={t('No of Points')}
                                value={values.levelProgress?.noOfPoints || ''}
                                onChange={handleNoOfPointsChange}
                                onBlur={validatePoints} // Trigger validation when the input field loses focus.
                                InputProps={{
                                    inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' }, // Ensure the input is numeric.
                                }}
                                error={!!values.error}
                                helperText={values.helperText}
                                FormHelperTextProps={{
                                    style: {
                                        color: values.helperTextColor || 'inherit', // Use the dynamic color or default if not set.
                                    },
                                }}
                            />
                        </FormControl>


                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%', pl: 1, mt: 2}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.cancelBtn"))}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep1}>
                    {(t('common.nextBtn'))}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step1;
