import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";

const Footer = () => {

    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"body1"} color={"text.primary"} style={{ fontSize: "0.7rem"}}>
                    Copyright HipeKids © 2024 Powered By{" "}
                    <a href="https://deliverydigital.fr/" target="_blank">DELIVERY Digital</a>
                </Typography>

                <Div sx={{display: 'flex', alignItems: 'center'}}>
                </Div>
            </Div>
        </Div>
    );
};

export default Footer;
