import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Chip,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";
import Div from "@jumbo/shared/Div";
import {useAppMutation} from "../../../services";
import adminServices from "../../../services/admin-services";
import {useParentContext} from "./context";
import moment from "moment";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";

const PurchaseInfo = () => {
    const {t} = useTranslation();
    const {globalState, setGlobalState} = useParentContext();
    const [planId, setPlanId] = useState('');
    const [parentId, setParentId] = useState('');
    const [disabledDelete, setDisableDelete] = useState(false);

    const {
        mutate: loadSubscriptionsByParent,
        data: subscriptions,
        isSuccess: isSubscriptionsSuccess,
        isLoading: isSubscriptionsLoading
    } = useAppMutation(adminServices.loadSubscriptionsByParent);

    const {
        mutate: loadFreePacks,
        data: freePacks,
    } = useAppMutation(adminServices.loadFreePacks);


    const {
        mutate: deleteFreePlan,
        data: DFPData,
        isSuccess: isDFPSuccess,
        isError: isDFPError
    } = useAppMutation(adminServices.deleteFreePlan);

    const {
        mutate: addFreePacks,
        data: AFPData,
        isLoading: isAFPLoading,
        isSuccess: isAFPSuccess,
        isError: isAFPError
    } = useAppMutation(adminServices.addFreePacks);


    useEffect(() => {
        if (isDFPSuccess) {
            hide();
        } else if (isDFPError) {
            setDisableDelete(false);
        }
    }, [isDFPSuccess, isDFPError]);

    useEffect(() => {
        if (isAFPSuccess) {
            hide();
        }
    }, [isAFPSuccess,]);

    const hide = () => {
        setGlobalState({...globalState, openPurchaseInfo: false, refreshList: true, parent: undefined})
    }

    useEffect(() => {
        if (globalState && globalState.parent) {
            loadFreePacks();
            loadSubscriptionsByParent(globalState.parent.parentId)
            setParentId(globalState.parent.parentId);
        }
    }, [globalState]);


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {mt: 5},
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <Div sx={{minWidth: 120}}>

                    <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                        <InputLabel id="demo-multiple-checkbox-label">Plan</InputLabel>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                        <InputLabel id="demo-multiple-checkbox-label">Plan</InputLabel>
                        <Select
                            value={planId}
                            label={t("parent-subscription.title.sub")}
                            onChange={(event) => {
                                setPlanId(event.target.value);
                            }}
                            renderValue={(selected) => {
                                const selectedChild = freePacks?.find((child) => child.planId === selected);
                                return (
                                    <Div sx={{display: 'flex', alignItems: 'center'}}>
                                        {selectedChild?.name}
                                    </Div>
                                );
                            }}
                        >
                            {freePacks && freePacks.map((item) => (
                                <MenuItem key={item.planId} value={item.planId}>
                                    <div>{item.name}
                                        <br/>
                                        No of Booking {item.noOfBooking}
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>


                    </FormControl>
                    <LoadingButton loading={isAFPLoading} onClick={() => {
                        addFreePacks({planId, parentId})
                    }} disabled={!planId}>Add Free Plan</LoadingButton>

                    <FormControl fullWidth sx={{mb: 3, mt: 1}}>

                        <List dense sx={{width: '100%', bgcolor: 'background.paper'}}>
                            {subscriptions && subscriptions.map((item, index) => (
                                <ListItem
                                    key={index}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText
                                            primary={`${item.plan.name}`} // Include plan name here
                                            secondary={
                                                <>
                                                    <Typography>{t('parent-subscription.title.startDate') + ` : ${moment(item.startDate).format('MMMM DD YYYY')}`}</Typography>
                                                    <Typography>{t('parent-subscription.title.expiryDate') + `: ${moment(item.expiredDate).format('MMMM DD YYYY')}`}</Typography>
                                                    <Typography>{t("common.bookingUtilize") + `: ${item.noOfUsedBooking}/${item.noOfBooking}`}</Typography>
                                                    <Typography>{t('admin-plans.title.totalAmount') + `: ${item.chargedAmount}`}</Typography>

                                                </>
                                            }
                                        />


                                        {item.cancelled && <Chip
                                            label={t('common.cancelled')}
                                            color="error"
                                            size="small"
                                        />}

                                        {item.plan.category === 'subscription' && !item?.paid && <Chip
                                            label={'Pay Now'}
                                            color="warning"
                                            size="small"
                                        />}

                                        <Chip
                                            label={moment(item.expiredDate).isBefore(moment()) ? t('parent-subscription.title.expired') : t('parent-subscription.title.active')}
                                            color={moment(item.expiredDate).isBefore(moment()) ? "error" : "success"}
                                            size="small"
                                        />

                                        {item.plan.category === 'packs' && item.plan.amount <= 0 &&
                                            <LoadingButton disabled={disabledDelete} sx={{ml: 1}} loading={false}
                                                           onClick={() => {
                                                               setDisableDelete(true)
                                                               deleteFreePlan(item.subscriptionId)
                                                           }}>Remove</LoadingButton>}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            {(!subscriptions || subscriptions.length === 0) && (
                                <Div sx={{py: {lg: 1}}}>
                                    <Typography>
                                        {t('parent-subscription.title.noSubscription')}
                                    </Typography>
                                </Div>
                            )}
                        </List>


                    </FormControl>
                </Div>
            </Div>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center', justifyContent: 'center'}}>
                <Button
                    onClick={hide}
                    sx={{mr: 1}}
                >
                    {t("common.closeBtn")}
                </Button>
            </Div>
        </Box>
    );
};

export default PurchaseInfo;
