import React from 'react';
import {useTranslation} from "react-i18next";
import parentServices from "../../../services/parent-services";
import {Alert, Box, Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import Stack from "@mui/material/Stack";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentModel = ({subscription, hide}) => {

    const stripe = useStripe();
    const [errMsg, setErrMsg] = React.useState('');
    const [paymentProcessing, setPaymentProcessing] = React.useState(false);
    const elements = useElements();
    const baseUrl = window.location.origin;
    const {t} = useTranslation();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        setPaymentProcessing(true);
        event.preventDefault();


        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Index Element
            elements,
            confirmParams: {
                return_url: baseUrl + "/parent/complete/payment",
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            setErrMsg(result.error.message);
            console.log(result.error.message);
            setPaymentProcessing(false);
        } else {
            window.location = '/dashboard/subscription'
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return <Div sx={{width: '100%'}}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <form onSubmit={handleSubmit}>
                <PaymentElement/>


                <Stack sx={{width: '100%', pl: 1, mt: 2}} spacing={2}>
                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                </Stack>
                <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                    <Button
                        onClick={() => {
                            hide(true);
                        }}
                        sx={{mr: 1}}
                    >
                        {t('common.cancelBtn')}
                    </Button>
                    <Div sx={{flex: '1 1 auto'}}/>

                    <LoadingButton disabled={!stripe} loading={paymentProcessing} variant={"variant"}
                                   onClick={handleSubmit}>
                        {t('parent-subscription.title.proceed')}
                    </LoadingButton>


                </Div>
            </form>
        </Box>
    </Div>

}
const Payment = ({subscription, hide}) => {
    const {t} = useTranslation();
    const [options, setOptions] = React.useState('');

    React.useEffect(async () => {

        if (!!subscription?.subscriptionId) {
            const response = await parentServices.loadSecret({subscriptionId: subscription?.subscriptionId});
            console.log(response);
            setOptions({clientSecret: response.secret})
        }

    }, [subscription])

    console.log(options);

    if (options) {
        return (

            <Dialog open={!!subscription?.subscriptionId}>
                <DialogTitle>{t('parent-subscription.title.planPay')}</DialogTitle>
                <DialogContent sx={{width: '100%'}}>
                    {!!subscription?.subscriptionId &&
                        <Elements stripe={stripePromise} options={options}><PaymentModel hide={hide}
                                                                                         subscription={subscription}/></Elements>}
                </DialogContent>
            </Dialog>)
    }

    return null;


};

export default Payment;
