import {createContext, useContext, useState} from 'react';

const PromotionsContext = createContext({});

export const usePromotionsContext = () => {
    return useContext(PromotionsContext);
}

export const PromotionsProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshPromotionList : false});

    return <PromotionsContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </PromotionsContext.Provider>
}
