import {createContext, useContext, useState} from 'react';

const PlansContext = createContext({});

export const usePlansContext = () => {
    return useContext(PlansContext);
}

export const PlansProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshPlansList : false});

    return <PlansContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </PlansContext.Provider>
}
