import React, {useEffect} from 'react';
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, TextField, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import Select from "@mui/material/Select";
import FileBase64 from 'react-file-base64';
import DDFile from "../../../../component/DDFile";
import commonServices from "../../../../services/common-services";


const HOURS = []

for (let i = 1; i <= 40; i++) {
    if (i === 1) {
        HOURS.push({key: i + '-hour', label: i + ' Hour'});
    } else {
        HOURS.push({key: i + '-hours', label: i + ' Hours'});
    }

}
const Step4 = ({hide, setStepsData, stepsData, handleNext}) => {

    const [values, setValues] = React.useState({
        videoDemoLink: '',
        referred: '',
        references: '',
        weeklyHoursToWork: '',
    });
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(adminServices.saveTeacher);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [value, setValue] = React.useState();

    useEffect(() => {
        console.log(isSuccess, isError)
        if (stepsData) {
            setValues({...values, ...stepsData});
        }

    }, [stepsData]);

    useEffect(() => {
        if (isSuccess) {
            hide();
        } else if (isError) {
            setErrMsg(t('errMsg.saving'));
        }
    }, [isSuccess, isError])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep4 = async () => {
        setErrMsg('')

        const {
            videoDemoLink,
            referred,
            references,
            weeklyHoursToWork,
        } = values;

        setStepsData({
            ...stepsData, videoDemoLink,
            referred,
            references,
            weeklyHoursToWork
        })

        handleNext(5);


    };

    const backToStep3 = async () => {
        handleNext(3);
    };

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {},
            }}
            noValidate
            autoComplete="off"
        >
            <Div sx={{display: 'flex', flexDirection: 'column', mt: 2}}>

                <FormControl sx={{
                    width: '100%',
                    mt: 1,
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('teacherForm.refer')}
                        value={values.references}
                        name="references"
                        onChange={handleChange('references')}
                    />


                </FormControl>

                <FormControl sx={{
                    width: '100%',
                    mt: 3,
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('teacherForm.whoRefer')}
                        value={values.referred}
                        name="reffered"
                        onChange={handleChange('referred')}
                    />


                </FormControl>

                <FormControl sx={{
                    width: '100%',
                    mt: 3,
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('teacherForm.link')}
                        value={values.videoDemoLink}
                        name="demoLink"
                        onChange={handleChange('videoDemoLink')}
                    />


                </FormControl>


                <FormControl sx={{
                    width: '100%',
                    mt: 3,
                }}>
                    <InputLabel id="demo-simple-select-label">{t('teacherForm.duration')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-hoursToWork"
                        value={values.weeklyHoursToWork}
                        onChange={handleChange('weeklyHoursToWork')}
                        label={t('teacherForm.duration')}
                    >
                        <MenuItem value="">{t('common.select')}</MenuItem>

                        {HOURS.map((item, index) => {
                            return <MenuItem value={item.key}>{item.label}</MenuItem>
                        })}


                    </Select>
                </FormControl>
                <FormControl sx={{
                    width: '100%',
                    mt: 2,
                }}>
                    <Typography variant={"h4"}>{t('teacherForm.evidence')}</Typography>
                    <FileBase64
                        maxSize={1000000}
                        multiple={false}
                        onDone={(file) => {
                            if (file.file.size > 1000000) {
                                setErrMsg(t("common.sizeErr"))
                                setTimeout(() => {
                                    setErrMsg("");
                                }, 3000);
                                setValues({...values, noCriminalBase64: undefined})
                            } else {
                                setValues({...values, noCriminalBase64: file.base64})
                            }
                        }}/>
                    {values.noCriminalBase64 && <DDFile base64={values.noCriminalBase64}/>}
                    {!values.noCriminalBase64 && values?.noCriminalFiles?.length > 0 &&
                        <DDFile path={values?.noCriminalFiles[0]}/>}
                </FormControl>

            </Div>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        hide();
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.closeBtn")}
                </Button>
                <Button
                    onClick={backToStep3}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>
                <Button variant={"contained"} onClick={submitStep4}>
                    {t("common.nextBtn")}
                </Button>
            </Div>
        </Box>
    );

};

export default Step4;
