import React from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useTeacherContext} from "../context";

const Steps = ({}) => {

    const {
        mutate,
        isError: isLTError,
        isSuccess: isLTSuccess,
        data: teacher,
        isLoading
    } = useAppMutation(adminServices.loadTeacher);
    const [stepsData, setStepsData] = React.useState(undefined);
    const {globalState, setGlobalState} = useTeacherContext();
    const {t} = useTranslation();
    const steps = [t('teacherForm.form1'),t('teacherForm.form2'),t('teacherForm.form3'),t('teacherForm.form4'),t('teacherForm.form5')];
    const [activeStep, setActiveStep] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);
    const dispatch = useDispatch();


    const hide = () => {
        setGlobalState({...globalState, openAddEdit: false, refreshList: true, teacher: undefined})
    }

    React.useEffect(() => {
        console.log(globalState)
        if (globalState && globalState.teacher) {
            mutate(globalState.teacher.teacherId)
        }
    }, [globalState])

    React.useEffect(() => {
        if (isLTSuccess) {
            setStepsData({...teacher});
        }
    }, [isLTError, isLTSuccess])

    {/*}
    useEffect(() => {
        console.log(globalState)
        if (globalState && globalState.teacher) {
            mutate(globalState.teacher.teacherId)
        }
    }, [globalState])

    useEffect(() => {
        if (isLPSuccess) {
            setStepsData({...teacher});
        }


    }, [isLPError, isLPSuccess]) */
    }


    const handleNext = (step) => {

        // alert(step);

        if (step === 6) {
            hide();
        } else {
            setActiveStep(step - 1);
        }
    };


    const handleReset = () => {
        setActiveStep(0);
    };

    return (

        <Div sx={{width: '100%'}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (<Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>);
                })}
            </Stepper>
            <Div>
                {activeStep + 1 === 1 &&
                    <Step1 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 2 &&
                    <Step2 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 3 &&
                    <Step3 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 4 &&
                    <Step4 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 5 &&
                    <Step5 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
            </Div>
        </Div>
    )
};

export default Steps;
