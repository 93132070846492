import React,{useState} from 'react';
import {Button, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {useChildrenProgressContext} from "./context";
import {useTranslation} from "react-i18next";
import Avatar from "@mui/material/Avatar";
import {getAssetPath} from "../../../utils/appHelpers";
import {ASSET_AVATARS} from "../../../utils/constants/paths";
import DDImage from "../../../component/DDImage";

const ChildrenAppSidebar = ({title, subheader}) => {

    const {globalState, setGlobalState} = useChildrenProgressContext();
    const {t} = useTranslation();
    const [student, setStudent] = useState({});



    React.useEffect(() => {
        if (globalState.statisticsData?.student) {
            // Save the exact data from globalState.statisticsData.student
            setStudent(globalState.statisticsData.student);

            // Log the student data to the console (optional)
            console.log('Student Data:', globalState.statisticsData.student);
        }

    }, [globalState])


    return (
        <React.Fragment>

            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3,
                }}
            >
                <Div>
                    {student.images?.length > 0 ? (
                            <DDImage path={student.images[0]} alt={student.firstName + ' ' + student.lastName} sx={{ width: 60, height: 60, mr:1 }}/>) :
                        (<Avatar alt={student.firstName + ' ' + student.lastName} sx={{ width: 60, height: 60, mr:1 }}/>)}
                </Div>
                <Div sx={{flex: '1 1 auto'}}>
                    <Typography variant={"h5"} mb={.35}>{student.firstName}</Typography>
                    <Typography variant={"h5"} >{student.lastName}</Typography>
                </Div>
            </Div>



        </React.Fragment>
    );
};
export default React.memo(ChildrenAppSidebar);
