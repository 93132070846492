import React, {useState} from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import {
    Alert,
    Autocomplete,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle, FormControl,
    ListItem,
    ListItemIcon,
    ListItemText, TextField,
    Typography
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {COUNTRIES, findCountryByCode} from "../../../../utils/constants/appActions";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CottageIcon from '@mui/icons-material/Cottage';
import {useAppMutation} from "../../../../services";
import {useProfileContext} from "../../../parent/Profile/context";
import parentServices from "../../../../services/parent-services";
import {useTranslation} from "react-i18next";

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));

const AboutForm = ({hide, location}) => {

    const {
        mutate: updateInfo,
        isSuccess,
        data,
        isError,
        isLoading
    } = useAppMutation(parentServices.updateProfileAddressDetails);
    const {globalState, setGlobalState} = useProfileContext();
    const [values, setValues] = React.useState({});
    const [errMsg, setErrMsg] = React.useState('');
    const [countryRefresh, setCountryRefresh] = React.useState(false);
    const {t} = useTranslation();

    React.useEffect(() => {

        console.log('location---------------------', location)
        if (location) {
            const country = findCountryByCode(location.country);
            setValues({...location, country});
            setCountryRefresh(true);
        }

    }, [location]);


    React.useEffect(() => {
        if (isSuccess) {
            setGlobalState({...globalState, refreshList: true})
            hide(false);
        }
    }, [isError, data, isSuccess])


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    }

    const saveAddress = async () => {

        setErrMsg('')

        const {address1, address2,  country, city, postalCode} = values;

        console.log(values)

        // if (address1 || address2  && country && city && postalCode) {

        if (country) {
            updateInfo({location: {...values, country :  values.country?.code}})
        } else {
            setErrMsg(t('common.inputError'))
        }


    };

    const handleCountryChange = (event, newValue) => {
        setValues({...values, country: newValue});
    };

    return <Div><FormControl noValidate autoComplete="off">
        <Div
            sx={{
                '& .MuiTextField-root': {
                    mb: 3,
                    mt: 0.5
                },
            }}
        >
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                name="address1"
                label={t('parent-profile.title.address1')}
                value={values.address1}
                onChange={handleChange('address1')}
            />
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                name="address2"
                label={t('parent-profile.title.address2')}
                value={values.address2}
                onChange={handleChange('address2')}
            />

            {countryRefresh  && <Autocomplete
                id="country-select-demo"
                sx={{maxWidth: '100%'}}
                options={COUNTRIES}
                autoHighlight
                value={values?.country}
                onChange={handleCountryChange}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label} ({option.code})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        required
                        {...params}
                        label={t('parent-profile.title.country')}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                    />
                )}
            />}

            <Div sx={{display: 'flex', alignItems: 'center'}}>
                <FormControl variant="outlined" sx={{marginRight: 1}}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="city"
                        label={t('parent-profile.title.city')}
                        value={values.city}
                        onChange={handleChange('city')}
                    />
                </FormControl>

                <FormControl variant="outlined">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="postalCode"
                        label={t('common.postalCode')}
                        value={values.postalCode}
                        onChange={handleChange('postalCode')}
                    />
                </FormControl>
            </Div>

            <Stack
                sx={{
                    mb: 2,
                    width: '90%',
                    mx: 'auto',
                    display: 'block',
                    position: 'relative', // Add position relative to enable absolute positioning of loader
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    sx={{mr: 1,width:"30%"}}
                    onClick={() => hide(false)}
                >
                    {t('common.closeBtn')}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>


                <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isLoading}
                    onClick={saveAddress}
                    sx={{width:"30%"}}
                >
                    {t('common.saveBtn')}
                </LoadingButton>
            </Div>



        </Div>
    </FormControl></Div>
}


const Address = () => {

    const [value, setValue] = React.useState('1');
    const [openDialog, setOpenDialog] = React.useState(false);
    const {globalState, setGlobalState} = useProfileContext();
    const [country, setCountry] = useState();
    const {t} = useTranslation();

    React.useEffect(() => {
        if (globalState?.profile) {
            setCountry(findCountryByCode(globalState.profile.location.country));
            setValues({...globalState.profile})
        }
    }, [globalState])

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };


    const [values, setValues] = React.useState({location: {}});

    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>{t('parent-profile.title.addressDetails')}
                    <Span sx={{color: 'text.secondary', fontSize: 13}}>
                        <EditOutlinedIcon onClick={handleEmailIconClick} sx={{fontSize: 13,ml:1}} />
                    </Span>
                </Typography>
            }
            action={
                <TabContext value={value}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        {/*<TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Overview" value="1"/>
                            <Tab label="Work" value="2"/>
                            <Tab label="Education" value="3"/>
                        </TabList>*/}
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{mb: 3.75}}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                    mb:2
                }}
            >
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '100%'},
                    }}
                >
                    <StyledListItemIcon>
                        <CottageIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText

                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent-profile.title.address')}</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">
                            {values.location.address1}{values.location.address2} </Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <ApartmentIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent-profile.title.city')}
                    </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{values.location.city}</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <LanguageOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('parent-profile.title.country')}
                    </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{country?.label}</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <FlagOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t('common.postalCode')}
                    </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{values.location.postalCode}</Typography>}
                    />
                </ListItem>
            </List>

            <Div>
                <Dialog open={openDialog}>
                    <DialogTitle>{t('parent-profile.title.updateAddress')}</DialogTitle>
                    <DialogContent>
                        <AboutForm hide={setOpenDialog} location={globalState?.profile?.location}/>
                    </DialogContent>
                </Dialog>
            </Div>

        </JumboCardQuick>
    );
};

export default Address;
