import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
    FormControl,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    InputAdornment,
    Alert, Autocomplete, Box
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Span from "@jumbo/shared/Span";
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import Stack from "@mui/material/Stack";
import Popover from '@mui/material/Popover';
import SaveIcon from '@mui/icons-material/Save';
import {PHONE_COUNTRIES} from "../../../../utils/constants/appActions";
import {useTranslation} from "react-i18next";
const Contacts = () => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const [values, setValues] = React.useState({
        email: 'contact@deliverydigital.fr',
        firstName: 'Delivery',
        lastName: 'Digital',
        phone: '7 49 70 77 73',
        country: {
            countryCode: 'FR',
            label: 'France',
            code: '33',
            suggested: true,
        },
    });

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleSignup = () => {
        if (
            !values.email ||
            !values.firstName ||
            !values.lastName ||
            !values.country ||
            !values.phone
        ) {
            setErrMsg(t("admin-profile.title.errMsg"));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else {
            const fullPhoneNumber = `+${values.country.code}${values.phone}`;
            const obj = {
                ...values,
                location: {country: values.country.code},
                phone: {
                    fullNumber: fullPhoneNumber,
                    code: values.country.code,
                    number: values.phone
                },
            };
            console.log("Contact Update------------", obj);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [loading, setLoading] = React.useState(false);
    const {t} = useTranslation();

    function handleClick1() {
        setLoading(true);
    }

    const handleCountryChange = (event, newValue) => {
        console.log(newValue)
        setValues({...values, country: newValue});
    };


    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>Contact
                    <Span sx={{color: 'text.secondary', fontSize: 13}}>
                        <EditOutlinedIcon onClick={handleEmailIconClick} sx={{fontSize: 13, ml:1, cursor: 'pointer'}} />
                    </Span>
                </Typography>
            }
            noWrapper>
            <List disablePadding sx={{mb: 2}}>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <EmailOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t("common.email")}</Typography>}
                        secondary={<Link variant="body1" aria-describedby={id} onClick={handleClick}  underline="none">contact@deliverydigital.fr</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <InsertLinkOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t("admin-profile.title.name")}</Typography>}
                        secondary={<Link variant="body1" href="#" underline="none">Delivery Digital</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <LocalPhoneOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t("admin-profile.title.phone")}</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">+33 7 49 70 77 73</Typography>}
                    />
                </ListItem>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{ // Set width and height using sx prop
                        '& .MuiPopover-paper': {
                            width: 'auto',
                        },
                    }}
                >
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                m:2
                            },
                        }}
                    >
                    <TextField
                        id="input-with-icon-textfield"
                        sx={{ width: '300px' }}
                        size="small"
                        name="email"
                        label={t("common.email")}
                        value={values.email}
                        onChange={handleChange('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon/>
                                </InputAdornment>
                            ),
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                        </Div>
                </Popover>
            </List>


            <Div>
                <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>{t("admin-profile.title.updateContact")}</DialogTitle>
                    <DialogContent>


                        <FormControl fullWidth noValidate autoComplete="off">
                            <Div
                                sx={{
                                    '& .MuiTextField-root': {
                                        mb: 3,
                                        mt:0.9
                                    },
                                }}
                            >
                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="email"
                                    label={t('common.email')}
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon/>
                                            </InputAdornment>
                                        ),
                                        readOnly: true,
                                    }}
                                    variant="filled"
                                />

                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="firstName"
                                    label={t('common.firstName')}
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="filled"
                                    error={!values.firstName}
                                    helperText={!values.firstName ? t("admin-profile.title.firstNameError") : ''}
                                />

                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="lastName"
                                    label={t("common.lastName")}
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="filled"
                                    error={!values.lastName}
                                    helperText={!values.lastName ? t("admin-profile.title.lastNameError") : ''}
                                />


                                <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                                    <FormControl variant="outlined" sx={{flex: '0 0 110px', marginRight: 2}}>
                                        <Autocomplete
                                            defaultValue={values.country}
                                            id="country-select-demo"
                                            options={PHONE_COUNTRIES}
                                            autoHighlight
                                            onChange={handleCountryChange}
                                            getOptionLabel={(option) => {
                                                return ' +' + option.code;
                                            }}
                                            disableClearable={true}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                    {option.label} ({option.countryCode}) +{option.code}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("admin-profile.title.country")}
                                                    required
                                                    variant="filled"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                {values.country && (
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${values.country.countryCode.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${values.country.countryCode.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>

                                    <FormControl variant="outlined" sx={{flex: 1}}>
                                        <TextField
                                            required
                                            id="phone"
                                            label={t("admin-profile.title.phone")}
                                            fullWidth
                                            value={values.phone}
                                            onChange={handleChange('phone')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="filled"
                                            error={!values.phone}
                                            helperText={!values.phone ? 'Phone is required' : ''}
                                        />
                                    </FormControl>
                                </Div>


                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    endIcon={<SaveIcon/>}
                                    loading={loading}
                                    loadingPosition="end"
                                    sx={{
                                        mb: 2,
                                        width: '90%',
                                        mx: 'auto',
                                        justifyContent:"center",
                                        alignItems:'center',
                                        display:"flex"
                                    }}
                                    onClick={handleSignup}
                                >
                                    {t("common.saveBtn")}
                                </LoadingButton>


                                <Stack
                                    sx={{
                                        mb: 2,
                                        width: '90%',
                                        mx: 'auto',
                                        display: 'block',
                                        position: 'relative', // Add position relative to enable absolute positioning of loader
                                    }}
                                    spacing={2}>
                                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                </Stack>

                            </Div>
                        </FormControl>

                    </DialogContent>
                </Dialog>
            </Div>


        </JumboCardQuick>
    );
};

export default Contacts;
