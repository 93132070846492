import Div from "@jumbo/shared/Div";
import React from "react";
import {Step, StepLabel, Stepper} from "@mui/material";
import Step2 from "./step2";
import Step1 from "./step1";
import {useTranslation} from "react-i18next";



const Form = ({hide, setShowTeacherFeedBack, teacherFeedback}) => {

    const [activeStep, setActiveStep] = React.useState(1);
    const {t} = useTranslation();
    const steps = [t('feedbackForm.basicInfo'),t('feedbackForm.complete'),t('feedbackForm.finish')];
    const handleNext = (step) => {

        if (step === 4) {


            hide();

        } else {
            setActiveStep(step - 1);
        }
    };


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>

        <Div>
            {activeStep + 1 === 1 &&
                <></>}
            {activeStep + 1 === 2 &&
                <Step1 teacherFeedback={teacherFeedback}
                       setShowTeacherFeedBack={setShowTeacherFeedBack}
                       handleNext={handleNext}
                       hide={hide}/>}
            {activeStep + 1 === 3 &&
                <Step2 teacherFeedback={teacherFeedback} handleNext={handleNext}
                       hide={hide}/>}


        </Div>
    </Div>;
}


export default Form;
