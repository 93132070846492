import React from 'react';
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import {CalendarProvider, useCalendarContext} from "./context";
import CalendarBasic from "./calendars/CalendarBasic";
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import BookingForm from "./BookingForm";
import {useTranslation} from "react-i18next";
import AppointmentForm from "./AppointmentForm";
import moment from "moment";


const Children = () => {
    return <CalendarProvider><InnerChildren/></CalendarProvider>
}

const InnerChildren = () => {

    const {globalState, setGlobalState} = useCalendarContext();
    const {t} = useTranslation();


    return (
        <JumboContentLayout
            layoutOptions={{}}
            //sidebar={<Sidebar/>}
            header={
                <Header title={t("pages.title.calendar")}
                        subheader={t("parent.title.bookingSubheader")}/>
            }

        >
            <CalendarBasic/>


            <Dialog open={globalState.addEditBooking}>
                <DialogTitle>{t("parent.title.courseForm")}</DialogTitle>
                <DialogContent>
                    <BookingForm/>
                </DialogContent>
            </Dialog>

            <Dialog open={globalState.addEditAppointment}>
                <DialogTitle>{t("admin-appointments.title.appointmentForm")}</DialogTitle>
                <DialogContent>
                    <AppointmentForm/>
                </DialogContent>
            </Dialog>


            <Dialog open={globalState.add}>
                <DialogTitle>{t("calendar.add")}</DialogTitle>
                <DialogContent>


                    <Button
                        type="button"
                        variant="contained"
                        size="medium"
                        onClick={() => {

                            const selectedDate = globalState.selectedDate;
                            // selectedDate.setDate(selectedDate.getDate() + 1)

                            // Set the time of the current date to midnight
                            const currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);


                            const maxDate = new Date();
                            maxDate.setMonth(maxDate.getMonth() + 1);
                            maxDate.setHours(0, 0, 0, 0);


                            // Adjust time zone offset when formatting the date
                            const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
                            const booking = {
                                sstartDate: new Date(selectedDate),
                                startDate: moment(selectedDate).format('YYYY-MM-DD'),
                            }
                            setGlobalState({
                                ...globalState,
                                booking,
                                minDate: new Date(),
                                maxDate,
                                addEditBooking: true,
                                add: false
                            })


                            // setGlobalState({...globalState, addEditBooking: true})
                        }}
                        sx={{
                            mr: .5,
                        }}
                    >
                        {t("calendar.bookingBtn")}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            const selectedDate = globalState.selectedDate;
                            // selectedDate.setDate(selectedDate.getDate() + 1)

                            // Set the time of the current date to midnight
                            const currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);


                            const maxDate = new Date();
                            maxDate.setMonth(maxDate.getMonth() + 1);
                            maxDate.setHours(0, 0, 0, 0);


                            // Adjust time zone offset when formatting the date
                            const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
                            const appointment = {
                                sstartDate: new Date(selectedDate),
                                startDate: moment(selectedDate).format('YYYY-MM-DD'),
                            }
                            setGlobalState({
                                ...globalState,
                                appointment,
                                minDate: new Date(),
                                maxDate,
                                addEditAppointment: true,
                                add: false
                            })

                        }}
                        sx={{

                            ml: .5,
                        }}
                    >
                        {t("calendar.appointmentBtn")}
                    </Button>

                    <Button
                        type="button"
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            setGlobalState({...globalState, add: false})
                        }}
                        sx={{

                            ml: .5,
                        }}
                    >
                        {t("common.closeBtn")}
                    </Button>

                </DialogContent>
            </Dialog>


        </JumboContentLayout>

    );
};

export default Children;
