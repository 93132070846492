import React, {useEffect} from 'react';
import {
    Alert,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl, IconButton, InputAdornment, InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText, OutlinedInput, TextField,
    Typography
} from "@mui/material";
import {Switch} from "@mui/material";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@mui/material/styles/styled";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import List from "@mui/material/List";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import {useTranslation} from "react-i18next";
import teacherServices from "../../../../services/teacher-services";
import SendIcon from '@mui/icons-material/Send';
import adminServices from "../../../../services/admin-services";

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));

const Settings = () => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [successMsg, setSucessMsg] = React.useState('');
    const {mutate: resetPassword, error, isError, isLoading, data: result, isSuccess} = useAppMutation(adminServices.resetPassword);
    const {t} = useTranslation();

    useEffect(() => {
        if (result && isSuccess) {
            console.log("Reset----",result, isSuccess)
        }
        if (isError && error) {
            setErrMsg(t('common.signInErrorMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
        if(isSuccess)
        {
            setSucessMsg(t('errMsg.passwordReset'))
            setTimeout(() => {
                setSucessMsg("");
                setOpenDialog(false);
            }, 3000);
        }
        console.log('Reset-----------------------', isError, error, isSuccess, result)
    }, [result, isSuccess, isError])

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [values, setValues] = React.useState({
        password: '',
        newPassword: '',
        showPassword: false,
        showNewPassword: false,

    });

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleResetPassword = () => {
        if (!values.password){
            setErrMsg(t('parent-profile.title.currentPasswordMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (!values.newPassword) {
            setErrMsg(t('parent-profile.title.newPasswordMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (values.password === values.newPassword) {
            setErrMsg(t('parent-profile.title.differentPasswordMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (values.newPassword.length < 8) {
            setErrMsg(t('parent-profile.title.validPasswordMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (!/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])/.test(values.newPassword)) {
            setErrMsg(t('parent-profile.title.validationMsg'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else {
            console.log("Reset Password------------", values);
            resetPassword(values);
            setValues({
                password: '',
                newPassword: '',
                showPassword: false,
                showNewPassword: false,
            });
        }
    };


    const [checked, setChecked] = React.useState(['wifi']);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>Settings </Typography>
            }
            wrapperSx={{pt: 0}}
        >
            {/*<List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                    marginTop:2
                }}
            >
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '100%'}
                    }}
                >
                    <StyledListItemIcon>
                        <LockResetOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Link variant="body1" href="#" onClick={handleEmailIconClick} sx={{fontSize: 15}} underline="none">Reset Password</Link>}
                    />
                </ListItem>

            </List>*/}

            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
            }}
            >
                <ListItem>
                    <ListItemIcon>
                        <LockResetOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" primary={t('sidebar.menuItem.resetPassword')}/>
                    <Typography
                        variant="body1"
                        href="#"
                        onClick={handleEmailIconClick}
                        sx={{
                            fontSize: 15,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        underline="none"
                    >{t('common.resetBtn')}
                    </Typography>
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <FeedOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-bluetooth" primary={t('common.newsLetter')}/>
                    <Switch
                        edge="end"
                        onChange={handleToggle('bluetooth')}
                        checked={checked.indexOf('bluetooth') !== -1}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-bluetooth',
                        }}
                    />
                </ListItem>

            </List>

            <Div>
                <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>{t("common.resetPassword")}</DialogTitle>
                    <DialogContent>

                        <FormControl fullWidth noValidate autoComplete="off">
                            <Div
                            >
                                <Div sx={{mt: 1, mb: 2}}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">{t("common.password")}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t('common.password')}
                                            required
                                        />
                                    </FormControl>
                                </Div>

                                <Div sx={{mt: 1, mb: 2, width:"100%"}}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">{t("admin-profile.title.newPass")}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            value={values.newPassword}
                                            onChange={handleChange('newPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={t('common.newPassword')}
                                            placeholder="Example@123"
                                            required
                                        />
                                    </FormControl>
                                </Div>

                                <Stack
                                    sx={{
                                        mb: 2,
                                        width: '90%',
                                        mx: 'auto',
                                        display: 'block',
                                        position: 'relative', // Add position relative to enable absolute positioning of loader
                                    }}
                                    spacing={2}>
                                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                    {successMsg && <Alert severity="success">{successMsg}</Alert>}
                                </Stack>


                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    endIcon={<SendIcon/>}
                                    loading={isLoading}
                                    loadingPosition="end"
                                    onClick={handleResetPassword}
                                >{t('admin-profile.title.resetBtn')}
                                </LoadingButton>



                            </Div>
                        </FormControl>
                    </DialogContent>
                </Dialog>
            </Div>

        </JumboCardQuick>
    );
};

export default Settings;
