import Div from "@jumbo/shared/Div";
import React from "react";
import {Step, StepLabel, Stepper} from "@mui/material";
import Step1 from "./step1";

import {useCalendarContext} from "../context";
import Step3 from "./step3";
import Step2 from "./step2";
import Evaluation from "./evaluation";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import {useTranslation} from "react-i18next";
import Feedback from "./feedback";
import ChildInfoForm from "../ChildInfoForm";


const steps = ['Basic Info', 'Complete', 'Finish'];
const Form = () => {

    const {globalState, setGlobalState} = useCalendarContext();
    const [activeStep, setActiveStep] = React.useState(0);
    const [showEvaluation, setShowEvaluation] = React.useState(false);
    const [showFeedback, setShowFeedback] = React.useState(false);
    const [booking, setBooking] = React.useState(undefined);
    const [evaluation, setEvaluation] = React.useState(undefined);
    const [feedbacks, setFeedbacks] = React.useState(undefined);
    const {t} = useTranslation();

    const {
        mutate: loadStudentEvaluation,
        isSuccess: isLESuccess,
        isError,
        data: dEvaluation
    } = useAppMutation(teacherServices.loadStudentEvaluation);


    const {
        mutate: loadStudentFeedbacks,
        isSuccess: isLFSuccess,
        data: dFeedbacks
    } = useAppMutation(teacherServices.loadStudentFeedbacks);

    React.useEffect(() => {

        if (globalState && globalState.booking) {
            console.log('Booking', globalState.booking)
            setBooking(globalState.booking)
            loadStudentFeedbacks(globalState.booking.studentId)
            loadStudentEvaluation(globalState.booking.studentId)
        }
    }, [globalState]);

    React.useEffect(() => {

        if (isLESuccess) {
            setEvaluation(dEvaluation);
        }

    }, [isLESuccess]);

    React.useEffect(() => {

        if (isLFSuccess) {
            setFeedbacks(dFeedbacks);
        }

    }, [isLFSuccess])


    const handleNext = ({step}) => {

        if (step === 4) {

            console.log(booking);

            hideDialog();

        } else {
            setActiveStep(step - 1);
        }
    };


    const hideDialog = () => {
        setGlobalState({...globalState, booking: undefined, refreshList: true, openBookingAddEdit: false});
    }


    if (showEvaluation) {
        return <Evaluation setShowEvaluation={setShowEvaluation} evaluation={evaluation}/>
    }

    if (showFeedback) {
        return <Feedback setShowFeedback={setShowFeedback} feedbacks={feedbacks}/>
    }

    if (globalState.showStudentInfo) {
        return <ChildInfoForm  />;
    }

    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>
        <Div>
            {activeStep + 1 === 1 &&
                <Step1 evaluation={evaluation} feedbacks={feedbacks} setBooking={setBooking} booking={booking}
                       handleNext={handleNext}
                       hide={hideDialog} setShowEvaluation={setShowEvaluation} setShowFeedback={setShowFeedback}/>}
            {activeStep + 1 === 3 &&
                <Step3 setBooking={setBooking} booking={booking} handleNext={handleNext}
                       hide={hideDialog}/>}
            {activeStep + 1 === 2 &&
                <Step2 setBooking={setBooking} booking={booking} handleNext={handleNext}
                       hide={hideDialog}/>}


        </Div>
    </Div>;
}


export default Form;
