import React from "react";
import {IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {useChildrenContext} from "../../../parent/Children/context";

const ProgramFab = () => {

     const {globalState, setGlobalState} = useChildrenContext();

    return (
        <SpeedDial
            ariaLabel={"contact-fab"}
            icon={<SpeedDialIcon/>}
            sx={{
                position: "fixed",
                right: 40,
                bottom: 80
            }}
        >
            <SpeedDialAction
                icon={
                    <IconButton onClick={() => {
                        const openAddEditProgram = true;
                        setGlobalState({...globalState, openAddEditProgram});
                    }}>
                        <PersonAddIcon/>
                    </IconButton>
                }
                tooltipTitle={"Add Contact"}
            />

        </SpeedDial>
    );
};
export default ProgramFab;
