import Div from "@jumbo/shared/Div";
import React from "react";
import {Button, FormControl, Rating, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";


const Step2 = ({hide, teacherFeedback, handleNext, showBookingForm}) => {


    const [ratings, setRatings] = React.useState(teacherFeedback?.ratings);
    const {t} = useTranslation();


    const handleComplete = () => {
        handleNext(4)
    }


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off" fullWidth>
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >
                    <FormControl fullWidth sx={{mt: 0.5,}}>
                        <Div fullWidth sx={{'& > legend': {mt: 2}}}
                        > {ratings && ratings.map((item, index) => {
                            return <><Typography component="legend">{t('feedback.ratingLabel' + item.key)}</Typography>
                                <Rating
                                    readOnly
                                    name="simple-controlled"
                                    value={item.value}
                                    onChange={(event, newValue) => {

                                        if (newValue) {

                                        }
                                        // alert(newValue)

                                    }}
                                /></>
                        })}
                        </Div>


                    </FormControl>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {
                                handleNext(2)
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>


                        <LoadingButton variant={"variant"} onClick={handleComplete}>
                            {t("common.finishBtn")}
                        </LoadingButton>

                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step2;
