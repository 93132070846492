import Div from "@jumbo/shared/Div";
import React from "react";
import {Step, StepLabel, Stepper} from "@mui/material";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import {useCalendarContext} from "../context";
import {useTranslation} from "react-i18next";
import ChildInfoForm from "../ChildInfoForm";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const steps = ['Basic Info', 'Complete', 'Finish'];
const Form = () => {

    const {authUser} = useSelector(state => state.auth);
    const {globalState, setGlobalState} = useCalendarContext();
    const [activeStep, setActiveStep] = React.useState(0);
    const [appointment, setAppointment] = React.useState(undefined);
    const navigate = useNavigate();

    const {t} = useTranslation();
    React.useEffect(() => {
        if (globalState && globalState.appointment) {
            console.log('Appointment',globalState.appointment);
            globalState.appointment.evaluated = globalState?.appointment?.evaluation ? true : false;
            setAppointment(globalState.appointment)
        }
    }, [globalState]);

    const handleNext = ({step}) => {

        if (step === 4) {

            if (appointment.evaluated) {
                hideDialog();

            } else {

                // console.log(appointment);
                const {firstName: teacherFirstName, lastName: teacherLastName, email: teacherEmail} = authUser
                const { parent , student, startDate, startTime} = appointment;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'TeacherCompletedEvaluation',
                    startDate,
                    startTime,
                    parentEmail : parent?.email,
                    parentFirstName : parent?.firstName,
                    parentLastName : parent?.lastName,
                    parentPhone : parent?.phone?.fullNumber,
                    parentCountry : parent?.phoneCountry?.label,
                    studentFirstName : student.firstName,
                    studentLastName : student.lastName,
                    teacherEmail,
                    teacherFirstName,
                    teacherLastName
                });
                hideDialog();
                navigate('/dashboard/calendar/success/evaluated-by-teacher');
            }

        } else {
            setActiveStep(step - 1);
        }
    };

    const hideDialog = () => {
        setGlobalState({...globalState, appointment: undefined, refreshList: true, openAppointmentAddEdit: false});
    }

    if (globalState.showStudentInfo) {
        return <ChildInfoForm/>;
    }


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>

        <Div>
            {activeStep + 1 === 1 &&
                <Step1 setAppointment={setAppointment} appointment={appointment} handleNext={handleNext}
                       hide={hideDialog}/>}
            {activeStep + 1 === 2 &&
                <Step2 setAppointment={setAppointment} appointment={appointment} handleNext={handleNext}
                       hide={hideDialog}/>}
            {activeStep + 1 === 3 &&
                <Step3 setAppointment={setAppointment} appointment={appointment} handleNext={handleNext}
                       hide={hideDialog}/>}


        </Div>
    </Div>;
}


export default Form;
