import React, {useEffect} from 'react';
import {Alert, Box, Button, FormControl, Switch, TextField, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import DDImageField from "../../../../component/DDImageField";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import {Form, Formik} from "formik";
import commonServices from "../../../../services/common-services";


const Step3 = ({hide, setStepsData, stepsData, handleNext}) => {

    const [values, setValues] = React.useState({images: [], active: true});
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(adminServices.saveParent);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [value, setValue] = React.useState();

    useEffect(() => {
        console.log(isSuccess, isError)
        if (stepsData) {
            const images = stepsData.images;
            const active = stepsData.active;
            setValues({images, active});
        }

    }, [stepsData]);

    useEffect(() => {
        if (isSuccess) {
            hide();
        } else if (isError) {
            setErrMsg(t('errMsg.saving'));
        }
    }, [isSuccess, isError])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep = async () => {
        setErrMsg('')
        const {active, newImage, newPassword} = values;
        let imageBase64 = undefined;
        if (newImage) {
            let b = await fetch(newImage).then(r => r.blob());
            imageBase64 = await commonServices.blobToBase64(b)
        }
        mutate({...stepsData, active, imageBase64, newPassword});
    };


    const backToStep2 = async () => {
        handleNext(2);
    };

    return (<Box
        component="form"
        sx={{
            '& .MuiTextField-root': {},
        }}
        noValidate
        autoComplete="off"
    >
        <Div sx={{display: 'flex', flexDirection: {xs: 'column'}, mt: 2}}>

            <Formik
                initialValues={values}
                onSubmit={() => {
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form noValidate autoComplete="off"> <Div sx={{
                        maxWidth: '100%',

                        mt: 1
                    }}>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            ml: 2,
                        }}>
                            <Typography variant={"h5"}>{t('common.profileImage')}</Typography>
                            {!values.newImage && values.images?.length > 0 ? (
                                    <DDImageField name={"profile_pic"}
                                                  alt={"user profile pic"}
                                                  onFileSelection={async (file) => {
                                                      let b = await fetch(file).then(r => r.blob());
                                                      if (b.size > 1000000) {
                                                          setErrMsg(t('errMsg.sizeErr'))
                                                          setTimeout(() => {
                                                              setErrMsg("");
                                                          }, 3000);
                                                      } else {
                                                          setValues({...values, newImage: file});
                                                      }
                                                  }}
                                                  sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                                  path={values.images[0]}
                                                  alt={values.firstName + ' ' + values.lastName}/>) :
                                <JumboAvatarField
                                    name={"profile_pic"}
                                    alt={"user profile pic"}
                                    src={values.newImage}
                                    onFileSelection={async (file) => {
                                        let b = await fetch(file).then(r => r.blob());
                                        if (b.size > 1000000) {
                                            setErrMsg(t('errMsg.sizeErr'))
                                            setTimeout(() => {
                                                setErrMsg("");
                                            }, 3000);
                                        } else {
                                            setValues({...values, newImage: file});
                                        }
                                    }}
                                    sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                />}

                            <Typography>{t("admin-student.title.txtContent")}</Typography>

                        </FormControl>
                    </Div>
                        <Div sx={{
                            maxWidth: '100%',
                            mt: 1
                        }}>

                            <FormControl sx={{
                                width: 500,

                                maxWidth: '100%',

                            }}>
                                <TextField
                                    label={t('teacher-profile.title.newPassword')}
                                    value={values.newPassword}
                                    onChange={handleChange('newPassword')}

                                />
                            </FormControl>

                        </Div>
                        <Div sx={{
                            maxWidth: '100%',

                            mt: 1
                        }}>
                            <FormControlLabel
                                label={t('admin-teacher.title.active')}
                                labelPlacement="top"
                                control={
                                    <Switch
                                        edge="end"
                                        checked={values.active}
                                        onChange={(event) => {
                                            setValues({...values, active: event.target.checked})
                                        }}
                                        inputProps={{
                                            'aria-labelledby': 'switch-list-label-bluetooth',
                                        }}
                                    />
                                }
                            />

                        </Div></Form>
                )}
            </Formik>


        </Div>


        <Stack sx={{width: '100%'}} spacing={2}>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

            <Button
                onClick={() => {
                    hide();
                }}
                sx={{mr: 1}}
            >
                {t("common.closeBtn")}
            </Button>
            <Button
                onClick={backToStep2}
                sx={{mr: 1}}
            >
                {t("common.backBtn")}
            </Button>
            <Div sx={{flex: '1 1 auto'}}/>
            <Button variant={"contained"} onClick={submitStep}>
                {t("common.finishBtn")}
            </Button>
        </Div>
    </Box>);

};

export default Step3;
