import React from 'react';
import {Button} from "@mui/material";
import Div from "@jumbo/shared/Div";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {useChildrenContext} from "./context";
import {useTranslation} from "react-i18next";

const ChildrenAppSidebar = ({title, subheader}) => {

    const {globalState, setGlobalState} = useChildrenContext();
    const {t} = useTranslation();



    React.useEffect(() => {
        console.log('ContactsAppSidebar------------------',globalState);

    }, [globalState])


    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 4
            }}
        >
            <Button
                fullWidth
                disableElevation
                variant={"contained"}
                startIcon={<PersonAddAltIcon/>}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={() => {
                    const openAddEditChild = true;
                     setGlobalState({...globalState, openAddEditChild});
                 }}
            >{(t('parent-children.title.childEnrollBtn'))}</Button>

        </Div>
    );
};
export default React.memo(ChildrenAppSidebar);
