import React from "react";
import Div from "@jumbo/shared/Div";
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    TextField
} from "@mui/material";
import Select from "@mui/material/Select";
import {
    actualLevels,
    learningStyle, levelsWithPoints,
    levelsWithPoints1,
    objectiveLevel,
    programFrequency
} from "../../../../utils/constants/appActions";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useTranslation} from "react-i18next";
import {useCalendarContext} from "../context";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Evaluation = ({showForm, teachers, evalutation}) => {

    const [errMsg, setErrMsg] = React.useState('');
    const {globalState, setGlobalState} = useCalendarContext();
    const {t} = useTranslation();
    const [loaded, setLoaded] = React.useState(false);

    const [values, setValues] = React.useState({});
    const [appointmentId, setAppointmentId] = React.useState(undefined);
    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(adminServices.saveStudentEvaluation);


    React.useEffect(() => {

        if (!loaded) {
            console.log("evalutation--------",evalutation)
            if (evalutation) {
                setValues({...evalutation})
            } else {
                setValues({
                    personality: '',
                    actualLevel: '',
                    objectiveLevel: '',
                    learningStyles: [],
                    needsLinguistic: '',
                    booksRecommended: '',
                    teachersRecommended: [],
                    programFrequencyRecommended: '',
                    actualLevel2: { label: '', points: 0 }, // Changed to object
                    objectiveLevel2: { label: '', points: 0 }, // Changed to object
                    noOfPoints: ''
                });
            }
            setLoaded(true);
        }
    }, [loaded])

    React.useEffect(() => {

        if (globalState.appointment?.appointmentId) {
            setAppointmentId(globalState.appointment.appointmentId)
        }

    }, [globalState])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    React.useEffect(() => {

        if (isSuccess) {
            setGlobalState({...globalState, refreshList: true, appointment: undefined, addEditAppointment: false})

        }

        if (isError) {
            setErrMsg(t('errMsg.evaluation'));
        }

    }, [isSuccess, isError])

    const [showHelperText, setShowHelperText] = React.useState(false); // State to track when to show helper text

    React.useEffect(() => {
        // Check if actualLevel2.label is defined
        if (values.actualLevel2?.label) {
            const selectedLevel = levelsWithPoints1.find(
                (level) => level.label === values.actualLevel2.label
            );

            if (selectedLevel) {
                console.log("Selected Level:", selectedLevel);
                console.log("Min Points:", selectedLevel.points);
                console.log("Max Points:", selectedLevel.maxPoints);

                // Automatically set minPoints and maxPoints based on the selected level
                setValues((prevValues) => ({
                    ...prevValues,
                    minPoints: selectedLevel.points, // Set minPoints
                    maxPoints: selectedLevel.maxPoints, // Set maxPoints
                    helperText: `Enter points between ${selectedLevel.points} and ${selectedLevel.maxPoints}`, // Set helper text
                    noOfPoints: values.noOfPoints, // Reset noOfPoints to ensure the user enters valid points again
                }));
            }
        }
    }, [values.actualLevel2?.label, levelsWithPoints1]);

    const handleChangeMultiple = (name) => (event) => {
        const {
            target: {value},
        } = event;

        // Use the name prop to dynamically set the state
        setValues((prevValues) => ({
            ...prevValues,
            [name]: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const getTeacherNameByIds = (teacherIds) => {

        let name = '';
        if (teachers) {
            for (const id of teacherIds) {
                const teacher = teachers.find(t => t.teacherId === id)

                if(!teacher){
                    continue;
                }
                if (name) {
                    name += ', ' + teacher.firstName + ' ' + teacher.lastName
                } else {
                    name += teacher.firstName + ' ' + teacher.lastName
                }
            }
        }

        return name;

    }


    const handleSave = () => {

        const enteredPoints = parseInt(values.noOfPoints, 10);

        if (values.personality &&
            values.actualLevel2 &&
            values.objectiveLevel2 &&
            values.learningStyles &&
            values.needsLinguistic &&
            values.booksRecommended &&
            values.teachersRecommended &&
            values.programFrequencyRecommended
        ) {
            // Check if points are valid based on the selected level's minPoints and maxPoints
            if (enteredPoints < values.minPoints || enteredPoints > values.maxPoints) {
                setErrMsg(`Points should be between ${values.minPoints} and ${values.maxPoints}`);
                setTimeout(() => {
                    setErrMsg("");
                }, 5000);
                return; // Stop the process if points are invalid
            }

            console.log("Complete Form Values----", values);
            const evaluation = {...values}
            mutate({appointmentId, evaluation});

        } else {
            setErrMsg(t("admin-appointments.title.errorMsg"));
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
        }
    };


    if (!loaded) {
        return null
    }

    const handleLevelChange = (e) => {
        const selectedLabel = e.target.value;
        const selectedLevel = levelsWithPoints1.find((level) => level.label === selectedLabel);

        console.log("Selected Level:", selectedLevel); // Log the selected level

        if (selectedLevel) {
            console.log("Min Points:", selectedLevel.points); // Log minPoints (selectedLevel points)
            console.log("Max Points:", selectedLevel.maxPoints); // Log maxPoints

            setValues((prevValues) => ({
                ...prevValues,
                actualLevel2: {
                    label: selectedLevel.label,
                    points: selectedLevel.points,
                }, // Correctly updating actualLevel2
                noOfPoints: '', // Reset points when changing the level
                error: '', // Clear any previous errors
                minPoints: selectedLevel.points, // Set the minimum points for the selected level
                maxPoints: selectedLevel.maxPoints, // Set the maxPoints based on the selected level
                helperText: `Enter points between ${selectedLevel.points} and ${selectedLevel.maxPoints}`, // Set the helper text for guidance
            }));
        } else {
            console.log("Level not found");
        }
    };

    const handleNoOfPointsChange = (e) => {
        const input = e.target.value;
        // Ensure the input contains only numbers by removing any non-digit characters.
        const onlyNumbers = input.replace(/\D/g, '');

        // Check if the input number is within the valid range of minPoints and maxPoints
        const isValid = onlyNumbers >= values.minPoints && onlyNumbers <= values.maxPoints;

        // Update the state with the cleaned input directly.
        setValues((prevValues) => ({
            ...prevValues,
            noOfPoints: onlyNumbers, // Directly update noOfPoints in the state
            error: !isValid, // Set error based on validity of the input
            helperText: onlyNumbers
                ? `Enter points between ${values.minPoints} and ${values.maxPoints}` // Show helper text based on the current min/max points
                : '', // Clear helper text if no input
            helperTextColor: isValid ? 'blue' : 'red', // Dynamic helper text color based on validation
        }));
    };

    const handleChangePoint = (field) => (event) => {
        let newValue = event.target.value;

        // For the objectiveLevel2 field, find the selected level and store the object with label and points
        if (field === 'objectiveLevel2') {
            const selectedLabel = newValue;
            const selectedItem = levelsWithPoints.find(item => item.label === selectedLabel);

            // Store the selected level object with label and points
            newValue = selectedItem || { label: '', points: 0 };
        }

        // Update the state with the new value
        setValues(prevValues => ({
            ...prevValues,
            [field]: newValue
        }));
    };

    const handleFocus = () => {
        setShowHelperText(true); // Show helper text when the field is focused
    };

    const handleBlur = () => {
        setShowHelperText(false); // Optionally hide helper text when the field loses focus, if desired
    };

    const getNextLevel = (actualLevel2) => {
        const currentLevelIndex = levelsWithPoints1.findIndex(level => level.label === actualLevel2);
        return levelsWithPoints1.slice(currentLevelIndex + 1);
    };

    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 2,
                            mt: 0.5
                        },
                    }}
                >


                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("appointmentForm.personality")}
                            fullWidth
                            value={values?.personality}
                            onChange={handleChange('personality')}
                        />
                    </FormControl>

                    {/* Actual Level Dropdown */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="actual-level-select-label">{t("appointmentForm.actualLevel")}</InputLabel>
                        <Select
                            labelId="actual-level-select-label"
                            id="actual-level-select"
                            value={values.actualLevel2?.label || ''}
                            label={t("appointmentForm.actualLevel")}
                            onChange={handleLevelChange}
                        >
                            {levelsWithPoints1.map((level, index) => (
                                <MenuItem key={index} value={level.label}>
                                    {level.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Points TextField */}
                    <FormControl fullWidth>
                        <TextField
                            required
                            label="No. of Points"
                            fullWidth
                            value={values.noOfPoints}
                            onChange={handleNoOfPointsChange}
                            disabled={!values.actualLevel2?.label}
                            onFocus={handleFocus} // Show helper text when focused
                            onBlur={handleBlur} // Optionally hide helper text when blurred (if you want to hide it again)
                            InputProps={{
                                inputProps: { min: 0, inputMode: 'numeric', pattern: '[0-9]*' }, // Ensure the input is numeric.
                            }}
                            error={!!values.error}
                            helperText={showHelperText ? `Enter points between ${values.minPoints} and ${values.maxPoints}` : ''} // Show helper text only when the field is focused
                            FormHelperTextProps={{
                                style: {
                                    color: values.helperTextColor || 'inherit', // Use the dynamic color or default if not set.
                                },
                            }}
                        />
                    </FormControl>

                    {/* Objective Level Dropdown */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="objective-level-select-label">{t("teacher-appointment-form.title.objectiveLevel")}</InputLabel>
                        <Select
                            labelId="objective-level-select-label"
                            id="objective-level-select"
                            value={values.objectiveLevel2?.label || ''}
                            label={t("teacher-appointment-form.title.objectiveLevel")}
                            onChange={handleChangePoint('objectiveLevel2')}
                        >
                            {getNextLevel(values.actualLevel2?.label || '').map((level, index) => (
                                <MenuItem key={index} value={level.label}>
                                    {level.label}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>


                    {/*<FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("appointmentForm.actualLevel")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.actualLevel}
                                label={t('appointmentForm.actualLevel')}
                                onChange={handleChange('actualLevel')}
                        >
                            {actualLevels?.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.objectiveLevel")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.objectiveLevel}
                                label={t("admin-appointments.title.objectiveLevel")}
                                onChange={handleChange('objectiveLevel')}
                        >
                            {objectiveLevel?.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>*/}

                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.learningStyle")}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={values?.learningStyles}
                            label={t("admin-appointments.title.learningStyle")}
                            onChange={handleChangeMultiple('learningStyles')}
                            multiple
                            input={<OutlinedInput label="Learning Style"/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {learningStyle?.map((item) => (
                                <MenuItem key={item} value={item}>
                                    <Checkbox
                                        checked={values?.learningStyles?.indexOf(item) > -1}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            handleChangeMultiple('learningStyle')({
                                                target: {
                                                    value: checked
                                                        ? [...values.learningStyles, item]
                                                        : values.learningStyles.filter((value) => value !== item),
                                                },
                                            });
                                        }}
                                    />
                                    <ListItemText primary={item}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("admin-appointments.title.needsLinguistic")}
                            fullWidth
                            value={values.needsLinguistic}
                            onChange={handleChange('needsLinguistic')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("admin-appointments.title.books")}
                            fullWidth
                            value={values.booksRecommended}
                            onChange={handleChange('booksRecommended')}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.teacherRec")}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={values.teachersRecommended}
                            onChange={handleChangeMultiple('teachersRecommended')}
                            multiple
                            input={<OutlinedInput label={t("admin-appointments.title.teacherRec")}/>}
                            renderValue={(selected) => {
                                return getTeacherNameByIds(selected);
                            }}
                            MenuProps={MenuProps}
                        >
                            {teachers && teachers.map((item, index) => (
                                <MenuItem key={'key-teacher' + index} value={item.teacherId}>
                                    <Checkbox
                                        checked={values?.teachersRecommended?.indexOf(item.teacherId) > -1}
                                    />
                                    <ListItemText primary={item.firstName + ' ' + item.lastName}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.programRec")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.programFrequencyRecommended}
                                label={t("admin-appointments.title.programRec")}
                                onChange={handleChange('programFrequencyRecommended')}
                        >
                            {programFrequency.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                setGlobalState({...globalState, appointment: undefined, addEditAppointment: false})
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {
                                showForm();
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>

                        <LoadingButton variant={"variant"} onClick={handleSave}>
                            {t("common.saveBtn")}
                        </LoadingButton>

                    </Div>


                </Div>
            </FormControl>
        </Div>
    </Div>;
}


export default Evaluation;
