import React from 'react';
import {Button, FormControl, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";

const Evaluation = ({evaluation, onClose}) => {

    const [values, setValues] = React.useState({});
    const {t} = useTranslation();

    React.useEffect(() => {
        if (evaluation) {
            setValues({evaluation: evaluation})
        }
    }, [evaluation])

    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>

            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >


                    <Div>
                        <Typography variant="h5" gutterBottom component="div"
                                    sx={{pr: 10, pl: 10, textAlign: 'center'}}
                        >
                            {t('feedbackForm.info')}
                        </Typography>

                        <Divider sx={{backgroundColor: 'lightgrey', height: 1.5, mb: 2, mx: 'auto', width: '75%'}}/>


                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center', mt: 2}}>
                            {t('appointmentForm.overall')} : {values.evaluation?.overall
                            ? values.evaluation?.overall
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.personality')}: {values.evaluation?.personality && values.evaluation?.personality.length > 0
                            ? values.evaluation?.personality
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.actualLevel')}: {values.evaluation?.actualLevel && values.evaluation?.actualLevel.length > 0
                            ? values.evaluation?.actualLevel
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.objectiveLevel')}: {values.evaluation?.objectiveLevel && values.evaluation?.objectiveLevel.length > 0
                            ? values.evaluation?.objectiveLevel
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.learningStyle')}: {values.evaluation?.learningStyles && values.evaluation?.learningStyles.length > 0
                            ? values.evaluation?.learningStyles.join(', ')
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.needs')}: {values.evaluation?.needsLinguistic && values.evaluation?.needsLinguistic.length > 0
                            ? values.evaluation?.needsLinguistic
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.booksRec')}: {values.evaluation?.booksRecommended && values.evaluation?.booksRecommended.length > 0
                            ? values.evaluation?.booksRecommended
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Teacher
                            Recommended: {values.evaluation?.teachers && values.evaluation?.teachers.length > 0
                            ? values.evaluation.teachers.map(item => {
                                return <Typography variant="body1" gutterBottom component="div"
                                                   sx={{textAlign: 'center'}}>{item.firstName + ' ' + item.lastName}</Typography>
                            })
                            : 'No teachers recommended'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            {t('appointmentForm.program')}: {values.evaluation?.programFrequencyRecommended && values.evaluation?.programFrequencyRecommended.length > 0
                            ? values.evaluation?.programFrequencyRecommended
                            : '---'}
                        </Typography></Div>


                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Div sx={{flex: '1 1 auto'}}/>

                        <Button
                            onClick={() => {
                                onClose()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>

                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;
};

export default Evaluation;
