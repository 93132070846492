import React from 'react';
import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const PricePlan = ({plan, children, headerSx, sx, recommended}) => {
    const {t} = useTranslation();

    const title = plan.amount + ' €';
    const subheader = plan.name;
    const subTitle = plan.compareAmount > 0 ? `${plan.compareAmount} €` : null;
    return (
        <Card
            sx={{
                border: 0,
                borderColor: 'transperent',
                borderRadius: 2,
                transform: 'scale(.95)',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                    transform: 'scale(1)',
                },
                ...sx
            }}
        >

            <CardHeader
                title={
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                        mt: 1,
                        position: 'relative'
                    }}>
                        {subTitle && (
                            <Typography
                                variant="subtitle1"
                                fontSize={20}
                                fontWeight={400}
                                color="black"
                                sx={{
                                    textTransform: 'none',
                                    textDecoration: 'line-through',
                                }}
                            >
                                {subTitle}
                            </Typography>
                        )}
                        <Typography
                            variant="h2"
                            fontSize={36}
                            fontWeight={600}
                            color="black"
                        >
                            {title}
                        </Typography>
                    </Box>

                }
                subheader={
                    <Typography
                        variant={"h5"}
                        color="inherit"
                        sx={{

                            textTransform: 'uppercase',
                            letterSpacing: 3,
                            mb: 1.5,
                            mt: 1.5
                        }}
                    >
                        {subheader}
                    </Typography>
                }
                sx={{
                    py: 5,
                    color: 'black',
                    ...headerSx,

                }}
            />

            {recommended && (
                <Box
                    sx={{
                        position : 'absolute',
                        top: { xl : 150, xs : 150, md : 150 , sm : 150},
                        width : '40%',
                        right: 2,
                        transform: 'translateY(-40%)',
                        backgroundColor: '#FFC107',
                        padding: '2% 3%',
                        borderRadius: '5px',
                        mt: 1
                    }}
                >
                    <Typography
                        variant="h6"
                        fontSize={12}
                        sx={{color: 'black', letterSpacing: 1}}
                        fontWeight={500}
                    >
                        {t('parent-subscription.title.recommend')}
                    </Typography>
                </Box>
            )}

            <CardContent
                sx={{

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 5,
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};
/* Todo prop define */
PricePlan.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
}

export default PricePlan;
