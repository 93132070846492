import Div from "@jumbo/shared/Div";
import React from "react";
import {Button, Divider, FormControl} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import moment from "moment";


const Feedback = ({setShowFeedback, feedbacks}) => {


    const [values, setValues] = React.useState({});
    const {t} = useTranslation();

    React.useEffect(() => {
        if (feedbacks) {
            setValues({feedbacks})
        }
    }, [feedbacks])


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>

        <Div sx={{mt: 3}}>

            <FormControl noValidate autoComplete="off" fullWidth>
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        }
                    }}
                >

                    <Typography variant="h5" gutterBottom component="div" sx={{
                        textAlign: 'center',
                        fontWeight: 500,
                        color: 'black',
                        mb: 2,
                    }}>
                        {t('feedbackForm.feedbackInfo')}
                    </Typography>


                    {values.feedbacks && values.feedbacks.map((item, index) => {
                        console.log(index, item);
                        return <Div key={'key-feedback-' + index}> <Typography variant="h5" gutterBottom component="div"
                                                                               sx={{
                                                                                   pr: 10,
                                                                                   pl: 10,
                                                                               }}
                        >

                        </Typography>
                            <Divider sx={{
                                backgroundColor: 'black',
                                height: 4,
                                mb: 3,
                                mx: 'auto',
                                width: '100%',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                opacity: 0.7,
                                border: 'none'
                            }}/>

                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}>{t('evaluation.date')}</span>:
                                <span
                                    style={{marginLeft: '0.5em'}}>{moment(item.startDate).format('DD/MM/YYYY, hh:mm A')}</span>
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}>{t('feedbackForm.bookName')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.bookName}</span>
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}>{t('feedbackForm.comments')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.comments}</span>
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}>{t('feedbackForm.completed')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.completed}</span>
                            </Typography>


                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span
                                    style={{
                                        fontWeight: 450,
                                        color: 'secondary.main',
                                        marginRight: '0.5em'
                                    }}>{t('feedbackForm.home2')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.homeWorkLink2}</span>
                            </Typography>


                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}>{t('feedbackForm.home')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.homeWorkLink}</span>
                            </Typography>


                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}> {t('feedbackForm.pageNo')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.pageNumber}</span>
                            </Typography>


                            <Typography variant="body1" gutterBottom component="div" sx={{mt: 2}}>
                                <span style={{
                                    fontWeight: 450,
                                    color: 'secondary.main',
                                    marginRight: '0.5em'
                                }}> {t('feedbackForm.worked')}</span>:
                                <span style={{marginLeft: '0.5em'}}>{item.teacherFeedback.workedOn}</span>
                            </Typography>

                            {item.teacherFeedback.ratings.map((it, index) => (
                                <Typography key={index} variant="body1" gutterBottom component="div"
                                            sx={{mt: 2}}>
                                    <span style={{fontWeight: 450, color: 'secondary.main', marginRight: '0.5em'}}>
                                    {t('feedback.ratingLabel' + it.key)}</span>:
                                    <span style={{marginLeft: '0.5em'}}>{it.value}/5</span>
                                </Typography>
                            ))}


                        </Div>
                    })}


                    <Div sx={{display: 'flex', justifyContent: 'center', pt: 2}}>
                        <Button
                            onClick={() => {
                                setShowFeedback(false)
                            }}
                            sx={{textAlign: 'center'}}
                        >
                            {t("common.backBtn")}
                        </Button>
                    </Div>


                </Div>
            </FormControl>
        </Div>
    </Div>;

}


export default Feedback;
