import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import {Alert, Box, Button, FormControl, InputLabel, Select, TextField,} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {Form, Formik} from "formik";
import {usePlansContext} from "../context";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

const Step4 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    // const [values, setValues] = React.useState({});
    const [errMsg, setErrMsg] = React.useState('');
    const {globalState, setGlobalState} = usePlansContext();
    const {
        mutate: savePlan,
        isError,
        isLoading: isProgramSaving,
        isSuccess,
        data: program,
        error
    } = useAppMutation(adminServices.savePlan);

    const [values, setValues] = React.useState({
        amount: '',
        validity: '',
        compareAmount:''
    });


    useEffect(() => {

        if (stepsData.name) {
            setValues({...stepsData});
        }
    }, [stepsData])

    useEffect(() => {

        if (isSuccess && program) {
            handleNext(5);
        }

        if (isError && error) {
            console.log('Error-----', isError, error);
        }

    }, [isSuccess, program, isError, error])


    const submitStep = async () => {
        setErrMsg('');
        const {amount, compareAmount} = values;
        const data = {...stepsData, ...values};
        setStepsData(data);
        savePlan(data);
    };
    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const {t} = useTranslation();
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                //validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'}
                        }}>
                            <TextField
                                required
                                label={t("admin-plans.title.amount")}
                                value={values.amount}
                                onChange={handleChange('amount')}

                            />
                        </FormControl><FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'}, mt:1.5
                        }}>
                            <TextField
                                label={t("Comapre Amount")}
                                value={values.compareAmount}
                                onChange={handleChange('compareAmount')}

                            />
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'}, mt:2
                        }}>
                            <InputLabel id="selectValidity">{t("admin-plans.title.validity")}</InputLabel>
                            <Select
                                required
                                labelId="selectValidity"
                                id="selectValidity"
                                value={values.validity}
                                label={t("admin-plans.title.validity")}
                                onChange={handleChange('validity')}
                                sx={{ml: 1, mr: 1}}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>

                            </Select>
                        </FormControl>

                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    onClick={() => {
                        handleNext(2)
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Button
                    onClick={() => {
                        hideDialog()
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>

                <Div sx={{flex: '1 1 auto'}}/>


                <LoadingButton loading={isProgramSaving} variant={"variant"} onClick={() => {
                    submitStep()
                }
                }>
                    {values.planId ? t("common.updateBtn") : t("admin-plans.title.createButton")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step4;
