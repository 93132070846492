import * as React from 'react';
import {useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Div from "@jumbo/shared/Div";
import {Alert, Button, Card, CardContent, Chip, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import PricingPlan from "./PricingPlan";
import List from "@mui/material/List";
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import {useAppMutation} from "../../../services";
import parentServices from "../../../services/parent-services";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Payment from "./Payment";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Stack from "@mui/material/Stack";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }
    return url;
};

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Div>
            )}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const FullWidthTabs = () => {
    const params = useParams();
    const theme = useTheme();
    const [value, setValue] = React.useState(1);
    const {t} = useTranslation();
    const {showDialog, hideDialog} = useJumboDialog();
    const [selectedSubscription, setSelectedSubscription] = useState({});
    const [cancelReason, setCancelReason] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogSubscriptionId, setDialogSubscriptionId] = useState(null);
    const [errCancel, setErrCancel] = React.useState('');

    const {
        mutate: loadSubscriptions,
        data: sData,
        isSuccess: isSSuccess,
        isLoading: isSLoading
    } = useAppMutation(parentServices.loadSubscriptions);

    const {
        mutate: cancelSubscription,
        data: cancelData,
        isError: isCError,
        isSuccess: isCSuccess,
        isLoading: isCLoading
    } = useAppMutation(parentServices.cancelSubscription);

    const [checked, setChecked] = React.useState([0]);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const hideSubscription = () => {
        setSelectedSubscription({});
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    React.useEffect(() => {
        loadSubscriptions({});
    }, []);

    React.useEffect(() => {
        if (isSSuccess) {
            loadSubscriptions({});
            setDialogOpen(false);
            setCancelReason('');
        } else if (isCError) {
            setDialogOpen(false);
            setCancelReason('');
        }
    }, [isCSuccess, isCError]);

    React.useEffect(() => {
        if (params && params.category) {
            if (params.category === 'plans') {
                setValue(1);
            }
        }
    }, [params]);

    const handleCancelSubscription = (subscriptionId) => {
        setDialogSubscriptionId(subscriptionId);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCancelReason('');
    };

    const handleDialogConfirm = () => {

        if (cancelReason) {
            cancelSubscription({subscriptionId: dialogSubscriptionId, cancelReason});
        } else {
            setErrCancel(t('parent-subscription.title.cancelReasonRequired'))
        }


    };

    return (
        <Card
            sx={{
                display: 'flex', minWidth: 0, flexDirection: {xs: 'column', md: 'row'}
            }}
        >
            <CardContent sx={{
                flex: 1,
                direction: 'column',
                p: 2,
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                 marginLeft : -1,
                marginRight : -1
            }}>
                <Div sx={{maxWidth: '100%', bgcolor: 'background.paper'}}>
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label={t("parent-subscription.title.subscribed")} {...a11yProps(0)} />
                            <Tab label={t("parent-subscription.title.plan")} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        style={{ marginRight : -20, marginLeft : -20}}
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Payment subscription={selectedSubscription} hide={hideSubscription}/>

                            <List dense sx={{width: '100%', bgcolor: 'background.paper'}}>
                                {sData && sData.map((item, index) => {
                                    let expired = false;
                                    const endDate = new Date(item.expiredDate);
                                    if (endDate < new Date()) {
                                        expired = true;
                                    }

                                    if (item.plan.category === 'subscription') {

                                        return <Div key={'key-subscription-' + index} sx={{display: 'flex'}}>
                                            <Div sx={{
                                                width: '80%',
                                                mt: 1
                                            }}>
                                                <Typography variant={"h6"} mb={.25}>{item.plan.name}</Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('common.subscriptionTitle').replace('?', item.noOfBooking) + ` (${item.chargedAmount}€/${t("parent-subscription.title.month")})`}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('parent-subscription.title.startDate') + moment(item.commitmentStartDate).format('DD/MM/YYYY')}
                                                </Typography>
                                                {/* <Typography variant={"body1"}
                                                            color={"text.secondary"}
                                                            fontSize={"12px"}
                                                >                                                    {'Abonnement ' + item.noOfBooking + ' cours'}
                                                </Typography> */}

                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >{
                                                    'Prochain prélèvement ' + moment(item.nextPaymentDate).format('DD/MM/YYYY')
                                                }
                                                </Typography>

                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('parent-subscription.title.commitmentEnd') + moment(item.commitmentEndDate).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t("common.bookingUtilize") + " " + item.noOfUsedBooking + "/" + item.noOfBooking}

                                                </Typography>
                                            </Div>
                                            <Div sx={{
                                                flexDirection: 'row', justifyContent: 'flex-end',
                                                width: '15%',
                                            }}> {item.cancelled && <Chip
                                                label={t('common.cancelled')}
                                                color={"error"}
                                                sx={{m: .5}}
                                                size={"small"}/>}<Chip
                                                label={expired ? t('parent-subscription.title.expired') : t('parent-subscription.title.active')}
                                                color={expired ? "error" : "success"}
                                                sx={{m: .5}}
                                                size={"small"}/>
                                                {!item?.paid &&
                                                    <Chip onClick={() => {
                                                        setSelectedSubscription(item);
                                                    }}
                                                          sx={{m: .5}}

                                                          label={'Pay now'}
                                                          color={"error"}
                                                          size={"small"}/>}
                                                {!item.cancelled && item.allowCancellation && (
                                                    <Chip
                                                        onClick={() => handleCancelSubscription(item.subscriptionId)}
                                                        sx={{m: .5}}
                                                        label={t('parent-subscription.title.cancelBtn')}
                                                        color="warning"
                                                        size="small"
                                                    />
                                                )}
                                            </Div>
                                        </Div>

                                    } else if (item.plan.category === 'packs') {
                                        return <Div key={'key-subscription-' + index} sx={{display: 'flex'}}>
                                            <Div sx={{
                                                width: '80%',
                                                mt: 1
                                            }}>
                                                <Typography variant={"h6"} mb={.25}>{item.plan.name}</Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('common.packTitle').replace('?', item.noOfBooking) + ` (${item.chargedAmount}€)`}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('parent-subscription.title.startDate') + moment(item.startDate).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('parent-subscription.title.expiryDate') + moment(item.expiredDate).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t("common.bookingUtilize") + " " + item.noOfUsedBooking + "/" + item.noOfBooking}

                                                </Typography>


                                            </Div>
                                            <Div sx={{
                                                flexDirection: 'row', justifyContent: 'flex-end',
                                                width: '15%',
                                            }}> <Chip
                                                label={expired ? t('parent-subscription.title.expired') : t('parent-subscription.title.active')}
                                                color={expired ? "error" : "success"}
                                                sx={{m: .5}}
                                                size={"small"}/>
                                            </Div>
                                        </Div>
                                    }


                                    return <Div key={'key-subscription-' + index} sx={{display: 'flex'}}>
                                        <Div sx={{
                                            width: '80%',
                                            mt: 1
                                        }}>

                                            <Typography variant={"h6"} mb={.25}>{item.plan.name}</Typography>

                                            <Typography
                                                variant={"body1"}
                                                color={"text.secondary"}
                                                fontSize={"12px"}
                                            >
                                                qqq {t('parent-subscription.title.startDate') + moment(item.startDate).format('DD/MM/YYYY')}
                                            </Typography>
                                            <Typography variant={"body1"}
                                                        color={"text.secondary"}
                                                        fontSize={"12px"}
                                            >{t('parent-subscription.title.noOfBooking') + item.noOfBooking}</Typography>

                                            <Typography
                                                variant={"body1"}
                                                color={"text.secondary"}
                                                fontSize={"12px"}
                                            >
                                                {t('parent-subscription.title.expiryDate') + moment(item.expiredDate).format('DD/MM/YYYY')}
                                            </Typography>
                                            <Typography
                                                variant={"body1"}
                                                color={"text.secondary"}
                                                fontSize={"12px"}
                                            >
                                                {t("Amount") + ": " + item.amount}

                                            </Typography>


                                            {item.plan.category === 'subscription' && <>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('parent-subscription.title.renewalDate') + moment(item.nextPaymentDate).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {'Engagement du ' + moment(item.commitmentStartDate).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography
                                                    variant={"body1"}
                                                    color={"text.secondary"}
                                                    fontSize={"12px"}
                                                >
                                                    {t('parent-subscription.title.commitmentEnd') + moment(item.commitmentEndDate).format('DD/MM/YYYY')}
                                                </Typography>
                                            </>}

                                        </Div>
                                        <Div sx={{
                                            flexDirection: 'row', justifyContent: 'flex-end',
                                            width: '15%',
                                        }}> <Chip
                                            label={expired ? t('parent-subscription.title.expired') : t('parent-subscription.title.active')}
                                            color={expired ? "error" : "success"}
                                            sx={{m: .5}}
                                            size={"small"}/>
                                            {item.plan.category === 'subscription' && !item?.paid &&
                                                <Chip onClick={() => {
                                                    setSelectedSubscription(item);
                                                }}
                                                      sx={{m: .5}}

                                                      label={'Pay now'}
                                                      color={"error"}
                                                      size={"small"}/>}</Div>
                                    </Div>

                                })}

                                {!sData || sData.length == 0 && <>


                                    <Div sx={{py: {lg: 1}}}>
                                        <Typography

                                            aria-haspopup="true"

                                        >
                                            {t('parent-subscription.title.noSubscription')}
                                        </Typography>
                                    </Div>

                                </>}

                            </List>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <PricingPlan/>
                        </TabPanel>

                    </SwipeableViews>
                </Div>
            </CardContent>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{t("parent-subscription.title.deleteTitle")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("parent-subscription.title.deleteContent")}</Typography>
                    <TextField
                        sx={{mb: 2}}
                        label={t("parent-subscription.title.reason")}
                        fullWidth
                        margin="normal"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                    />
                    <Stack sx={{width: '100%', pl: 1, mt: 2}} spacing={2}>
                        {errCancel && <Alert severity="error">{errCancel}</Alert>}
                    </Stack>
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 4}}>
                        <Button onClick={() => {
                            setDialogOpen(false);
                            setCancelReason('');
                        }} color="primary" sx={{mr: 2}}>

                            {t("common.noBtn")}
                        </Button>
                        <Button onClick={handleDialogConfirm} color="primary">
                            {t("common.yesBtn")}
                        </Button>
                    </div>
                </DialogContent>


            </Dialog>
        </Card>
    );
};

export default FullWidthTabs;
