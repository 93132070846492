import React, {useState} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import CalendarWrapper from "./CalendarWrapper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {useTranslation} from "react-i18next";
import {
    Alert,
    Avatar,
    Button,
    ButtonGroup,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemAvatar,
    MenuItem,
    TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import Snackbar from '@mui/material/Snackbar';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DDImage from "../../../../component/DDImage";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {timings} from "../../../../utils/constants/appActions";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSelector} from "react-redux";


// const today = new Date();
// const currentYear = today.getFullYear();
const localizer = momentLocalizer(moment);

const EventComponent = ({event, index}) => {
    console.log('event----', event)
    const formattedStartTime = new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    return (
        <Div sx={{display: 'flex', alignItems: 'center', height: 20}} key={'calendar-' + index}>
            <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                {event.student.firstName}
            </Typography>
            <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                {event.student.lastName}
            </Typography>
            <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                {"(" + formattedStartTime + ")"}
            </Typography>
        </Div>
    );
}
const AgendaEventComponent = ({event}) => {
    const formattedStartTime = new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    return (
        <Div>
            <Typography variant="h6" gutterBottom component="div">
                {event.title}
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
                {event.student.firstName} {event.student.lastName}
            </Typography>
        </Div>
    );
};

const CalendarBasic = () => {
    const {authUser} = useSelector(state => state.auth);
    const [type] = useState('evaluation');
    const [minDate, setMinDate] = useState();
    const [pastDate, setPastDate] = useState(false);
    const [isEditable, setEditable] = useState(false);
    const [maxDate, setMaxDate] = useState();
    const [sTimings, setSTimings] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {mutate: loadChildren, isSuccess: isCSuccess, data: cResult} = useAppMutation(parentServices.loadChildren);
    const {
        mutate: loadAvailableTeachers,
        isESuccess: isESuccess,
        data: evaluators
    } = useAppMutation(parentServices.loadAvailableTeachers);


    const {
        mutate: cancelAppointment,
        isSuccess: isCASuccess,
        isError: isCAError,
        isLoading: isCALoading,
        data: caResult
    } = useAppMutation(parentServices.cancelAppointment);

    const {
        mutate: saveAppointment,
        isSuccess: isSASuccess,
        isLoading: isLoadingSave,
        isError: isSAError,
        data: saResult
    } = useAppMutation(parentServices.saveAppointment);


    const {
        mutate: loadAppointment,
        isSuccess: isLASuccess,
        isLoading: isLALoading,
        isError: isLAError,
        data: laResult
    } = useAppMutation(parentServices.loadAppointment);


    const {
        mutate: loadAvailableDates,
        isSuccess: isLDSuccess,
        isLoading: isLDLoading,
        isError: isLDError,
        data: ldResult
    } = useAppMutation(parentServices.loadAvailableDates);


    const {
        mutate: loadAvailableTimes,
        isSuccess: isLTSuccess,
        isLoading: isLTLoading,
        isError: isLTError,
        data: ltResult
    } = useAppMutation(parentServices.loadAvailableTimes);


    const [selectedDate, setSelectedDate] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [events, setEvents] = useState([]);
    const [children, setChildren] = React.useState([]);

    const params = useParams();


    React.useEffect(() => {


        if (params && params.category) {
            if (params.category === 'add') {
                setValues({
                    startDate: '',
                    startTime: '',
                    childId: params.id,
                    description: '',
                    appointmentId: undefined
                });
                loadChildren();
                setOpenDialog(true);
                setEditable(true);
            } else if (params.category === 'edit') {
                loadAppointment(params.id);
                setEditable(false);
            }
        }

    }, [params]);
    // const [currentDate, setCurrentDate] = useState(new Date());

    const [searchStartDate, setSearchStartDate] = useState(new Date());

    React.useEffect(() => {
        if (!minDate) {
            const currentMin = new Date();
            const currentMax = new Date();
            currentMin.setDate(currentMin.getDate() + 2);
            setMinDate(currentMin);
            currentMax.setDate(currentMax.getDate() + 30);
            setMaxDate(currentMax);
            if (params?.category === 'add') {
                loadAvailableDates({type, startDate: currentMin, endDate: currentMax, childId: params.id})
            }
        }
    }, [minDate])

    React.useEffect(() => {
        if (isCSuccess && cResult) {
            const chs = cResult.filter(item => item.studentId === values.childId);
            setChildren(chs);
        }
    }, [cResult, isCSuccess])


    React.useEffect(() => {
        if (isLASuccess && laResult) {

            const {appointment} = laResult;

            setValues({
                startDate: '',
                startTime: '',
                childId: params.id,
                description: '',
                appointmentId: undefined
            });


            const startDateTime = new Date(appointment.startDate);
            const formattedStartTime = moment(appointment.startDate).format('HH:mm');
            const startDate = moment(appointment.startDate).format('YYYY-MM-DD'); // laResult.startDate.slice(0, 10);
            const sstartDate = new Date(appointment.startDate);
            const startTime = formattedStartTime;

            const flag = isPastDate(sstartDate)
            setPastDate(flag);


            setValues({
                title: appointment.title,
                childId: appointment.student?.studentId,
                teacher: appointment.teacher,
                teacherId: appointment.teacher?.teacherId,
                appointmentId: appointment.appointmentId,
                description: appointment.description,
                cancelled: appointment.cancelled,
                startDate,
                sstartDate,
                startTime
            });


            loadChildren();

            loadEvaluators({childId: appointment.student?.studentId, startDate, startTime})

            setOpenDialog(true);

            loadAvailableDates({type, startDate: minDate, endDate: maxDate, childId: appointment.student?.studentId})

            loadAvailableTimes({
                appointmentId: appointment.appointmentId,
                date: startDate,
                childId: appointment.student?.studentId,
                type
            })

        }

        if (isLAError) {
            navigate('/dashboard');
        }

    }, [laResult, isLASuccess, isLAError])


    React.useEffect(() => {
        console.log(saResult, isSASuccess)

        if (isSASuccess && saResult) {

            try {


                const selectedStartDateTime = new Date(`${values.startDate}T${values.startTime}`);
                const selectedEndDateTime = new Date(`${values.startDate}T${values.startTime}`);
                selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 30);

                const newEvent = {
                    title: 'Student Appointment',
                    startDate: selectedStartDateTime.toISOString(),
                    endDate: selectedEndDateTime.toISOString(),
                    description: values.description,
                };

                const teacher = evaluators?.find((child) => child.teacherId === values.teacherId);
                const student = children.find((child) => child.studentId === values.childId);

                const {
                    firstName: parentFirstName,
                    lastName: parentLastName,
                    email: parentEmail,
                    dateOfBirth: parentDateOfBirth
                } = authUser
                const {firstName: teacherFirstName, lastName: teacherLastName} = teacher;
                const {firstName: studentFirstName, lastName: studentLastName} = student;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'ParentAddAppointment',
                    ...newEvent,
                    teacherFirstName,
                    teacherLastName,
                    parentFirstName,
                    parentLastName,
                    parentEmail,
                    studentFirstName,
                    studentLastName,
                    parentDateOfBirth
                });
            } catch (err) {
                console.log(err)
            }
            navigate('/dashboard');

            resetValues();

        } else if (isSAError) {
            navigate('/dashboard/error/added-by-parent');
            resetValues();
        }

    }, [saResult, isSASuccess, isSAError]);

    React.useEffect(() => {
        if (isCASuccess) {
            navigate('/dashboard/success/cancelled-by-parent');
        } else if (isCAError) {
            navigate('/dashboard/error/cancelled-by-parent');
        }
    }, [isCASuccess, isCAError])


    React.useEffect(() => {

        console.log(isLDSuccess, isLDSuccess, laResult);

    }, [isLDError, isLDSuccess, ldResult])

    React.useEffect(() => {

        console.log(isLTSuccess, isLTSuccess, ltResult);

        if (isLTSuccess && ltResult) {
            const ls = [];
            for (const t of ltResult?.timings) {

                let date = new Date(`${values.startDate}T${t}`);
                const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                date = new Date(date.getTime() - userTimezoneOffset);

                if (date > new Date()) {
                    const v = timings.find(item => item.value === moment(date).format('HH:mm'));

                    if (v) {
                        ls.push(v);
                    }
                }

            }
            console.log(ls);
            setSTimings(ls);
        } else {
            setSTimings([]);
        }


    }, [isLTError, isLTSuccess, ltResult])


    const [values, setValues] = React.useState({
        startDate: '',
        title: 'Student Appointment',
        startTime: '',
        childId: '',
        description: ''
    });

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});

        if (prop === 'startTime') {
            console.log(event.target.value)
            loadEvaluators({startTime: event.target.value});
        } else if (prop === 'startDate') {
            console.log(event.target.value)

            loadEvaluators({startDate: event.target.value});
        }
    };

    const handleCancelAppointment = () => {


        const {reason, appointmentId} = values;

        setErrMsg('');

        if (!reason || !reason.trim()) {
            setErrMsg('Please provide a reason for cancellation.');
        } else {
            // Proceed with cancellation action
            // Your cancellation logic here
            const obj = {
                appointmentId,
                reason
            };
            cancelAppointment(obj);
        }
    }

    const handleSaveAppointment = () => {

        if (values.startTime && values.startDate && values.childId) {

            const selectedStartDateTime = new Date(`${values.startDate}T${values.startTime}`);
            const selectedEndDateTime = new Date(`${values.startDate}T${values.startTime}`);
            selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 30);

            const newEvent = {
                title: 'Student Appointment',
                startDate: selectedStartDateTime,
                endDate: selectedEndDateTime,
                studentId: values.childId,
                description: values.description,
                appointmentId: values.appointmentId,
                teacherId: values.teacherId
            };


            saveAppointment(newEvent);

            // Update the events array in the state
            setEvents((prevEvents) => [...prevEvents, newEvent]);

            // resetValues();

            // Perform any additional logic, such as saving to a database

            // Close the dialog
            setOpenDialog(false);

        } else {
            setErrMsg(t("common.inputError"));
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
        }

    };

    const resetValues = () => {
        setValues({
            startDate: '',
            startTime: '',
            childId: '',
            description: '',
            appointmentId: undefined
        });
    };

    const isPastDate = (date) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return date < currentDate;
    };

    const handleSelectSlot = (slotInfo) => {
        const selectedDate = slotInfo.start;

        // Set the time of the current date to midnight
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Check if the selected date is in the past
        if (selectedDate < currentDate) {
            setErrMsg(t("errMsg.pastDateMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        const maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 1);
        maxDate.setHours(0, 0, 0, 0);

        // Check if the selected date is within the next month
        if (selectedDate < currentDate || selectedDate >= maxDate) {
            setErrMsg(t("errMsg.monthMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (isPastDate(selectedDate)) {
            setErrMsg(t("errMsg.pastDateMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        setSelectedDate(selectedDate);

        // Adjust time zone offset when formatting the date
        const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
        setValues({
            ...values,
            startDate: adjustedDate.toISOString().slice(0, 10), // Format the adjusted date for date textfield
        });

        setOpenDialog(true);
    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setErrMsg('');
    };

    const handleEventClick = (event) => {

        const startDateTime = new Date(event.start);
        const formattedStartTime = `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`;
        console.log("Formatted StartTime------", formattedStartTime);

        setValues({
            title: event.title,
            childId: event.student?.studentId,
            teacherId: '',
            appointmentId: event.appointmentId,
            description: event.description,
            startDate: event.start.toISOString().slice(0, 10),
            startTime: formattedStartTime
        });
        setOpenDialog(true);
    };


    const handleNavigate = (newDate, view, action) => {
        console.log('Navigating:', action, 'to', newDate, view);
        if (newDate.getMonth() != searchStartDate.getMonth()) {
            setSearchStartDate(newDate);
        }
    };


    const loadEvaluators = ({childId, startDate, startTime}) => {

        const sDate = startDate ? '' + startDate : '' + values.startDate;
        const sTime = startTime ? '' + startTime : '' + values.startTime;
        const c = childId ? childId : values.childId;
        const selectedStartDateTime = new Date(`${sDate}T${sTime}`);
        const selectedEndDateTime = new Date(`${sDate}T${sTime}`);

        selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 29);

        const obj = {type, childId: c, startDate: selectedStartDateTime, endDate: selectedEndDateTime}
        loadAvailableTeachers(obj);

    }

    const [open, setOpen] = React.useState(false);

    const handleCancelConfirmation = () => {
        setShowConfirmation(true);
    };


    const handleCancelNo = () => {
        setShowConfirmation(false);
    };


    return (
        <React.Fragment>
            {/*<Typography variant={"h1"} mb={3}>{t('pages.title.basicCalendar')}</Typography>*/}
            <Stack
                sx={{
                    mb: 2,
                    mt: -2
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>


            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={snackbarOpen}
                autoHideDuration={2500}
                startAccessor="startDate"
                endAccessor="endDate"
                onClose={handleSnackbarClose}
                message={errMsg}
                action={
                    <IconButton color={"primary"} size={"small"}>
                        <WarningAmberOutlinedIcon/>
                    </IconButton>
                }
            />

            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            step={15}
                            defaultDate={new Date()}
                            onNavigate={handleNavigate}
                            style={{height: 600}}
                            selectable={true} // Enable user selection
                            onSelectSlot={handleSelectSlot}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={(event) => {
                                handleEventClick(event)
                            }}
                            eventPropGetter={(event, start, end, isSelected) => {
                                return {
                                    style: {
                                        backgroundColor: isSelected ? '#ff8933' : (isPastDate(start) ? 'lightblue' : event.color),
                                        color: isSelected ? 'black' : event.color,
                                    },
                                };
                            }}
                            components={{
                                event: EventComponent,
                                agenda: {
                                    event: AgendaEventComponent,
                                },
                            }}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>

            <Div>

                <Dialog open={openDialog}>
                    <DialogTitle>{t("parent.title.title")}</DialogTitle>
                    <DialogContent>


                        <FormControl noValidate autoComplete="off">
                            <Div
                                sx={{
                                    '& .MuiTextField-root': {
                                        mb: 3,
                                        mt: 0.5
                                    },
                                }}
                            >
                                <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                                    <InputLabel id="demo-multiple-checkbox-label">{t("parent.title.child")}</InputLabel>
                                    <Select
                                        disabled={!isEditable || pastDate}
                                        value={values.childId}
                                        label={t("parent.title.child")}
                                        onChange={handleChange('childId')}
                                        renderValue={(selected) => {
                                            // Find the selected child based on the childId
                                            const selectedChild = children.find((child) => child.studentId === selected);

                                            // Display only the selected child's name
                                            return (
                                                <Div sx={{display: 'flex', alignItems: 'center'}}>
                                                    <ListItemAvatar>
                                                        {selectedChild && selectedChild.images?.length > 0 ? (
                                                            <DDImage path={selectedChild.images[0]}
                                                                     alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                                        ) : (
                                                            <Avatar
                                                                alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                                        )}
                                                    </ListItemAvatar>
                                                    {selectedChild?.firstName} {selectedChild?.lastName}
                                                </Div>
                                            );
                                        }}
                                    >
                                        {children && children.map((item) => (
                                            <MenuItem key={item.studentId} value={item.studentId}>
                                                <ListItemAvatar>
                                                    {item.images?.length > 0 ? (
                                                        <DDImage path={item.images[0]}
                                                                 alt={item.firstName + ' ' + item.lastName}/>
                                                    ) : (
                                                        <Avatar alt={item.firstName + ' ' + item.lastName}/>
                                                    )}
                                                </ListItemAvatar>
                                                {item.firstName} {item.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <FormControl fullWidth>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                disabled={!isEditable || pastDate || !values?.childId}
                                                open={open}
                                                onOpen={(event) => {
                                                    setOpen(true)
                                                }}
                                                onClose={(event) => {
                                                    setOpen(false)
                                                }}
                                                shouldDisableDate={(day) => {

                                                    if (pastDate) {
                                                        return false
                                                    } else if (ldResult?.availableDates &&
                                                        ldResult.availableDates.indexOf(moment(day).format('YYYY-MM-DD')) > -1) {
                                                        return false
                                                    } else {
                                                        return true
                                                    }
                                                }}
                                                minDate={pastDate ? null : minDate}
                                                maxDate={pastDate ? null : maxDate}
                                                loading={false}
                                                label={t('common.date')}
                                                format="dd/MM/yyyy"
                                                value={values.sstartDate}
                                                onChange={(event, newValue) => {
                                                    const startDate = moment(event).format('YYYY-MM-DD');
                                                    const sstartDate = event;
                                                    setValues({...values, startDate, sstartDate})

                                                    loadAvailableTimes({
                                                        date: startDate,
                                                        childId: values.childId,
                                                        type,
                                                        appointmentId: values.appointmentId
                                                    })

                                                }}
                                                renderInput={(params) => <TextField {...params}

                                                />}
                                            />

                                        </Stack>
                                    </LocalizationProvider>
                                </FormControl>


                                <FormControl fullWidth sx={{mb: 2, mt: 1}}>
                                    {pastDate && <TextField label={t("parent.title.time")} disabled={pastDate}
                                                            value={values.startTime}/>}
                                    {!pastDate && <>
                                        <InputLabel id="demo-simple-select-label">{t("parent.title.time")}</InputLabel>
                                        <Select labelId="demo-simple-select-label"
                                                disabled={!isEditable}
                                                id="demo-simple-select"
                                                value={values.startTime}
                                                label={t('common.time')}
                                                onChange={
                                                    handleChange('startTime')

                                                }

                                        >
                                            {sTimings.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select></>}
                                </FormControl>


                                <FormControl fullWidth sx={{mb: 3, mt: 1}}>

                                    {pastDate && <TextField label={t("parent.title.teacher")} disabled={pastDate}
                                                            value={values?.teacher?.firstName + ' ' + values?.teacher?.lastName}/>}
                                    {!pastDate && <>
                                        <InputLabel
                                            id="demo-multiple-checkbox-label">{t("parent.title.teacher")}</InputLabel>

                                        <Select
                                            disabled={!isEditable}
                                            value={values.teacherId}
                                            label={t("parent.title.teacher")}
                                            onChange={handleChange('teacherId')}
                                            renderValue={(selected) => {
                                                // Find the selected child based on the childId
                                                const selectedChild = evaluators?.find((child) => child.teacherId === selected);

                                                // Display only the selected child's name
                                                return (
                                                    <Div sx={{display: 'flex', alignItems: 'center'}}>
                                                        <ListItemAvatar>
                                                            {selectedChild && selectedChild.images?.length > 0 ? (
                                                                <DDImage path={selectedChild.images[0]}
                                                                         alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                                            ) : (
                                                                <Avatar
                                                                    alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                                            )}
                                                        </ListItemAvatar>
                                                        {selectedChild?.firstName} {selectedChild?.lastName}
                                                    </Div>
                                                );
                                            }}
                                        >
                                            {evaluators && evaluators.map((item) => (
                                                <MenuItem key={'key-teacher-' + item.teacherId} value={item.teacherId}>
                                                    <ListItemAvatar>
                                                        {item.images?.length > 0 ? (
                                                            <DDImage path={item.images[0]}
                                                                     alt={item.firstName + ' ' + item.lastName}/>
                                                        ) : (
                                                            <Avatar alt={item.firstName + ' ' + item.lastName}/>
                                                        )}
                                                    </ListItemAvatar>
                                                    {item.firstName} {item.lastName}
                                                </MenuItem>
                                            ))}
                                        </Select></>}

                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        disabled={!isEditable || pastDate}
                                        id="outlined-multiline-static"
                                        label={t("common.desc")}
                                        multiline
                                        rows={2}
                                        value={values.description}
                                        name="description"
                                        onChange={handleChange('description')}
                                    />
                                </FormControl>
                                {t("parent.title.txt")}
                                <Stack
                                    sx={{
                                        mb: 2,
                                        width: '90%',
                                        mx: 'auto',
                                        display: 'block',
                                        position: 'relative', // Add position relative to enable absolute positioning of loader
                                    }}
                                    spacing={2}>
                                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                </Stack>

                                {!showConfirmation && (
                                    <ButtonGroup
                                        sx={{
                                            mb: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                        disableElevation
                                        variant="contained"
                                        color={"warning"}
                                    >

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            size="medium"
                                            onClick={() => {
                                                setOpenDialog(false);
                                                navigate('/dashboard')
                                            }}
                                            sx={{
                                                width: "30%"
                                            }}
                                        >
                                            {t("common.closeBtn")}
                                        </Button> )

                                        {isEditable && <Button
                                            type="submit"
                                            variant="contained"
                                            size="medium"
                                            onClick={handleSaveAppointment}
                                            sx={{
                                                width: "43%",
                                                ml: .5,
                                            }}
                                            loading={isLoadingSave}
                                        >
                                            {values.appointmentId ? t("common.updateBtn") : t("common.saveBtn")}
                                        </Button>}
                                        {!values.cancelled && values.appointmentId &&
                                            <Button
                                                type="button"
                                                variant="contained"
                                                size="medium"
                                                onClick={handleCancelConfirmation}
                                                sx={{
                                                    width: "35%",
                                                    ml: .5
                                                }}
                                            >
                                                {t("common.cancelBtn")}
                                            </Button>
                                        }
                                    </ButtonGroup>)}
                                {showConfirmation && (
                                    <>
                                        {/* Text field before the alert */}
                                        <TextField
                                            id="reason"
                                            label="Reason for cancellation"
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            multiline
                                            rows={2}
                                            onChange={handleChange('reason')}
                                            sx={{width: '100%'}}
                                        />
                                        <Alert severity="warning" sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}>
                                                <span style={{marginRight: '15px'}}>{t("common.cancelTxt")}</span>
                                                <Stack direction="row" spacing={2}>
                                                    <LoadingButton loading={isCALoading}
                                                                   onClick={handleCancelAppointment}>{t("common.yesBtn")}</LoadingButton>
                                                    <Button onClick={handleCancelNo}>{t("common.noBtn")}</Button>
                                                </Stack>
                                            </div>
                                        </Alert></>
                                )}
                            </Div>
                        </FormControl>
                    </DialogContent>
                </Dialog>
            </Div>
        </React.Fragment>

    )
        ;
};

export default CalendarBasic;
