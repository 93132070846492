import React, {useEffect} from 'react';
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Select from "@mui/material/Select";
import {useAppMutation} from "../../../services";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import teacherServices from "../../../services/teacher-services";


const HOURS = []

for (let i = 1; i <= 40; i++) {
    if (i === 1) {
        HOURS.push({key: i + '-hour', label: i + ' Hour'});
    } else {
        HOURS.push({key: i + '-hours', label: i + ' Hours'});
    }

}


const Step4 = ({stepsData, handleNext}) => {

    const [values, setValues] = React.useState({
        videoDemoLink: '',
        referred: '',
        references: '',
        weeklyHoursToWork: ''
    });
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(teacherServices.completeStep4);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [value, setValue] = React.useState();

    useEffect(() => {
        console.log(isSuccess, isError)
        if (stepsData) {
            setValues({...values, ...stepsData});
        }

    }, [stepsData]);

    useEffect(() => {
        if (error && isError) {
            setErrMsg(error.message);
        } else if (isSuccess) {
            handleNext(5);
        }
    }, [isError, isSuccess])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep4 = async () => {
        setErrMsg('')
        const {
            videoDemoLink,
            referred,
            references,
            weeklyHoursToWork
        } = values;

        mutate({
            videoDemoLink,
            referred,
            references,
            weeklyHoursToWork
        })

    };


    const backToStep2 = async () => {
        handleNext(3);
    };

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {},
            }}
            noValidate
            autoComplete="off"
        >
            <Div sx={{display: 'flex', flexDirection: 'column', mt: 2}}>

                <FormControl sx={{
                    width: '50%',
                    mt: 1,
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('teacherForm.refer')}
                        value={values.references}
                        name="references"
                        onChange={handleChange('references')}
                    />


                </FormControl>

                <FormControl sx={{
                    width: '50%',
                    mt: 1,
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('teacherForm.whoRefer')}
                        value={values.referred}
                        name="reffered"
                        onChange={handleChange('referred')}
                    />


                </FormControl>

                <FormControl sx={{
                    width: '50%',
                    mt: 1,
                }}>
                    <TextField
                        id="outlined-multiline-static"
                        label={t('teacherForm.link')}
                        value={values.videoDemoLink}
                        name="demoLink"
                        onChange={handleChange('videoDemoLink')}
                    />


                </FormControl>


                <FormControl sx={{
                    width: '50%',
                    mt: 1,
                }}>
                    <InputLabel id="demo-simple-select-label">{t('teacherForm.duration')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-hoursToWork"
                        value={values.weeklyHoursToWork}
                        onChange={handleChange('weeklyHoursToWork')}
                        label={t('teacherForm.duration')}
                    >
                        <MenuItem value="">{t('common.select')}</MenuItem>

                        {HOURS.map((item, index) => {
                            return <MenuItem value={item.key}>{item.label}</MenuItem>
                        })}


                    </Select>
                </FormControl>

            </Div>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={backToStep2}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>
                <Button variant={"contained"} onClick={submitStep4}>
                    {t("common.finishBtn")}
                </Button>
            </Div>
        </Box>
    );

};

export default Step4;
