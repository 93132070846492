import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import Select from "@mui/material/Select";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DDImage from "../../../../component/DDImage";
import DDImageField from "../../../../component/DDImageField";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});


const Segments = ({hide, module}) => {
    const {t} = useTranslation();
    const [values, setValues] = React.useState({
        module,
        name: '',
        color: '',
        description: '',
        segments: module?.segments ? [...module.segments] : []
    });
    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const [errMsg, setErrMsg] = React.useState('');


    const saveSegment = () => {

        const {name, color, description} = values;

        if (name && color && description) {
            //  hide(values)
            const segments = values.segments;
            segments.push({key: segments.length + 1, name, color, description});
            setValues({...values, segments});

        } else {
            setErrMsg('Error')
        }


    };


    const saveSegments = () => {


        hide(values.segments)


    };

    return <Box
        component="form"
        sx={{
            '& .MuiTextField-root': {m: 1},
        }}
        noValidate
        autoComplete="off"
    >
        <Div sx={{minHeight: 450}}>
            <Typography sx={{mt: 1, ml: 1}} variant="h4">{t("admin-program.title.seg")}</Typography>

            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '50%'}
            }}>
                <TextField
                    required
                    label={t("admin-program.title.segName")}
                    value={values.name}
                    onChange={handleChange('name')}

                />
            </FormControl>
            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '50%'}
            }}>
                <TextField
                    required
                    label={t("admin-program.title.segColor")}
                    value={values.color}
                    onChange={handleChange('color')}

                />
            </FormControl>

            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '100%'},
                mt: 1,

            }}>
                <TextField
                    required
                    multiline
                    maxRows={3}
                    minRows={3}
                    label={t("admin-program.title.segDesc")}
                    value={values.description}
                    onChange={handleChange('description')}

                />
            </FormControl>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton variant={"variant"} onClick={() => {
                    saveSegment();
                }}>
                    {t("admin-program.title.addSegBtn")}
                </LoadingButton>

            </Div>
            <Div sx={{display: 'flex', flexWrap: 'wrap'}}>
                {values.segments && values.segments.map(segment =>
                    <JumboCardQuick bgColor={module.color} sx={{m: 1}}>
                        <Div sx={{display: "flex", alignItems: 'center'}}>
                            <Div sx={{flex: 1}}>
                                <Typography color={'common.white'} variant={"h2"} mb={.5}>{segment.name}</Typography>
                                <Typography color={'common.white'} variant={"h5"}
                                            mb={0}>{segment.description}</Typography>
                            </Div>
                            <DeleteIcon onClick={() => {

                                let segments = [];
                                if (segment._id) {
                                    segments = values.segments.filter(item => item._id !== segment._id);
                                } else if (segments.key) {
                                    segments = values.segments.filter(item => item.key !== segment.key);
                                }

                                setValues({...values, segments});

                            }}/>
                        </Div>


                    </JumboCardQuick>)}
            </Div>

        </Div>
        <Stack sx={{width: '100%'}} spacing={2}>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

            <Button
                onClick={() => {
                    //  handleNext(1)
                    hide()

                }}
                sx={{mr: 1}}
            >
                {t("common.backBtn")}
            </Button>
            <Div sx={{flex: '1 1 auto'}}/>


            <Div sx={{flex: '1 1 auto'}}/>

            <LoadingButton variant={"variant"} onClick={() => {
                saveSegments();
            }}>
                {t("admin-program.title.saveSegBtn")}
            </LoadingButton>

        </Div>
    </Box>
}


const Module = ({hide, module}) => {

    const [values, setValues] = React.useState({
        ...module
    });
    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const [errMsg, setErrMsg] = React.useState('');

    const [showSegments, setShowSegments] = React.useState(false);

    const hideSegments = (segments) => {
        if (segments) {
            setValues({...values, segments})
        }
        setShowSegments(false)
    }


    const saveModule = () => {

        const {name, color, description, segments} = values;

        setErrMsg('');

        if (!name || !color || !description) {
            setErrMsg(t("admin-program.title.onSaveErr"))
            return;
        }

        if (!segments || segments.length <= 0) {

            setErrMsg(t("admin-program.title.segErr"))

            return
        }

        hide({...values});


    };
    const {t} = useTranslation();
    if (showSegments) {
        return <Segments module={{...values}} hide={hideSegments}/>
    } else {


        return <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},
            }}
            noValidate
            autoComplete="off"
        >
            <Div sx={{minHeight: 450}}>
                <Formik
                    validateOnChange={true}
                    initialValues={values}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={() => {
                    }}
                >

                    {({isSubmitting}) => (
                        <Form noValidate autoComplete="off">

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>
                                <TextField
                                    required
                                    label={t("admin-program.title.moduleName")}
                                    value={values.name}
                                    onChange={handleChange('name')}

                                />
                            </FormControl>
                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>
                                <TextField
                                    required
                                    label={t("admin-program.title.themeColor")}
                                    value={values.color}
                                    onChange={handleChange('color')}

                                />
                            </FormControl>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '100%'},
                                mt: 1,

                            }}>
                                <TextField
                                    required
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    label={t("common.desc")}
                                    value={values.description}
                                    onChange={handleChange('description')}

                                />
                            </FormControl>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '100%'},
                                mt: 1,
                                ml: 2,
                            }}>
                                <Typography variant="h5">{t("common.image")}</Typography>
                                {!values.newImage && values.images?.length > 0 ? (
                                        <DDImageField name={"profile_pic"}
                                                      alt={"Program Picture"}
                                                      onFileSelection={(file) => {
                                                          console.log(file)
                                                          setValues({...values, newImage: file});
                                                      }}
                                                      sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                                      path={values.images[0]}
                                                      alt={values.firstName + ' ' + values.lastName}/>) :
                                    <JumboAvatarField
                                        name={"profile_pic"}
                                        src={values.newImage}
                                        alt={"Program Picture"}
                                        onFileSelection={(file) => {
                                            setValues({...values, newImage: file});
                                        }}
                                        sx={{width: 100, height: 100, margin: '0 auto 24px'}}
                                    />}

                            </FormControl>


                        </Form>)}
                </Formik>
            </Div>
            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        //  handleNext(1)
                        hide();
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton variant={"variant"} onClick={() => {
                    setShowSegments(true);
                }}>
                    {t("admin-program.title.segBtn")}
                </LoadingButton>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton variant={"variant"} onClick={() => {
                    saveModule();
                }}>
                    {t("admin-program.title.saveModBtn")}
                </LoadingButton>

            </Div>
        </Box>
    }
}

const Step2 = ({hideDialog, stepsData, handleNext, setStepsData}) => {


    const [selectedModules, setSelectedModules] = React.useState([]);
    const [selectedModuleIds, setSelectedModuleIds] = React.useState([]);
    const [modules, setModules] = React.useState([]);
    const {mutate: loadModules, data: mResult} = useAppMutation(adminServices.loadModules);
    const [newModule, setNewModule] = React.useState({});
    const [isLoading] = React.useState(false);
    const {t} = useTranslation();
    const [values, setValues] = React.useState({
        selectedModules: [],
        newModule: {
            name: '',
            color: '',
            description: '',
            newImage: '',
            image: ''
        }
    });
    const [errMsg, setErrMsg] = React.useState('');


    const [addEditModule, setAddEditModule] = React.useState(false);

    useEffect(() => {

        if (stepsData.modules) {

            const ids = [];

            setSelectedModules(stepsData.modules);

            for (const m of stepsData.modules) {
                ids.push(m.moduleId);
            }

            setSelectedModuleIds(ids);

        }


    }, [stepsData])

    useEffect(() => {

        if (!mResult) {
            loadModules({size: 100})
        } else {
            setModules(mResult.modules ? mResult.modules : []);
        }

        console.log('loadModules', mResult)


    }, [mResult])

    const hideModule = (module) => {
        if (module) {

            console.log('Save Module', module)
            let index = -1;

            if (module.moduleId) {
                module.edit = true;
                index = selectedModules.findIndex(mod => mod.moduleId === module.moduleId);

            } else if (module.key) {
                module.add = true;
                index = selectedModules.findIndex(mod => mod.key === module.key);
            }

            console.log(index);

            if (index > -1) {
                selectedModules[index] = module;
            } else {
                selectedModules.push({...module, key: selectedModules.length + 1});
            }

        }
        setAddEditModule(false)
    }

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep = async () => {
        setErrMsg('');

        const {firstName, lastName, title, phone} = values;

        if (selectedModules && selectedModules.length > 0) {
            setStepsData({...stepsData, modules: selectedModules});
            handleNext(3);
        } else {
            setErrMsg(t("admin-program.title.modErr"))
        }


    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},
                minHeight: 500,

            }}
            noValidate
            autoComplete="off"
        >


            {!addEditModule && <Div sx={{minHeight: 450}}><Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={() => {
                    setNewModule({
                        name: '',
                        color: '',
                        description: '',
                        newImage: '',
                        image: '',
                        segments: []
                    })
                    setAddEditModule(true)
                }}>
                    {t("admin-program.title.addModBtn")}
                </LoadingButton>

            </Div>
                <Formik
                    validateOnChange={true}
                    initialValues={values}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={() => {
                    }}
                >
                    {({isSubmitting}) => (
                        <Form noValidate autoComplete="off">


                            {selectedModules && !addEditModule && <>
                                <FormControl sx={{
                                    width: {xs: '100%', sm: '100%', xl: '100%'},
                                    mt: 2,

                                }}>

                                    <InputLabel id="demo-simple-select-label">{t("admin-program.title.module")}</InputLabel>


                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        label={t("admin-program.title.module")}
                                        value={selectedModuleIds}
                                        required
                                        renderValue={(selected) => {
                                            // return selected.reduce((name, item) => (!name ? (name) : (name) + ',') + item.name, '');
                                            // return '';
                                        }}
                                        input={<OutlinedInput label="Tag"/>}
                                        multiple
                                        sx={{width: '100%', minWidth: '100%'}}
                                        onChange={(event) => {

                                            console.log('event', event);
                                            const {
                                                target: {value},
                                            } = event;

                                            const arr1 = selectedModules.filter(mm => mm.key)
                                            const arr2 = modules.filter(mm => value.includes(mm.moduleId));

                                            setSelectedModules([...arr1, ...arr2]);
                                            setSelectedModuleIds(value);


                                        }}

                                    >
                                        {modules && modules.map(module => {

                                            console.log(module);

                                            return <MenuItem key={'program-module-key-' + module.moduleId}
                                                             value={module.moduleId}>
                                                <Checkbox
                                                    checked={selectedModules.findIndex(mm => mm.moduleId === module.moduleId) > -1}/>
                                                <ListItemText primary={module.name}/>
                                            </MenuItem>
                                        })}

                                    < /Select>
                                </FormControl>


                            </>}
                        </Form>


                    )}</Formik>
                <Div sx={{display: 'flex', flexWrap: 'wrap'}}>
                    {selectedModules.map((m, index) => {
                        return <JumboCardQuick bgColor={m.color} sx={{m: 1}}>
                            <Div sx={{display: "flex", alignItems: 'center'}}>

                                {m.images?.length > 0 ?
                                    <DDImage sx={{width: 40, height: 40, cursor: 'pointer'}}
                                             alt={m.name} path={m.images[0]}/> :
                                    <Avatar
                                        sx={{width: 40, height: 40, cursor: 'pointer'}}
                                        alt={m.name}
                                        src={m.newImage}
                                    />}
                                <Div sx={{flex: 2}}>
                                    <Typography color={'common.white'} variant={"h2"}
                                                mb={.5}>{m.name}</Typography>
                                    <Typography color={'common.white'} variant={"h5"}
                                                mb={0}>{m.segments.length} {t("admin-program.title.seg")}</Typography>
                                </Div>
                                <Div sx={{flex: 1}}>
                                    <DeleteIcon onClick={() => {
                                        alert(index);

                                        const ms = selectedModules;

                                        ms.splice(index, 1);

                                        console.log(ms);

                                        setValues({...values, selectedModules: ms});

                                    }}/>

                                    <EditIcon onClick={() => {

                                        setNewModule({...m, key: index + 1});
                                        setAddEditModule(true);

                                    }}/>
                                </Div>

                            </Div>

                        </JumboCardQuick>
                    })}</Div></Div>}

            {addEditModule && <Module module={newModule} hide={hideModule}/>}


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            {!addEditModule &&
                <Div sx={{display: 'flex', flexDirection: 'row', pt: 1}}>

                    <Button
                        onClick={() => {
                            handleNext(1)
                        }}
                        sx={{mr: 1}}
                    >
                        {t("common.backBtn")}
                    </Button>

                    <Button
                        onClick={() => {
                            hideDialog()
                        }}
                        sx={{mr: 1}}
                    >
                        {t("common.cancelBtn")}
                    </Button>
                    <Div sx={{flex: '1 1 auto'}}/>

                    <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep}>
                        {t("common.nextBtn")}
                    </LoadingButton>

                </Div>}
        </Box>);

};

export default Step2;
