import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {Box, Typography} from '@mui/material';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {useChildrenProgressContext} from './context';
import {useTranslation} from "react-i18next";
import {formatDate} from "../../../utils/constants/appActions";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ProgressGraph = () => {
        const {globalState} = useChildrenProgressContext();
        const [xAxisLabels, setXAxisLabels] = useState([]);
        const [yAxisData, setYAxisData] = useState([]);
        const [progressPoint, setProgressPoint] = useState([]);
        const [totalNumberOfBookings, setTotalNumberOfBookings] = useState('');
        const [min, setMin] = useState(0);
        const [max, setMax] = useState(0);
        const [currentLevel, setCurrentLevelWithoutMinus] = useState({});
        const [dates, setDates] = useState([]); // Dummy dates
        const {t, i18n} = useTranslation();

        useEffect(() => {
            if (globalState?.statisticsData) {
                const {
                    levelsWithPointsWithoutMinus: yAxisLabels,
                    currentLevelWithoutMinus,
                    evaluation,
                    currentLevel
                } = globalState.statisticsData;
                const {
                    bookingEarnPoints,
                    noOfBookings,
                    bookings
                } = globalState.statisticsData.graph;

                const dts = []
                for (const d of bookings) {
                    console.log(d.lastBooking.startDate)
                    dts.push(d.lastBooking.startDate);
                }
                setDates(dts);


                setCurrentLevelWithoutMinus(currentLevelWithoutMinus)

                if (evaluation?.actualLevel?.label === currentLevel?.label) {
                    setMin(evaluation?.noOfPoints || 0)
                } else {
                    setMin(currentLevelWithoutMinus?.points || 0)

                }
                setMax(currentLevelWithoutMinus?.maxPoints + 21 || 0)

                if (currentLevelWithoutMinus) {

                    const b = [...noOfBookings];
                    let v = noOfBookings[noOfBookings.length - 1]
                    for (let i = 0; i < 5; i++) {
                        b.push(++v);
                    }

                    setXAxisLabels(b);
                    setYAxisData([0, ...bookingEarnPoints]);
                    //setDates(bookingDates || []);
                }


                setProgressPoint([...bookingEarnPoints]);
                setTotalNumberOfBookings(totalNumberOfBookings || '');
            }
        }, [globalState]);

        // Adjust Y-axis dynamically based on data
        const minYValue = Math.min(...yAxisData); // Find the minimum Y value
        const maxYValue = Math.max(...yAxisData); // Find the maximum Y value

        const chartData = {
            labels: xAxisLabels,
            datasets: [
                {
                    label: 'Progress Points',
                    data: [...progressPoint],
                    borderColor: '#FFD600',
                    backgroundColor: '#FFD600',
                    pointBackgroundColor: '#FFD600',
                    pointBorderColor: '#FFD600',
                    fill: false,
                    tension: 0.4,
                },
                /*{
                    label: 'B1 Level',
                    data: new Array(xAxisLabels.length).fill(1400),
                    borderColor: 'green',
                    borderDash: [5, 5],
                    pointRadius: 0,
                    fill: false,
                },*/
                {
                    label: currentLevel?.label || '',
                    data: new Array(xAxisLabels.length).fill((currentLevel?.maxPoints || 0) + 1),
                    borderColor: 'green',
                    backgroundColor: 'green',
                    pointRadius: 0,
                    fill: false
                },

                /*{
                    label: 'Projected Progress',
                    data: [...yAxisData, ...Array(5).fill(yAxisData[yAxisData.length - 1] || 0)],
                    borderColor: 'black',
                    borderDash: [5, 5],
                    fill: false,
                    tension: 0.2,
                },*/
                // Dataset for showing the progressPoint on the graph
                /*{
                    label: 'Current Progress Point',
                    data: [450, 100], // Single data point to be displayed
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                    pointBackgroundColor: 'orange',
                    pointBorderColor: 'orange',
                    pointRadius: 10, // Highlight the point with a larger radius
                    fill: false,
                    showLine: false, // Do not show line, only the point
                },*/
            ],
        };


        const chartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
                tooltip: {
                    callbacks: {
                        title: (tooltipItems) => {
                            const index = tooltipItems[0].dataIndex;
                            if (index === 0) {
                                return '';
                            }
                            const d = formatDate({
                                strFormat: 'DD-MM-YYYY hh:mm',
                                dateString: dates[index - 1],
                                language: i18n
                            })
                            return d;
                        },
                        label: (tooltipItem) => {
                            // Show progress points in the tooltip label
                            const points = progressPoint[tooltipItem.dataIndex];
                            return `Progress Points: ${points}`;
                        }
                    }
                }

            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Nombre de leçons',
                    },
                    ticks: {
                        callback: function (value, index, ticks) {
                            return value;
                        }
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Progress Points',
                    },
                    min: min, // Dynamically set the minimum Y-axis value
                    max: max, // Add padding above the maximum Y value for better spacing
                    ticks: {
                        // Include a dollar sign in the ticks
                        stepSize: 10,
                        callback: function (value, index, ticks) {
                            return value;
                        }
                    }
                },
            },
        };

        const targetIconPlugin = {
            id: 'targetIcon',
            afterDraw: (chart) => {
                const ctx = chart.ctx;
                const chartArea = chart.chartArea;
                const x = chart.scales.x.getPixelForValue(xAxisLabels.length - 1) - 12; // Position at the end of the X-axis
                const y = chart.scales.y.getPixelForValue((currentLevel?.maxPoints || 0) + 1); // Position for the green line

                // Load and draw the image at the calculated position
                const img = new Image();
                img.src = '/images/logos/target.png';
                img.onload = () => {
                    ctx.drawImage(img, x - 15, y - 15, 30, 30); // Center image on target
                };
            },
        };

        return (
            <Box sx={{width: '100%', height: '400px'}}>
                <Typography variant="h5" gutterBottom sx={{mb: 5}}>
                    Progression Graph
                </Typography>
                {xAxisLabels.length > 0 && yAxisData.length > 0 ? (
                    <Line data={chartData} options={chartOptions} plugins={[targetIconPlugin]}/>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No data available for the graph.
                    </Typography>
                )}
            </Box>
        )
            ;
    }
;

export default ProgressGraph;
