import React from 'react';
import {ListItem, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import List from "@mui/material/List";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {Alert, Stack} from "@mui/material";


const StyledCircle = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
}));
const Header = ({title, subheader}) => {

    const {t} = useTranslation();

    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 4
            }}
        >
            <Typography variant={"h2"}>{title}</Typography>
            <Typography variant={"body1"} mb={2} color={"text.secondary"}>{subheader}</Typography>
            <Stack sx={{width: '100%'}} spacing={2}>
                <Alert severity="info">{t('calendar.alertTxt')}</Alert>
            </Stack>
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                    mb: 2,
                    mt: 2
                }}
            >


                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                    }}
                >
                    <Div sx={{
                        backgroundColor: "#59B2FF",
                        minWidth: '90%',
                        minHeight: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography variant="body1" color="white">
                            {t('common.readyBooking')}
                        </Typography>
                    </Div>
                </ListItem>

                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                    }}
                >
                    <Div sx={{
                        backgroundColor: "#1D6ADE",
                        minWidth: '90%',
                        minHeight: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}><Typography variant="body1" color="white">
                        {t('common.bookingCompleted')}
                    </Typography></Div>

                </ListItem>

                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                    }}
                >
                    <Div sx={{
                        backgroundColor: "#dedede",
                        minWidth: '90%',
                        minHeight: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>                         <StyledCircle color="#D22B2B"/>

                        <Typography sx={{ ml : 0.5}} variant="body1" color="white">
                            {t('common.notAttended')}
                        </Typography>

                    </Div>
                </ListItem>


            </List>
        </Div>
    );
};

export default Header;
