import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    Button,
    List
} from '@mui/material';
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div";
import { useAppMutation } from "../../../services";
import adminServices from "../../../services/admin-services";
import { useParentContext } from "./context";
import moment from "moment";

const ReservationInfo = () => {
    const { t } = useTranslation();
    const { globalState, setGlobalState } = useParentContext();
    const [bookings , setBookings]= React.useState([])

    const {
        mutate: loadBookingsByParent,
        isSuccess: isLBSuccess,
        data: lbResults,
        isLoading: isLBLoading,
        isError: isLBError
    } = useAppMutation(adminServices.loadBookingsByParent);



    const hide = () => {
        setGlobalState({ ...globalState, openReservationInfo: false, refreshList: true, parent: undefined })
    }

    useEffect(() => {
        if (globalState && globalState.parent) {
             loadBookingsByParent({page : 1, size : 1000, parentId :globalState.parent.parentId });
        }
    }, [globalState]);

    useEffect(() => {
        if (isLBSuccess) {
            setBookings(lbResults.bookings);
        }
    }, [isLBSuccess]);



    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 5 },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <Div sx={{ minWidth: 120 }}>
                    <FormControl fullWidth sx={{ mb: 3, mt: 1 }}>
                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {bookings && bookings.map((booking, index) => {



                                    return (
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography variant="body1">{`Student Name: ${booking.student.firstName} ${booking.student.lastName}`}</Typography>
                                                            <Typography variant="body2">{`Teacher Name: ${booking.teacher.firstName} ${booking.teacher.lastName}`}</Typography>
                                                        </>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography>{`Start Date: ${moment(booking.startDate).format('MMMM DD YYYY')}`}</Typography>
                                                            <Typography>{`Start Time: ${moment(booking.startDate).format('h:mm A')}`}</Typography>
                                                            <Typography>{`End Time: ${moment(booking.endDate).format('h:mm A')}`}</Typography>
                                                        </>
                                                    }
                                                />

                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            {(!bookings ||bookings.length === 0) && (
                                <Div sx={{ py: { lg: 1 } }}>
                                    <Typography>
                                        {t('parent-subscription.title.noBookings')}
                                    </Typography>
                                </Div>
                            )}
                        </List>

                    </FormControl>
                </Div>
            </Div>
            <Div sx={{ display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center', justifyContent: 'center' }}>
                <Button
                    onClick={hide}
                    sx={{ mr: 1 }}
                >
                    {t("common.closeBtn")}
                </Button>
            </Div>
        </Box>
    );
};

export default ReservationInfo;
