import {createContext, useContext, useState} from 'react';

const TeacherContext = createContext({});


export const useTeacherContext = () => {
    return useContext(TeacherContext);
}

export const TeacherProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({refreshList: false, openAddEdit: false, openUnavailabilityForm: false});

    return <TeacherContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </TeacherContext.Provider>
}
