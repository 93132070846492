import React from "react";
import {Navigate} from "react-router-dom";
import adminRoutes from "./adminroutes";
import parentRoutes from "./parentroutes";
import teacherRoutes from "./teacherroutes";
import AdminLogin from "../pages/admin/AdminLogin";
import Page from "@jumbo/shared/Page";
import authServices from "../services/auth-services";
import ParentLogin from "../pages/parent/ParentLogin";
import ParentSignup from "../pages/parent/ParentSignup";
import ParentForgotPass from "../pages/parent/ParentForgotPass";
import TeacherLogin from "../pages/teacher/TeacherLogin";
import TeacherSignup from "../pages/teacher/TeacherSignup";
import TeacherForgotPass from "../pages/teacher/TeacherForgotPass";
import AdminForgotPass from "../pages/admin/AdminForgotPass";
import ParentCompletePayment from "../pages/parent/Subscription/PricingPlan/ParentCompletePayment";
import Students from "../pages/admin/Students";
import PaymentStatus from "../pages/parent/Subscription/PricingPlan/PaymentStatus";

const isSuperAdmin = authServices.isSuperAdmin();
const isParent = authServices.isParent();
const isTeacher = authServices.isTeacher();

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
let routesForAuthenticatedOnly = [{
    path: "/",
    element: <Navigate to={"/parent/login"}/>
}];

if (isSuperAdmin) {
    routesForAuthenticatedOnly = [{
        path: "/",
        element: <Navigate to={"/dashboard/calendar"}/>
    }, ...adminRoutes];
} else if (isParent) {
    routesForAuthenticatedOnly = [{
        path: "/",
        element: <Navigate to={"/dashboard"}/>
    }, ...parentRoutes];
} else if (isTeacher) {
    routesForAuthenticatedOnly = [{
        path: "/",
        element: <Navigate to={"/dashboard/calendar"}/>
    }, ...teacherRoutes];
}

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [

    {
        path: "/admin/login",
        element: <Page component={AdminLogin} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/parent/login",
        element: <Page component={ParentLogin} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/teacher/login",
        element: <Page component={TeacherLogin} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/parent/signup",
        element: <Page component={ParentSignup} layout={"solo-page"} disableSmLogin={true}/>
    },
    {   path:"/parent/signup/success",
        element: <Page component={ParentSignup} layout={"solo-page"} disableSmLogin={true}/>

    },
    {
        path: "/teacher/signup",
        element: <Page component={TeacherSignup} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/parent/forgotPassword",
        element: <Page component={ParentForgotPass} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/teacher/forgotPassword",
        element: <Page component={TeacherForgotPass} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/admin/forgotPassword",
        element: <Page component={AdminForgotPass} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/parent/complete/payment",
        element: <Page component={ParentCompletePayment} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: [
            "/parent/payment/:status"
        ],
        element: <Page component={PaymentStatus} layout={"solo-page"} disableSmLogin={true}/>,
    },

];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];


export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};
