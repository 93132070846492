import React, {useEffect, useState} from 'react';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useChildrenProgressContext} from './context';
import {useTranslation} from "react-i18next";
import {formatDate} from "../../../utils/constants/appActions";

const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
        case 1:
            return `${day}st`;
        case 2:
            return `${day}nd`;
        case 3:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};


const LinearProgressCustom = () => {
    const {globalState} = useChildrenProgressContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [progressBarData, setProgressBarData] = useState({progressPoints: 0, currentLevel: ''});
    const [levels, setLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState([]);
    const [currentLevelWithoutMinus, setCurrentLevelWithoutMinus] = useState([]);
    const [evaluation, setEvaluation] = useState([]);
    const [initialPoint, setInitialPoint] = useState(0);

    const {t, i18n} = useTranslation();
// Format date function with validation


    useEffect(() => {
        if (globalState.statisticsData) {

            setEvaluation(globalState.statisticsData.evaluation);
            setCurrentLevel(globalState.statisticsData.currentLevel);
            setCurrentLevelWithoutMinus(globalState.statisticsData.currentLevelWithoutMinus);

            setInitialPoint(globalState?.statisticsData?.evaluation?.noOfPoints);

            setProgressBarData(globalState.statisticsData.bar);
            const ls = globalState.statisticsData.levelsWithPointsWithoutMinus;
            for (const level of ls) {
                level.value = level.points
            }
            setLevels(ls);

        }
    }, [globalState]);

    const progressPoints = progressBarData.progressPoints;

    const totalBlocks = levels.length;

    // Calculate exact position of the initialPoint (1050) within each block
    let accumulatedWidth = 0;
    let initialPointPosition = 0;

    for (let i = 0; i < levels.length; i++) {
        const level = levels[i];
        const nextLevel = levels[i + 1] || {points: level.points + 100};

        // Check if initialPoint lies within the current level range
        if (initialPoint >= level.points && initialPoint <= nextLevel.points) {
            const levelRange = nextLevel.points - level.points;
            const withinLevelPosition = ((initialPoint - level.points) / levelRange) * (100 / totalBlocks);
            initialPointPosition = accumulatedWidth + withinLevelPosition;
            break;
        }

        // Accumulate width percentage if the initialPoint is beyond this level
        accumulatedWidth += 100 / totalBlocks;
    }

    return (
        <Box sx={{position: 'relative', mb: 2, px: isMobile ? 1 : 0, maxWidth: '100%'}}>
            <Typography variant="h5" gutterBottom sx={{mb: 6}}>
                Progression Bar
            </Typography>

            <Box
                sx={{
                    position: 'absolute',
                    top: '35px', // Adjust based on spacing needed
                    left: `${0 / totalBlocks}%`, // Position at start of Nursery blocks
                    width: `${(100 / totalBlocks) * 3}%`, // Span across three blocks
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#002465', // Adjust color as needed
                    borderBottom: '2px solid #002465', // Bar style
                }}
            >
                Nursery
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                    height: '40px',
                    background: '#ddd', // Gray background for the entire progress bar
                    borderRadius: '4px',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                {levels.map((level, index) => {
                    const cLevel = levels[index]; //  selected level
                    const nextLevel = levels[index + 1] || {points: level.points + 100};
                    const width = 100 / totalBlocks; // Set equal width for each block

                    // Calculate if the block should be red based on the progressPoints
                    const startRange = 400;
                    const endRange = Math.min(progressPoints, nextLevel.points); // Stop coloring after progressPoints
                    const isInRedRange = progressPoints >= startRange && endRange > level.points;

                    // Calculate how much of the block should be red
                    let blockBackground;
                    if (isInRedRange) {
                        // If the block is within the red range, calculate the percentage of the block that should be red
                        if (progressPoints >= nextLevel.points) {
                            blockBackground = '#FFD600'; // Fully red block
                        } else {

                            const redWidth = ((progressPoints - Math.max(level.points, startRange)) / (nextLevel.points - level.points)) * 100;
                            console.log('redWidth', redWidth)


                            blockBackground = `linear-gradient(to right, #FFD600 ${redWidth}%, #ddd ${redWidth}%)`; // Partial red and gray


                        }
                    } else {
                        blockBackground = '#ddd'; // Fully gray block
                    }

                    return (
                        <Box
                            key={level.label}
                            sx={{
                                width: `${width}%`,
                                background: blockBackground,
                                position: 'relative',
                                borderRight: index < levels.length - 1 ? '1px solid white' : 'none',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: '#002465',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                {/* Display 1, 2, 3 for the first three levels, otherwise show the original label */}
                                {index < 3 ? index + 1 : level.label}
                            </Typography>
                        </Box>
                    );
                })}
                <Box
                    sx={{
                        position: 'absolute',
                        left: `${initialPointPosition}%`, // Use calculated position within level
                        height: '100%',
                        width: '2px', // Thickness of the red line
                        backgroundColor: '#C2185B', // Red color for the line
                        zIndex: 1 // Ensure it stays above other elements
                    }}
                />

            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    left: `${initialPointPosition}%`, // Use calculated position within level
                    zIndex: 1, // Ensure it stays above other elements
                    top: 35,
                    ml: -0.5
                }}
            >
                <img src="/images/logos/flag.png" alt="Logo" width="30"/>
            </Box>

            {/* Red line to mark initialPoint */}


            <Box sx={{position: 'relative', mt: 1}}>
                {levels.map((level, index) => {
                    // Position the points at the start of each block
                    return (
                        <Typography
                            key={`points-${level.points}`}
                            sx={{
                                fontSize: '12px',
                                color: '#000',
                                position: 'absolute',
                                left: `${(100 / totalBlocks) * index}%`, // Place points at the start of each block
                                transform: 'translateX(-50%)', // Center the point
                            }}
                        >
                            {level.points}
                        </Typography>
                    );
                })}

                {levels.some(level => level.points) && ( // Check if any level.points exists
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: '#000',
                            position: 'absolute',
                            left: `100%`, // Place it at the far right of the progress bar
                            transform: 'translateX(-50%)', // Center the point
                        }}
                    >
                        2300
                    </Typography>
                )}
            </Box>

            <Box sx={{mt: 8, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* Centered Container for Points */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    p: 2,
                    width: 'fit-content'
                }}>
                    {/* Evaluation Points */}
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                        <Box
                            sx={{
                                width: 15,
                                height: 15,
                                backgroundColor: '#C2185B', // Color for Evaluation Points
                                borderRadius: '4px',
                                mr: 1,
                            }}
                        />
                        <Typography variant="body2">
                            Start Points: {evaluation?.noOfPoints ? evaluation.noOfPoints :
                            <strong>Student Not Evaluated</strong>}
                            {evaluation?.noOfPoints && evaluation?.createdAt && (
                                <> - ({formatDate({dateString: evaluation.createdAt, language: i18n.language})})</>
                            )}
                        </Typography>
                    </Box>

                    {/* Progress Points */}
                    <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                        <Box
                            sx={{
                                width: 15,
                                height: 15,
                                backgroundColor: '#FFD600', // Color for Progress Points
                                borderRadius: '4px',
                                mr: 1,
                            }}
                        />
                        <Typography variant="body2">
                            Total Points: {progressBarData.progressPoints}
                        </Typography>
                    </Box>
                </Box>
            </Box>


        </Box>
    );
};

export default LinearProgressCustom;
