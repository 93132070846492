import React, {useState} from "react";
import {useBookingContext} from "../context";
import FeedbackForm from "./FeedbackForm";
import AddBooking from "./add-booking";
import EditBooking from "./edit-booking";

const Form = () => {
    const {globalState, setGlobalState} = useBookingContext();
    const [isTeacherFeedback, setIsTeacherFeedBack] = useState(false);
    const [teacherFeedback, setTeacherFeedback] = useState(undefined);

    const [type, setType] = useState('Add');


    React.useEffect(() => {

        if (globalState?.booking && globalState?.booking?.bookingId) {
            setType('edit')
        } else if (globalState?.booking && !globalState?.booking?.bookingId) {
            setType('add')
        }


    }, [globalState])


    const setShowTeacherFeedBack = ({show, teacherFeedback}) => {
        setIsTeacherFeedBack(show);
        setTeacherFeedback(teacherFeedback);
    }
    const hide = () => {
        setGlobalState({...globalState, booking: undefined, addEditBooking: false})
    }


    if (isTeacherFeedback) {
        return <FeedbackForm teacherFeedback={teacherFeedback} hide={hide}
                             setShowTeacherFeedBack={setShowTeacherFeedBack}/>
    }

    if (type === 'edit') {
        return <EditBooking setShowTeacherFeedBack={setShowTeacherFeedBack}/>

    } else if (type === 'add') {
        return <AddBooking setShowTeacherFeedBack={setShowTeacherFeedBack}/>
    }

    return null
}

export default Form;
