import React, { useEffect } from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    IconButton,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useAppMutation } from "../../../services";
import adminServices from "../../../services/admin-services";
import { usePromotionsContext } from "./context";
import { useTranslation } from "react-i18next";
import DDImage from "../../../component/DDImage";
import ContactDetail from "../Plans/ContactDetail";

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const PromotionItem = ({ promotion, view }) => {
    const { showDialog, hideDialog } = useJumboDialog();
    const { globalState, setGlobalState } = usePromotionsContext();
    const { mutate: deleteDiscount, isSuccess } = useAppMutation(adminServices.deleteDiscount);
    const { t } = useTranslation();

    useEffect(() => {
        if (isSuccess) {
            setGlobalState({ ...globalState, refreshPromotionList: true });
            hideDialog(true);
        }
    }, [isSuccess]);

    const showContactDetail = React.useCallback(() => {
        showDialog({
            content: <ContactDetail promotion={promotion} onClose={hideDialog}/>
        })
    }, [showDialog, promotion]);

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                setGlobalState({ ...globalState, discountId: promotion.discountId, openAddEditPromotions: true });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: t("discount.deleteTitle"),
                    content: t("discount.deleteContent"),
                    onYes: () => deleteDiscount(promotion.discountId),
                    onNo: hideDialog
                });
                break;
            default:
                break;
        }
    };

    if (view === "grid") {
        return (
            <JumboGridItem xs={12} lg={6}>
                <Card variant="outlined" elevation={0}>
                    <CardHeader
                        avatar={
                            promotion.images?.length > 0 ? (
                                    <DDImage path={promotion.images[0]} alt={promotion.name}/>) :
                                (<Avatar alt={promotion.name}/>)
                        }
                        action={
                            <React.Fragment>
                                <IconButton>
                                    <MoreHorizIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                        title={
                            <Typography variant={"h6"} color={"text.primary"} mb={.25}>
                                {promotion.name}
                            </Typography>
                        }
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Divider sx={{ mb: 2 }} />
                        <List disablePadding>
                            <ListItem sx={{ p: theme => theme.spacing(.75, 1.5) }}>
                                <ListItemText primary={`Discount Amount: $${promotion.discountAmount}`} />
                            </ListItem>
                            <ListItem sx={{ px: 1.5 }}>
                                <ListItemText primary={`Uses: ${promotion.noOfUses}`} />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </JumboGridItem>
        );
    }

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={promotion}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            { icon: <EditIcon />, title: t("Edit"), action: "edit" },
                            { icon: <DeleteIcon />, title: t("Delete"), action: "delete" }
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >
                <ListItemAvatar onClick={showContactDetail}>
                    {promotion.images?.length > 0 ? (
                            <DDImage path={promotion.images[0]} alt={promotion.name}/>) :
                        (<Avatar alt={promotion.name}/>)}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 0 }}>
                                <Item sx={{ flexBasis: { xs: '100%', sm: '50%', md: '25%' } }}>
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0} noWrap>
                                        {promotion.name}
                                    </Typography>
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};

export default PromotionItem;
