import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";
import useJumboLayoutSidebar from "../../../@jumbo/hooks/useJumboLayoutSidebar";

const SoloPage = ({children}) => {
    const {setJumboLayoutOptions} = useJumboLayout();
    const {setSidebarOptions} = useJumboLayoutSidebar();

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig);
        setSidebarOptions({ hideSideMenu : true})
    }, []);


    return (
        <JumboLayout>
            {children}
        </JumboLayout>
    );
};

export default SoloPage;
