import React, {useEffect} from 'react';
import {Card, CardContent, Step, StepLabel, Stepper, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import {useAppMutation} from "../../../services";
import authServices from "../../../services/auth-services";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import teacherServices from "../../../services/teacher-services";




const Steps = ({disableSmLogin}) => {

    const {mutate, data: stepsData, isLoading} = useAppMutation(teacherServices.loadProfileForSteps);
    const {t} = useTranslation();
    // const steps = [t("parent.title.contact"), t("parent.title.address"), t("parent.title.finish")];
    const [activeStep, setActiveStep] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);
    const dispatch = useDispatch();
    const steps = [t('teacherForm.form1'),t('teacherForm.form2'),t('teacherForm.form3'),t('teacherForm.form4')];

    useEffect(() => {
        if (stepsData) {

            if (!loaded) {
                setLoaded(true);
                if (!stepsData.completedStep1) {
                    setActiveStep(0)
                } else if (!stepsData.completedStep2) {
                    setActiveStep(1)
                } else if (!stepsData.completedStep3) {
                    setActiveStep(2)
                } else if (!stepsData.completedStep4) {
                    setActiveStep(3)
                } else {
                    setActiveStep(4)
                }
            }

            if (stepsData.completedStep1 && stepsData.completedStep2 && stepsData.completedStep3 && stepsData.completedStep4) {
                authServices.updateAuthUser({
                    completedStep1: stepsData.completedStep1,
                    completedStep2: stepsData.completedStep2,
                    completedStep3: stepsData.completedStep3,
                    completedStep4: stepsData.completedStep4
                })
                window.location = '/dashboard/calendar';
            }


        } else {
            mutate();
        }
    }, [stepsData])


    const handleNext = (step) => {

        // alert(step);

        if (step === 5) {
            mutate()
            setActiveStep(step - 1);
        } else {
            mutate();
            setActiveStep(step - 1);
        }
    };


    const handleReset = () => {
        setActiveStep(0);
    };

    return (<Card
        sx={{
            display: 'flex', minWidth: 0, flexDirection: {xs: 'column', md: 'row'}
        }}
    >
        <CardContent sx={{flex: 1, p: 4}}>

            <Div sx={{width: '100%'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (<Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>);
                    })}
                </Stepper>
                {activeStep === steps.length ? (<Div>
                    <Typography sx={{mt: 2, mb: 1, justifyContent: 'center'}}>
                        All steps completed - you&apos;re finished
                    </Typography>

                </Div>) : (<Div>
                    {activeStep + 1 === 1 && <Step1 handleNext={handleNext} stepsData={stepsData}/>}
                    {activeStep + 1 === 2 && <Step2 handleNext={handleNext} stepsData={stepsData}/>}
                    {activeStep + 1 === 3 && <Step3 handleNext={handleNext} stepsData={stepsData}/>}
                    {activeStep + 1 === 4 && <Step4 handleNext={handleNext} stepsData={stepsData}/>}

                </Div>)}
            </Div>
        </CardContent>
    </Card>);
};

export default Steps;
