import React, {useEffect} from 'react';
import {Alert, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {COUNTRIES, TEACHER_ACCENT, TEACHER_LANGUAGES} from "../../../../utils/constants/appActions";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import Select from "@mui/material/Select";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];


const Step2 = ({hide, setStepsData, stepsData, handleNext}) => {


    const [values, setValues] = React.useState({
        country: {
            code: 'FR',
            label: 'France',
            phone: '33'
        },
        residenceCountry: 'FR',
        languagesSpoken: [],
        dateOfBirth: '',
        accent: '',
        placeOfBirth: '',
        address: '',
        postalCode: '',
        city: ''
    });

    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    useEffect(() => {
        console.log('Step 1', values, stepsData);
        if (stepsData) {
            let country = COUNTRIES.find(item => item.code === stepsData.residenceCountry)
            if (!country) {
                country = {
                    code: 'FR',
                    label: 'France',
                    phone: '33'
                };
            }
            setValues({...values, ...stepsData});
        }

    }, [stepsData])


    const handleChange = (prop) => (event) => {
        setValues(prevValues => ({
            ...prevValues,
            [prop]: event.target.value
        }));
    };


    const getMaxDate = () => {
        return new Date().toISOString().split('T')[0];
    }
    const submitStep2 = async () => {
        setErrMsg('')
        const {
            residenceCountry, dateOfBirth, accent, languagesSpoken, placeOfBirth, address, postalCode, city
        } = values;

        setStepsData({
            ...stepsData,
            residenceCountry,
            dateOfBirth,
            accent,
            languagesSpoken,
            placeOfBirth,
            address,
            postalCode,
            city
        })

        handleNext(3);
    };


    const backToStep1 = async () => {
        handleNext(1);
    };

    return (<Box
        component="form"
        sx={{
            '& .MuiTextField-root': {m: 1, mt: 5},
            width: '100%'
        }}
        noValidate
        autoComplete="off"
    >
        <Div>

            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '48%'},
                mr: 1
            }}>

                <TextField
                    id="date"
                    label={(t('parent-children.title.dateBirth'))}
                    type="date"
                    value={values.dateOfBirth}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: getMaxDate()
                    }}
                    onChange={handleChange('dateOfBirth')}
                />
            </FormControl>
            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '48%'},
                mr: 1
            }}>

                <TextField
                    id="placeOfBirth"
                    required
                    label={t('teacherForm.birthPlace')}
                    value={values.placeOfBirth}
                    onChange={handleChange('placeOfBirth')}
                />
            </FormControl>

            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '100%'},
                mr: 1, mt: -3

            }}>
                <Autocomplete
                    id="country-select-demo-countries"
                    sx={{maxWidth: '100%'}}
                    options={COUNTRIES}
                    autoHighlight
                    value={values.country}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.label} ({option.code})
                        </Box>
                    )}
                    disableClearable={true}
                    onChange={(event, value) => {
                        let {location} = values;
                        if (!location) {
                            location = {};
                        }
                        // location.lCountry = value;
                        setValues({...values, country: {...value}, residenceCountry: value.code});
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('teacherForm.residence')}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                            required
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <React.Fragment>
                                        {values.country?.code && (
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${values.country?.code?.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${values.country?.code?.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                        )}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>

            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '100%'},
                ml: 1, mt: 2
            }}>
                <InputLabel
                    id="demo-simple-select-label-languagesSpoken">{t('teacherForm.language')}</InputLabel>
                <Select
                    multiple
                    labelId="demo-simple-select-label-languagesSpoken"
                    id="demo-simple-select-languagesSpoken"
                    value={values.languagesSpoken}
                    onChange={(handleChange('languagesSpoken'))}
                    label={t('teacherForm.language')}
                >

                    {TEACHER_LANGUAGES.map(lang => (<MenuItem value={lang.code}>{lang.label}</MenuItem>))}
                </Select>
            </FormControl>

            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '100%'},
                ml: 1, mt: 3
            }}>
                <InputLabel
                    id="demo-simple-select-label">{t('teacherForm.accent')}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.accent}
                    onChange={(handleChange('accent'))}
                    label={t('teacherForm.title')}
                >

                    {TEACHER_ACCENT.map(lang => (<MenuItem value={lang.code}>{lang.label}</MenuItem>))}

                </Select>
            </FormControl>
            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '100%',}, mt: -2,
            }}>
                <TextField
                    label={t('teacher-profile.title.address1')}
                    value={values.address}
                    onChange={handleChange('address')}

                />
            </FormControl>
            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '50%'}, mt: -3,
            }}>
                <TextField
                    label={t('common.postalCode')}
                    value={values.postalCode}
                    onChange={handleChange('postalCode')}

                />
            </FormControl>
            <FormControl sx={{
                width: {xs: '100%', sm: '100%', xl: '50%'}, mt: -3,
            }}>
                <TextField
                    label={t('common.city')}
                    value={values.city}
                    onChange={handleChange('city')}

                />
            </FormControl>
        </Div>
        <Stack sx={{width: '100%'}} spacing={2}>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Button
                onClick={() => {
                    hide();
                }}
                sx={{mr: 1}}
            >
                {t("common.closeBtn")}
            </Button>
            <Button
                onClick={backToStep1}
                sx={{mr: 1}}
            >
                {t("common.backBtn")}
            </Button>
            <Div sx={{flex: '1 1 auto'}}/>
            <LoadingButton variant={"variant"} onClick={submitStep2}>
                {t("common.nextBtn")}
            </LoadingButton>
        </Div>
    </Box>);

};

export default Step2;
