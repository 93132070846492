import React from "react";
import {useCalendarContext} from "../context";
import Booking from "./booking";
import FeedbackForm from "./FeedbackForm";


const AForm = () => {

    const {globalState, setGlobalState} = useCalendarContext();
    const [values, setValues] = React.useState({isForm: true, isEvaluation: false, evaluation: {}});


    const showTeacherFeedback = ({teacherFeedback}) => {
        setValues({...values, isForm: false, isTeacherFeedback: true, teacherFeedback});
    }
    const showBookingForm = () => {
        setValues({...values, isForm: true, isTeacherFeedback: false, teacherFeedback: undefined});

    }

    const hide =()=>{
        setGlobalState({...globalState, refreshList : true, booking: undefined, addEditBooking: false})

    }

    React.useEffect(() => {
    }, []);

    if (values.isForm) {
        return <Booking hide={hide} showTeacherFeedback={showTeacherFeedback}/>;
    } else if (values.isTeacherFeedback) {
        return <FeedbackForm hide={hide}  teacherFeedback={values.teacherFeedback} showBookingForm={showBookingForm}/>

    }
    return null;
}


export default AForm;
