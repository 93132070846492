import Div from "@jumbo/shared/Div";
import {
    Alert,
    Avatar,
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    ListItemAvatar,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import DDImage from "../../../../component/DDImage";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import services from "../../../../services/admin-services";
import adminServices from "../../../../services/admin-services";
import {useTranslation} from "react-i18next";
import {useCalendarContext} from "../context";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import {useAppMutation} from "../../../../services";
import {isPastDate, timings} from "../../../../utils/constants/appActions";
import LoadingButton from "@mui/lab/LoadingButton";
import StudentInfoForm from "../StudentInfoForm"

const Appointment = ({showEvaluation, appointment, setAppointment}) => {

    const [type] = React.useState('evaluation');
    const [evaluators, setEvaluators] = useState([]);
    const [pastDate, setPastDate] = useState(false);
    const [isEditable, setEditable] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const {globalState, setGlobalState} = useCalendarContext();
    const [errMsg, setErrMsg] = React.useState('');
    const [stepsData, setStepsData] = useState(undefined);
    const {t} = useTranslation();
    const [students, setStudents] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [availableDates, setAvailableDates] = useState([]);
    const [openChildInfoDialog, setOpenChildInfoDialog] = useState(false);
    const [sTimings, setSTimings] = useState([]);
    const [values, setValues] = React.useState({
        startDate: '',
        startTime: '',
        title: 'Student Appointment',
        description: '',
        teacherId: '',
        studentId: '',
    });

    const {
        mutate: saveAppointment,
        isSuccess: isSASuccess,
        isLoading: isLoadingSave,
        data: saResult
    } = useAppMutation(services.saveAppointment);


    const {
        mutate: cancelAppointment,
        isSuccess: isCASuccess,
        isLoading: isCALoading,
        isError: isCAError,
        data: caResult
    } = useAppMutation(adminServices.cancelAppointment);


    const {
        mutate: deleteAppointment,
        isSuccess: isDASuccess,
        isLoading: isDALoading,
        data: daResult
    } = useAppMutation(services.deleteAppointment);

    const {
        mutate: loadAvailableTimes,
        isSuccess: isLTSuccess,
        isLoading: isLTLoading,
        isError: isLTError,
        data: ltResult
    } = useAppMutation(services.loadAvailableTimes);


    const {
        mutate: loadStudents,
        isSuccess: isLSSuccess, data: lsResult
    } = useAppMutation(services.loadStudents);


    const {
        mutate: loadAvailableDates,
        isSuccess: isLDSuccess,
        isLoading: isLDLoading,
        isError: isLDError,
        data: ldResult
    } = useAppMutation(services.loadAvailableDates);

    const {
        mutate: loadAvailableTeachers,
        isSuccess: isLAASuccess,
        data: laEvaluators
    } = useAppMutation(services.loadAvailableTeachers);

    const {
        mutate: loadAppointment,
        isSuccess: isLASuccess,
        data: laResult,
        isLoading: isLALoading,
        isError: isLAError
    } = useAppMutation(services.loadAppointment);


    React.useEffect(() => {
        if (globalState.appointment) {


            const appointment = {...values, ...globalState.appointment}
            setValues(appointment);

            console.log('appointment', appointment)

            const min = globalState.minDate
            const max = globalState.maxDate

            setMinDate(min);
            setMaxDate(max);
            loadStudents({page: 1, size: 1000000});

            const strDate = new Date();
            strDate.setDate(1);
            const endDate = new Date(strDate)
            strDate.setMonth(strDate.getMonth() - 3);
            endDate.setMonth(endDate.getMonth() + 4);

            loadAvailableDates({startDate: strDate, endDate: endDate, type});

            // setModify(isBefore24Hours(appointment.sstartDate));
            // setPastDate(isPastDate(appointment.startDate));

            console.log('Appointment--------------------------------------', appointment);

            if (globalState.appointment.appointmentId) {
                setEditable(false)
                loadAppointment(globalState.appointment.appointmentId)
                const flag = isPastDate(globalState.appointment.sstartDate)
                setPastDate(flag);

                if (!flag) {
                    loadEvaluators({
                        studentId: appointment.studentId,
                        startDate: appointment.startDate,
                        startTime: appointment.startTime
                    })
                }
            } else {
                setEditable(true)
            }
        }
    }, [globalState])


    React.useEffect(() => {
        if (isCASuccess) {
            setGlobalState({...globalState, appointment: undefined, addEditAppointment: false, refreshList: true})
        }
    }, [isCASuccess])

    React.useEffect(() => {
        if (isLAASuccess) {
            setEvaluators(laEvaluators);
        }
    }, [isLAASuccess])


    React.useEffect(() => {
        if (isLSSuccess) {
            const c = [...lsResult.students];
            console.log(c);
            setStudents(c);
        }
    }, [isLSSuccess])

    React.useEffect(() => {

        if (isLASuccess) {
            const appointment = {...values, ...laResult.appointment, teacherId: laResult.appointment.teacher.teacherId};
            appointment.sstartDate = new Date(appointment.startDate);
            const startDate = moment(appointment.sstartDate).format('YYYY-MM-DD'); // laResult.startDate.slice(0, 10);
            const startTime = moment(appointment.sstartDate).format('HH:mm');
            appointment.startDate = startDate;
            appointment.startTime = startTime;
            setValues(appointment);

            if (!isPastDate(appointment.sstartDate)) {

                loadAvailableTimes({
                    date: appointment.sstartDate,
                    studentId: appointment.studentId,
                    type,
                    appointmentId: values.appointmentId,
                })
            }
        }


    }, [isLASuccess]);


    React.useEffect(() => {
        if (isLDSuccess) {
            setAvailableDates(ldResult?.availableDates)
            setValues({...values, sstartDate: new Date(values.startDate)});
        }

    }, [isLDError, isLDSuccess, ldResult])


    React.useEffect(() => {
        if (isLTSuccess && ltResult) {
            const ls = [];
            for (const t of ltResult?.timings) {

                let date = new Date(`${values.startDate}T${t}`);
                const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                date = new Date(date.getTime() - userTimezoneOffset);
                //if (date > new Date()) {
                const v = timings.find(item => item.value === moment(date).format('HH:mm'));

                if (v) {
                    ls.push(v);
                }
                //}
            }
            console.log('timings...........', ls)
            setSTimings(ls);

        } else {
            setSTimings([]);
        }


    }, [isLTError, isLTSuccess, ltResult])


    React.useEffect(() => {
        console.log(saResult, isSASuccess)

        if (isSASuccess && saResult) {
            setGlobalState({...globalState, appointment: undefined, refreshList: true, addEditAppointment: false})
        }

    }, [saResult, isSASuccess])

    React.useEffect(() => {

        if (daResult && isDASuccess) {
            setGlobalState({...globalState, appointment: undefined, refreshList: true, addEditAppointment: false})

        }

    }, [daResult, isDASuccess])


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
        if (prop === 'startTime') {
            loadEvaluators({startTime: event.target.value});
        } else if (prop === 'startDate') {
            loadEvaluators({startDate: event.target.value});
        } else if (prop === 'studentId') {

            if (!sTimings || sTimings.length === 0) {
                if (values.sstartDate) {
                    loadAvailableTimes({
                        date: values.sstartDate,
                        studentId: event.target.value,
                        appointmentId: values.appointmentId,
                        type
                    })
                }
            }
        }
    };


    const handleSaveAppointment = () => {

        if (values.studentId && values.startTime && values.startDate && values.studentId && values.teacherId) {

            const selectedStartDateTime = new Date(`${values.startDate}T${values.startTime}`);
            const selectedEndDateTime = new Date(`${values.startDate}T${values.startTime}`);
            selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 30);


            const newEvent = {
                title: 'Student Appointment',
                startDate: selectedStartDateTime,
                endDate: selectedEndDateTime,
                studentId: values.studentId,
                teacherId: values.teacherId,
                description: values.description,
                appointmentId: values.appointmentId,
            };

            saveAppointment(newEvent);

            // Update the events array in the state
            // setEvents((prevEvents) => [...prevEvents, newEvent]);

            // resetValues();

            // Perform any additional logic, such as saving to a database

            // Close the dialog
            // setOpenDialog(false);

        } else {
            setErrMsg(t("common.inputError"));
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
        }

    };

    const loadEvaluators = ({studentId, startDate, startTime}) => {

        const sDate = startDate ? '' + startDate : '' + values.startDate;
        const sTime = startTime ? '' + startTime : '' + values.startTime;
        const c = studentId ? studentId : values.studentId;
        const selectedStartDateTime = new Date(`${sDate}T${sTime}`);
        const selectedEndDateTime = new Date(`${sDate}T${sTime}`);

        selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 29);

        const obj = {type, studentId: c, startDate: selectedStartDateTime, endDate: selectedEndDateTime}
        loadAvailableTeachers(obj);

    }


    const handleCancelAppointment = () => {
        const { reason, appointmentId} = values;

        setErrMsg('');

        if (!reason || !reason.trim()) {
            setErrMsg('Please provide a reason for cancellation.');
        } else {
            // Proceed with cancellation action
            // Your cancellation logic here
            const obj = {
                appointmentId,
                reason
            };
            cancelAppointment(obj);
        }
    }

    const handleChildInfoClick = () => {
        setOpenChildInfoDialog(true);
    };

    const handleCloseChildInfoDialog = () => {
        setOpenChildInfoDialog(false);
    };


    if (openChildInfoDialog) {
        return <StudentInfoForm
            hide={handleCloseChildInfoDialog}
            studentId={values.studentId}
        />
    }

    return <Div><FormControl noValidate autoComplete="off">
        <Div
            sx={{
                '& .MuiTextField-root': {
                    mb: 3,
                    mt: 0.5
                },
            }}
        >

            <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                <InputLabel id="demo-multiple-checkbox-label">{t("parent.title.child")}</InputLabel>
                <Select
                    disabled={!isEditable || pastDate}
                    value={values.studentId}
                    label={t('sidebar.menuItem.student')}
                    onChange={handleChange('studentId')}
                    renderValue={(selected) => {
                        // Find the selected child based on the studentId
                        const selectedStudent = students?.find((student) => student.studentId === selected);

                        console.log('selected----------------------', selected)
                        // Display only the selected child's name
                        return (
                            <Div sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar>
                                    {selectedStudent && selectedStudent.images?.length > 0 ? (
                                        <DDImage path={selectedStudent.images[0]}
                                                 alt={selectedStudent.firstName + ' ' + selectedStudent.lastName}/>
                                    ) : (
                                        <Avatar
                                            alt={selectedStudent?.firstName + ' ' + selectedStudent?.lastName}/>
                                    )}
                                </ListItemAvatar>
                                {selectedStudent?.firstName} {selectedStudent?.lastName}
                            </Div>
                        );
                    }}
                >
                    {students && students.map((item) => (
                        <MenuItem key={item.studentId} value={item.studentId}>
                            <ListItemAvatar>
                                {item.images?.length > 0 ? (
                                    <DDImage path={item.images[0]}
                                             alt={item.firstName + ' ' + item.lastName}/>
                                ) : (
                                    <Avatar alt={item.firstName + ' ' + item.lastName}/>
                                )}
                            </ListItemAvatar>
                            {item.firstName} {item.lastName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            disabled={!isEditable || pastDate || !values?.studentId}
                            open={open}
                            onOpen={(event) => {
                                setOpen(true)
                            }}
                            onClose={(event) => {
                                setOpen(false)
                            }}
                            shouldDisableDate={(day) => {


                                if (!isEditable) {
                                    return false
                                }

                                if (availableDates &&
                                    availableDates.indexOf(moment(day).format('YYYY-MM-DD')) > -1) {
                                    return false
                                } else {
                                    return true
                                }

                            }}
                            minDate={false ? minDate : null}
                            maxDate={false ? maxDate : null}
                            loading={false}
                            label={t('common.date')}
                            format="dd/MM/yyyy"
                            value={values.sstartDate}
                            onChange={(event, newValue) => {
                                const startDate = moment(event).format('YYYY-MM-DD');
                                const sstartDate = event;
                                setValues({...values, startDate, sstartDate})

                                // setPastDate(isPastDate(sstartDate));

                                loadAvailableTimes({
                                    date: startDate,
                                    studentId: values.studentId,
                                    type
                                })

                            }}
                            renderInput={(params) => <TextField {...params}

                            />}
                        />

                    </Stack>
                </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth sx={{mb: 2, mt: 1}}>

                {pastDate && <TextField label={t("parent.title.time")} disabled={pastDate} value={values.startTime}/>}
                {!pastDate && <><InputLabel id="demo-simple-select-label">{t("parent.title.time")}</InputLabel>
                    <Select

                        disabled={!isEditable || pastDate || !values?.studentId || !values?.startDate}

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.startTime}
                        label={t('common.time')}
                        onChange={
                            handleChange('startTime')

                        }

                    >
                        {sTimings.map((item, index) => (
                            <MenuItem key={'key-timing-' + index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select></>}
            </FormControl>


            <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                {!isEditable && values?.teacher?.teacherId && <><InputLabel
                    id="demo-multiple-checkbox-label">{t("parent.title.teacher")}</InputLabel>
                    <Select
                        disabled={true}
                        value={values.teacher.teacherId}
                        label={t("parent.title.teacher")}
                        renderValue={(selected) => {
                            // Find the selected child based on the studentIdal

                            const selectedChild = values.teacher

                            // Display only the selected child's name
                            return (
                                <Div sx={{display: 'flex', alignItems: 'center'}}>
                                    <ListItemAvatar>
                                        {selectedChild && selectedChild.images?.length > 0 ? (
                                            <DDImage path={selectedChild.images[0]}
                                                     alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                        ) : (
                                            <Avatar
                                                alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                        )}
                                    </ListItemAvatar>
                                    {selectedChild?.firstName} {selectedChild?.lastName}
                                </Div>
                            );
                        }}
                    >

                        <MenuItem key={'key-teacher-' + values.teacher.teacherId} value={values.teacher.teacherId}>
                            <ListItemAvatar>
                                {values.teacher.images?.length > 0 ? (
                                    <DDImage path={values.teacher.images[0]}
                                             alt={values.teacher.firstName + ' ' + values.teacher.lastName}/>
                                ) : (
                                    <Avatar alt={values.teacher.firstName + ' ' + values.teacher.lastName}/>
                                )}
                            </ListItemAvatar>
                            {values.teacher.firstName} {values.teacher.lastName}
                        </MenuItem>

                    </Select></>}
                {isEditable && <><InputLabel id="demo-multiple-checkbox-label">{t("parent.title.teacher")}</InputLabel>
                    <Select
                        disabled={!isEditable || !evaluators || evaluators.length === 0}
                        value={values.teacherId}
                        label={t("parent.title.teacher")}
                        onChange={handleChange('teacherId')}
                        renderValue={(selected) => {
                            // Find the selected child based on the studentIdal

                            const selectedChild = evaluators?.find((child) => child.teacherId === selected);

                            // Display only the selected child's name
                            return (
                                <Div sx={{display: 'flex', alignItems: 'center'}}>
                                    <ListItemAvatar>
                                        {selectedChild && selectedChild.images?.length > 0 ? (
                                            <DDImage path={selectedChild.images[0]}
                                                     alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                        ) : (
                                            <Avatar
                                                alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                        )}
                                    </ListItemAvatar>
                                    {selectedChild?.firstName} {selectedChild?.lastName}
                                </Div>
                            );
                        }}
                    >
                        {evaluators && evaluators.map((item) => (
                            <MenuItem key={'key-teacher-' + item.teacherId} value={item.teacherId}>
                                <ListItemAvatar>
                                    {item.images?.length > 0 ? (
                                        <DDImage path={item.images[0]}
                                                 alt={item.firstName + ' ' + item.lastName}/>
                                    ) : (
                                        <Avatar alt={item.firstName + ' ' + item.lastName}/>
                                    )}
                                </ListItemAvatar>
                                {item.firstName} {item.lastName}
                            </MenuItem>
                        ))}
                    </Select></>}
            </FormControl>


            <FormControl fullWidth>
                <TextField
                    disabled={!isEditable || pastDate}
                    id="outlined-multiline-static"
                    label={t("common.desc")}
                    multiline
                    rows={2}
                    value={values.description}
                    name="description"
                    onChange={handleChange('description')}


                />
            </FormControl>

            {!showDeleteConfirmation && !values.cancelled && <Typography variant="body2">
                {t("parent.title.txt")}
            </Typography>}


            <Stack

                sx={{
                    mb: 2,
                    width: '90%',
                    mx: 'auto',
                    display: 'block',
                    position: 'relative', // Add position relative to enable absolute positioning of loader
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            {!showCancelConfirmation && !showDeleteConfirmation && <ButtonGroup
                sx={{
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'center',
                }}
                disableElevation
                variant="contained"
                color={"warning"}
            >
                <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                        setGlobalState({...globalState, appointment: undefined, addEditAppointment: false})
                    }}
                    sx={{
                        width: "20%",
                        height: "10%",
                        mr: .5
                    }}
                >
                    {t("common.closeBtn")}
                </Button>

                <Button
                    size="medium"
                    variant="contained"
                    onClick={handleChildInfoClick}
                    sx={{mr: 1, height: "10%", width: '25%',}}>
                    {t('common.studentBtn')}
                </Button>

                {isEditable && <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={handleSaveAppointment}
                    sx={{
                        width: "20%",
                        height: "10%",
                        mr: .5
                    }}
                    loading={isLoadingSave}

                >
                    {values.cancelled ? (<>Undo</>) : (
                        values.bookingId ? t("common.updateBtn") : t("common.saveBtn")
                    )}
                </Button>}

                {!values.evaluation && values.appointmentId &&
                    <Button
                        type="submit"
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            setShowDeleteConfirmation(true)
                        }}
                        sx={{
                            width: "20%",
                            height: "10%",
                            mr: .5
                        }}
                    >
                        {t("common.deleteBtn")}
                    </Button>}


                {!pastDate && !values.evaluation && values.appointmentId && !values.cancelled && <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                        // setGlobalState({...globalState, appointment: undefined, addEditAppointment: false})
                        setShowCancelConfirmation(true);
                    }}
                    sx={{
                        width: "20%",
                        height: "10%",
                        mr: .5

                    }}
                >
                    {t("common.cancelBtn")}
                </Button>}

                {!values.cancelled && values.appointmentId && <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                        showEvaluation({evaluation: values.evaluation});
                    }}
                    sx={{
                        width: "20%",
                        height: "10%",
                    }}
                >
                    {t("common.evaluationBtn")}
                </Button>}
            </ButtonGroup>}


            {showCancelConfirmation && (
                <>
                    <TextField
                        id="reason"
                        label="Reason for cancellation"
                        variant="outlined"
                        size="small"
                        margin="dense"
                        multiline
                        rows={2}
                        onChange={handleChange('reason')}
                        sx={{width: '100%'}}
                    /> <Alert severity="warning" sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                        <span style={{marginRight: '15px'}}>{t("common.cancelTxt")}</span>
                        <Stack direction="row" spacing={2}>
                            <LoadingButton loading={isCALoading}
                                           onClick={handleCancelAppointment}>{t("common.yesBtn")}</LoadingButton>
                            <Button onClick={() => {
                                setShowCancelConfirmation(false);
                            }}>{t("common.noBtn")}</Button>
                        </Stack>
                    </div>
                </Alert></>
            )}

            {showDeleteConfirmation && (
                <Alert severity="warning" sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                        <span style={{marginRight: '15px'}}>{t("common.deleteTxt")}</span>
                        <Stack direction="row" spacing={2}>
                            <LoadingButton loading={isDALoading}
                                           onClick={() => {
                                               deleteAppointment(values.appointmentId)
                                           }}>{t("common.yesBtn")}</LoadingButton>
                            <Button onClick={() => {
                                setShowDeleteConfirmation(false);
                            }}>{t("common.noBtn")}</Button>
                        </Stack>
                    </div>
                </Alert>
            )}
        </Div>
    </FormControl></Div>;
}


export default Appointment;
