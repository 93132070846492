import React from 'react';
import {Checkbox, FormControl, FormControlLabel, ListItem, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import {useCalendarContext} from "./context";
import styled from "@emotion/styled";
import List from "@mui/material/List";
import JumboSearch from "@jumbo/components/JumboSearch";


const StyledCircle = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
}));


const StyledSquare = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '5px',
}));


const Header = ({title, subheader}) => {
    const {t} = useTranslation();
    const {globalState, setGlobalState} = useCalendarContext();

    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 4
            }}
        >
            <Typography variant={"h2"}>{title}</Typography>
            <Typography variant={"body1"} mb={2} color={"text.secondary"}>{subheader}</Typography>

            <div style={{alignItems: 'center'}}>


                <List
                    disablePadding
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: theme => theme.spacing(0, -2),
                        mb: 2,
                        mt: 2
                    }}
                >

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#8C52FF",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.available')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#FF8933",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.evaluated')}
                            </Typography>
                        </Div>
                    </ListItem>
                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#FFB733",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.toBeEvaluated')}
                            </Typography>
                        </Div>
                    </ListItem>
                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#59B2FF",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t('common.readyBooking')}
                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#1D6ADE",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><Typography variant="body1" color="white">
                            {t('common.bookingCompleted')}
                        </Typography></Div>

                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#D22B2B",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant="body1" color="white">
                                {t("common.cancelBtn")}                            </Typography>
                        </Div>
                    </ListItem>

                    <ListItem
                        sx={{
                            width: {xs: '100%', sm: '50%', xl: '20%'}, mt: 1
                        }}
                    >
                        <Div sx={{
                            backgroundColor: "#dedede",
                            minWidth: '90%',
                            minHeight: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}> <StyledCircle color="#D22B2B"/>

                            <Typography sx={{ml: 0.5}} variant="body1" color="white">
                                {t('common.notAttended')}
                            </Typography>

                        </Div>
                    </ListItem>


                </List>


            </div>
            <Div sx={{display: 'flex'}}> <JumboSearch
                onChange={(val) => {
                    console.log(val);
                    setGlobalState({...globalState, search: val, refreshList: true})
                }}
                placeholder={'Search by name'}
                sx={{
                    maxWidth: 'none',
                    height: '100%',
                    display: 'flex',

                    '& .MuiInputBase-root': {
                        flex: 1,
                        borderRadius: 0,
                        background: theme => 'white',
                    },
                    '& .MuiInputBase-input': {
                        pr: 6,
                    }
                }}
            /></Div>
            <Div sx={{display: 'flex'}}>
                <FormControl sx={{mr: 1}} component="fieldset" variant="standard">
                    <FormControlLabel
                        label={t("calendar.appointments")}
                        control={<Checkbox checked={globalState.showAppointments} onChange={(event) => {
                            setGlobalState({...globalState, showAppointments: event.target.checked, refreshList: true})
                        }}/>}
                    />
                </FormControl>

                <FormControl sx={{ml: 1}} component="fieldset" variant="standard"><FormControlLabel
                    label={t("calendar.bookings")}
                    control={<Checkbox checked={globalState.showBookings} onChange={(event) => {
                        setGlobalState({...globalState, showBookings: event.target.checked, refreshList: true})
                    }}/>}
                /></FormControl>

                <FormControl sx={{ml: 1}} component="fieldset" variant="standard"><FormControlLabel
                    label={t("calendar.avail")}
                    control={<Checkbox checked={globalState.showAvailabilities} onChange={(event) => {
                        setGlobalState({...globalState, showAvailabilities: event.target.checked, refreshList: true})
                    }}/>}
                /></FormControl>


            </Div>
        </Div>
    );
};

export default Header;
