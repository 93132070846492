import Div from "@jumbo/shared/Div";
import React from "react";
import {Button, Divider, FormControl} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";


const Evaluation = ({setShowEvaluation, evaluation}) => {


    const [values, setValues] = React.useState({});
    const {t} = useTranslation();

    React.useEffect(() => {
        if (evaluation) {
            setValues({evaluation: evaluation})
        }
    }, [evaluation])


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>

        <Div sx={{mt: 3}}>

            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >


                    <Div>
                        <Typography variant="h5" gutterBottom component="div"
                                    sx={{pr: 10, pl: 10, textAlign: 'center'}}
                        >
                            {t('feedbackForm.info')}
                        </Typography>

                        <Divider sx={{backgroundColor: 'lightgrey', height: 1.5, mb: 2, mx: 'auto', width: '75%'}}/>


                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center', mt: 2}}>
                            Overall : {values.evaluation?.overall
                            ? values.evaluation?.overall
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Personality
                            Perceived: {values.evaluation?.personality && values.evaluation?.personality.length > 0
                            ? values.evaluation?.personality
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Actual
                            Level: {values.evaluation?.actualLevel2
                            ? values.evaluation?.actualLevel2?.label
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Objective
                            Level: {values.evaluation?.objectiveLevel2
                            ? values.evaluation?.objectiveLevel2?.label
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            No of Points: {values.evaluation?.noOfPoints ?  values.evaluation?.noOfPoints
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Learning
                            Style: {values.evaluation?.learningStyles && values.evaluation?.learningStyles.length > 0
                            ? values.evaluation?.learningStyles.join(', ')
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Needs
                            Linguistic: {values.evaluation?.needsLinguistic && values.evaluation?.needsLinguistic.length > 0
                            ? values.evaluation?.needsLinguistic
                            : '---'}
                        </Typography>

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Books
                            Recommended: {values.evaluation?.booksRecommended && values.evaluation?.booksRecommended.length > 0
                            ? values.evaluation?.booksRecommended
                            : '---'}
                        </Typography>

                        {/*<Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Teacher
                            Recommended: {values.evaluation?.teachersRecommended && values.evaluation?.teachersRecommended.length > 0
                            ? values.evaluation.teachersRecommended
                            : 'No teachers recommended'}
                        </Typography>*/}

                        <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                            Program
                            Frequency: {values.evaluation?.programFrequencyRecommended && values.evaluation?.programFrequencyRecommended.length > 0
                            ? values.evaluation?.programFrequencyRecommended
                            : '---'}
                        </Typography></Div>


                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Div sx={{flex: '1 1 auto'}}/>

                        <Button
                            onClick={() => {
                                setShowEvaluation(false)
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>

                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;

}


export default Evaluation;
