import appAxios from "./config";

const parentServices = {};

parentServices.loadProfileForSteps = async (obj) => {
    const {data} = await appAxios.get('v1/parent/profile/steps');
    return data;
};


parentServices.signUp = async (obj) => {
    const {data} = await appAxios.post('v1/parent/signup', obj);
    return data;
};

parentServices.forgotPassword = async (obj) => {
    const {data} = await appAxios.post('v1/parent/forgot-password', obj);
    return data;
};


parentServices.resetForgotPassword = async (obj) => {
    const {data} = await appAxios.post('v1/parent/reset-forgot-password', obj);
    return data;
};

parentServices.resetPassword = async (obj) => {
    const {data} = await appAxios.post('v1/parent/reset-password', obj);
    return data;
};


parentServices.saveChild = async (obj) => {
    const {data} = await appAxios.post('v1/parent/child', obj);
    return data;
};


parentServices.loadChildren = async () => {
    const {data} = await appAxios.post('v1/parent/children', {});
    return data;
};


parentServices.loadEvaluatedChildren = async () => {
    let {data} = await appAxios.post('v1/parent/children', {});
    data = data.filter(item => item.evaluated);
    return data;
};


parentServices.loadAvailableTeachers = async (obj) => {
    const {data} = await appAxios.post('v1/parent/available-teachers', obj);
    return data;
};



parentServices.loadAvailableTeachersBySearch = async (obj) => {
    const {data} = await appAxios.post('v1/parent/load-available-teachers-by-search', obj);
    return data;
};


parentServices.loadAvailableDates = async (obj) => {
    const {data} = await appAxios.post('v1/parent/load-available-dates', obj);
    return data;
};


parentServices.loadAvailableDatesByTeacher = async (obj) => {
    const {data} = await appAxios.post('v1/parent/load-available-dates-by-teacher', obj);
    return data;
};


parentServices.cancelBooking = async (obj) => {
    const {data} = await appAxios.post('v1/parent/booking/cancel', obj);
    return data;
};


parentServices.loadAvailableTimes = async (obj) => {
    const {data} = await appAxios.post('v1/parent/load-available-times', obj);
    return data;
};



parentServices.loadAvailableTimesByTeacher = async (obj) => {
    const {data} = await appAxios.post('v1/parent/load-available-times-by-teacher', obj);
    return data;
};


parentServices.saveAppointment = async (obj) => {
    const {data} = await appAxios.post('v1/parent/appointment', obj);
    return data;
};


parentServices.cancelAppointment = async (obj) => {
    const {data} = await appAxios.post('v1/parent/appointment/cancel', obj);
    return data;
};


parentServices.saveBooking = async (obj) => {
    const {data} = await appAxios.post('v1/parent/booking', obj);
    return data;
};


parentServices.deleteAppointment = async (appointmentId) => {
    const {data} = await appAxios.delete('v1/parent/appointment/' + appointmentId);
    return data;
};


parentServices.loadAppointments = async (obj) => {
    const {data} = await appAxios.post('v1/parent/appointments', obj);
    return data;
};

parentServices.loadBookings = async (obj) => {
    const {data} = await appAxios.post('v1/parent/bookings', obj);
    return data;
};


parentServices.loadActiveSubscriptions = async (obj) => {
    const {data} = await appAxios.post('v1/parent/active-subscriptions', obj);
    return data;
};


parentServices.loadBooking = async (bookingId) => {
    const {data} = await appAxios.get('v1/parent/booking/' + bookingId);
    return data;
};


parentServices.loadAppointment = async (appointmentId) => {
    const {data} = await appAxios.get('v1/parent/appointment/' + appointmentId);
    return data;
};

parentServices.loadChild = async (childrenId) => {
    const {data} = await appAxios.get('v1/parent/child/' + childrenId);
    return data;
};


parentServices.deleteChild = async (childrenId) => {
    const {data} = await appAxios.delete('v1/parent/child/' + childrenId);
    return data;
};


parentServices.saveProfileImage = async (base64) => {
    const {data} = await appAxios.post('v1/parent/profile/image', {base64});
    return data;
};

parentServices.completeStep1 = async (obj) => {
    const {data} = await appAxios.post('v1/parent/completestep1', obj);
    return data;
};


parentServices.completeStep2 = async (obj) => {
    const {data} = await appAxios.post('v1/parent/completestep2', obj);
    return data;
};


parentServices.completeStep3 = async (obj) => {
    const {data} = await appAxios.post('v1/parent/completestep3', obj);
    return data;
};


parentServices.loadProgram = async (programId) => {
    const {data} = await appAxios.get('v1/parent/program/' + programId);
    return data;
};


parentServices.loadPlans = async (obj) => {
    const {data} = await appAxios.post('v1/parent/plans', obj);
    return data;
};



parentServices.isEligibleForDiscount = async (obj) => {
    const {data} = await appAxios.post('v1/parent/eligible-for-discount', obj);
    return data;
};


parentServices.completePayment = async ({paymentIntent, clientSecret}) => {
    const {data} = await appAxios.get('v1/parent/complete/payment/' + paymentIntent + '/' + clientSecret);
    return data;
};


parentServices.loadSubscriptions = async (obj) => {
    const {data} = await appAxios.post('v1/parent/subscriptions', obj);
    return data;
};


parentServices.loadSecret = async (obj) => {
    const {data} = await appAxios.post('v1/parent/secret', obj);
    return data;
};

parentServices.loadEnrollmentInfo = async () => {
    const {data} = await appAxios.get('v1/parent/children/enrollmentinfo');
    return data;
};

parentServices.loadProfile = async () => {
    const {data} = await appAxios.get('v1/parent/profile');
    return data;
};

parentServices.updateProfileAddressDetails = async (obj) => {
    const {data} = await appAxios.post('v1/parent/profile/address-details', obj);
    return data;
};

parentServices.updateProfileContactInfo = async (obj) => {
    const {data} = await appAxios.post('v1/parent/profile/contact-info', obj);
    return data;
};

parentServices.updateProfileNewsLetterSubscription = async (obj) => {
    const {data} = await appAxios.post('v1/parent/profile/news-letter-subscription', obj);
    return data;
};

parentServices.cancelSubscription = async (obj) => {
    const {data} = await  appAxios.post('v1/parent/cancel-subscription',obj);
    return data;
}
parentServices.showDiscountCode = async (obj) => {
    const {data} = await  appAxios.post('v1/parent/show-discount-code',obj);
    return data;
}

parentServices.statistics = async (obj) => {
    const {data} = await appAxios.post('v1/parent/statistics', obj);
    return data;
};
export default parentServices;
