import React, {useState} from 'react';
import {Avatar,} from "@mui/material";
import commonServices from "../../services/common-services";
import {useSelector} from "react-redux";


const DDImage = (props) => {
    const {
        isParent,
        isSuperAdmin,
        isTeacher,
        authUser
    } = useSelector(state => state.auth);

    const [base64, setBase64] = useState('');
    const [fileName, setFileName] = useState('');


    React.useEffect(async () => {


            if (props.path) {
                let fullUrl = '';

                if (isParent) {
                    fullUrl = 'parent' + props.path;
                } else if (isTeacher) {
                    fullUrl = 'teacher' + props.path;
                } else if (isSuperAdmin) {
                    fullUrl = 'admin' + props.path;
                }


                const result = await commonServices.loadFile(fullUrl);
                setBase64(result.base64)
                setFileName(new Date().getTime()+'.'+result.ext)
                //console.log(result)
            }else if(props.base64){
                setBase64(props.base64)

            }


        }, [props]
    )

    if(props.base64){
        return <a   target={'_blank'} href={base64} >Download</a>
    }

    return <a download={fileName} target={'_blank'} href={base64} >Download</a>
}

/* Todo child, view prop define */
export default DDImage;
