import React, {useState} from "react";
import {Avatar, FormControl, InputLabel, ListItemAvatar, MenuItem, TextField} from "@mui/material";
import Select from "@mui/material/Select";
import Div from "@jumbo/shared/Div";
import DDImage from "../../../../component/DDImage";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import {timings} from "../../../../utils/constants/appActions";


const AddBookingByDate = ({
                              availableDates,
                              type,
                              pastDate,
                              values,
                              setValues,
                              minDate,
                              maxDate,
                              setMaxDate
                          }) => {

    const {t} = useTranslation();
    const [sTimings, setSTimings] = useState([]);
    const [open, setOpen] = React.useState(false);

    const {
        mutate: loadAvailableTeachers,
        isESuccess: isESuccess,
        data: evaluators
    } = useAppMutation(parentServices.loadAvailableTeachers);

    const {
        mutate: loadAvailableTimes,
        isSuccess: isLTSuccess,
        isLoading: isLTLoading,
        isError: isLTError,
        data: ltResult
    } = useAppMutation(parentServices.loadAvailableTimes);

    React.useEffect(() => {
        if (isLTSuccess && ltResult) {
            const ls = [];
            for (const t of ltResult?.timings) {
                let date = new Date(`${values.startDate}T${t}`);
                const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                date = new Date(date.getTime() - userTimezoneOffset);
                if (date > new Date()) {
                    const v = timings.find(item => item.value === moment(date).format('HH:mm'));

                    if (v) {
                        ls.push(v);
                    }
                }
            }
            setSTimings(ls);
        } else {
            setSTimings([]);
        }
    }, [isLTError, isLTSuccess])

    React.useEffect(() => {
        if (maxDate) {

            if (values.sstartDate && values.childId) {
                const startDate = moment(values.sstartDate).format('YYYY-MM-DD');
                // setMaxDate(null);
                loadAvailableTimes({
                    date: startDate,
                    childId: values.childId,
                    type
                })
            }

        }
    }, [maxDate])

    const handleChange = (prop) => (event) => {

        if (prop === 'startTime') {
            setValues({...values, [prop]: event.target.value, teacherId : undefined});
            loadEvaluators({startTime: event.target.value});
        } else if (prop === 'startDate') {
            setValues({...values, [prop]: event.target.value});
            loadEvaluators({startDate: event.target.value});
        }else{
            setValues({...values, [prop]: event.target.value});
        }
    };


    const loadEvaluators = ({childId, startDate, startTime}) => {

        const sDate = startDate ? '' + startDate : '' + values.startDate;
        const sTime = startTime ? '' + startTime : '' + values.startTime;
        const c = childId ? childId : values.childId;
        const selectedStartDateTime = new Date(`${sDate}T${sTime}`);
        const selectedEndDateTime = new Date(`${sDate}T${sTime}`);

        selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 29);

        const obj = {type, childId: c, startDate: selectedStartDateTime, endDate: selectedEndDateTime}
        loadAvailableTeachers(obj);

    }

    return <Div>
        <FormControl fullWidth sx={{ width : { xs : '100%' , xl : '100%', sm : '100%', md : '100%'}, mt:2}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <DesktopDatePicker

                        disabled={pastDate || !values?.childId}
                        open={open}
                        onOpen={(event) => {
                            setOpen(true)
                        }}
                        onClose={(event) => {
                            setOpen(false)
                        }}
                        shouldDisableDate={(day) => {

                            if (pastDate) {
                                console.log('pastDate', day);
                                return false
                            } else if (availableDates &&
                                availableDates.indexOf(moment(day).format('YYYY-MM-DD')) > -1) {
                                const currentDate = new Date();
                                if (moment(currentDate).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')) {
                                    console.log('availableDates', day);
                                    return true
                                }
                                return false
                            } else {
                                return true
                            }
                        }}
                        minDate={pastDate ? null : minDate}
                        maxDate={pastDate ? null : maxDate}
                        loading={false}
                        label={t('common.date')}
                        format="dd/MM/yyyy"
                        value={values.sstartDate}
                        onChange={(event, newValue) => {
                            const startDate = moment(event).format('YYYY-MM-DD');
                            const sstartDate = event;
                            setValues({...values, startDate, sstartDate, startTime : undefined, teacherId : undefined })

                            loadAvailableTimes({
                                date: startDate,
                                childId: values.childId,
                                bookingId: values.bookingId,
                                type
                            })
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth  sx={{mt: 2}}>
            <InputLabel id="demo-simple-select-label">{t("parent.title.time")}</InputLabel>
            <Select
                disabled={!values?.childId || !values?.startDate}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.startTime}
                label={t('common.time')}
                onChange={handleChange('startTime')}
            >
                {sTimings.map((item, index) => (
                    <MenuItem key={'key-timing-' + index} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        <FormControl fullWidth  sx={{mt: 2}}>
            <InputLabel id="demo-multiple-checkbox-label">{t("parent.title.teacher")}</InputLabel>
            <Select
                disabled={!evaluators || evaluators.length === 0}
                value={values.teacherId}
                label={t('sidebar.menuItem.teacher')}
                onChange={handleChange('teacherId')}
                renderValue={(selected) => {
                    const selectedChild = evaluators?.find((child) => child.teacherId === selected);
                    return (
                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                            <ListItemAvatar>
                                {selectedChild && selectedChild.images?.length > 0 ? (
                                    <DDImage path={selectedChild.images[0]}
                                             alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                ) : (
                                    <Avatar
                                        alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                )}
                            </ListItemAvatar>
                            {selectedChild?.firstName} {selectedChild?.lastName}
                        </Div>
                    );
                }}
            >
                {evaluators && evaluators.map((item) => (
                    <MenuItem key={'key-teacher-' + item.teacherId} value={item.teacherId}>
                        <ListItemAvatar>
                            {item.images?.length > 0 ? (
                                <DDImage path={item.images[0]}
                                         alt={item.firstName + ' ' + item.lastName}/>
                            ) : (
                                <Avatar alt={item.firstName + ' ' + item.lastName}/>
                            )}
                        </ListItemAvatar>
                        {item.firstName} {item.lastName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Div>
}

export default AddBookingByDate;
