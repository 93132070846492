import {LAYOUT_CONTAINER_STYLES} from "@jumbo/utils/constants/layout";
import {mainTheme} from "../themes/main/default";
import {headerTheme} from "../themes/header/default";
import {sidebarTheme} from "../themes/sidebar/default";
import {footerTheme} from "../themes/footer/default";
import LAYOUT_NAMES from "../layouts/layouts";
import {createJumboTheme} from "@jumbo/utils";
import authServices from "../services/auth-services";
import appAxios from "../services/config";

const user = authServices.getAuthUser();

const getFallBackBath =()=>{


    if(authServices.isSuperAdmin()){
        return '/admin/login';
    }
    else if(authServices.isParent()){
        return '/parent/login';
    }
    else if(authServices.isTeacher()){
        return '/teacher/login';
    }
    else{
        return  '/'
    }
}

const config = {

    defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,

    authSetting: {
        axiosObject: appAxios,
        fallbackPath:  getFallBackBath(),
        getAuthUserService: authServices.getCurrentUser,
        redirectNotAuthenticatedPath: "/dashboard"
    },

    theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),

};

export {config};
