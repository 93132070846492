import React, {useCallback} from 'react';
import ReactDOM from "react-dom";
import useApp from "../../hooks/useApp";

const JumboCustomizerButton = () => {
    const {customizerVisibility, setCustomizerVisibility} = useApp();

    const toggleCustomizerVisibility = useCallback(() => {
        setCustomizerVisibility(!customizerVisibility)
    }, [setCustomizerVisibility, customizerVisibility]);
    return (
        ReactDOM.createPortal(
            <></>,
            document.getElementsByTagName('body')[0]
        )
    );
};

export default JumboCustomizerButton;
