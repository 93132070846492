import React, {useEffect} from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
    FormControl,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    InputAdornment,
    Alert, Autocomplete, Box, Button
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Span from "@jumbo/shared/Span";
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import Stack from "@mui/material/Stack";
import Popover from '@mui/material/Popover';
import SaveIcon from '@mui/icons-material/Save';
import {useProfileContext} from "../context";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import {PHONE_COUNTRIES,countryFormattingRules} from "../../../../utils/constants/appActions";
import parentServices from "../../../../services/parent-services";
import {useTranslation} from "react-i18next";

const Contacts = ({setStepsData, hide, stepsData, handleNext}) => {

    const {globalState, setGlobalState} = useProfileContext();
    const [errPhone, setErrPhone] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [values, setValues] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
        country: {
            countryCode: 'FR',
            label: 'France',
            code: '33',
            suggested: true,
        },
        phone: '',
        profession : ''
    });
    const {
        mutate: updateInfo,
        isSuccess,
        data,
        isError
    } = useAppMutation(parentServices.updateProfileContactInfo);

    React.useEffect(() => {
        if (globalState.profile) {
            console.log('----------------------->',globalState.profile)
            setValues({...globalState.profile})
        }
    }, [globalState])

    useEffect(() => {
        if (stepsData) {
            const val = PHONE_COUNTRIES.find(item => item.code === stepsData.phone?.code)
            stepsData.phone.label = val?.label;
            stepsData.phone.countryCode = val?.countryCode;
            setValues({...values, ...stepsData});
        }
    }, [stepsData])
    React.useEffect(() => {
        if (isSuccess) {
            setGlobalState({...globalState, refreshList: true})
        }
    }, [isError, data, isSuccess])


    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [loading, setLoading] = React.useState(false);

    function handleClick11() {
        if (values.firstName && values.lastName && values.phone?.code && values.phone?.number) {
            const phone = values.phone;
            const fullNumber = `+${phone.code}${phone.number}`;
            phone.fullNumber = fullNumber;
            updateInfo({firstName: values.firstName, lastName: values.lastName, phone});
            setOpenDialog(false);
        }
        else {
            setErrMsg(t('common.inputError'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    }

    const handleClick1 = async () => {
        setErrMsg('');

        const { firstName, lastName, title, phone, } = values;
        const fullPhoneNumber = `+${phone.code}${phone.number}`;
        console.log("fullPhoneNumber", fullPhoneNumber);
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');

        if (values.firstName && values.lastName && values.phone?.code && values.phone?.number) {
            if (!fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
                setErrMsg(t('errMsg.invalidPhone'));
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            }
            const validatedFullPhoneNumber = fullPhoneNumberWithoutSpaces;
            const obj = {
                ...values,
                phone: {
                    fullNumber: fullPhoneNumberWithoutSpaces,
                    code: values.phone.code,
                    number: values.phone.number
                },
            };
            console.log("Step2------------", obj);
            updateInfo(obj)
            setOpenDialog(false);
        } else {
            setErrMsg(t('errMsg.notFill'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    };

    const handleCountryChange = (event, newValue) => {
        console.log(newValue)
        setValues({ ...values, phone: { ...values.phone, code: newValue.code } });
    };


    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>Informations
                    <Span sx={{color: 'text.secondary', fontSize: 13}}>
                        <EditOutlinedIcon onClick={handleEmailIconClick} sx={{fontSize: 13,ml:1}} />
                    </Span>
                </Typography>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            noWrapper>
            <List disablePadding sx={{mb: 2}}>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <EmailOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t('common.email')}</Typography>}
                        secondary={<Link variant="body1" aria-describedby={id} onClick={handleClick}  underline="none">{values.email}</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <InsertLinkOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t('parent-profile.title.name')}</Typography>}
                        secondary={<Link variant="body1" href="#" underline="none">{values.firstName + ' ' + values.lastName}</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <LocalPhoneOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t('parent-profile.title.phone')}</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{values.phone?.fullNumber}</Typography>}
                    />
                </ListItem>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{ // Set width and height using sx prop
                        '& .MuiPopover-paper': {
                            width: 'auto',
                        },
                    }}
                >
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                m:2
                            },
                        }}
                    >
                    <TextField
                        id="input-with-icon-textfield"
                        sx={{ width: '300px' }}
                        size="small"
                        name="email"
                        label={t('common.email')}
                        value={values.email}
                        onChange={handleChange('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon/>
                                </InputAdornment>
                            ),
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                        </Div>
                </Popover>
            </List>


            <Div>
                <Dialog open={openDialog}>
                    <DialogTitle>{t('parent-profile.title.updateContact')}</DialogTitle>
                    <DialogContent>


                        <FormControl noValidate autoComplete="off">
                            <Div
                                sx={{
                                    '& .MuiTextField-root': {
                                        mb: 3,
                                        mt: 0.9
                                    },
                                }}
                            >
                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="email"
                                    label={t('common.email')}
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon/>
                                            </InputAdornment>
                                        ),
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />

                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="firstName"
                                    label={t('common.firstName')}
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />

                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="lastName"
                                    label={t('common.lastName')}
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />

                                <TextField
                                    id="input-with-icon-textfield"
                                    fullWidth
                                    size="small"
                                    name="professional"
                                    label={t('common.profession')}
                                    value={values.profession}
                                    onChange={handleChange('profession')}
                                    variant="outlined"
                                />

                                <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                                    <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                                        {values.phone?.code && <Autocomplete
                                            defaultValue={values.phone}
                                            options={PHONE_COUNTRIES}
                                            autoHighlight
                                            onChange={handleCountryChange}
                                            getOptionLabel={(option) => {
                                                return ' +' + option.code;
                                            }}
                                            disableClearable={true}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${option.countryCode?.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${option.countryCode?.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                    {option.label} ({option.countryCode}) +{option.code}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={'Code'}
                                                    error={errPhone}
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                {values.phone && values.phone.countryCode && (
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${values.phone.countryCode?.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${values.phone.countryCode?.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />}
                                    </FormControl>

                                    <FormControl variant="outlined" sx={{flex: 1}}>
                                        <TextField
                                            required
                                            id="phone"
                                            label={t('common.phone')}
                                            error={errPhone}
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                            value={values.phone?.number}
                                            onChange={(event) => {
                                                let inputNumber = event.target.value;

                                                // Remove leading zeros
                                                const sanitizedNumber = inputNumber.replace(/\D/g, '').replace(/^0+/, '');

                                                let phone = values.phone;
                                                phone.number = sanitizedNumber;

                                                // Get selected country code
                                                const selectedCountryCode = phone.code;

                                                // Find the formatting rule for the selected country code
                                                const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

                                                // Enforce maximum digits restriction based on country code
                                                if (countryFormattingRule) {
                                                    const maxDigits = countryFormattingRule.maxDigits;

                                                    // If the input length exceeds the maximum allowed digits, truncate the input
                                                    if (phone.number.length > maxDigits) {
                                                        phone.number = phone.number.slice(0, maxDigits);
                                                    }

                                                    // If the input length equals the maximum allowed digits, prevent further input
                                                    if (phone.number.length === maxDigits) {
                                                        event.preventDefault();
                                                    }
                                                }

                                                setValues({...values, phone});
                                            }}
                                        />
                                    </FormControl>
                                </Div>

                                <Stack
                                    sx={{
                                        mb: 2,
                                        width: '90%',
                                        mx: 'auto',
                                        display: 'block',
                                        position: 'relative', // Add position relative to enable absolute positioning of loader
                                    }}
                                    spacing={2}>
                                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                </Stack>

                                <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        sx={{mr: 1,width:"30%"}}
                                        onClick={() => setOpenDialog(false)}
                                    >
                                        {t('common.closeBtn')}
                                    </Button>
                                    <Div sx={{flex: '1 1 auto'}}/>


                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        loading={loading}
                                        onClick={handleClick1}
                                        sx={{width:"30%"}}
                                    >
                                        {t('common.saveBtn')}
                                    </LoadingButton>
                                </Div>


                            </Div>
                        </FormControl>

                    </DialogContent>
                </Dialog>
            </Div>


        </JumboCardQuick>
    );
};

export default Contacts;
