import Div from "@jumbo/shared/Div";
import React from "react";
import {Alert, Button, FormControl, Rating, Typography,Slider} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0.5),
    margin: 0, // Ensures no margin on chips
}));

const chipLabels = [
    { label: "0: Not attended", value: 0 },
    { label: "0.5 : Poor", value: 0.5 },
    { label: "1: Standard", value: 1 },
    { label: "1.5 : Good", value: 1.5 },
    { label: "2: Very good", value: 2 },
    { label: "2.5 : Excellent", value: 2.5 },
    { label: "3 : Magic", value: 3 },
    { label: "3.5 : Phenomenal", value: 3.5 },
    { label: "4 : Legendary", value: 4 },
];


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Step1 = ({booking, handleNext, hide, setBooking}) => {

    const [ratings, setRatings] = React.useState([
        {key: 1, value: 5},
        {key: 2, value: 5},
        {key: 3, value: 5},
    ]);

    const [progress, setProgress] = React.useState(0);

    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(teacherServices.saveBookingFeedBack);


    React.useEffect(() => {
        console.log('booking---------------', booking);
        if (booking.teacherFeedback?.ratings?.length > 0) {
            setRatings(booking.teacherFeedback.ratings)
        }
        if (booking.teacherFeedback?.progress >= 0) {
            setProgress(booking.teacherFeedback.progress);  // Initialize progress
        }
    }, [booking])


    React.useEffect(() => {

        if (isSuccess) {
            handleNext({step: 4})
        }
        if (isError) {
            setErrMsg(t('errMsg.saving'));
        }

    }, [isError, isSuccess])


    const handleSave = () => {

        const b = {...booking};
        b.teacherFeedback = b.teacherFeedback ? b.teacherFeedback : {};
        b.teacherFeedback = {...b.teacherFeedback, ratings,progress}
        mutate({bookingId: b.bookingId, teacherFeedback: b.teacherFeedback})

    };


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off" fullWidth>
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 2,
                            mt: 0.5
                        },
                    }}
                >

                    <FormControl fullWidth sx={{mt: 0.5,}}>

                        <Div fullWidth sx={{'& > legend': {mt: 2}}}
                        > {ratings && ratings.map((item, index) => {
                            return <><Typography component="legend">{t('feedback.ratingLabel' + item.key)}</Typography>
                                <Rating
                                    name="simple-controlled"
                                    value={item.value}
                                    onChange={(event, newValue) => {

                                        if (newValue) {
                                            item.value = newValue;
                                            setRatings([...ratings]);
                                        }
                                        // alert(newValue)

                                    }}
                                /></>
                        })}
                        </Div>

                        <Div sx={{mt: 4, width: '100%'}}>
                            <Typography component="legend" sx={{mb: 2}}>
                                {t('Global Progress')}
                            </Typography>
                            <Div sx={{ml:2}}>
                            <Slider
                                aria-label="Slider"
                                value={progress}
                                step={0.5}
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 0.5, label: '0.5' },
                                    { value: 1, label: '1' },
                                    { value: 1.5, label: '1.5' },
                                    { value: 2, label: '2' },
                                    { value: 2.5, label: '2.5' },
                                    { value: 3, label: '3' },
                                    { value: 3.5, label: '3.5' },
                                    { value: 4, label: '4' }
                                ]}
                                min={0}
                                max={4}
                                valueLabelDisplay="auto"
                                onChange={(event, newValue) => setProgress(newValue)}
                                sx={{
                                    color: 'orange',
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: 'orange',
                                    },
                                    '& .MuiSlider-track': {
                                        backgroundColor: 'orange',
                                    },
                                    '& .MuiSlider-rail': {
                                        backgroundColor: '#ffd699',
                                    }
                                }}
                            />
                            </Div>
                        </Div>

                    </FormControl>

                    <Div sx={{ mt: 5 }}>
                        <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start">
                            {chipLabels.map((chip, index) => (
                                <Item key={index} onClick={() => setProgress(chip.value)}>
                                    <JumboChipsGroup
                                        chips={[chip]}
                                        mapKeys={{ label: "name" }}
                                        spacing={1}
                                        size="small"
                                        defaultColor={progress === chip.value ? "orange" : "#ffd699"} // Conditional color
                                    />
                                </Item>
                            ))}
                        </Stack>
                    </Div>






                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {


                                handleNext({step: 2})


                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>

                        <LoadingButton
                            variant="variant"
                            loading={isLoading}
                            onClick={handleSave}
                        >
                            {t("common.finishBtn")}
                        </LoadingButton>
                    </Div>
                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step1;
