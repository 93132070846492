import {createContext, useContext, useState} from 'react';

const ChildrenContext = createContext({});

export const useChildrenContext = () => {
    return useContext(ChildrenContext);
}

export const ChildrenProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshChildrenList :  false, openAddEditChild : false});

    return <ChildrenContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </ChildrenContext.Provider>
}
