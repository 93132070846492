import React from "react";
import {IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {useChildrenContext} from "../context";

const ContactFab = () => {

    const {globalState, setGlobalState} = useChildrenContext();


    return (
        <SpeedDial
            ariaLabel={"contact-fab"}
            icon={<SpeedDialIcon/>}
            sx={{
                position: "fixed",
                right: 30,
                bottom: 30
            }}
        >
            <SpeedDialAction
                icon={
                    <IconButton onClick={() => {
                        const openAddEditChild = true;
                        setGlobalState({...globalState, openAddEditChild});
                    }}>
                        <PersonAddIcon/>
                    </IconButton>
                }
                tooltipTitle={"Add Contact"}
            />

        </SpeedDial>
    );
};
export default ContactFab;
