import axios from "./config";
import {useMutation} from "react-query";

/*
const mock1 = new MockAdapter(axios, {
    delayResponse: 300,
    onNoMatch: 'passthrough',
});*/

const mock = () => {
}


export const useAppMutation = (fn) => {
    return useMutation(fn, {
            onSuccess: (data) => {
                console.log(data, "Checkout success response");
            },
            onError: (data) => {
                console.log(data, "Checkout error response");
            }
        }
    );
}
export default mock;
