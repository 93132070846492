export const orders = [
    {month: 'Jan', orders: 100},
    {month: 'Feb', orders: 150},
    {month: 'Mar', orders: 350},
    {month: 'Apr', orders: 300},
    {month: 'May', orders: 400},
    {month: 'Jun', orders: 250},
    {month: 'Jul', orders: 520},
    {month: 'Aug', orders: 350},
    {month: 'Sep', orders: 400},
    {month: 'Oct', orders: 250},
];
