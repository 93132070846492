export const TYPE_LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const TYPE_LOGOUT = "LOGOUT";
export const TYPE_RELOAD_USER = "RELOAD_USER";



export const onSuccessLogin = (payload) => (dispatch) => {
    dispatch({type: TYPE_LOGIN_SUCCESS, payload});
}


export const onCompleteLogout = () => (dispatch) => {
    dispatch({type: TYPE_LOGOUT});
}


export const reloadUser = () => (dispatch) =>{
     dispatch({type: TYPE_RELOAD_USER});
}
