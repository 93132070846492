import React from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    TextField,
    Typography
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import DDImageField from "../../../../component/DDImageField";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import {Form, Formik} from "formik";
import Select from "@mui/material/Select";
import * as yup from "yup";
import {HOBBIES, learningStyle, TEACHER_GRADES} from "../../../../utils/constants/appActions";
import DDFile from "../../../../component/DDFile";
import FileBase64 from 'react-file-base64';
import commonServices from "../../../../services/common-services";
import LoadingButton from "@mui/lab/LoadingButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step5 = ({hide, setStepsData, stepsData, handleNext}) => {

    const [values, setValues] = React.useState({
        profileImage: '',
        nickName: '',
        hipeKidsEmail: '',
        beforeGrade: '',
        afterGrade: '',
        meetingLink: '',
        approved: 'yes',
        evaluator: 'no'
    });
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(adminServices.saveTeacher);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    React.useEffect(() => {
        if (stepsData) {
            setValues({
                ...values, ...stepsData,
                approved: stepsData.approved ? 'yes' : 'no',
                evaluator: stepsData.evaluator ? 'yes' : 'no'
            })
        }
    }, [stepsData])


    React.useEffect(() => {
        if (isSuccess) {
            handleNext(6)
        } else if (isSuccess) {
            setErrMsg('Error in Saving...')
        }
    }, [isError, isSuccess])

    const submitStep = async () => {
        setErrMsg('')
        const {newImage, evaluator, approved} = values;
        let imageBase64 = undefined;
        if (newImage) {
            let b = await fetch(newImage).then(r => r.blob());
            imageBase64 = await commonServices.blobToBase64(b)
        }
        mutate({...stepsData, ...values, imageBase64, evaluator: evaluator === 'yes', approved: approved === 'yes'});


    };


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const backToStep4 = async () => {


        handleNext(4);
    };

    const handleChangeMultiple = (name) => (event) => {
        const {target: {value},} = event;

        setValues((prevValues) => ({
            ...prevValues,
            [name]: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form noValidate autoComplete="off">
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 2,
                            ml: 2,
                        }}>
                            <Typography variant={"h5"}>{(t('parent-children.title.profileImage'))}</Typography>
                            {!values.newImage && values.images?.length > 0 ? (
                                    <DDImageField name={"profile_pic"}
                                                  alt={"user profile pic"}
                                                  onFileSelection={async (file) => {
                                                      let b = await fetch(file).then(r => r.blob());
                                                      if (b.size > 1000000) {
                                                          setErrMsg(t("common.sizeErr"))
                                                          setTimeout(() => {
                                                              setErrMsg("");
                                                          }, 3000);
                                                      } else {
                                                          setValues({...values, newImage: file});
                                                      }
                                                  }}
                                                  sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                                  path={values.images[0]}
                                                  alt={values.firstName + ' ' + values.lastName}/>) :
                                <JumboAvatarField
                                    name={"profile_pic"}
                                    alt={"user profile pic"}
                                    src={values.newImage}
                                    onFileSelection={async (file) => {
                                        let b = await fetch(file).then(r => r.blob());
                                        if (b.size > 1000000) {
                                            setErrMsg(t("common.sizeErr"))
                                            setTimeout(() => {
                                                setErrMsg("");
                                            }, 3000);
                                        } else {
                                            setValues({...values, newImage: file});
                                        }
                                    }}
                                    sx={{width: 60, height: 60, margin: '0 auto 24px'}}
                                />}

                            <Typography sx={{mb: 2}}>{'Profile Picture'}</Typography>

                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                        }}>
                            <TextField
                                label={t('teacherForm.nickName')}
                                value={values.nickName}
                                onChange={handleChange('nickName')}
                            />
                        </FormControl>
                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                        }}>
                            <TextField
                                label={t('common.email')}
                                value={values.hipeKidsEmail}
                                onChange={handleChange('hipeKidsEmail')}
                            />
                        </FormControl>

                        <Div sx={{
                            mt: -1,
                        }}>
                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '100%'},
                                mr: 2, mt: 2, ml: 1
                            }}>
                                <InputLabel
                                    id="demo-simple-select-label-languagesSpoken">{t('teacherForm.gradeBefore')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-languagesSpoken"
                                    id="demo-simple-select-languagesSpoken"
                                    value={values.beforeGrade}
                                    onChange={(handleChange('beforeGrade'))}
                                    label={t('teacherForm.gradeBefore')}
                                >

                                    {TEACHER_GRADES.map(grades => (
                                        <MenuItem value={grades.code}>{grades.label}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '100%'},
                                mr: 2, mt: 2, ml: 1
                            }}>
                                <InputLabel
                                    id="demo-simple-select-label-languagesSpoken">{t('teacherForm.gradeAfter')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-languagesSpoken"
                                    id="demo-simple-select-languagesSpoken"
                                    value={values.afterGrade}
                                    onChange={(handleChange('afterGrade'))}
                                    label={t('teacherForm.gradeAfter')}
                                >

                                    {TEACHER_GRADES.map(gradesAfter => (
                                        <MenuItem value={gradesAfter.code}>{gradesAfter.label}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Div>

                        <FormControl sx={{
                            width: '100%',
                            mt: 1,
                        }}>
                            <TextField
                                id="outlined-multiline-static"
                                label={t('teacher-profile.title.zoomLink')}
                                value={values.meetingLink}
                                name="demoLink"
                                onChange={handleChange('meetingLink')}
                            />


                        </FormControl>

                        <FormControl sx={{
                            width: '100%',
                            mt: 2,
                            ml:1
                        }}>
                            <InputLabel id="demo-simple-select-label">{t('teacherForm.isApproved')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-isApproved"
                                value={values.approved}
                                onChange={handleChange('approved')}
                                label={t('teacherForm.isApproved')}
                            >
                                <MenuItem value="yes">{t('common.yesBtn')}</MenuItem>
                                <MenuItem value="no">{t('common.noBtn')}</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl sx={{
                            width: '100%',
                            mt: 2,
                            ml:1
                        }}>
                            <InputLabel id="demo-simple-select-label">{t('admin-teacher.title.evaluator')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-isApproved"
                                value={values.evaluator}
                                onChange={handleChange('evaluator')}
                                label={'Evaluator'}
                            >
                                <MenuItem value="yes">{t('common.yesBtn')}</MenuItem>
                                <MenuItem value="no">{t('common.noBtn')}</MenuItem>

                            </Select>
                        </FormControl>
                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        hide();
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.closeBtn")}
                </Button>
                <Button
                    onClick={backToStep4}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>
                <LoadingButton loading={isLoading} variant={"contained"} onClick={submitStep}>
                    {t("common.finishBtn")}
                </LoadingButton>
            </Div>
        </Box>);

};

export default Step5;
