import React from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import {
    Alert,
    Autocomplete,
    Box,
    Dialog,
    DialogContent,
    DialogTitle, FormControl,
    ListItem,
    ListItemIcon,
    ListItemText, TextField,
    Typography
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {COUNTRIES} from "../../../../utils/constants/appActions";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CottageIcon from '@mui/icons-material/Cottage';
import {useTranslation} from "react-i18next";

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));


const About = () => {

    const [value, setValue] = React.useState('1');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [values, setValues] = React.useState({
        address1: '470 Promenade',
        address2: 'Street 2',
        city: 'Nice',
        country: {
            code: 'FR',
            label: 'France',
            phone: '33',
            suggested: true,
        },
        postalCode: '06200',
    });

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleSignup = () => {
        if (
            !values.address1 ||
            !values.city ||
            !values.country ||
            !values.postalCode
        ) {
            setErrMsg(t("common.inputError"));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else {
            const obj = {
                ...values,
                location: {country: values.country.label},
            };
            console.log("About Update------------", obj);
        }
    };

    const handleCountryChange = (event, newValue) => {
        console.log(newValue)
        setValues({...values, country: newValue});
    };


    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>{t("admin-profile.title.addressDetails")}
                    <Span sx={{color: 'text.secondary', fontSize: 13}}>
                        <EditOutlinedIcon onClick={handleEmailIconClick} sx={{fontSize: 13, ml:1, cursor: 'pointer'}} />
                    </Span>
                </Typography>
            }
            action={
                <TabContext value={value}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        {/*<TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Overview" value="1"/>
                            <Tab label="Work" value="2"/>
                            <Tab label="Education" value="3"/>
                        </TabList>*/}
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{mb: 3.75}}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                    marginTop:2
                }}
            >
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '100%'}
                    }}
                >
                    <StyledListItemIcon>
                        <CottageIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("admin-profile.title.address")}</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">
                            470 Promenade Des Anglais</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <ApartmentIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("common.city")}
                    </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">Nice</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <LanguageOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("admin-profile.title.country")}
                    </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">France</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                    <StyledListItemIcon>
                        <FlagOutlinedIcon fontSize={"inherit"}/>
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                            {t("common.postalCode")}
                    </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">06200</Typography>}
                    />
                </ListItem>
            </List>

            <Div>
                <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle> {t("admin-profile.title.updateContact")}</DialogTitle>
                    <DialogContent>


                                <FormControl fullWidth noValidate autoComplete="off">
                                    <Div
                                        sx={{
                                            '& .MuiTextField-root': {
                                                mb: 3,
                                            },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            size="small"
                                            sx={{mt:1}}
                                            variant="filled"
                                            name="address1"
                                            label={t("admin-profile.title.address1")}
                                            value={values.address1}
                                            onChange={handleChange('address1')}
                                            error={!values.address1}
                                            helperText={!values.address1 ? t("admin-profile.title.address1error") : ''}
                                        />
                                         <TextField
                                            fullWidth
                                            size="small"
                                            variant="filled"
                                            name="address2"
                                            label={t("admin-profile.title.address2")}
                                            value={values.address2}
                                            onChange={handleChange('address2')}
                                            error={!values.address2}
                                            helperText={!values.address2 ? t("admin-profile.title.address2error") : ''}
                                        />

                                        <Autocomplete
                                            defaultValue={values.country}
                                            id="country-select-demo"
                                            options={COUNTRIES}
                                            autoHighlight
                                            onChange={handleCountryChange}
                                            getOptionLabel={(option) => {
                                                return option.label;
                                            }}
                                            disableClearable={true}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                    {option.label} ({option.code}) +{option.phone}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("admin-profile.title.country")}
                                                    variant="filled"
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <React.Fragment>
                                                                {values.country && (
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${values.country.code.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${values.country.code.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />

                                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                                            <FormControl variant="outlined" sx={{
                                                marginRight:1,
                                                width: {xs: '100%', sm: '100%', xl: '50%'}
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="filled"
                                                    name="city"
                                                    label={t("common.city")}
                                                    value={values.city}
                                                    onChange={handleChange('city')}
                                                    error={!values.city}
                                                    helperText={!values.city ? t("admin-profile.title.cityError") : ''}
                                                />
                                            </FormControl>

                                            <FormControl variant="outlined" sx={{
                                                width: {xs: '100%', sm: '100%', xl: '50%'}
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="filled"
                                                    name="postalCode"
                                                    label={t("common.postalCode")}
                                                    value={values.postalCode}
                                                    onChange={handleChange('postalCode')}
                                                    error={!values.postalCode}
                                                    helperText={!values.postalCode ? t("admin-profile.title.postalCodeError") : ''}
                                                />
                                            </FormControl>
                                        </Div>

                                        <Stack
                                            sx={{
                                                mb: 2,
                                                width: '90%',
                                                mx: 'auto',
                                                display: 'block',
                                                position: 'relative', // Add position relative to enable absolute positioning of loader
                                            }}
                                            spacing={2}>
                                            {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                        </Stack>


                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{
                                                mb: 2,
                                                width: '90%',
                                                mx: 'auto',
                                                display: 'block',
                                                position: 'relative', // Add position relative to enable absolute positioning of loader
                                            }}
                                            onClick={handleSignup}
                                        >
                                            {t("common.saveBtn")}
                                        </LoadingButton>


                                    </Div>
                                </FormControl>
                    </DialogContent>
                </Dialog>
            </Div>

        </JumboCardQuick>
    );
};

export default About;
