import React, {useEffect, useState} from 'react';
import {Step, StepLabel, Stepper, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import {useChildrenContext} from "../context";
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import {useTranslation} from "react-i18next";


const steps1 = ['Basic Info', 'Language', 'Learning Needs', 'Personality', 'Appointment'];


const Steps = ({}) => {

    const [stepsData, setStepsData] = useState(undefined);
    const [activeStep, setActiveStep] = React.useState(0);
    const {globalState, setGlobalState} = useChildrenContext();
    const {t} = useTranslation();
    const {mutate: loadChild, isError, data, isSuccess, isLoading} = useAppMutation(parentServices.loadChild);

    const steps = [
        (t('parent-children.title.basicInfo')),
        (t('parent-children.title.language')),
        (t('parent-children.title.learningNeeds')),
        (t('parent-children.title.personality'))
    ];


    useEffect(() => {

        console.log('Steps--------', isSuccess, data)

        if (isSuccess) {

            setStepsData(data);

        } else if (isError) {

        } else if (globalState && globalState.childId) {
            // setStepsData({})

            loadChild(globalState.childId);
        }

        console.log(globalState);


    }, [globalState, isSuccess, data, isError])


    const handleNext = (step) => {

        // alert(step);

        if (step === 5) {
            hideDialog(false);
        } else {
            setActiveStep(step - 1);
        }
    };

    const hideDialog = () => {
        setGlobalState({...globalState, childId: undefined, refreshChildrenList : true, openAddEditChild: false});
    }


    return (<Div sx={{width: '100%'}}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{mb:4}}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>
        {activeStep === steps.length ? (<Div>
            <Typography sx={{mt: 2, mb: 1, justifyContent: 'center'}}>
                All steps completed - you&apos;re finished
            </Typography>

        </Div>) : (<Div>
            {activeStep + 1 === 1 && <Step1 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}
            {activeStep + 1 === 2 && <Step2 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}
            {activeStep + 1 === 3 && <Step3 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}
            {activeStep + 1 === 4 && <Step4 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}

            {false && activeStep + 1 === 5 &&
                <Step5 handleNext={handleNext} stepsData={stepsData} setStepsData={setStepsData}/>}

        </Div>)}
    </Div>);
};

export default Steps;
