import React, {useEffect} from 'react';
import {Card, CardContent, Step, StepLabel, Stepper} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useParentContext} from "../context";

const Steps = ({}) => {

    const {
        mutate,
        isError: isLPError,
        isSuccess: isLPSuccess,
        data: parent,
        isLoading
    } = useAppMutation(adminServices.loadParent);
    const [stepsData, setStepsData] = React.useState(undefined);
    const {globalState, setGlobalState} = useParentContext();
    const {t} = useTranslation();
    const steps = [t("parent.title.contact"), t("parent.title.address"), t("parent.title.comment"), t("parent.title.finish")];
    const [activeStep, setActiveStep] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);
    const dispatch = useDispatch();


    const hide = () => {
        setGlobalState({...globalState, openAddEdit: false, refreshList: true, parent: undefined})
    }


    useEffect(() => {
        console.log(globalState)
        if (globalState && globalState.parent) {
            mutate(globalState.parent.parentId)
        }
    }, [globalState])

    useEffect(() => {
        if (isLPSuccess) {
            setStepsData({...parent});
        }


    }, [isLPError, isLPSuccess])


    const handleNext = (step) => {

        // alert(step);

        if (step === 5) {
            hide();
        } else {
            setActiveStep(step - 1);
        }
    };


    const handleReset = () => {
        setActiveStep(0);
    };

    return (<Card
        sx={{
            display: 'flex', minWidth: 0, flexDirection: {xs: 'column', md: 'row'}
        }}
    > <CardContent sx={{flex: 1}}>

        <Div sx={{width: '100%'}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (<Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>);
                })}
            </Stepper>
            <Div>
                {activeStep + 1 === 1 &&
                    <Step1 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 2 &&
                    <Step2 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 3 &&
                    <Step3 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
                {activeStep + 1 === 4 &&
                    <Step4 hide={hide} setStepsData={setStepsData} handleNext={handleNext} stepsData={stepsData}/>}
            </Div>
        </Div>
    </CardContent>
    </Card>);
};

export default Steps;
