import appAxios from "./config";

const authServices = {};

authServices.getCurrentUser = async () => {
    console.log('getCurrentUser', authServices.getAuthUser());
    return authServices.getAuthUser();
};


authServices.isSuperAdmin = () => {
    const user = authServices.getAuthUser();
    console.log('getCurrentUser', authServices.getAuthUser());
    return user?.role === 'SuperAdmin'
};


authServices.isParent = () => {
    const user = authServices.getAuthUser();
    return user?.role === 'Parent'
};

authServices.isTeacher = () => {
    const user = authServices.getAuthUser();
    return user?.role === 'Teacher'
};

authServices.adminSignIn = async (loginCreds) => {
    const {data} = await appAxios.post('v1/admin/login', loginCreds);
    authServices.setAuthToken(data.token);
    authServices.setAuthUser({
        email: data.email, role: data.role, firstName: 'Administrator',
        lastName: '',
        images : data.images

    });
    return data;
};


authServices.parentSignIn = async (loginCreds) => {
    const {data} = await appAxios.post('v1/parent/login', loginCreds);
    console.log(JSON.stringify(data));
    authServices.setAuthToken(data.token);
    authServices.setAuthUser({
        email: data.email,
        role: data.role,
        completedStep1: data.completedStep1,
        completedStep2: data.completedStep1,
        completedStep3: data.completedStep3,
        firstName: data.firstName,
        lastName: data.lastName,
        images : data.images,
        dateOfBirth : data.dateOfBirth,
    });
    return data;
};

authServices.teacherSignIn = async (loginCreds) => {
    const {data} = await appAxios.post('v1/teacher/login', loginCreds);
    console.log(JSON.stringify(data));
    authServices.setAuthToken(data.token);
    authServices.setAuthUser({
        email: data.email,
        role: data.role,
        completedStep1: data.completedStep1,
        completedStep2: data.completedStep1,
        completedStep3: data.completedStep3,
        completedStep4: data.completedStep4,
        firstName: data.firstName,
        lastName: data.lastName,
        images : data.images
    });
    return data;
};


authServices.logout = () => {
    localStorage.removeItem('@Token')
    localStorage.removeItem('@User')
};


authServices.setAuthToken = (token) => {
    localStorage.setItem('@Token', token);
};


authServices.getAuthToken = () => {
    return localStorage.getItem("@Token");
};

authServices.setAuthUser = (user) => {
    localStorage.setItem('@User', JSON.stringify(user));
};


authServices.updateAuthUser = (obj) => {
    const user = authServices.getAuthUser();
    const newObj = {...user, ...obj}
    localStorage.setItem('@User', JSON.stringify(newObj));
};


authServices.getAuthUser = () => {

    const user = localStorage.getItem('@User');

    if (user && user !== 'undefined') {
        return JSON.parse(user);
    }
    return null
};





export default authServices;
