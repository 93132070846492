import React from 'react';
import {useParams} from "react-router-dom";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import {AppointmentProvider, useAppointmentContext} from "./context";
import CalendarBasic from "./calendars/CalendarBasic";
import {useTranslation} from "react-i18next";


const Children = () => {
    return <AppointmentProvider><InnerChildren/></AppointmentProvider>
}

const InnerChildren = () => {

    const {globalState, setGlobalState} = useAppointmentContext();
    const {t} = useTranslation();


    return (
        <JumboContentLayout
            layoutOptions={{}}
            //sidebar={<Sidebar/>}
            header={
                <Header title={t("calendar.appointments")}
                        subheader={t("parent.title.subheader")}/>
            }

        >
            <CalendarBasic/>
        </JumboContentLayout>

    );
};

export default Children;
