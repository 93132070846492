import {createContext, useContext, useState} from 'react';

const CalendarContext = createContext({});

export const useCalendarContext = () => {
    return useContext(CalendarContext);
}

export const CalendarProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({
        showBookings: true,
        showAppointments: true,
        showAvailabilities: true,
        refreshList: false,
        showStudentInfo : false
    });

    return <CalendarContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </CalendarContext.Provider>
}
