import Div from "@jumbo/shared/Div";
import {
    Alert,
    Avatar,
    Button,
    ButtonGroup,
    Chip,
    FormControl,
    InputLabel,
    ListItemAvatar,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import DDImage from "../../../../component/DDImage";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment/moment";
import React, {useState} from "react";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {isPastDate, timings} from "../../../../utils/constants/appActions";
import {useTranslation} from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import {useCalendarContext} from "../context";
import StudentInfoForm from "../StudentInfoForm";

const BookingForm = ({hide, showTeacherFeedback, appointment, setAppointment}) => {

    const [type] = React.useState('courses');
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [pastDate, setPastDate] = useState(false);
    const [isEditable, setEditable] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const {t} = useTranslation();
    const {globalState, setGlobalState} = useCalendarContext();
    const [children, setChildren] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [availableDates, setAvailableDates] = useState([]);
    const [sTimings, setSTimings] = useState([]);
    const [cancelReason, setCancelReason] = useState('');
    const [openChildInfoDialog, setOpenChildInfoDialog] = useState(false);
    const [values, setValues] = React.useState({
        startDate: '',
        startTime: '',
        title: 'Student Booking',
        description: '',
        subscriptionId: '',
        teacherId: '',
        studentId: '',
    });

    const {
        mutate: loadAvailableDates,
        isSuccess: isLDSuccess,
        isLoading: isLDLoading,
        isError: isLDError,
        data: ldResult
    } = useAppMutation(adminServices.loadAvailableDates);


    const {
        mutate: deleteBooking,
        isSuccess: isDBSuccess,
        isLoading: isDBLoading,
        isError: isDBError,
        data: dbResult
    } = useAppMutation(adminServices.deleteBooking);


    const {
        mutate: cancelBooking,
        isSuccess: isCBSuccess,
        isLoading: isCBLoading,
        isError: isCBError,
        data: cbResult
    } = useAppMutation(adminServices.cancelBooking);


    const {
        mutate: loadAvailableTimes,
        isSuccess: isLTSuccess,
        isLoading: isLTLoading,
        isError: isLTError,
        data: ltResult
    } = useAppMutation(adminServices.loadAvailableTimes);


    const {mutate: loadStudents, isSuccess: isCSuccess, data: cResult} = useAppMutation(adminServices.loadStudents);

    const {
        mutate: saveBooking,
        isSuccess: isBSuccess,
        data: bResult,
        isLoading: isBLoading,
        isError: isBError
    } = useAppMutation(adminServices.saveBooking);

    const {
        mutate: loadBooking,
        isSuccess: isLBSuccess,
        data: lbResult,
        isLoading: isLBLoading,
        isError: isLBError
    } = useAppMutation(adminServices.loadBooking);

    const {
        mutate: loadAvailableTeachers,
        isSuccess: isESuccess,
        data: evaluators
    } = useAppMutation(adminServices.loadAvailableTeachers);

    const {
        mutate: loadActiveSubscriptions,
        isSuccess: isLASSuccess,
        data: lasData
    } = useAppMutation(adminServices.loadActiveSubscriptions);

    React.useEffect(() => {

        if (globalState.booking) {


            const booking = {...values, ...globalState.booking}
            setValues(booking);

            const min = globalState.minDate
            const max = globalState.maxDate
            setMinDate(min);
            setMaxDate(max);
            loadStudents({page: 1, size: 2000});

            const strDate = new Date();
            strDate.setDate(1);
            const endDate = new Date(strDate)
            strDate.setMonth(strDate.getMonth() - 3);
            endDate.setMonth(endDate.getMonth() + 4);

            loadAvailableDates({startDate: strDate, endDate, type});

            console.log('Booking--------------------------------------', booking);
            if (globalState.booking.bookingId) {
                setEditable(false);
                loadBooking(globalState.booking.bookingId)
                const flag = isPastDate(globalState.booking.sstartDate)
                setPastDate(flag);
                loadEvaluators({
                    studentId: booking.studentId,
                    startDate: booking.startDate,
                    startTime: booking.startTime
                })

            } else {
                setEditable(true);
                loadActiveSubscriptions({
                    studentId: booking.studentId,
                    subscriptionId: values.subscriptionId,
                    date: globalState.booking.sstartDate
                });
            }
        }


    }, [globalState]);

    React.useEffect(() => {
        if (isCBSuccess) {
            setGlobalState({...globalState, booking: undefined, addEditBooking: false, refreshList: true})
        }
    }, [isCBSuccess])

    React.useEffect(() => {
        if (isDBSuccess) {
            setGlobalState({...globalState, booking: undefined, addEditBooking: false, refreshList: true})
        }
    }, [isDBSuccess])

    const [subscription, setSubscription] = React.useState({});
    const [subscriptions, setSubscriptions] = React.useState([]);

    React.useEffect(() => {

        console.log('isLASSuccess', isLASSuccess, lasData)
        if (isLASSuccess && lasData) {

            const {subscription, subscriptions} = lasData;

            console.log('Subscriptions.......', subscription, subscriptions)
            setSubscriptions([...subscriptions]);
            setSubscription({...subscription});
            /*if (!values.subscriptionId) {
                setValues({
                    ...values, subscriptionId:
                    lasData?.subscription?.subscriptionId
                })
            }*/
        }
    }, [isLASSuccess, lasData])


    React.useEffect(() => {

        if (isLBSuccess) {
            const booking = {...values, ...lbResult.booking, teacherId: lbResult.booking.teacher.teacherId};
            booking.sstartDate = new Date(booking.startDate);
            const startDate = moment(booking.sstartDate).format('YYYY-MM-DD'); // laResult.startDate.slice(0, 10);
            const startTime = moment(booking.sstartDate).format('HH:mm');
            booking.startDate = startDate;
            booking.startTime = startTime;
            setValues(booking);

            loadActiveSubscriptions({
                studentId: booking.studentId, subscriptionId: booking.subscriptionId,
                date: booking.sstartDate
            });

            loadAvailableTimes({
                date: booking.sstartDate,
                studentId: booking.studentId,
                bookingId: booking.bookingId,
                type
            })

        }


    }, [isLBSuccess]);


    React.useEffect(() => {

        if (isCSuccess && cResult) {
            const c = [...cResult.students]
            setChildren(c);
            // setValues({...values, studentId : values.studentId});
        }

    }, [isCSuccess, cResult])


    React.useEffect(() => {

        if (isLDSuccess) {
            setAvailableDates(ldResult?.availableDates)
            setValues({...values, sstartDate: new Date(values.startDate)});
        }

    }, [isLDError, isLDSuccess, ldResult])


    React.useEffect(() => {

        console.log(isLTSuccess, isLTSuccess, ltResult);

        if (isLTSuccess && ltResult) {
            const ls = [];
            for (const t of ltResult?.timings) {

                let date = new Date(`${values.startDate}T${t}`);
                const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                date = new Date(date.getTime() - userTimezoneOffset);
                //if (date > new Date()) {
                const v = timings.find(item => item.value === moment(date).format('HH:mm'));

                if (v) {
                    ls.push(v);
                }
                //}
            }
            console.log('timings...........', ls)
            setSTimings(ls);

        } else {
            setSTimings([]);
        }


    }, [isLTError, isLTSuccess, ltResult])


    React.useEffect(() => {

        if (isBSuccess) {
            setGlobalState({...globalState, booking: undefined, addEditBooking: false, refreshList: true})

        } else if (isBError) {
            setErrMsg(t('errMsg.booking'))
        }

    }, [isBError, isBSuccess])


    const [errMsg, setErrMsg] = React.useState('');

    const loadEvaluators = ({studentId, startDate, startTime}) => {

        const sDate = startDate ? '' + startDate : '' + values.startDate;
        const sTime = startTime ? '' + startTime : '' + values.startTime;
        const c = studentId ? studentId : values.studentId;
        const selectedStartDateTime = new Date(`${sDate}T${sTime}`);
        const selectedEndDateTime = new Date(`${sDate}T${sTime}`);

        selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 29);

        const obj = {type, studentId: c, startDate: selectedStartDateTime, endDate: selectedEndDateTime}
        loadAvailableTeachers(obj);

    }

    const handleCancelBooking = () => {


        if (!cancelReason.trim()) {
            setErrMsg(t('errMsg.cancelReason'));
        } else {
            // Proceed with cancellation action
            setErrMsg('');
            // Your cancellation logic here

            const obj = {
                bookingId: values.bookingId,
                reason: cancelReason
            };
            cancelBooking(obj);
        }

    }
    const handleSaveBooking = () => {

        console.log(values);

        if (values.startTime && values.startDate && values.studentId && values.description) {

            const selectedStartDateTime = new Date(`${values.startDate}T${values.startTime}`);
            const selectedEndDateTime = new Date(`${values.startDate}T${values.startTime}`);
            selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 30);

            const booking = {
                description: values.description,
                startDate: selectedStartDateTime,
                endDate: selectedEndDateTime,
                studentId: values.studentId,
                bookingId: values.bookingId,
                teacherId: values.teacherId,
                subscriptionId: values.subscriptionId
            };

            saveBooking(booking);


        } else {
            setErrMsg(t("common.inputError"));
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
        }

    };

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
        if (prop === 'startTime') {
            loadEvaluators({startTime: event.target.value});
        } else if (prop === 'startDate') {
            loadEvaluators({startDate: event.target.value});
        } else if (prop === 'studentId') {

            loadActiveSubscriptions({
                studentId: event.target.value, subscriptionId: values.subscriptionId, date: values.sstartDate
            });


            if (!sTimings || sTimings.length === 0) {
                if (values.sstartDate) {
                    loadAvailableTimes({
                        date: values.sstartDate,
                        studentId: event.target.value,
                        bookingId: values.bookingId,
                        type
                    })
                }
            }
        }
    };


    const isDisableSave = () => {

        if (subscriptions?.length == 0) {
            return true
        }

        return false;
    }
    const handleChildInfoClick = () => {
        setOpenChildInfoDialog(true);
    };

    const handleCloseChildInfoDialog = () => {
        setOpenChildInfoDialog(false);
    };

    if (openChildInfoDialog) {
        return <StudentInfoForm
            hide={handleCloseChildInfoDialog}
            studentId={values.studentId}
        />
    }


    return (<FormControl noValidate autoComplete="off">
        <Div
            sx={{
                '& .MuiTextField-root': {
                    mb: 3,
                    mt: 0.5
                },
            }}
        >
            <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                <InputLabel id="demo-multiple-checkbox-label">{t("parent.title.child")}</InputLabel>
                <Select
                    disabled={pastDate || !isEditable}
                    value={values.studentId}
                    label={t("parent.title.child")}
                    onChange={handleChange('studentId')}
                    renderValue={(selected) => {
                        // Find the selected child based on the studentId
                        const selectedChild = children?.find((child) => child.studentId === selected);

                        console.log('selected----------------------', selected)
                        // Display only the selected child's name
                        return (
                            <Div sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar>
                                    {selectedChild && selectedChild.images?.length > 0 ? (
                                        <DDImage path={selectedChild.images[0]}
                                                 alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                    ) : (
                                        <Avatar
                                            alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                    )}
                                </ListItemAvatar>
                                {selectedChild?.firstName} {selectedChild?.lastName}
                            </Div>
                        );
                    }}
                >
                    {children && children.map((item) => (
                        <MenuItem key={item.studentId} value={item.studentId}>
                            <ListItemAvatar>
                                {item.images?.length > 0 ? (
                                    <DDImage path={item.images[0]}
                                             alt={item.firstName + ' ' + item.lastName}/>
                                ) : (
                                    <Avatar alt={item.firstName + ' ' + item.lastName}/>
                                )}
                            </ListItemAvatar>
                            {item.firstName} {item.lastName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                <InputLabel id="demo-multiple-checkbox-label">{t('calendar.subscription')}</InputLabel>
                <Select
                    disabled={!isEditable || pastDate || !values?.studentId}
                    value={values.subscriptionId}
                    label={t("parent-subscription.title.sub")}
                    onChange={handleChange('subscriptionId')}
                    renderValue={(selected) => {
                        // Find the selected child based on the studentId
                        const selectedChild = subscriptions?.find((child) => child.subscriptionId === selected);

                        // Display only the selected child's name
                        return (
                            <Div sx={{display: 'flex', alignItems: 'center'}}>
                                {selectedChild?.plan?.name}
                            </Div>
                        );
                    }}
                >
                    {subscriptions.map((item) => (
                        <MenuItem key={item.subscriptionId} value={item.subscriptionId}>
                            <div>{item.plan.name}
                                <br/>
                                {t('common.expired')} {moment(item.expiredDate).format('YYYY-MM-DD')}
                                <br/>
                                {t('common.bookingUtilize')} {item.noOfUsedBooking}/{item.noOfBooking}
                            </div>
                            {item.subscriptionId === subscription?.subscriptionId &&
                                <div><Chip label={t('common.recommended')}
                                           color={"success"}
                                           size={"small"}/></div>}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <FormControl fullWidth>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            disabled={!isEditable || pastDate || !values?.studentId}
                            open={open}
                            onOpen={(event) => {
                                setOpen(true)
                            }}
                            onClose={(event) => {
                                setOpen(false)
                            }}
                            shouldDisableDate={(day) => {

                                if (pastDate)
                                    return false;

                                if (!isEditable)
                                    return false;

                                if (availableDates &&
                                    availableDates.indexOf(moment(day).format('YYYY-MM-DD')) > -1) {
                                    return false
                                } else {
                                    return true
                                }

                            }}
                            minDate={true ? null : (pastDate ? null : minDate)}
                            maxDate={true ? null : (pastDate ? null : maxDate)}
                            loading={false}
                            label={t("common.date")}
                            format="dd/MM/yyyy"
                            value={values.sstartDate}
                            onChange={(event, newValue) => {
                                const startDate = moment(event).format('YYYY-MM-DD');
                                const sstartDate = event;
                                setValues({...values, startDate, sstartDate})

                                loadAvailableTimes({
                                    date: startDate,
                                    studentId: values.studentId,
                                    type
                                })

                            }}
                            renderInput={(params) => <TextField {...params}

                            />}
                        />

                    </Stack>
                </LocalizationProvider>
            </FormControl>


            <FormControl fullWidth sx={{mb: 2, mt: 1}}>
                {(!isEditable) &&
                    <TextField label={t("parent.title.time")} disabled={!isEditable} value={values.startTime}/>}
                {isEditable && <>
                    <InputLabel id="demo-simple-select-label">{t("parent.title.time")}</InputLabel>
                    <Select

                        disabled={!isEditable || !values?.studentId || !values?.startDate}

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.startTime}
                        label={t("parent.title.time")}
                        onChange={
                            handleChange('startTime')

                        }

                    >
                        {sTimings.map((item, index) => (
                            <MenuItem key={'key-timing-' + index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select></>}
            </FormControl>


            <FormControl fullWidth sx={{mb: 3, mt: 1}}>
                {!isEditable && values.teacher?.teacherId && <><InputLabel id="demo-multiple-checkbox-label">{t("parent.title.teacher")}</InputLabel>
                    <Select
                        disabled={true}
                        value={values.teacher?.teacherId}
                        label={t("parent.title.teacher")}
                        renderValue={(selected) => {
                            // Find the selected child based on the studentIdal

                            const selectedChild = values.teacher;

                            // Display only the selected child's name
                            return (
                                <Div sx={{display: 'flex', alignItems: 'center'}}>
                                    <ListItemAvatar>
                                        {selectedChild && selectedChild.images?.length > 0 ? (
                                            <DDImage path={selectedChild.images[0]}
                                                     alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                        ) : (
                                            <Avatar
                                                alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                        )}
                                    </ListItemAvatar>
                                    {selectedChild?.firstName} {selectedChild?.lastName}
                                </Div>
                            );
                        }}
                    >
                        <MenuItem key={'key-teacher-' + values?.teacher?.teacherId} value={values?.teacher?.teacherId}>
                            <ListItemAvatar>
                                {values?.teacher?.images?.length > 0 ? (
                                    <DDImage path={values?.teacher?.images[0]}
                                             alt={values?.teacher?.firstName + ' ' + values?.teacher?.lastName}/>
                                ) : (
                                    <Avatar alt={values?.teacher?.firstName + ' ' + values?.teacher?.lastName}/>
                                )}
                            </ListItemAvatar>
                            {values?.teacher?.firstName} {values?.teacher?.lastName}
                        </MenuItem>
                    </Select></>}

                {isEditable && <>
                    <InputLabel id="demo-multiple-checkbox-label">{t("parent.title.teacher")}</InputLabel>
                    <Select
                        disabled={!isEditable || !evaluators || evaluators.length === 0}
                        value={values.teacherId}
                        label={t("parent.title.teacher")}
                        onChange={handleChange('teacherId')}
                        renderValue={(selected) => {
                            // Find the selected child based on the studentIdal

                            const selectedChild = evaluators?.find((child) => child.teacherId === selected);

                            // Display only the selected child's name
                            return (
                                <Div sx={{display: 'flex', alignItems: 'center'}}>
                                    <ListItemAvatar>
                                        {selectedChild && selectedChild.images?.length > 0 ? (
                                            <DDImage path={selectedChild.images[0]}
                                                     alt={selectedChild.firstName + ' ' + selectedChild.lastName}/>
                                        ) : (
                                            <Avatar
                                                alt={selectedChild?.firstName + ' ' + selectedChild?.lastName}/>
                                        )}
                                    </ListItemAvatar>
                                    {selectedChild?.firstName} {selectedChild?.lastName}
                                </Div>
                            );
                        }}
                    >
                        {evaluators && evaluators.map((item) => (
                            <MenuItem key={'key-teacher-' + item.teacherId} value={item.teacherId}>
                                <ListItemAvatar>
                                    {item.images?.length > 0 ? (
                                        <DDImage path={item.images[0]}
                                                 alt={item.firstName + ' ' + item.lastName}/>
                                    ) : (
                                        <Avatar alt={item.firstName + ' ' + item.lastName}/>
                                    )}
                                </ListItemAvatar>
                                {item.firstName} {item.lastName}
                            </MenuItem>
                        ))}
                    </Select></>}
            </FormControl>


            <FormControl fullWidth>
                <TextField
                    disabled={!isEditable || pastDate}
                    id="outlined-multiline-static"
                    label={t("common.desc")}
                    multiline
                    rows={2}
                    value={values.description}
                    name="description"
                    onChange={handleChange('description')}
                />
            </FormControl>

            {
                !showDeleteConfirmation && !values.cancelled &&
                <Typography variant="body2">
                    {t("parent.title.txt")}
                </Typography>
            }

            <Stack
                sx={{
                    mb: 2,
                    width: '90%',
                    mx: 'auto',
                    display: 'block',
                    position: 'relative', // Add position relative to enable absolute positioning of loader
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            {
                !showCancelConfirmation && !showDeleteConfirmation && (
                    <ButtonGroup
                        sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        disableElevation
                        variant="contained"
                        color={"warning"}
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            onClick={() => {
                                hide()
                            }}
                            sx={{
                                width: "20%",
                                height: "10%",
                                mr: .5

                            }}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            size="medium"
                            variant="contained"
                            onClick={handleChildInfoClick}
                            sx={{mr: 1, height: "10%", width: '25%',}}>
                            {t('common.studentBtn')}
                        </Button>
                        {isEditable &&
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                size="medium"
                                disabled={isDisableSave()}
                                onClick={handleSaveBooking}
                                sx={{
                                    width: "25%",
                                    mr: .5
                                }}
                                loading={isBLoading}

                            >
                                {/^true$/i.test(values.cancelled) ? (<>Undo</>) : (
                                    values.bookingId ? t("common.updateBtn") : t("common.saveBtn")
                                )}

                            </LoadingButton>}

                        {values.bookingId && values.teacherFeedback?.ratings?.length === 0 &&
                            <Button
                                type="button"
                                variant="contained"
                                size="medium"
                                onClick={() => {
                                    setShowDeleteConfirmation(true);
                                }}
                                sx={{
                                    width: "20%",
                                    mr: .5
                                }}
                            >
                                {t("common.deleteBtn")}

                            </Button>}

                        {!pastDate && values.teacherFeedback?.ratings?.length === 0 && !values.cancelled && values.bookingId &&
                            <Button
                                type="button"
                                variant="contained"
                                size="medium"
                                onClick={() => {
                                    setShowCancelConfirmation(true)
                                }}
                                sx={{
                                    width: "20%",
                                    mr: .5

                                }}
                            >
                                {t("common.cancelBtn")}
                            </Button>}

                        {values.teacherFeedback?.ratings?.length > 0 && <Button
                            type="button"
                            variant="contained"
                            size="medium"
                            onClick={() => {
                                showTeacherFeedback(values.teacherFeedback)
                            }}
                            sx={{
                                width: "30%",

                            }}
                        >
                            {t("feedbackForm.feedback")}
                        </Button>}
                    </ButtonGroup>
                )
            }
            {
                showCancelConfirmation && (
                    <>
                        <TextField
                            id="cancelReason"
                            label="Reason for cancellation"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            multiline
                            rows={2}
                            onChange={(e) => setCancelReason(e.target.value)}
                            sx={{width: '100%'}}
                        />
                        <Alert severity="warning" sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                <span style={{marginRight: '15px'}}>{t("common.cancelTxt")}</span>
                                <Stack direction="row" spacing={2}>
                                    <LoadingButton loading={isCBLoading}
                                                   onClick={(handleCancelBooking)}>{t("common.yesBtn")}</LoadingButton>
                                    <Button onClick={() => {
                                        setShowCancelConfirmation(false)
                                    }}>{t("common.noBtn")}</Button>
                                </Stack>
                            </div>
                        </Alert>
                    </>
                )
            }

            {
                showDeleteConfirmation && (
                    <Alert severity="warning" sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                        <div
                            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <span style={{marginRight: '15px'}}>{t("common.deleteTxt")}</span>
                            <Stack direction="row" spacing={2}>
                                <LoadingButton loading={isDBLoading}
                                               onClick={(() => {
                                                   deleteBooking(values.bookingId)
                                               })}>{t("common.yesBtn")}</LoadingButton>
                                <Button onClick={() => {
                                    setShowDeleteConfirmation(false)
                                }}>{t("common.noBtn")}</Button>
                            </Stack>
                        </div>
                    </Alert>
                )
            }
        </Div>
    </FormControl>)
}

export default BookingForm;
