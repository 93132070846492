import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, TextField, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import Select from "@mui/material/Select";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import {useTranslation} from "react-i18next";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step3 = ({stepsData, handleNext}) => {

    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        profileImage: '',
        backgroundInfo: ''
    });
    const [errMsg, setErrMsg] = React.useState('');
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);
    const [addSegment, setAddSegment] = React.useState(false);
    const {t} = useTranslation();

    useEffect(() => {


    }, [])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep = async () => {
        setErrMsg('');

        const {firstName, lastName, title, phone} = values;

        handleNext(4);

        if (firstName && lastName) {


        } else {
            // setErrMsg('Fields not filled.');
        }


    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form noValidate autoComplete="off">


                        <Alert onClose={() => {
                        }} sx={{m: 1, backgroundColor: '#FBD9D3'}}> Reading 1/3 : Understanding simple sentences</Alert>
                        <Alert onClose={() => {
                        }} sx={{m: 1, backgroundColor: '#FBD9D3'}}> Reading 2/3 : Follow short stories in simple lg</Alert>
                        <Alert onClose={() => {
                        }} sx={{m: 1, backgroundColor: '#FBD9D3'}}> Reading 3/3 : Recognise and write letters in the alpabet</Alert>


                        {!addSegment && <>


                            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                                <Div sx={{flex: '1 1 auto'}}/>

                                <LoadingButton loading={isLoading} variant={"variant"} onClick={() => {
                                    setAddSegment((true))
                                }}>
                                    {t("admin-program.title.addSegBtn")}
                                </LoadingButton>

                            </Div>

                        </>}
                        {addSegment && <>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>
                                <TextField
                                    required
                                    error={errFirstName}
                                    label={t("admin-program.title.segName")}
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}

                                />
                            </FormControl>
                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '50%'}
                            }}>
                                <TextField
                                    required
                                    error={errFirstName}
                                    label={t("admin-program.title.segColor")}
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}

                                />
                            </FormControl>

                            <FormControl sx={{
                                width: {xs: '100%', sm: '100%', xl: '100%'},
                                mt: 1,

                            }}>
                                <TextField
                                    required
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    error={errFirstName}
                                    label={t("admin-program.title.segDesc")}
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}

                                />
                            </FormControl>

                            </>}


                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        handleNext(2)
                    }}
                    sx={{mr: 1}}
                >
                   {t("common.backBtn")}
                </Button>

                <Button
                    onClick={() => {

                    }}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep}>
                    {t("common.nextBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step3;
