import {createContext, useContext, useState} from 'react';

const ChildrenProgressContext = createContext({});

export const useChildrenProgressContext = () => {
    return useContext(ChildrenProgressContext);
}

export const ChildrenProgressProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshChildrenList :  false, openAddEditChild : false});

    return <ChildrenProgressContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </ChildrenProgressContext.Provider>
}
