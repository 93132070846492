import baseAxios from "axios";
import authServices from "./auth-services";

const axios = baseAxios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});


axios.interceptors.response.use(function (response) {

    // console.log('interceptors-------------', response.status, response)


    return {data: response?.data?.result};
}, function (error) {


    // console.log(error)

    if (error.response.status === 401) {

        const isSuperAdmin = authServices.isSuperAdmin();
        const isParent = authServices.isParent();
        const isTeacher = authServices.isTeacher();
        authServices.logout();
        if (isSuperAdmin) {
            // navigate("/admin/login");
            window.location = '/admin/login'
        } else if (isParent) {
            // navigate("/parent/login");
            window.location = '/parent/login'

        } else if (isTeacher) {
            // navigate("/parent/login");
            window.location = '/teacher/login'

        }

        return;
    }

    if (error.response?.data?.error) {
        return Promise.reject(error.response.data.error);
    }
    return Promise.reject(error);
});


axios.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `${getAuthToken()}`;
        config.headers['UserTimeZone'] = `${getUserTimeZone()}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getUserTimeZone = () => {
    const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
    return timeZone;
};


export const getAuthToken = () => {
    return localStorage.getItem("@Token");
};
//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;
