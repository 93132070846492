import {createContext, useContext, useState} from 'react';

const StudentContext = createContext({});

export const useStudentContext = () => {
    return useContext(StudentContext);
}

export const StudentProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({refreshList: false, openAddEdit: false});

    return <StudentContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </StudentContext.Provider>
}
