import React, {useEffect, useState} from 'react';
import {Step, StepLabel, Stepper, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import {useAppMutation} from "../../../../services";
import {useStudentContext} from "../context";
import adminServices from "../../../../services/admin-services";
import {useTranslation} from "react-i18next";




const Steps = ({}) => {

    const [stepsData, setStepsData] = useState(undefined);
    const [activeStep, setActiveStep] = React.useState(0);
    const {globalState, setGlobalState} = useStudentContext();
    const {mutate: loadStudent, isError, data, isSuccess, isLoading} = useAppMutation(adminServices.loadStudent);
    const {t} = useTranslation();
    const steps = [t('childForm.step1'),t('childForm.step2'),t('childForm.step3'),t('childForm.step4')];
    useEffect(() => {

        console.log('Steps--------', isSuccess, data)

        if (isSuccess) {

            setStepsData(data);

        } else if (isError) {

        } else if (globalState && globalState.studentId) {
            // setStepsData({})

            loadStudent(globalState.studentId);
        }

        console.log(globalState);


    }, [globalState, isSuccess, data, isError])


    const handleNext = (step) => {

        // alert(step);

        if (step === 5) {
            hideDialog(false);
        } else {
            setActiveStep(step - 1);
        }
    };

    const hideDialog = () => {
        setGlobalState({...globalState, studentId: undefined, refreshList: true, openAddEdit :  false});
    }


    return (<Div sx={{width: '100%'}}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>
        {activeStep === steps.length ? (<Div>
            <Typography sx={{mt: 2, mb: 1, justifyContent: 'center'}}>
                All steps completed - you&apos;re finished
            </Typography>

        </Div>) : (<Div>
            {activeStep + 1 === 1 && <Step1 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}
            {activeStep + 1 === 2 && <Step2 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}
            {activeStep + 1 === 3 && <Step3 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}
            {activeStep + 1 === 4 && <Step4 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                                            setStepsData={setStepsData}/>}

            {false && activeStep + 1 === 5 &&
                <Step5 handleNext={handleNext} stepsData={stepsData} setStepsData={setStepsData}/>}

        </Div>)}
    </Div>);
};

export default Steps;
