import {createContext, useContext, useState} from 'react';

const ProgramsContext = createContext({});

export const useProgramsContext = () => {
    return useContext(ProgramsContext);
}

export const ProgramsProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshProgramsList : false});

    return <ProgramsContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </ProgramsContext.Provider>
}
