import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField
} from "@mui/material";
import Select from "@mui/material/Select";
import {countryFormattingRules, PHONE_COUNTRIES} from "../../../../utils/constants/appActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});


const Step1 = ({setStepsData, hide, stepsData, handleNext}) => {

    const [values, setValues] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
        title: '',
        dateOfBirth: '',
        newPassword: '',
        showPassword: false,
    });
    const [errMsg, setErrMsg] = React.useState('');
    // const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(adminServices.completedStep1);
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);
    const {t} = useTranslation();
    useEffect(() => {
        if (stepsData) {
            const val = PHONE_COUNTRIES.find(item => item.code === stepsData.phone?.code)
            stepsData.phone.label = val?.label;
            stepsData.phone.countryCode = val?.countryCode;
            setValues({...values, ...stepsData});
        }
    }, [stepsData])


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };


    const submitStep1 = async () => {
        setErrMsg('');

        const {firstName, lastName, title, phone, newPassword} = values;
        const fullPhoneNumber = `+${phone.code}${phone.number}`;
        console.log("fullPhoneNumber", fullPhoneNumber);
        const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');

        if (!fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
            setErrMsg(t('errMsg.invalidPhone'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        setStepsData({...stepsData, firstName, lastName, title, phone, newPassword})

        handleNext(2);

    }


    const handleCountryChange = (event, newValue) => {
        console.log(newValue)
        setValues({...values, phone: newValue});
    };
    const handleEvaluator = () => {
        const newEvaluator = !values.evaluator;

        setValues((prevValues) => ({
            ...prevValues,
            evaluator: newEvaluator,
        }));
    };


    const handleApproved = () => {
        const newApproved = !values.approved;
        setValues((prevValues) => ({
            ...prevValues,
            approved: newApproved,
        }));
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangePhone = (event) => {
        let inputNumber = event.target.value;

        const sanitizedNumber = inputNumber.replace(/\D/g, '');

        let phone = sanitizedNumber;

        phone = phone.replace(/^0+/, '');

        // Get selected country code
        const selectedCountryCode = values.country.code; // Corrected this line to use `values.country.code` instead of `values.selectedCountryCode`

        // Find the formatting rule for the selected country code
        const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

        // Enforce maximum digits restriction based on country code
        if (countryFormattingRule) {
            const maxDigits = countryFormattingRule.maxDigits;

            // If the input length exceeds the maximum allowed digits, truncate the input
            if (phone.length > maxDigits) {
                phone = phone.slice(0, maxDigits);
            }

            if (phone.length === maxDigits) {
                event.preventDefault();
            }
        }

        setValues({...values, phone});
    };


    return (

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {mt: 5},
                width: '100%' // Set the width to 100%

            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Div sx={{minWidth: 100}}>

                    <FormControl sx={{
                        mt: 4,
                        ml: 1,
                        width: "21%"

                    }}>
                        <InputLabel error={errTitle}
                                    id="demo-simple-select-label">{t("teacherForm.title")}</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.title}
                            onChange={handleChange('title')}
                            label={t('teacherForm.title')}
                            error={errTitle}
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="Mr">{t('common.mr')}</MenuItem>
                            <MenuItem value="Mrs">{t('common.mrs')}</MenuItem>
                        </Select>
                    </FormControl>
                </Div>
                <Div sx={{
                    mt: -2,
                }}>

                    <FormControl sx={{
                        width: 500,
                        maxWidth: '95%',

                    }}>
                        <TextField
                            required
                            error={errFirstName}
                            label={t("common.firstName")}
                            value={values.firstName}
                            onChange={handleChange('firstName')}

                        />
                    </FormControl>

                    <FormControl sx={{
                        width: 500,
                        maxWidth: '95%',

                    }}>
                        <TextField
                            required
                            error={errLastName}
                            label={t("common.lastName")}
                            value={values.lastName}
                            onChange={handleChange('lastName')}
                        />
                    </FormControl>
                    <FormControl sx={{
                        width: '95%',

                        minWidth: '50%',

                    }}>
                        <TextField
                            disabled={true}
                            error={errFirstName}
                            label={t("common.email")}
                            value={values.email}


                        />
                    </FormControl>
                </Div>
                <Div sx={{mt: 3, mb: 1, width: '95%'}}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">{t("common.newPassword")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'newPassword'}
                            value={values.newPassword}
                            onChange={handleChange('newPassword')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t('common.newPassword')}
                            required
                        />
                    </FormControl>
                </Div>
                <Div sx={{mt: -2, mb: 2, display: 'flex', alignItems: 'center'}}>
                    <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                        {values.phone?.code && <Autocomplete
                            defaultValue={values.phone}
                            options={PHONE_COUNTRIES}
                            autoHighlight
                            onChange={handleCountryChange}
                            getOptionLabel={(option) => {
                                return ' +' + option.code;
                            }}
                            disableClearable={true}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.countryCode?.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.countryCode?.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.countryCode}(+{option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={'Code'}
                                    error={errPhone}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <React.Fragment>
                                                {values.phone && values.phone.countryCode && (
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${values.phone.countryCode?.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${values.phone.countryCode?.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                )}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />}
                    </FormControl>
                    <FormControl variant="outlined" sx={{flex: 1}}>
                        <TextField
                            required
                            id="phone"
                            label={t('common.phone')}
                            error={errPhone}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            value={values.phone?.number}
                            onChange={(event) => {
                                let inputNumber = event.target.value;

                                // Remove leading zeros
                                const sanitizedNumber = inputNumber.replace(/\D/g, '').replace(/^0+/, '');

                                let phone = values.phone;
                                phone.number = sanitizedNumber;

                                // Get selected country code
                                const selectedCountryCode = phone.code;

                                // Find the formatting rule for the selected country code
                                const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

                                // Enforce maximum digits restriction based on country code
                                if (countryFormattingRule) {
                                    const maxDigits = countryFormattingRule.maxDigits;

                                    // If the input length exceeds the maximum allowed digits, truncate the input
                                    if (phone.number.length > maxDigits) {
                                        phone.number = phone.number.slice(0, maxDigits);
                                    }

                                    // If the input length equals the maximum allowed digits, prevent further input
                                    if (phone.number.length === maxDigits) {
                                        event.preventDefault();
                                    }
                                }

                                setValues({...values, phone});
                            }}
                        />
                    </FormControl>
                </Div>
            </Div>


            {/* APPROVED AND EVALUATOR SWITCH
              <Box sx={{flexDirection: 'row', mt: 3, mb: 2, ml: -1}}>
                <FormControlLabel
                    label={t("admin-teacher.title.approved")}
                    labelPlacement="top"
                    control={
                        <Switch
                            edge="end"
                            checked={values.approved}
                            onChange={handleApproved}
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-bluetooth',
                            }}
                        />
                    }
                />

                <FormControlLabel
                    label={t("admin-teacher.title.evaluator")}
                    labelPlacement="top"
                    control={
                        <Switch
                            edge="end"
                            checked={values.evaluator}  // You may want to replace this with the actual state for the Evaluator switch
                            onChange={handleEvaluator}
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-bluetooth',
                            }}
                        />
                    }
                    sx={{ml: 5}}
                />
            </Box> */}
            <Stack sx={{width: '95%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        hide();
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.closeBtn")}
                </Button>

                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton variant={"variant"} onClick={submitStep1}>
                    {t("common.nextBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step1;
