import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import commonServices from "../../../../services/common-services";
import adminServices from "../../../../services/admin-services";
import {useTranslation} from "react-i18next";
import Select from "@mui/material/Select";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const valuetext = (value) => {
    return value;
};


const Step4 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        receivedHomeWork: '',
        autoTeacherReplacement: '',
        influencer: ''

    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    const marks = [
        {
            value: 1,
            label: (t('parent-children.title.disagree')),
        },
        {
            value: 10,
            label: (t('parent-children.title.agree')),
        },
    ];


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    useEffect(() => {

        if (stepsData) {
            setValues({...values, ...stepsData});
        }

    }, [stepsData])

    const submitStep = async () => {
        setErrMsg('');

        const {receivedHomeWork, autoTeacherReplacement, influencer} = values;


        try {
            setIsLoading(true);
            if (stepsData.newImage) {
                let b = await fetch(stepsData.newImage).then(r => r.blob());
                stepsData.imageBase64 = await commonServices.blobToBase64(b)
            }
            const v = {...stepsData, receivedHomeWork, autoTeacherReplacement, influencer}
            setStepsData(v);
            await adminServices.saveStudent(v);
            handleNext(5);
        } catch (error) {
            setErrMsg(error.message);
            setIsLoading(false);
        }


    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">


                        <Typography sx={{mt: 1, ml: 1}}
                                    variant={"h5"}>{t('student-form.workTxt')}</Typography>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            mb: 1,
                            ml: 1

                        }}>
                            <InputLabel id="demo-simple-select-label">{t('common.select')}</InputLabel>
                            <Select
                                sx={{width: '97%', minWidth: '50%'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.receivedHomeWork}
                                disabled={true}
                                onChange={handleChange('receivedHomeWork')}
                                label={'Select'}


                            >
                                <MenuItem value='yes'>{t("common.yesBtn")}</MenuItem>
                                <MenuItem value='no'>{t("common.noBtn")}</MenuItem>
                            </Select>
                        </FormControl>


                        <Typography sx={{mt: 1, ml: 1}}
                                    variant={"h5"}>{t("student-form.txt")}</Typography>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            mb: 1,
                            ml: 1

                        }}>
                            <InputLabel id="demo-simple-select-label">{t("student-form.select")}</InputLabel>
                            <Select
                                sx={{width: '97%', minWidth: '50%'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.autoTeacherReplacement}
                                disabled={true}
                                onChange={handleChange('autoTeacherReplacement')}
                                label={t("student-form.select")}


                            >
                                <MenuItem value='yes'>{t("common.yesBtn")}</MenuItem>
                                <MenuItem value='no'>{t("common.noBtn")}</MenuItem>
                            </Select>
                        </FormControl>


                        <Typography sx={{mt: 1, ml: 1}}
                                    variant={"h5"}>{t("student-form.childTxt")}</Typography>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            mb: 1,
                            ml: 1

                        }}>
                            <InputLabel id="demo-simple-select-label">{t("student-form.select")}</InputLabel>
                            <Select
                                required
                                sx={{width: '97%', minWidth: '50%'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.influencer}
                                disabled={true}
                                onChange={handleChange('influencer')}
                                label={t("student-form.select")}


                            >
                                <MenuItem value='yes'>{t("common.yesBtn")}</MenuItem>
                                <MenuItem value='no'>{t("common.noBtn")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Form>
                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        handleNext(3)
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.backBtn"))}
                </Button>

                 <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.cancelBtn"))}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                {/* <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep}>
                    {(t('common.finishBtn'))}
                </LoadingButton>
                */}
            </Div>
        </Box>);

};

export default Step4;
