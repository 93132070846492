import React, {useEffect} from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Button, Card, Dialog, DialogContent, DialogTitle, useMediaQuery} from "@mui/material";
import {useParams} from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import Sidebar from "./sidebar";
import {ChildrenProgressProvider, useChildrenProgressContext} from "./context";
import {useJumboTheme} from "@jumbo/hooks";
import Stack from "@mui/material/Stack";
import parentServices from "../../../services/parent-services";
import {useTranslation} from "react-i18next";
import ProgressScreen from './progressScreen';
import {useAppMutation} from "../../../services";
import teacherServices from "../../../services/teacher-services";
import commonServices from "../../../services/common-services";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }
    return url;
};

const BulkActions = () => {
    return <>Test</>
}

const Progress = () => {
    return <ChildrenProgressProvider><InnerProgress/></ChildrenProgressProvider>
}

const InnerProgress = () => {

    const {globalState, setGlobalState} = useChildrenProgressContext();
    const params = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        console.log("params-------",params)
    }, [params]);

    const {theme} = useJumboTheme();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));

    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(commonServices.statistics);


    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);


    const { studentId } = params;

    useEffect(() => {
        if (studentId) {
            mutate({studentId});  // Passing studentId to mutate
        }
    }, [studentId, mutate]); // Run when studentId changes

    // Log data when mutation is successful
    useEffect(() => {
        if (isSuccess && data) {
            console.log("Mutation successful, data:", data);
            setGlobalState(prevState => ({
                ...prevState,
                statisticsData: data, // Assuming you want to store the data in a key like 'statisticsData'
            }));
        }
    }, [isSuccess, data]);

    return (

        <JumboContentLayout
            layoutOptions={layoutOptions}
            sidebar={<Sidebar title={(t('parent-children.title.children'))}
                              subheader={(t('parent-children.title.childrenSub'))}/>}
            header={
                <Header title={(t('parent-children.title.progress'))}
                        subheader={(t('parent-children.title.childrenSub'))}/>
            }

        >
            <ProgressScreen studentId={studentId} isLoading={isLoading} />

        </JumboContentLayout>

    );
};

export default Progress;
