import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, Slider, TextField, Typography} from "@mui/material";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import {HOBBIES} from "../../../../utils/constants/appActions";
import {useTranslation} from "react-i18next";


const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Step2 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        hobbies: [],
        currentClass: '',
        personality: {newFriends: 1, intonation: 1, stayCalm: 1},
    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [hobbies, setHobbies] = React.useState([]);


    useEffect(() => {
        if (stepsData) {
            setValues({...values, ...stepsData});
        }

        const translatedHobbies = HOBBIES.map(hobby => ({

            key: hobby.key,
            value: t(hobby.key)
        }));
        setHobbies(translatedHobbies);
    }, [stepsData])

    const handleChange = (prop) => (event) => {
        console.log(event.target.value)
        setValues({...values, [prop]: event.target.value});
    };

    const submitStep = async () => {
        setErrMsg('');

        const {currentClass, hobbies, personality} = values;

        // handleNext(3);

        const v = {...stepsData, currentClass, hobbies, personality};
        console.log(v)

        setStepsData(v)

        handleNext(3);
    };


    const handleCountryChange = (event, newValue) => {
        setValues({...values, phone: newValue});
    };


    const marks = [
        {
            value: 1,
            label: 1,
        },
        {
            value: 10,
            label: 10,
        },
    ];

    const valuetext = (value) => {
        return value;
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting, setFieldValue}) => (
                    <Form noValidate autoComplete="off">


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '47%'},
                        }}>
                            <TextField
                                required
                                label={t("student-form.class")}
                                disabled={true}
                                value={values.currentClass}
                                onChange={handleChange('currentClass')}

                            />
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                            mb: 1,
                            ml: 1

                        }}>
                            <InputLabel id="demo-simple-select-label">{t("student-form.hobby")}</InputLabel>
                            <Select
                                required
                                sx={{width: '97%', minWidth: '50%'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.hobbies}
                                disabled={true}
                                onChange={handleChange('hobbies')}
                                label={t("student-form.hobby")}
                                multiple

                            >
                                {hobbies.map(hobby => (
                                    <MenuItem key={hobby.key} value={hobby.key}>
                                        {t(`hobbyList.${hobby.key}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            justifyContent: 'center', alignContent: 'center',
                            alignItems: 'center'

                        }}>

                            <Typography variant={"h5"} sx={{mt: 1, ml: 1}}>{t("student-form.friendTxt")}</Typography>

                            <Slider
                                sx={{width: '80%'}}
                                aria-label="Small steps"
                                value={values.personality?.newFriends}
                                disabled={true}
                                getAriaValueText={valuetext}
                                onChange={(event) => {
                                    const personality = values.personality;
                                    personality.newFriends = event.target.value;
                                    setValues({...values, personality})
                                    console.log(personality);
                                }}
                                step={1}
                                marks={marks}
                                min={1}
                                max={10}
                                valueLabelDisplay="auto"
                            />

                        </FormControl>


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            justifyContent: 'center', alignContent: 'center',
                            alignItems: 'center'

                        }}>

                            <Typography variant={"h5"} sx={{
                                mt: 1,
                                ml: 1
                            }}>{t("student-form.speakTxt")}</Typography>

                            <Slider
                                sx={{width: '80%'}}
                                aria-label="Small steps"
                                value={values.personality?.intonation}
                                disabled={true}
                                getAriaValueText={valuetext}
                                onChange={(event) => {
                                    const personality = values.personality;
                                    personality.intonation = event.target.value;
                                    setValues({...values, personality})
                                    console.log(personality);
                                }}
                                step={1}
                                marks={marks}
                                min={1}
                                max={10}
                                valueLabelDisplay="auto"
                            />

                        </FormControl>


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            justifyContent: 'center', alignContent: 'center',
                            alignItems: 'center'

                        }}>

                            <Typography variant={"h5"} sx={{
                                mt: 1,
                                ml: 1
                            }}>{t("student-form.calmTxt")}</Typography>

                            <Slider
                                sx={{width: '80%'}}
                                aria-label="Small steps"
                                value={values.personality?.stayCalm}
                                disabled={true}
                                getAriaValueText={valuetext}
                                onChange={(event) => {
                                    const personality = values.personality;
                                    personality.stayCalm = event.target.value;
                                    setValues({...values, personality})
                                    console.log(personality);
                                }}
                                step={1}
                                marks={marks}
                                min={1}
                                max={10}
                                valueLabelDisplay="auto"
                            />

                        </FormControl>

                    </Form>


                )
                }</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Button
                    onClick={() => {
                        handleNext(1)
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.backBtn"))}
                </Button>

                 <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{mr: 1}}
                >
                    {(t("common.cancelBtn"))}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep}>
                    {(t('common.nextBtn'))}
                </LoadingButton>

            </Div>
        </Box>)
        ;

};

export default Step2;
