import {createContext, useContext, useState} from 'react';

const ProfileContext = createContext({});

export const useProfileContext = () => {
    return useContext(ProfileContext);
}

export const ProfileProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({});

    return <ProfileContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </ProfileContext.Provider>
}
