import React, {useEffect, useState} from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useProgramsContext} from "../context";
import {useTranslation} from "react-i18next";

const steps1 = ['Basic Info', 'Modules', 'Segments', 'Finish'];



const Steps = ({disableSmLogin}) => {

    const [stepsData, setStepsData] = useState({modules: []});
    const [activeStep, setActiveStep] = React.useState(0);
    const {globalState, setGlobalState} = useProgramsContext();
    const {mutate: loadProgram, isError, data, isSuccess, isLoading} = useAppMutation(adminServices.loadProgram);
    const {t} = useTranslation();
    const steps = [t("admin-program.title.basicInfo"), t("admin-program.title.modules"), t("admin-program.title.finish")];
    useEffect(() => {

        console.log('Steps--------', isSuccess, data)

        if (isSuccess) {

            setStepsData(data);

        } else if (isError) {

        } else if (globalState && globalState.programId) {

            loadProgram(globalState.programId);
        }

        console.log(isError,data);


    }, [globalState, isSuccess, data, isError])


    const handleNext = (step) => {

        // alert(step);

        if (step === 5) {
            hideDialog();
        } else {
            setActiveStep(step - 1);
        }
    };


    const hideDialog = () => {
        setGlobalState({...globalState, programId: undefined, refreshProgramsList: true, openAddEditProgram: false});
    }


    // width: {xs: '100%', sm: '100%', xl: '50%'}

    return (<Div sx={{width: '100%', minHeight: 500, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>
        <Div>
            {activeStep + 1 === 1 &&
                <Step1 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                       setStepsData={setStepsData}/>}
            {activeStep + 1 === 2 &&
                <Step2 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                       setStepsData={setStepsData}/>}
            {activeStep + 1 === 9 &&
                <Step3 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                       setStepsData={setStepsData}/>}
            {activeStep + 1 === 3 &&
                <Step4 hideDialog={hideDialog} handleNext={handleNext} stepsData={stepsData}
                       setStepsData={setStepsData}/>}

        </Div>
    </Div>);
};

export default Steps;
